import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Switch, Input } from 'antd';

import _debounce from 'lodash/debounce';

import {
  getProjectById,
  editProject,
  updateProjectPreferences,
  updateProject,
  deleteProject,
  toggleCustomFieldsModal,
  toggleDuplicateProjectModal,
  leaveProject,
  toggleWorkspaceFieldsModal
} from '../../../actions';
import Button from "../atoms/global/Button";

import ProjectCustomFields from '../molecules/project-dashboard/ProjectCustomFields';
import ConfirmationModal from '../molecules/modals/ConfirmationModal';
import { FormattedMessage, useIntl } from 'react-intl';

const ProjectSettings = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { project } = props;

  const [name, setName] = useState(project?.title);
  const me = project?.participants?.find(member => member._id === props?.user._id);

  const [isOwnerOrAdmin, setIsOwnerOrAdmin] = useState(me?.role == 'owner' || me?.role == 'admin')

  useEffect(() => {
    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });
    document
      .querySelector(`.settingsTopNavbarLink`)
      ?.classList?.add('topNavBar-activeLink');
  }, []);

  useEffect(() => {
    if (!project?.participants) {
      const { 1: projectId, 2: path } = window.location.pathname.split("/");
      dispatch(getProjectById(project?._id || projectId));
    }
    const me = project?.participants?.find(member => member._id === props?.user._id);
    setIsOwnerOrAdmin(me?.role == 'owner' || me?.role == 'admin')
  }, [project?._id]);

  const toggleProjectAccess = async () => {
    const access = project?.access == "private" ? "public" : "private";
    await dispatch(editProject({ _id: project._id, access }));
  };


  const updateProjPreferences = async () => {
    await dispatch(updateProjectPreferences({ ...me, notifications: !me?.notifications, projectId: project?._id }));
  }

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLeaveProject, setIsLeaveProject] = useState(false);

  const updateProjectInfo = useCallback(
    _debounce((value) => {
      dispatch(updateProject({ _id: project?._id, title: value }));
    }, 800),
    []
  );

  return (
    <div style={{ margin: 20 }}>
      <ConfirmationModal
        title={isLeaveProject
          ? <FormattedMessage id="LEAVE_PROJECT_CONFIRMATION" defaultMessage="Leave Project?" />
          : <FormattedMessage id="DELETE_PROJECT_CONFIRMATION" defaultMessage="Delete Project?" />
        }
        description={isLeaveProject
          ? <FormattedMessage id="LEAVE_PROJECT_CONFIRMATION_DESCRIPTION" defaultMessage="Are you sure you want to leave {projectTitle}?" values={{ projectTitle: project?.title }} />
          : <FormattedMessage id="DELETE_PROJECT_CONFIRMATION_DESCRIPTION" defaultMessage="Are you sure you want to delete {projectTitle}?" values={{ projectTitle: project?.title }} />}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={() => {
          if (isLeaveProject) {
            dispatch(leaveProject({ projectId: project?._id }))
          } else {
            dispatch(deleteProject(project?._id));
          }
          setOpen(false);
          navigate('/', { replace: true });
        }}
      />
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 font-bold mb-5">
          <FormattedMessage id="PROJECT_SETTINGS" defaultMessage="Project Settings" />
        </h2>
        {/* General */}
        <section>
          <h3 className="text-xl leading-snug text-slate-800 font-bold mb-1">
            <FormattedMessage id="GENERAL" defaultMessage="General" />
          </h3>
          <ul>
            {isOwnerOrAdmin && <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="PROJECT_NAME" defaultMessage="Project Name" />
                </div>
                <div className='flex align-center'>
                  <Input
                    className='my-4 mr-4 h-9'
                    style={{
                      borderRadius: '7px',
                      width: '50vw'
                    }}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <div className='cursor-pointer px-3 py-1.5 bg-indigo-400 hover:bg-indigo-300 text-white rounded font-16' onClick={() => updateProjectInfo(name)}>
                    <FormattedMessage id="UPDATE" defaultMessage="Update" />
                  </div>
                </div>
              </div>
            </li>}
            <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="NOTIFICATIONS" defaultMessage="Notifications" />
                </div>
                <div className="text-sm">
                  <FormattedMessage id="RECEIVE_NOTIFICATIONS_DESCRIPTION" defaultMessage="Receive notifications for project updates" />
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <div className="text-sm text-slate-400 italic mr-2">{me?.notifications
                  ? <FormattedMessage id="ENABLED" defaultMessage="Enabled" />
                  : <FormattedMessage id="DISABLED" defaultMessage="Disabled" />
                }</div>
                <div className="form-switch">
                  <Switch checked={me?.notifications} onClick={updateProjPreferences} />
                </div>
              </div>
            </li>
            {isOwnerOrAdmin && <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="PUBLIC_PRIVATE" defaultMessage="Public / Private" />
                </div>
                <div className="text-sm">
                  <FormattedMessage id="PUBLIC_PRIVATE_DESCRIPTION" defaultMessage="Anyone can join this project if marked public" />
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <div className="text-sm text-slate-400 italic mr-2">{project?.access !== "public"
                  ? <FormattedMessage id="PRIVATE" defaultMessage="Private" />
                  : <FormattedMessage id="PUBLIC" defaultMessage="Public" />
                }</div>
                <div className="form-switch">
                  <Switch checked={project?.access !== "public"} onClick={() => toggleProjectAccess()} />
                </div>
              </div>
            </li>}
            {isOwnerOrAdmin && <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="DUPLICATE_PROJECT" defaultMessage="Duplicate Project" />
                </div>
                <div className="text-sm">
                  <FormattedMessage
                    id="CREATE_A_COPY_OF_PROJECT"
                    defaultMessage="Create a copy of project <span>{projectTitle}</span>"
                    values={{
                      span: (msg) => (<span className='text-indigo-600 font-bold'>{msg}</span>),
                      projectTitle: project?.title
                    }}
                  />
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <Button
                  name={intl.formatMessage({ id: 'DUPLICATE_PROJECT', defaultMessage: 'Duplicate Project' })}
                  src="Refresh.svg"
                  height="16px"
                  class={`font-14 text-indigo-500 hover:text-indigo-300 ${isLoading && 'disabled'}`}
                  onClick={() => {
                    dispatch(toggleDuplicateProjectModal(true, project));
                  }}
                  style={{ width: 'max-content', height: 10 }}
                />
              </div>
            </li>}
            <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="LEAVE_PROJECT" defaultMessage="Leave Project" />
                </div>
                <div className="text-sm">
                  <FormattedMessage id="LEAVE_PROJECT_DESCTIPTION" defaultMessage="Leave this project, you can always join again!" />
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <Button
                  name={intl.formatMessage({ id: 'LEAVE_PROJECT', defaultMessage: 'Leave Project' })}
                  src="Exit.svg"
                  height="16px"
                  class="font-14 text-rose-500"
                  onClick={() => {
                    setIsLeaveProject(true);
                    setOpen(true);
                  }}
                  style={{ width: 'max-content', height: 10 }}
                />
              </div>
            </li>
            {isOwnerOrAdmin && <li className="flex justify-between items-center py-3 border-b border-slate-200">
              {/* Left */}
              <div>
                <div className="text-slate-800 font-semibold">
                  <FormattedMessage id="DELETE_PROJECT" defaultMessage="Delete Project" />
                </div>
                <div className="text-sm">
                  <FormattedMessage id="DELETE_PROJECT_DESCRIPTION" defaultMessage="Remove this project from your team and workspace" />
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center ml-4">
                <Button
                  name={intl.formatMessage({ id: 'DELETE_PROJECT', defaultMessage: 'Delete Project' })}
                  src="delete-red.svg"
                  height="16px"
                  class="font-14 text-rose-500"
                  onClick={() => setOpen(true)}
                  style={{ width: 'max-content', height: 10 }}
                />
              </div>
            </li>}
          </ul>
        </section>

        {isOwnerOrAdmin && <section>
          <div className="flex justify-between items-center py-3">
            {/* Left */}
            <h3 className="text-xl leading-snug text-slate-800 font-bold mb-1"><FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" /></h3>           
          </div>
          <ProjectCustomFields project={project} fields={props?.fields} workspace={props?.workspace} customFields={props?.customFields} />
        </section>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.getProjects,
  user: state.userReducer.user,
  fields: state.aiReducer.customFieldSuggestions,
  customFields: state.projectReducer.projectCustomFields,
  workspace: state.workspaceReducer.currentWorkspace
});

export default connect(mapStateToProps)(ProjectSettings);
