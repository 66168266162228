import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import { addWorkspace, editUser, addProject, getUser, getProjectTemplates } from '../../../actions';
import { useEffect } from 'react';
import { logout } from '../../../actions/logoutAction';
import { useAuth0 } from '@auth0/auth0-react';

import ProductNavBar from '../../UI/molecules/upper-navbar/ProductNavBar';
import StepOne from '../molecules/onboarding/StepOne';
import StepTwo from '../molecules/onboarding/StepTwo';
import StepThree from '../molecules/onboarding/StepThree';
import StepFour from '../molecules/onboarding/StepFour';
import { notification, Popover } from 'antd';
import LastStep from '../molecules/onboarding/LasStep';
import { getSectionColor } from '../../../js/helper';
import { FormattedMessage, useIntl } from 'react-intl';

const CreateWorkspace = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { logout: auth0Logout } = useAuth0();

  const user = useSelector((state) => state.userReducer.user);
  const { workspaces, templates } = props;

  const fullName = user?.fullName;
  const name = fullName?.split(' ')?.[0] || fullName;

  const [workspaceTitle, setWorkspaceTitle] = useState('');
  const [workspaceDesc, setWorkspaceDesc] = useState('');
  const [projectName, setProjectName] = useState('Project Name');
  const [selectedTemplate, setSelectedTemplate] = useState(templates?.find(t => t?.default));

  const [isCreating, setIsCreating] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getProjectTemplates());
  }, []);

  useEffect(() => {
    if (props.unauthorized) {
      setIsCreating(false);
      localStorage.removeItem('token');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('organization');
      localStorage.removeItem('toggle');
      localStorage.removeItem('inviteUrl');
      localStorage.removeItem('Id');

      sessionStorage.removeItem('workspaceId');
      sessionStorage.removeItem('organization');

      dispatch(logout());
      auth0Logout({
        logoutParams: { returnTo: `${window.location.origin}/signin` },
      });
      window.location.href = '/signin';
    }
  }, [props.unauthorized]);

  const handleWorkspaceInput = (e) => {
    setWorkspaceTitle(e.target.value);
  };

  const handleWorkspaceDecInput = (e) => {
    setWorkspaceDesc(e.target.value);
  };

  const handleCreateWorkspace = async () => {
    if (!isCreating) {
      setIsCreating(true);
      //TODO: @Osama we may need a dropdown on Create workspace screen where we ask user to specify organization.
      // Here I am passing the first organization from user.organizations array.
      const organizationId =
        user?.organizations?.length > 0 ? user?.organizations[0]?._id : null;
      await dispatch(
        addWorkspace(
          {
            title: workspaceTitle || 'My workspace',
            description: workspaceDesc,
            invitees: [],
            organizationId,
          },
          async (workspaceDetils) => {
            setIsCreating(false);
            try {
              await dispatch(
                addProject({
                  title: projectName,
                  workspace: workspaceDetils?._id,
                  template: selectedTemplate?._id || templates?.[0]?._id,
                  access: isPrivate ? 'private' : 'public',
                })
              );
            } catch (error) {
              notification.error({
                message: intl.formatMessage({ id: 'EH_OH', defaultMessage: 'Eh oh!' }),
                placement: 'bottomRight',
              });
            }
          }
        )
      );
      setCurrentStep(4);
      // @Osama, this will set onboarding to complete on each workspace creation. We only need to do this on first workspace creation.
    }
  };
  return (
    <main className="bg-white overflow-hidden">
      <ProductNavBar button="Back to Workflo"
        onClick={() => {
          if (workspaces.length === 0) {
            handleCreateWorkspace();
          }
        }}
      />
      <div className="d-flex create-workspace" style={{ height: '100vh' }}>
        <div className="w-full md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              {/* Progress bar */}
              <div className="px-4 pt-12 my-16 pb-8">
                <div className="max-w-md mx-auto w-full">
                  <div className="relative">
                    <div
                      className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200"
                      aria-hidden="true"
                    ></div>
                    <ul className="relative flex justify-between w-full">
                      <li>
                        <div
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-blue-500 text-white"
                          to="/onboarding-01"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${currentStep > 1
                            ? 'bg-blue-500 text-white'
                            : 'bg-slate-100 text-slate-500'
                            }`}
                          to="/onboarding-02"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${currentStep > 2
                            ? 'bg-blue-500 text-white'
                            : 'bg-slate-100 text-slate-500'
                            }`}
                          to="/onboarding-03"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${currentStep > 3
                            ? 'bg-blue-500 text-white'
                            : 'bg-slate-100 text-slate-500'
                            }`}
                          to="/onboarding-04"
                        >
                          4
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {currentStep == 1 && (
              <StepOne
                handleClick={() => workspaceTitle && setCurrentStep(2)}
                onChange={handleWorkspaceInput}
                workspaceTitle={workspaceTitle}
              />
            )}
            {/* {currentStep == 2 && (
              <StepTwo
                handleClick={() => setCurrentStep(3)}
                onChange={handleWorkspaceDecInput}
                workspaceDesc={workspaceDesc}
                onBack={() => setCurrentStep(1)}
              />
            )} */}
            {currentStep == 2 && (
              <StepThree
                handleClick={() => setCurrentStep(3)}
                setIsPrivate={setIsPrivate}
                onChange={(e) => {
                  if (e?.target?.value) {
                    setProjectName(e?.target?.value);
                  } else if (typeof e === 'string') {
                    setProjectName(e);
                  }
                }}
                onBack={() => setCurrentStep(1)}
              />
            )}
            {currentStep == 3 && (
              <StepFour
                handleClick={() => selectedTemplate && handleCreateWorkspace()}
                onBack={() => setCurrentStep(2)}
                templates={templates}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                isCreating={isCreating}
              />
            )}
            {currentStep == 4 && (
              <LastStep
                handleClick={() => navigate('/', { replace: true })}
                name={fullName}
              />
            )}
          </div>
        </div>
        <div
          style={{ background: '#F3F3F3', width: '60%' }}
          className="flex right-panel pt-20 pl-20"
        >
          <div className="flex mt-20 ml-20 w-full">
            <div
              className="flex justify-center h-full w-32"
              style={{ background: '#202c3e' }}
            >
              <div className="h-10 w-10 bg-white mt-7 border-radius10 flex align-center justify-center">
                {workspaceTitle?.substring(0, 2)?.toUpperCase()}
              </div>
            </div>
            <div className="w-full">
              <div className="flex h-full w-full">
                {currentStep !== 3 && <div
                  className="flex flex-col align-center h-full w-96 text-white"
                  style={{ background: '#1e293b' }}
                >
                  <div className="font-bold font-20 mt-9">
                    {workspaceTitle?.substring(0, 20) || intl.formatMessage({ id: 'NAME_APPEARS_HERE', defaultMessage: 'Name appears here' })}
                  </div>
                  {currentStep >= 2 && (
                    <div className="mt-40 flex align-center">
                      <Popover placement="right" content={intl.formatMessage({ id: 'ALL_PROJECTS_WILL_BE_SHOWN_HERE', defaultMessage: 'All your projects will be shown here' })} open={currentStep === 2}>
                        <div className="">{projectName?.substring(0, 20)}</div>
                      </Popover>
                    </div>
                  )}
                </div>
                }
                <div className="h-full bg-slate-100 w-full">
                  <div className="flex align-center justify-center w-full bg-white h-20">
                    <div className="search" />
                  </div>
                  <div className="flex flex-col align-center p-10">
                    {currentStep == 3 && selectedTemplate ?
                      <div className="max-w-[750px]">
                        <label className='font-20 font-bold'>
                          <FormattedMessage id="PREVIEW_OF_PROJECT_SECTIONS" defaultMessage="Preview of project sections" />
                        </label>
                        <div className="d-flex mt-4 overflow-scroll w-full no-scrollbar">
                          {selectedTemplate.sections?.map((section, i) => {
                            const color = getSectionColor(i);
                            return (
                              <div style={{
                                marginRight: 15,
                                background: color?.bg,
                                borderRadius: 12,
                                padding: 10,
                                border: `1px solid ${color?.border}`,
                                height: '60vh',
                              }}>
                                <div style={{ minWidth: 180, fontSize: 16 }} className="mini-section">
                                  <FormattedMessage id={`${section.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={section.title} />
                                </div>
                              </div>
                            )
                          }
                          )}
                        </div>
                      </div>
                      : <div className="bg-indigo-200 w-full p-5 font-18">
                        <FormattedMessage id="WELCOME" defaultMessage="Welcome to Workflo 🎉" />
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    workspaces: state.workspaceReducer.getWorkspaces,
    unauthorized: state.userReducer.unauthorized,
    templates: state.projectReducer.publicTemplates,
  };
}

export default connect(mapStateToProps)(CreateWorkspace);
