import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import SubNavBar from '../UI/molecules/upper-navbar/SubNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectById, getSingleMessage, setTasks } from '../../actions';
import TaskBoard from '../UI/organisms/TaskBoard';
import TaskListView from '../UI/organisms/TaskListView';
import { isChatOpen as chatOpener, unsetActiveProject, getTasks, isTicketOpen as ticketOpener, getProjectCustomField } from '../../actions';
import { APPLICATION_NAME } from '../../js/constants';

const TaskProject = (props) => {
  const dispatch = useDispatch();
  // const params = useParams();
  const navigate = useNavigate();

  const projectData = useSelector((state) => state.projectReducer.getProjects);
  const isChatOpen = useSelector((state) => state.toggleReducer.isChatOpen);
  const isTicketOpen = useSelector((state) => state.toggleReducer.isTicketOpen);
  const { 1: projectId, 2: path } = window.location.pathname.split("/");

  const [showSection, setShowSection] = useState(false);

  const isListView = path === 'list';
  const isBoardView = path === 'board';

  useEffect(() => {
    dispatch(getProjectById(projectId)).then(res => {
      if (res >= 400) {
        navigate('/', { replace: true });
      } else {
        const existingProjects = props.lruProjectTasks || {};
        const tasks = existingProjects[projectId];
        if (tasks) {
          dispatch(setTasks(tasks));
        } else {
          dispatch(getProjectCustomField(projectId));
          dispatch(getTasks(projectId));
        }
      }
    });
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/signin');
    }

    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });

    if (isListView) {
      document
        .querySelector(`.listTopNavbarLink`)
        ?.classList?.add('topNavBar-activeLink');
    } else if (isBoardView) {
      document
        .querySelector(`.boardTopNavbarLink`)
        ?.classList?.add('topNavBar-activeLink');
    }

  }, [navigate]);

  useEffect(() => {
    if (isTicketOpen) {
      dispatch(chatOpener(false));
    }
  }, [isTicketOpen]);

  dispatch(unsetActiveProject());

  document.title = `${projectData?.title} - ${APPLICATION_NAME}` || APPLICATION_NAME;

  return (
    <>
      {localStorage.getItem('token') && (
        <div className='w-70 h-[92.5%]'>
          <SubNavBar
            project={projectData}
            user={props?.user}
            showFilters={true}
            setShowSection={setShowSection}
            isMobile={props.isMobile} />
          {isBoardView ?
            <TaskBoard setShowSection={setShowSection} showSection={showSection} /> :
            <TaskListView setShowSection={setShowSection} showSection={showSection} />
          }
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    toggle: state.navReducer.toggle,
    teamDrawerState: state?.toggleReducer?.teamDrawerState,
    user: state.userReducer.user,
    isMobile: state.toggleReducer.isMobile,
    lruProjectTasks: state.taskReducer.lruProjectTasks
  };
}
export default connect(mapStateToProps)(TaskProject);
