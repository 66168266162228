import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  notification,
  Dropdown,
  Tooltip
} from 'antd';

import {
  isTicketOpen,
  editTask,
  clearSingleTask
} from '../../../../actions';
import Icon from '../../atoms/global/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { showTaskAsModal } from '../../../../actions/toggleAction';

import { FormattedMessage, useIntl } from 'react-intl';

const TaskNavSection = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    task,
    setDeleteModalOpen,
    isModal = false,
    setIsModalView,
    setSelectedTab
  } = props;

  const [isComplete, setIsComplete] = useState(task.isCompleted);
  const navigate = useNavigate();
  const workspaceId = sessionStorage.getItem("workspaceId");


  const closeTicket = () => {
    dispatch(isTicketOpen(false));
    const params = new URLSearchParams(location.search);
    params.delete('selectedTask'); // Remove the specified parameter

    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);
    dispatch(clearSingleTask());
  };

  useEffect(() => {
    setIsComplete(task?.isCompleted);
  }, [task]);

  const collaboratorsIds = task.participants && task.participants.map((member) => member._id);

  const items = [
    {
      key: '2',
      label:
        <div
          className='flex align-center p-2 font-14'
          onClick={() => {
            setSelectedTab('3');
          }}>
          <Icon
            className={'hover:opacity-60'}
            name='share.svg'
            height='15px'
            pr='13px'
          />
          <div><FormattedMessage id="ADD_SUBTASK" defaultMessage="Add Subtask" /></div>
        </div>,
    },
    {
      key: '3',
      label:
        <div
          className='flex align-center p-2 font-14'
          onClick={() => {
            dispatch(showTaskAsModal(!isModal));
            setIsModalView(!isModal);
          }}>
          <Icon
            className={'hover:opacity-60'}
            name='double-arrow.svg'
            height='16px'
            pr='13px'
          />
          <div>
            {
              isModal
                ? <FormattedMessage id="OPEN_SIDE_PANEL" defaultMessage="Open Side Panel" />
                : <FormattedMessage id="OPEN_FULLSCREEN" defaultMessage="Open Fullscreen" />
            }
          </div>
        </div>,
    },
    {
      key: '4',
      label:
        <div
          className='flex align-center p-2 font-14'
          onClick={() => setDeleteModalOpen(true)}>
          <Icon
            className={'hover:opacity-60'}
            name='delete-red.svg'
            height='20px'
            pr='13px'
          />
          <div><FormattedMessage id="DELETE_TASK" defaultMessage="Delete Task" /></div>
        </div>,
      danger: true
    },
  ];

  return (
    <div className='user-panel'>
      <div className='d-flex justify-content-between' style={{ padding: isModal ? 20 : '10px 15px', borderBottom: '1px solid #F3F3F3' }}>
        <div className='d-flex align-center'>
          <div className='font-16 mr-3'>
            {task && task?.titleIdentifier}
          </div>

          <div className={`d-flex btn-2 lg:pl-2 ${isComplete && 'border-emerald-500 hover:border-emerald-400'}`}>
            <div
              className='d-flex align-center'
              onClick={async () => {
                const newStatus = !isComplete;
                setIsComplete(newStatus);
                dispatch(
                  editTask(
                    {
                      _id: task._id,
                      isCompleted: newStatus,
                      collaborators: collaboratorsIds,
                    },
                    null
                  )
                );
              }}>
              <svg className={`w-5 h-6 fill-current ${isComplete && 'text-emerald-500'} shrink-0`} viewBox="0 0 16 16">
                <path d="m2.457 8.516.969-.99 2.516 2.481 5.324-5.304.985.989-6.309 6.284z" />
              </svg>
              <div style={{ color: isComplete ? '#51CF73' : '#434343' }} className='lg:ml-1 mark-as-button font-14'>
                {!isComplete
                  ? <FormattedMessage id="MARK_COMPLETE" defaultMessage="Mark Complete" />
                  : <FormattedMessage id="COMPLETED" defaultMessage="Completed" />
                }
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-center' style={{ position: 'relative' }}>

          <CopyToClipboard text={`${process.env.REACT_APP_URL_PREFIXED || process.env.REACT_APP_URL}/${workspaceId?.toString()}?selectedTask=${task?.titleIdentifier}`}>
            <Tooltip title={intl.formatMessage({ id: 'COPY_TASK_LINK', defaultMessage: 'Copy Task Link' })}>
              <>
                <Icon
                  name='copy.svg'
                  height='20px'
                  className='mx-2 hover:opacity-60'
                  onClick={() => {
                    notification.success({
                      message: intl.formatMessage({ id: 'LINK_COPIED', defaultMessage: 'Link copied to clipboard' }),
                      placement: 'bottomRight',
                    });
                  }}
                />
              </>
            </Tooltip>
          </CopyToClipboard>
          <Tooltip title={intl.formatMessage({ id: 'ATTACH_FILE', defaultMessage: 'Attach File' })}>
            <>
              <Icon
                name='ticket-pin.svg'
                height='20px'
                className='mx-2 mr-4 hover:opacity-60'
                onClick={() => document.getElementById('file-input')?.click()}
              />
            </>
          </Tooltip>
          <div className='mr-5'>
            {!isModal ? <Dropdown
              menu={{
                items,
              }}
              placement='bottomRight'
              trigger={['click']}
              arrow
            >
              <Tooltip title={intl.formatMessage({ id: 'MORE_OPTIONS', defaultMessage: 'More options' })}>
                <img
                  className='hover:opacity-60'
                  src={'/images/icons/threeDots.svg'}
                  style={{
                    cursor: 'pointer',
                    height: '16px',
                    width: '16px',
                  }}
                  alt='icon'
                />
              </Tooltip>
            </Dropdown> :
              <div className='flex align-center'>
                <Tooltip title={intl.formatMessage({ id: 'ADD_SUBTASK', defaultMessage: 'Add Subtask' })}>
                  <>
                    <Icon
                      className={'hover:opacity-60'}
                      onClick={() => setSelectedTab('3')}
                      name='share.svg'
                      height='16px'
                      pr='13px'
                    />
                  </>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'MOVE_TO_SIDEVIEW', defaultMessage: 'Move to sideview' })}>
                  <>
                    <Icon
                      className={'hover:opacity-60'}
                      onClick={() => {
                        dispatch(showTaskAsModal(!isModal));
                        setIsModalView(!isModal);
                      }}
                      name='double-arrow.svg'
                      height='16px'
                      pr='16px'
                    />
                  </>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'DELETE_TASK', defaultMessage: 'Delete Task' })}>
                  <>
                    <Icon
                      className={'hover:opacity-60'}
                      name='delete-red.svg'
                      onClick={() => setDeleteModalOpen(true)}
                      height='24px'
                      pr='5px'
                    />
                  </>
                </Tooltip>
              </div>}
          </div>
          <Tooltip title={intl.formatMessage({ id: 'CLOSE', defaultMessage: 'Close' })} className='mr-6'>
            <>
              <Icon
                className={'hover:opacity-60'}
                name='close.svg'
                height='14px'
                onClick={() => {
                  closeTicket();
                }}
              />
            </>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default TaskNavSection;
