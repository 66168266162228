import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Resizable } from 're-resizable';
import { useNavigate, useParams } from 'react-router-dom';

import Select from 'react-select';
import { notification, Tooltip } from 'antd';

import {
  isTicketOpen,
  setDrawerWidth,
  isChatSettingsOpen,
  addChatRoom,
  updateChatRoomPreferences,
  addUserToChat,
  removeUserFromChat,
  getChatRoomFiles,
  exitFromChatRoom,
} from '../../../actions';

import { setRoomId } from '../../../actions/roomAction';

import {
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
} from '../../../actions/types/chat.types';

import { updateChatRoomMessagesReadBy } from '../../../actions/chatRoomAction';

import {
  clearMessagesState,
  updateQueryState,
} from '../../../actions/messageAction';

import Icon from '../atoms/global/Icon';
import ConfirmationModal from '../molecules/modals/ConfirmationModal';
import RoomPicture from '../molecules/global/RoomPicture';
import TaskFileHolder from '../organisms/TaskFileHolder';

import AssigneeOptionLabel from '../atoms/ticket-drawer/AssigneeOptionLabel';
import { getDateTextLongFormat } from '../../../js/functions';
import { MemberActionsMenu } from '../molecules/right-click-menu/MemberActionsMenu';
import {
  isMemberDrawerOpen,
  isUserDrawerOpen,
} from '../../../actions/toggleAction';
import UserAvatar from '../atoms/ticket-drawer/UserAvatar';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatActions = ({ item }) => {
  if (!item?.title || !item?.description) return null;

  return (
    <div
      className={`flex flex-row gap-2 md:pr-0 pr-3 items-center border-b border-b-[#f2f2f2] md:py-4 py-2 ${
        item.icon?.type === 'p' ? '' : 'hover:opacity-60 cursor-pointer'
      }`}
      onClick={item.onPress}
    >
      {item.isAction ? (
        <img
          src={`/images/icons/${item.icon}`}
          className="md:size-11 size-8 cursor-pointer"
        />
      ) : (
        <div className="cursor-default">{item.icon}</div>
      )}
      <div>
        <p className="font-semibold md:text-lg text-md">{item.title}</p>
        <p className="md:text-md text-sm">{item.description}</p>
      </div>
    </div>
  );
};

const UserChatPanel = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const {
    customClass,
    drawerWidth,
    allUsers,
    senderId,
    chats,
    me,
    currentWorkspace,
  } = props;

  const resizableRef = useRef(null);
  const ticketDrawerRef = useRef(null);

  const chat =
    chats &&
    chats.find((chat) =>
      senderId ? senderId == chat?._id : chat._id === param.chatId
    );
  const user = allUsers?.find((user) => user?._id == senderId);

  let membersNotInChat = [];
  const [chatMembers, setChatMembers] = useState([]);

  if (chatMembers) {
    membersNotInChat = currentWorkspace?.members.filter(
      (wMember) => !chatMembers.some((member) => member._id === wMember._id)
    );
  }

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [assigneeValue, setAssigneeValue] = useState([]);
  const [openMenuMemberId, setOpenMenuMemberId] = useState(null);
  const menuRefs = useRef({});

  const toggleMenu = (memberId) => {
    setOpenMenuMemberId((prev) => (prev === memberId ? null : memberId));
  };

  useEffect(() => {
    if (!user && !chat) {
      dispatch(isChatSettingsOpen(false));
    }

    if (chat?._id) {
      dispatch(getChatRoomFiles(chat?._id)).then((files) => {
        setFiles(files);
      });

      setChatMembers(chat?.members);
    }
  }, [user, chat]);

  const updateChatPreferences = () => {
    dispatch(
      updateChatRoomPreferences({
        notifications: !chat?.notifications,
        chatId: chat?._id,
      })
    );
  };

  const openChat = async (userId) => {
    const existingChat = chats?.find(
      (chat) =>
        chat?.roomType === 'direct' &&
        chat?.members?.find((mem) => mem?._id === userId)
    );

    if (existingChat) {
      // navigate to chat
      dispatch(updateQueryState(1));
      dispatch(clearMessagesState());
      dispatch(setRoomId(existingChat?._id));
      dispatch({ type: RESET_COUNTER, payload: existingChat?._id });
      navigate(`/chat/${existingChat?._id}`, { replace: true });
      dispatch({
        type: UPDATE_CURRENT_RECEIVER,
        payload: existingChat?.roomName,
      });

      dispatch(updateChatRoomMessagesReadBy(existingChat?._id, 'ChatsNavBar1'));
    } else {
      // create Chat
      const participants = [userId];
      dispatch(
        addChatRoom({
          members: participants,
          roomName: null,
          workspaceId: currentWorkspace?._id,
        })
      );
    }

    dispatch(isChatSettingsOpen(false));
  };

  const isDirectChat = chat?.roomType == 'direct';
  const directMessageRecipient =
    isDirectChat && chat?.members?.find((member) => member._id !== user?._id);

  const isMe = me?._id == user?._id;
  const header = user
    ? `${intl.formatMessage({ id: 'USER_INFO', defaultMessage: 'User Info' })} ${isMe ? '( ' + intl.formatMessage({ id: 'ME', defaultMessage: 'Me' }) +' )' : ''}`
    : `${intl.formatMessage({ id: `${chat?.roomType}_CHAT_TYPE`.toUpperCase(), defaultMessage: chat?.roomType})} ${intl.formatMessage({ id: 'CHAT_SETTINGS', defaultMessage: 'Chat - Settings' })}`;
  const cardImage = user?.avi || chat?.roomAvi || directMessageRecipient?.avi;

  const actions = [
    {
      icon: 'member.svg',
      title: showAddMember
        ? intl.formatMessage({ id: 'VIEW_MEMBERS', defaultMessage: 'View Members' })
        : intl.formatMessage({ id: 'ADD_MEMBERS', defaultMessage: 'Add Members' }),
      description: showAddMember
      ? intl.formatMessage({ id: 'VIEW_MEMBERS_DESCRIPTION', defaultMessage: 'View members from your chat' })
      : intl.formatMessage({ id: 'ADD_MEMBERS_DESCRIPTION', defaultMessage: 'Add members from your workspace to this chat' }),
      onPress: () => {
        setShowAddMember(true);
      },
      isAction: true,
    },
    !isDirectChat && {
      icon: 'deleteMessageBin.svg',
      title: intl.formatMessage({ id: 'EXIT_CHAT', defaultMessage: 'Exit Chat' }),
      description: intl.formatMessage({ id: 'EXIT_CHAT_DESCRIPTION', defaultMessage: 'Remove this chat from your workspace' }),
      onPress: () => {
        setIsExitModalOpen(true);
      },
      isAction: true,
    },
    {
      icon: (
        <p
          className={`md:text-5xl text-4xl ${
            showAddMember
              ? membersNotInChat?.length < 10
                ? 'mr-3'
                : ''
              : chatMembers?.length < 10
              ? 'mr-3'
              : ''
          }`}
        >
          {showAddMember ? membersNotInChat?.length : chatMembers?.length}
        </p>
      ),
      title: showAddMember
        ? intl.formatMessage({ id: 'MEMBERS_NOT_IN_CHAT', defaultMessage: 'Members not in chat' })
        : intl.formatMessage({ id: 'MEMBERS', defaultMessage: 'Members' }),
      description: showAddMember
        ? intl.formatMessage({ id: 'SELECT_MEMBERS_TO_ADD', defaultMessage: 'Select individual members to add to chat' })
        : intl.formatMessage({ id: 'SELECT_MEMBERS_FOR_MORE_DETAILS', defaultMessage: 'Select individual members for more details' }),
      onPress: () => {},
      isAction: false,
    },
  ];

  const cardImageRenderer = useCallback(() => {
    switch (true) {
        case !!user?.avi: {
            return (
                <UserAvatar
                    classNames="rounded-full md:size-[100px] size-[50px] object-cover"
                    fullName={user?.fullName}
                    userId={user?._id}
                    src={user?.avi}
                />
            );
        }
        case !!directMessageRecipient?.avi: {
            return (
                <UserAvatar
                    classNames="rounded-full md:size-[100px] size-[50px] object-cover"
                    fullName={directMessageRecipient?.fullName}
                    userId={directMessageRecipient?._id}
                    src={directMessageRecipient?.avi}
                />
            );
        }
        default: {
            return (
                <img
                    src={cardImage}
                    className="rounded-full md:size-[100px] size-[50px] object-cover"
                />
            );
        }
    }
  }, [cardImage, directMessageRecipient, user]);

  return (
    <>
      <ConfirmationModal
        title={`${intl.formatMessage({ id: 'DELETE_TASK', defaultMessage: 'Delete Task' })}?`}
        description={intl.formatMessage({ id: 'DELETE_TASK_CONFIRMATION', defaultMessage: 'Are you sure you want to delete this task?' })}
        isModalOpen={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onYesClick={() => {}}
      />
      <ConfirmationModal
        title={`${intl.formatMessage({ id: 'EXIT_CHAT', defaultMessage: 'Exit Chat' })}?`}
        description={intl.formatMessage({ id: 'EXIT_CHAT_CONFIRMATION', defaultMessage: 'Are you sure you want to exit this chat?' })}
        isModalOpen={isExitModalOpen}
        onCancel={() => setIsExitModalOpen(false)}
        onYesClick={() => {
          setIsExitModalOpen(false);
          dispatch(
            exitFromChatRoom({ chatId: chat._id, user: me._id }, () => {
              dispatch(isChatSettingsOpen(false));
              navigate('/chat');
            })
          );
        }}
      />
      <div className="relative" ref={ticketDrawerRef}>
        <div className="flex align-center sticky top-0 left-0 right-0 z-30 bg-white w-full justify-between p-3 ">
          <div className="flex align-center">
            <Icon
              name="arrow-left.svg"
              className="mx-2 mr-4 md:size-7 size-5 hover:opacity-60"
              onClick={() => {
                dispatch(isChatSettingsOpen(false));
                dispatch(isTicketOpen(true));
              }}
            />
            <div className="md:text-lg text-md font-bold">
              {header}
            </div>
          </div>
          <div className="flex align-center">
            {chat && (
              <Tooltip title={chat?.notifications
                ? intl.formatMessage({ id: 'MUTE', defaultMessage: 'Mute' })
                : intl.formatMessage({ id: 'UNMUTE', defaultMessage: 'Unmute' })
              }>
                <>
                  <Icon
                    name="noti-black.svg"
                    className={`mx-2 mr-4 md:size-5 size-4 hover:opacity-60 ${
                      !chat?.notifications && 'opacity-60'
                    }`}
                    onClick={() => {
                      updateChatPreferences();
                    }}
                  />
                </>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="flex flex-col ">
          <div className="my-5 self-center ">
            {chat && chat?.roomAvi ? (
              <RoomPicture room={chat} isRoom={true} />
            ) : cardImage ? (
              cardImageRenderer()
            ) : (
              <div
                className="rounded-full md:size-[100px] size-[50px] flex align-center justify-center md:text-3xl text-lg capitalize text-white"
                style={{ background: 'red' }}
              >
                {chat?.roomName?.substring(0, 2)}
              </div>
            )}
          </div>
          {chat && (
            <div>
              <div className="md:text-2xl text-xl font-bold text-center px-2">
                {chat?.roomName}
              </div>
              <div className="md:text-lg text-md text-center px-2">
                <FormattedMessage id="CREATED" defaultMessage="Created" /> {getDateTextLongFormat(new Date(chat?.createdAt), intl)}
              </div>
              <div className="pl-5 my-10 gap-5 flex flex-col ">
                {actions.map((action, idx) => (
                  <ChatActions key={idx} item={action} />
                ))}
              </div>
              {/* {files && <div className='h-full px-5 mt-5'>
                    <div className='font-16 mb-3'>
                      Files
                    </div>
                    <div className=''>
                      <TaskFileHolder files={files} />
                    </div>
                  </div>} */}
              <div className=" h-full px-5 mt-10">
                {showAddMember && (
                  <div className="flex align-center mb-8 ml-2">
                    <Icon
                      name="close.svg"
                      height="14px"
                      className="hover:opacity-60 mr-5"
                      onClick={() => {
                        setShowAddMember(false);
                      }}
                    />
                    <Select
                      value={assigneeValue}
                      isMulti
                      isClearable={false}
                      options={allUsers
                        ?.filter(
                          (newUser) =>
                            !chat?.members?.some(
                              (existingUser) =>
                                existingUser?._id === newUser?._id
                            )
                        )
                        ?.map((participant) => {
                          return {
                            value: participant?._id,
                            label: participant?.fullName,
                            email: participant?.email,
                            avi: participant?.avi,
                            role: 'collaborator',
                          };
                        })}
                      formatOptionLabel={AssigneeOptionLabel}
                      onChange={(participants) =>
                        setAssigneeValue(participants)
                      }
                      autoFocus={true}
                      placeholder={intl.formatMessage({ id: 'ADD_TO_CHAT', defaultMessage: 'Add to chat...' })}
                      classNames={{
                        menuList: () => 'scroll-y',
                        control: () =>
                          'flex mr-5 items-center bg-white text-sm font-medium text-slate-800 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out',
                        container: () => 'search-container',
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          width: '80%',
                        }),
                      }}
                    />
                    <div
                      onClick={() => {
                        const userIds =
                          assigneeValue.map((val) => val?.value) || [];
                        dispatch(
                          addUserToChat({
                            users: userIds,
                            chatId: chat?._id,
                            accessTime: new Date(),
                          })
                        );
                        setShowAddMember(false);
                      }}
                      className="bg-indigo-400 py-2.5 font-14 px-4 text-white cursor-pointer hover:bg-indigo-300"
                    >
                      <FormattedMessage id="ADD" defaultMessage="Add" />
                    </div>
                  </div>
                )}
                {chatMembers?.map((member, i) => {
                  const actions = [
                    {
                      label: intl.formatMessage({ id: 'MESSAGE', defaultMessage: 'Message' }),
                      icon: '/images/icons/message.svg',
                      description: intl.formatMessage({ id: 'SEND_DM_DETAILS', defaultMessage: 'Send a direct message to {name}' }, { name: member?.fullName }),
                      onClick: () => {
                        const chat = chats?.find(
                          (chat) =>
                            chat.roomType === 'direct' &&
                            chat.members?.some((mem) => mem._id === member._id)
                        );
                        openChat(member._id);
                      },
                    },
                    {
                      label: intl.formatMessage({ id: 'COPY_EMAIL', defaultMessage: 'Copy Email' }),
                      icon: '/images/icons/copy.svg',
                      description: intl.formatMessage({ id: 'COPY_EMAIL_DETAILS', defaultMessage: 'Copy {email} to clipboard' }, { email: member?.email }),
                      onClick: () => {
                        navigator.clipboard.writeText(member.email || '');
                        notification.success({
                          message: intl.formatMessage({ id: 'EMAIL_COPIED_TO_CLIPBOARD', defaultMessage: 'Email copied to clipboard' }),
                          placement: 'bottomRight',
                        });
                      },
                    },
                    {
                      label: intl.formatMessage({ id: 'REMOVE', defaultMessage: 'Remove' }),
                      icon: '/images/icons/delete-red.svg',
                      description: intl.formatMessage({ id: 'REMOVE_MEMBER_DETAILS', defaultMessage: 'Remove {email} from Chat' }, { email: member?.email }),
                      onClick: () => {
                        dispatch(
                          removeUserFromChat(
                            {
                              chatId: chat._id,
                              users: [member._id],
                            },
                            () => {
                              const updatedMembers = [...chatMembers];
                              updatedMembers.splice(i, 1);
                              setChatMembers(updatedMembers);
                            }
                          )
                        );
                      },
                    },
                  ];
                  const positionStyle =
                    i === chatMembers.length - 1
                      ? { top: '-140px', right: '20px' }
                      : i === chatMembers.length - 2
                      ? { top: '-90px', right: '15px' }
                      : i === chatMembers.length - 3
                      ? { top: '-30px', right: '15px' }
                      : { top: '10px', right: '0' };

                  return (
                    <div
                      key={i + member?._id}
                      className="d-flex align-center cursor-pointer justify-content-between mb-3"
                    >
                      <div
                        className="d-flex align-center"
                        onClick={() => {
                          dispatch(isChatSettingsOpen(false));
                          me?._id === member?._id
                            ? setTimeout(() => {
                                dispatch(isUserDrawerOpen(true));
                              }, 300)
                            : setTimeout(() => {
                                dispatch(isMemberDrawerOpen(true, { member }));
                              }, 300);
                        }}
                      >
                        <UserAvatar
                          classNames="md:h-[47px] md:w-[47px] h-[30px] w-[30px]"
                          fullName={member?.fullName}
                          userId={member?._id}
                          src={member?.avi}
                          styles={{
                            borderRadius: 40,
                            objectFit: 'cover',
                          }}
                        />
                        <div className="ml-2 md:ml-4">
                          <div className="text-md font-bold md:text-lg">
                            {member?.fullName}
                          </div>
                          <div className="d-flex align-center">
                            <div className="text-xs md:text-sm text-slate-400 mr-2">
                              {member?.email || ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="relative  inline-block "
                        ref={(el) => (menuRefs.current[member._id] = el)}
                      >
                        {!(me?._id === member?._id || isDirectChat) && (
                          <>
                            <img
                              src="/images/icons/threeVerticalDots.svg"
                              alt="Three dots menu"
                              className="cursor-pointer md:size-6 size-4"
                              onClick={() =>
                                me?._id === member?._id
                                  ? ''
                                  : toggleMenu(member._id)
                              }
                            />
                            <MemberActionsMenu
                              isMenuOpen={openMenuMemberId === member._id}
                              closeMenu={() => setOpenMenuMemberId(null)}
                              menuRef={menuRefs.current[member._id]}
                              actions={actions}
                              positionStyle={positionStyle}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    chats: state.chatReducer.chats,
    drawerWidth: state.toggleReducer.drawerWidth,
    allUsers: state?.userReducer?.users,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
  };
}

export default connect(mapStateToProps)(UserChatPanel);
