import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { addTask, clearSingleTask } from '../../../../actions';
import { useIntl } from 'react-intl';

const AddSubTask = (props) => {
  const intl = useIntl();
  const [taskName, setTaskName] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    await dispatch(addTask(
      { ...props?.taskProperties, taskTitle: taskName }
    ), () =>  dispatch(clearSingleTask()));
    setTaskName('');
  };

  return (
    <div
      className="d-flex justify-content-between border-2 p-2 rounded-lg"
      style={{ background: '#FBFBFB' }}
    >
      <input
        name="taskTitle"
        placeholder={intl.formatMessage({ id: 'ENTER_SUBRASK_TITLE', defaultMessage: 'Enter subtask title' })}
        value={taskName}
        onChange={(e) => {
          setTaskName(e?.target?.value);
        }}
        required
        autoFocus
        type="text"
        style={{
          background: '#FBFBFB',
          width: '300px',
          border: 'none',
          outline: 'none',
          paddingX: 10
        }}
      />
      <div className='flex align-center'>
        <div className='bg-[#0473cc] size-6 mr-2 rounded-full flex justify-center items-center cursor-pointer' onClick={() => {
          handleSubmit();
          props.setIsComponentVisible(false);
        }}>
          <img src='/images/icons/thick-white.svg' className='size-2' />
        </div>
        <div className='bg-red-500 size-6 rounded-full flex justify-center items-center cursor-pointer' onClick={() => {
          setTaskName('');
          props.setIsComponentVisible(false);
        }}>
          <img src='/images/icons/close.svg' className='size-2'
            style={{ filter: 'invert(1) brightness(2)' }} />
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    singleTask: state.taskReducer.singleTask,
    taskProperties: state.taskReducer.taskProperties,
    tasks: state.taskReducer.tasks,
  };
}

export default connect(mapStateToProps)(AddSubTask);
