import { useIntl } from 'react-intl';
import { taskPriority } from '../../../../js/constants';

const subPriorityMenu = (updateFilters) => {
  const items = [];
  taskPriority.map((priority, i) => {
    items.push({
      key: i + 3,
      label: priority.name,
      onClick: () => updateFilters(priority.rank, 'priority')
    });
  });
  return items;
}

const FilterItems = (props) => {

  const {
    statusFilters,
    assignees,
    updateFilters,
    resetFilters,
    dueDateFilters
  } = props;

  const intl = useIntl();

  return (
    [
      {
        key: 0,
        label: intl.formatMessage({ id: 'RESET_FILTERS', defaultMessage: 'Reset filters' }),
        onClick: () => resetFilters()
      },
      {
        key: 1,
        label: intl.formatMessage({ id: 'PRIORITY', defaultMessage: 'Priority' }),
        children: subPriorityMenu(updateFilters),
      },
      {
        key: 2,
        label: intl.formatMessage({ id: 'ASSIGNED_TO', defaultMessage: 'Assigned to' }),
        children: assignees
      },
      {
        key: 3,
        label: intl.formatMessage({ id: 'STATUS', defaultMessage: 'Status' }),
        children: statusFilters
      },
      {
        key: 4,
        label: intl.formatMessage({ id: 'DUE_DATE', defaultMessage: 'Due date' }),
        children: dueDateFilters
      }
    ]
  );
};

export default FilterItems;
