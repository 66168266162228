import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';

import {
  addChatRoom,
  removeMemberFromWorkspace,
  updateProjectUsers,
  updateWorkspaceUsers,
  removeMemberFromProject,
  getProjectById
} from '../../../actions';
import ConfirmationModal from '../../../components/UI/molecules/modals/ConfirmationModal';
import UserAvatar from '../../../components/UI/atoms/ticket-drawer/UserAvatar';
import { useIntl } from 'react-intl';

const DashboardMembers = ({
  member,
  chats,
  workspaceId,
  me,
  showRole = false,
  isProject = '',
  callback = (participant) => { }
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hover, setHover] = useState(false);
  const [role, setRole] = useState(member?.role);
  const [open, setOpen] = useState(false);

  const {
    fullName,
    email,
    avi,
    _id,
  } = member;

  const openChat = async () => {
    const existingChat = chats?.find(chat => chat?.roomType === 'direct' && chat?.members?.find(mem => mem?._id === _id));

    if (existingChat) {
      navigate(`/chat/${existingChat?._id}`, { replace: true });
    } else {
      const participants = [_id];
      dispatch(addChatRoom({ members: participants, roomName: null, workspaceId: workspaceId }));
    }
  }

  const updateRole = async (role, userId) => {
    let newRole = {
      "users": [
        {
          "userId": userId,
          "role": role
        }
      ]
    }

    if (!isProject) {
      newRole.workspaceId = workspaceId
      dispatch(updateWorkspaceUsers(newRole, () => setRole(role)));
    } else {
      newRole.projectId = isProject;
      dispatch(updateProjectUsers(newRole, () => setRole(role)));
    }
  }

  return (
    <div
      className='hover:bg-blue-100 cursor-pointer p-2 m-1 rounded-xl flex align-center justify-between'
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className='flex align-center' onClick={() => callback && callback(member)}>
        <UserAvatar
          alt="User"
          classNames="rounded-full w-8 h-8 mr-3"
          fullName={fullName}
          userId={_id}
          src={avi}
          styles={{
              objectFit: 'cover',
            }}
        />
        <div>
          <div className='text-sm font-bold'>{fullName} {me !== _id ? '' : `( ${intl.formatMessage({ id: 'YOU', defaultMessage: 'You' })} )`}</div>
          <div className='text-xs'>{email}</div>
        </div>
      </div>
      <div className='flex align-center'>
        {me !== _id && hover && <div onClick={() => openChat()} className='bg-white h-9 w-9 rounded-full flex align-center justify-center border-2 border-blue-300 hover:bg-blue-50'>
          <img
            src="/images/icons/message.svg"
            style={{ height: "18px", width: "18px" }}
            alt="icon"
          />
        </div>}
        {me !== _id && hover && showRole && <div onClick={() => {
          setOpen(true);
        }} className='bg-white h-9 w-9 rounded-full flex align-center ml-2 justify-center border-2 border-blue-300 hover:bg-blue-50'>
          <img
            src="/images/icons/delete-red.svg"
            style={{ height: "18px", width: "18px" }}
            alt="icon"
          />
        </div>}
        {showRole && (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'guest',
                  label: intl.formatMessage({ id: 'GUEST', defaultMessage: 'Guest' }),
                  onClick: () => updateRole('guest', _id)
                },
                {
                  key: 'member',
                  label: intl.formatMessage({ id: 'MEMBER', defaultMessage: 'Member' }),
                  onClick: () => updateRole('member', _id)
                },
                {
                  key: 'admin',
                  label: intl.formatMessage({ id: 'ADMIN', defaultMessage: 'Admin' }),
                  onClick: () => updateRole('admin', _id)
                },
              ],
            }}
            placement='bottom'
            arrow
            disabled={role === 'owner'}
          >
            <div className='h-7 capitalize ml-3 w-20 rounded-full flex align-center justify-center border-2 border-blue-300 text-[14px] bg-white'>
              {role == 'collaborator' ? intl.formatMessage({ id: 'MEMBER', defaultMessage: 'Member' }) : intl.formatMessage({ id: `${role}`.toUpperCase(), defaultMessage: role })}
            </div>
          </Dropdown>
        )}
      </div>
      <ConfirmationModal
        title={isProject
          ? intl.formatMessage({ id: 'REMOVE_FROM_PROJECT', defaultMessage: 'Remove from project' }) + '?'
          : intl.formatMessage({ id: 'REMOVE_FROM_WORKSPACE', defaultMessage: 'Remove from workspace' }) + '?'
        }
        description={intl.formatMessage({ id: 'REMOVE_THIS_MEMBER_CONFIRMATION', defaultMessage: 'Are you sure you want to remove this member?' })}
        isModalOpen={open}
        onCancel={() => {
          setOpen(false);
        }}
        onYesClick={async () => {
          if (isProject) {
            await dispatch(removeMemberFromProject({
              projectId: isProject,
              members: [
                _id
              ]
            }, () => {
              dispatch(getProjectById(isProject))
            }))
          } else {
            await dispatch(removeMemberFromWorkspace({
              workspaceId: workspaceId,
              members: [
                _id
              ]
            }))
          }
          setOpen(false);
        }}
      />
    </div>
  );
};

export default DashboardMembers;