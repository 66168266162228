import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Dropdown, Switch } from 'antd';
import moment from 'moment-timezone';


import Icon from '../../atoms/global/Icon';
import ProfileAndName from '../../atoms/ticket-drawer/ProfileAndName';

import { logout } from '../../../../actions/logoutAction';
import WorkspaceSearchBar from './WorkspaceSearchBar';

import { getWorkspaceNotifications } from '../../../../actions/notificationAction';
import {
  editProject,
  getSingleTask,
  getTeamsByWorkspaceId,
  getUser,
  updateProjectPreferences,
  isChatSettingsOpen,
  getWorkspaceById
} from '../../../../actions';
import {
  isChatOpen,
  isListView,
  isTicketOpen,
  isUserDrawerOpen,
  setTeamsState,
} from '../../../../actions/toggleAction';

import { getUserLicense } from '../../../../js/functions';
import { COLOURS } from '../../../../js/constants';
import UserAvatar from '../../atoms/ticket-drawer/UserAvatar';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';

const TopNavbar = (props) => {
  const intl = useIntl();
  const notificationOverlayRef = useRef(null);
  const bellIconRef = useRef(null);
  const calenderOverlayRef = useRef(null);
  const param = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { chats } = useSelector((state) => state.chatReducer);
  const chat = chats && chats.find((chat) => chat._id === param.chatId);

  const { user: loggedInUser, projectData, phase } = props;
  const projectUser = projectData?.participants?.find(mem => mem?._id?.toString() == loggedInUser?._id?.toString());

  const URL = window.location.pathname;

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        isNotificationOpen &&
        notificationOverlayRef?.current &&
        !notificationOverlayRef?.current.contains(event.target) &&
        !bellIconRef?.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNotificationOpen]);

  let member = chat?.members.find(
    (member) => member._id !== localStorage.getItem('Id')
  );

  let primaryTimezone =
    loggedInUser &&
    loggedInUser.timezones &&
    loggedInUser.timezones.find((tz) => tz.isPrimary);
  primaryTimezone = primaryTimezone && primaryTimezone.name;

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('workspaceId');
    localStorage.removeItem('organization');
    localStorage.removeItem('toggle');
    localStorage.removeItem('inviteUrl');
    localStorage.removeItem('Id');


    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('organization');

    dispatch(logout());
    window.location.href = '/signin';
  };

  const toggleProjectAccess = async () => {
    const access = props?.projectData?.access == "private" ? "public" : "private";
    const projectId = props?.projectData?._id;
    await dispatch(editProject({ _id: projectId, access }));
    const workspaceId = sessionStorage.getItem("workspaceId");
    if (workspaceId) {
      dispatch(getWorkspaceById());
      // dispatch(getTeamsByWorkspaceId(workspaceId));
    }
  };

  const updateProjPreferences = async () => {
    await dispatch(updateProjectPreferences({ ...projectUser, notifications: !projectUser?.notifications, projectId: props?.projectData?._id }));
    const workspaceId = sessionStorage.getItem("workspaceId");
    if (workspaceId) {
      dispatch(getWorkspaceById());
      // dispatch(getTeamsByWorkspaceId(workspaceId));
    }
  }


  // useEffect(() => {
  //   props.getWorkspaceNotifications();
  // }, [dispatch]);


  // closing notifications modal on click

  const projectDropdownItems = [
    {
      key: '1',
      label:
        <div
          className='d-flex toggle-dropdown'
          onClick={toggleProjectAccess}>
          <div><FormattedMessage id="MAKE_PRIVATE" defaultMessage="Make Private" /></div>
          <Switch checked={props?.projectData?.access !== "public"} />
        </div>,
    },
    {
      key: '2',
      label:
        <div
          className='d-flex toggle-dropdown'
          onClick={updateProjPreferences}>
          <div><FormattedMessage id="NOTIFICATIONS" defaultMessage="Notifications" /></div>
          <Switch checked={projectUser?.notifications} />
        </div>,
    },
  ];

  const projectLinks = () => {
    return (
      <div
        className='d-flex flex-grow1'
        style={{ alignSelf: 'flex-start' }}
      >
        {/* <Link
          to={`/${URL.split('/')[1]}/members`}
          className='topNavbarLink membersTopNavbarLink'>
          Members
        </Link> */}
        <Link
          to={`/${URL.split('/')[1]}/calendar`}
          className='topNavbarLink calendarTopNavbarLink'
        >
          <img
            src='/images/icons/date.svg'
            alt='Calendar Icon'
            className='mr-1 size-4'
          />
          <FormattedMessage id="CALENDAR" defaultMessage="Calendar" />
        </Link>
        {/* <Link
          to={`/${URL.split('/')[1]}/chat`}
          className='topNavbarLink chatTopNavbarLink'
        >
          Chat
        </Link> */}
        <Link
          onClick={() => {
            dispatch(isListView(false))
          }}
          to={`/${URL.split('/')[1]}/board`}
          className='topNavbarLink boardTopNavbarLink'
        >
          <img
            src='/images/icons/board-gray.svg'
            alt='Board Icon'
            className='mr-1 size-4'
          />
          <FormattedMessage id="BOARD" defaultMessage="Board" />
        </Link>
        <Link
          onClick={() => {
            dispatch(isListView(true))
          }}
          to={`/${URL.split('/')[1]}/list`}
          className='topNavbarLink listTopNavbarLink'
        >
          <img
            src='/images/icons/list.svg'
            alt='List Icon'
            className='mr-1 size-4'
          />
          <FormattedMessage id="LIST" defaultMessage="List" />
        </Link>
        <Link
          to={`/${URL.split('/')[1]}/settings`}
          className='topNavbarLink settingsTopNavbarLink'>
          <img
            src='/images/icons/settings.svg'
            alt='Settings Icon'
            className='mr-1 size-4'
          />
          <FormattedMessage id="SETTINGS" defaultMessage="Settings" />
        </Link>
      </div>
    );
  }

  const license = getUserLicense(loggedInUser, sessionStorage.getItem("organization") || localStorage.getItem("organization"))

  return (
    <header className="sticky top-0 bg-white border-b border-[#E2E8F0] z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className={`${props.phase !== 'search' ? 'justify-between' : 'justify-normal'} flex align-center h-16 -mb-px`}>

          {/* Header: Left side */}
          <div className="flex">
            {props.phase === 'chat' && (
              <div className='d-flex align-center'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(isChatSettingsOpen(true));
                }}>
                <div key={member ? member._id : ''}>
                  {((chat?.roomAvi || member?.avi) && chat.roomType !== 'project') ?
                    <ProfileAndName
                      height='30px'
                      width='30px'
                      name={
                        chat?.roomName !== null && chat?.roomName !== ''
                          ? chat?.roomName
                          : member?.fullName
                      }
                      src={
                        chat && chat.roomType == 'group'
                          ? chat.roomAvi
                          : member && member.avi
                      }
                      imageOverwrite={true}
                      id={member ? member?._id : ''}
                    /> :
                    <div className='d-flex align-center'>
                      <div className='square-letter-large'
                        style={{
                          background: 'red',
                        }}>
                        {(chat?.roomName !== null && chat?.roomName !== ''
                          ? chat?.roomName
                          : member?.fullName)?.slice(0, 2)}
                      </div>
                      <div className='flex align-center'>
                        <div className='font-20 truncate'>
                          {chat?.roomName !== null && chat?.roomName !== ''
                            ? chat?.roomName
                            : member?.fullName}
                        </div>
                        <img
                          className='hover:opacity-60'
                          src={'/images/icons/info.svg'}
                          style={{
                            cursor: 'pointer',
                            height: '22px',
                            width: '22px',
                          }}
                          alt='icon'
                        />
                      </div>
                    </div>}
                </div>
              </div>
            )}

            {/*............. Project top navbar............... */}
            {props.phase === 'project' && (
              <div className='d-flex ' style={{ height: '100%' }}>
                <div className='square-letter-large bg-[#0473cc]'>
                  <img src='/images/icons/board.svg' />
                </div>
                <div style={{ paddingLeft: '8px', position: 'relative' }} className='d-flex flex-column'>
                  <div className='d-flex align-center'>
                    <p
                      className='font-18 project-nav-title truncate'
                      style={{
                        marginBottom: '0',
                        fontWeight: 700
                      }}
                    >
                      {props.name?.substring(0, 30)}
                    </p>
                    <div className='ml-3'>
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: projectDropdownItems,
                        }}
                        placement='bottom'
                        arrow
                      >
                        <div>
                          <img
                            src={'/images/icons/black-arrow-down.svg'}
                            style={{
                              cursor: 'pointer',
                              height: '14px',
                              width: '14px',
                            }}
                            alt='icon'
                          />
                        </div>
                      </Dropdown>
                    </div>
                    <div className='project-setting-icons'>
                      {props?.projectData?.access == "private" && <div style={{ padding: '2px 5px' }}>
                        <Icon name='lock.svg' height='18px' width='18px' />
                      </div>}
                      {/* {projectUser && !projectUser?.notifications && <div style={{ padding: '4px 5px' }}>
                      <Icon name='no-notification.svg' height='18px' width='18px' />
                    </div>} */}
                    </div>
                  </div>
                  {projectLinks()}
                </div>
              </div>
            )}

            {/*............. Team top navbar............... */}
            {(props.phase === 'workspace') && (
              <div className='d-flex align-center' style={{ height: '100%' }}>
                <div className='p-2 rounded px-3 mr-2 text-white font-bold' style={{ background: '#0074cc' }}>
                  {props?.name?.slice(0, 2)?.toUpperCase() || props?.currentWorkspace?.title?.slice(0, 2)?.toUpperCase()}
                </div>
                <div style={{ paddingLeft: '8px' }}>
                  <div className='font-18 font-semibold'>{props.name || props?.currentWorkspace?.title}</div>
                  <div className='font-14'>
                    <div className='hidden sm:hidden md:hidden lg:inline-block'>
                      {props.phase === 'workspace'
                        ? <FormattedMessage id="WORKSPACE_DASHBOARD" defaultMessage="Workspace Dashboard" />
                        : <FormattedMessage id="TEAM_DASHBOARD" defaultMessage="Team Dashboard" />
                      }
                    </div>
                    <div className='lg:hidden'>
                      {props.phase === 'workspace'
                        ? <FormattedMessage id="WORKSPACE" defaultMessage="Workspace" />
                        : <FormattedMessage id="TEAM" defaultMessage="Team" />
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(props.phase === 'teams') && (
              <div className='d-flex align-center' style={{ height: '100%' }}>
                <div>
                  <div className='font-18'><FormattedMessage id="TEAMS_DIRECTORY" defaultMessage="Teams Directory" /></div>
                  <div className='font-14-600'>
                    <FormattedMessage
                      id="ALL_WORKSPACE_TEAMS"
                      defaultMessage={`All ${props?.currentWorkspace?.title}'s teams`}
                      values={{
                        workspaceTitle: props?.currentWorkspace?.title
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/*.............Home top navbar............... */}

            {props.phase === 'home' && (
              <>
                {/* userTasksCount */}
                <div className='flex mr-5 text-slate-600' style={{ alignItems: 'flex-end' }}>
                  <span className='font-black mr-2 text-2xl text-blue-500'>
                    <FormattedPlural
                      value={Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' })}
                      other={
                        intl.formatMessage({ id: 'OTHER_TASKS', defaultMessage: '{value} Tasks' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                      zero={
                        intl.formatMessage({ id: 'ZERO_TASKS', defaultMessage: '{value} Tasks' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                      one={
                        intl.formatMessage({ id: 'ONE_TASK', defaultMessage: '{value} Task' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                      two={
                        intl.formatMessage({ id: 'TWO_TASKS', defaultMessage: '{value} Tasks' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                      few={
                        intl.formatMessage({ id: 'FEW_TASKS', defaultMessage: '{value} Tasks' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                      many={
                        intl.formatMessage({ id: 'MANY_TASKS', defaultMessage: '{value} Tasks' },
                          { value: Number.parseInt(props?.userTasksCount) || intl.formatMessage({ id: 'ALL', defaultMessage: 'All' }) }
                        )
                      }
                    />
                  </span>
                </div>
              </>
            )}
          </div>



          {/* Header: Right side */}
          <div className={`flex items-center justify-between ${props.phase !== 'search' ? 'space-x-3 w-auto' : 'space-x-0 w-full'}`}>
            <div className={`${props.phase !== 'search' ? '' : 'flex justify-center items-center w-[100vw]'}`}>
              <WorkspaceSearchBar tasks={props.tasks} header search={props.phase !== 'search' ? false : true} />
            </div>
            {/*  Divider */}
            {license === 'Free Plan' && <div style={{ margin: '0 10px 0 25px' }} className={`${props.phase !== 'search' ? '' : 'xl:block hidden'}`}>
              <div className='text-xs md:text-sm text-[#27282D]'><FormattedMessage id="FREE_PLAN" defaultMessage="Free Plan" /></div>
              <div className='d-flex align-center' style={{ cursor: 'pointer' }} onClick={() => navigate('/pricing')}>
                <div className='text-xs link-button mr-2'><FormattedMessage id="UPGRADE" defaultMessage="Upgrade" /></div>
                <img src='/images/icons/blue-arrow-right.svg' className='size-3' />
              </div>
            </div>}
            {/*  Divider */}
            <hr className="w-px h-6 bg-[#E2E8F0] border-none xl:block hidden" />

            <div className={`xl:flex  flex align-center cursor-pointer`} onClick={() => dispatch(isUserDrawerOpen(true))}>
              <UserAvatar 
                fullName={loggedInUser?.fullName}
                userId={loggedInUser?._id}
                src={loggedInUser?.avi}
                styles={{
                  height: '34px',
                  width: '34px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
              />
            </div>
          </div>

        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  const pathName = window.location.pathname.split('/')[2];
  return {
    getWorkspaceNotifications: () => dispatch(getWorkspaceNotifications()),
    teamsOpener: (arg) => dispatch(setTeamsState(arg)),
    chatOpener: (arg) => dispatch(isChatOpen(arg)),
    taskOpener: (arg) => dispatch(isTicketOpen(arg)),
    getUser: () => dispatch(getUser()),
    getCurrentTask: () =>
      pathName !== 'board' && dispatch(getSingleTask(pathName)),
  };
};

const mapStateToProp = (state) => {
  return {
    user: state.userReducer.user,
    notifications: state.notificationReducer.notifications,
    currentTask: state.taskReducer.singleTask,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    tasks: state.taskReducer.originalTasks,
    userTasksCount: state.taskReducer.userTasksCount,
  };
};
export default connect(mapStateToProp, mapDispatchToProps)(TopNavbar);
