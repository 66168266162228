import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import IconAndName from '../../atoms/side-navbar/IconAndName';
import FilterItems from '../../atoms/dropdown-items/FilterItems';
import GroupAvatar from '../../atoms/side-navbar/GroupAvatar';

import { dueDateFilterOptions, taskStatus, lastUpdatedFilterOptions } from '../../../../js/constants';
import { setFilters, toggleNewTaskModal, toggleWorkspaceFieldsModal, openInvitePanel } from '../../../../actions';

const SubNavBar = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isTicketOpened = useSelector((state) => state.toggleReducer.isTicketOpen);
  const isChatOpened = useSelector((state) => state.toggleReducer.isChatOpen);
  const filters = useSelector((state) => state.taskReducer.filters);
  const selectedProject = useSelector((state) => state.projectReducer.getProjects);

  const teamDrawerState = useSelector(
    (state) => state?.toggleReducer?.teamDrawerState
  );

  const [assigneeList, setAssigneeList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(taskStatus.allTasks.label);
  const [selectedDueDateOption, setSelectedDueDateOption] = useState('Due date')
  const [selectedLastUpdateOption, setSelectedLastUpdateOption] = useState('Last Updated')

  const {
    project,
    showFilters,
    setShowSection
  } = props;

  const hasRequests = project?.accessRequests?.length > 0;

  useEffect(() => {
    let items = [{
      label: <div onClick={() => dispatch(setFilters({
        ...filters, assignedTo: [], projectId: selectedProject?._id
      }))}>
        -- <FormattedMessage id="DEFAULT" defaultMessage="Default" /> --
      </div>,
      key: 1
    }];
    project?.participants?.map((participant, i) => {
      items.push({
        label:
          <div onClick={() => dispatch(setFilters({
            ...filters, assignedTo: [participant], projectId: selectedProject?._id
          }))}>
            {participant.fullName}
          </div>,
        key: participant._id,
      });
    });
    setAssigneeList(items);
  }, [project?.participants]);

  const updateFilters = (status, type) => {
    let x = filters;
    x.projectId = selectedProject?._id;

    if (type == 'priority') {
      x.priority = status;
    } else if (type == 'status') {
      x.status = status.key;
      setSelectedStatus(status.label);
    } else if (type == 'dueDate') {
      x.dueDate = status.key;
      setSelectedDueDateOption(status.label === dueDateFilterOptions?.allTasks?.label ? 'Due date' : `Due: ${status.label}`);
    } else if (type == 'updatedAt') {
      x.updatedAt = status.key;
      setSelectedLastUpdateOption(status.label === lastUpdatedFilterOptions?.allTasks?.label ? 'Last Updated' : `Last Updated: ${status.label}`);
    }
    dispatch(setFilters(x));
  }

  const resetFilters = () => {
    dispatch(setFilters({ projectId: selectedProject?._id }, true));
  }

  const statusFilters = [
    {
      ...taskStatus.allTasks,
      label: intl.formatMessage({ id: `${taskStatus.allTasks.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: taskStatus.allTasks.label }),
      onClick: () => updateFilters(taskStatus.allTasks, 'status')
    },
    {
      ...taskStatus.completedTasks,
      label: intl.formatMessage({ id: `${taskStatus.completedTasks.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: taskStatus.completedTasks.label }),
      onClick: () => updateFilters(taskStatus.completedTasks, 'status')
    },
    {
      ...taskStatus.incompletedTasks,
      label: intl.formatMessage({ id: `${taskStatus.incompletedTasks.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: taskStatus.incompletedTasks.label }),
      onClick: () => updateFilters(taskStatus.incompletedTasks, 'status')
    },
  ]

  const dueDateFilters = [
    {
      ...dueDateFilterOptions.allTasks,
      label: intl.formatMessage({ id: `${dueDateFilterOptions.allTasks.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: dueDateFilterOptions.allTasks.label }),
      onClick: () => updateFilters(dueDateFilterOptions.allTasks, 'dueDate')
    },
    {
      ...dueDateFilterOptions.dueToday,
      label: intl.formatMessage({ id: `${dueDateFilterOptions.dueToday.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: dueDateFilterOptions.dueToday.label }),
      onClick: () => updateFilters(dueDateFilterOptions.dueToday, 'dueDate')
    },
    {
      ...dueDateFilterOptions.dueThisWeek,
      label: intl.formatMessage({ id: `${dueDateFilterOptions.dueThisWeek.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: dueDateFilterOptions.dueThisWeek.label }),
      onClick: () => updateFilters(dueDateFilterOptions.dueThisWeek, 'dueDate')
    },
    {
      ...dueDateFilterOptions.dueThisMonth,
      label: intl.formatMessage({ id: `${dueDateFilterOptions.dueThisMonth.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: dueDateFilterOptions.dueThisMonth.label }),
      onClick: () => updateFilters(dueDateFilterOptions.dueThisMonth, 'dueDate')
    },
  ]

  const lastUpdatedFilters = [
    {
      ...lastUpdatedFilterOptions.allTasks,
      label: intl.formatMessage({ id: `${lastUpdatedFilterOptions.allTasks.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: lastUpdatedFilterOptions.allTasks.label }),
      onClick: () => updateFilters(lastUpdatedFilterOptions.allTasks, 'updatedAt')
    },
    {
      ...lastUpdatedFilterOptions.dueToday,
      label: intl.formatMessage({ id: `${lastUpdatedFilterOptions.dueToday.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: lastUpdatedFilterOptions.dueToday.label }),
      onClick: () => updateFilters(lastUpdatedFilterOptions.dueToday, 'updatedAt')
    },
    {
      ...lastUpdatedFilterOptions.dueThisWeek,
      label: intl.formatMessage({ id: `${lastUpdatedFilterOptions.dueThisWeek.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: lastUpdatedFilterOptions.dueThisWeek.label }),
      onClick: () => updateFilters(lastUpdatedFilterOptions.dueThisWeek, 'updatedAt')
    },
    {
      ...lastUpdatedFilterOptions.dueThisMonth,
      label: intl.formatMessage({ id: `${lastUpdatedFilterOptions.dueThisMonth.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: lastUpdatedFilterOptions.dueThisMonth.label }),
      onClick: () => updateFilters(lastUpdatedFilterOptions.dueThisMonth, 'updatedAt')
    },
  ]

  const openInvites = () => {
    dispatch(openInvitePanel(true, {
      pastInvites: project?.pendingInvites || [],
      existingMembers: project?.participants || [],
      selectWorkspaceMembers: true,
      type: 'Project'
    }));
  }

  return (
    <div className='d-flex sticky top-0'>
      <div className='flex align-center justify-between sub-nav-area mt-1 px-4'>
        {(!isTicketOpened && !isChatOpened && !teamDrawerState) || showFilters ? (
          <ul className="flex flex-wrap -m-1">
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'add-task',
                    label: <div className='font-14 py-2'><FormattedMessage id="ADD_TASK" defaultMessage="Add Task" /></div>,
                    onClick: () => dispatch(toggleNewTaskModal({ value: true }))
                  },
                  {
                    key: 'add-section',
                    label: <div className='font-14 py-2'><FormattedMessage id="ADD_SECTION" defaultMessage="Add Section" /></div>,
                    onClick: () => setShowSection(true)
                  },
                  {
                    key: 'add-custom-field',
                    label: <div className='font-14 py-2'><FormattedMessage id="ADD_CUSTOM_FIELD" defaultMessage="Add Custom Field" /></div>,
                    onClick: () => dispatch(toggleWorkspaceFieldsModal(true))
                  },
                  {
                    key: 'add-member',
                    label: <div className='font-14 py-2'>Add Member</div>,
                    onClick: () => {
                      openInvites();
                    }
                  },
                ],
              }}
              placement='bottomRight'
              trigger={['click']}
            >
              <li className="m-1">
                <button className="inline-flex items-center justify-center font-14 font-medium leading-5 rounded-full px-4 py-1.5 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out">
                  <img src='/images/icons/add-circle.svg' className='size-4' />
                  <span className='ml-1'>
                    <FormattedMessage id="ADD_TO_PROJECT" defaultMessage="Add to project" />
                  </span>
                </button>
              </li>
            </Dropdown>
            <Dropdown
              menu={{
                items: FilterItems({ assignees: assigneeList, updateFilters, resetFilters, statusFilters, dueDateFilters, lastUpdatedFilters }),
              }}
              placement='bottomRight'
              trigger={['click']}
            >
              <li className="m-1">
                <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out">
                  <img src='/images/icons/filter-glass.svg' className='size-4' /> {!isTicketOpened && <span className='ml-1'>
                    <FormattedMessage id="FILTERS" defaultMessage="Filters" />
                  </span>}
                </button>
              </li>
            </Dropdown>
            {!isTicketOpened && <div className='vertical-line mt-2' style={{ height: 20 }} />}
            {!isTicketOpened && <div className='easy-filters'>
              <Dropdown
                menu={{
                  items: statusFilters,
                }}
                placement='bottomRight'
                trigger={['click']}
              >
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    <FormattedMessage id={`${selectedStatus}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedStatus} />
                  </button>
                </li>
              </Dropdown>
              <Dropdown
                menu={{
                  items: dueDateFilters,
                }}
                placement='bottomRight'
                trigger={['click']}
              >
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    <FormattedMessage id={`${selectedDueDateOption}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedDueDateOption} />
                  </button>
                </li>
              </Dropdown>
              <Dropdown
                menu={{
                  items: lastUpdatedFilters,
                }}
                placement='bottomRight'
                trigger={['click']}
              >
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    <FormattedMessage id={`${selectedLastUpdateOption}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedLastUpdateOption} />
                  </button>
                </li>
              </Dropdown>
              <Dropdown
                menu={{
                  items: assigneeList,
                }}
                trigger={['click']}
                placement='bottomRight'
              >
                <li className="m-1 flex align-center">
                  <button className="mr-3 inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    <FormattedMessage id="ASSIGNED_TO" defaultMessage="Assigned to" />
                  </button>
                  <GroupAvatar
                    participants={
                      filters.assignedTo
                    }
                    size={18}
                  />
                </li>
              </Dropdown>
            </div>}
          </ul>
        ) : ''}
        <div
          onClick={() => {
            openInvites();
          }}
          className='flex align-center project-share-container mr-2 cursor-pointer'>
          <GroupAvatar participants={(project?.participants)} size={32} count={3} />
          <div
            className={`ml-4 inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-transparent shadow-sm ${hasRequests ? 'bg-red-500' : 'bg-blue-500'} hover:opacity-70 text-white duration-150 ease-in-out`}
          >
            {hasRequests
              ? <FormattedMessage id="VIEW_REQUESTS" defaultMessage="View Requests!" />
              : <FormattedMessage id="INVITE" defaultMessage="Invite" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubNavBar;
