import io from 'socket.io-client';

const getSocketConfig = () => ({
  transports: ['websocket'],
  maxHttpBufferSize: 1e8,
  query: {
    userId: localStorage.getItem('Id'),
    userAgent: navigator.userAgent,
    token: `Bearer ${localStorage.getItem("token")}`
  },
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 15
});

let socket = null;
let retryCount = 0;
let retryTimer = null;
const MAX_RETRIES = 20;
const RETRY_DELAY = 2000;

function cleanupSocket() {
  if (socket) {
    socket.removeAllListeners();
    socket.disconnect();
    socket = null;
  }
  if (retryTimer) {
    clearTimeout(retryTimer);
    retryTimer = null;
  }
}

function tryConnection() {
  // Clean up any existing socket
  cleanupSocket();
  
  // Create new socket
  socket = io(`${process.env.REACT_APP_SOCKET_HOST}`, getSocketConfig());

  socket.on('connect', () => {
    console.log(`Socket connected (attempt ${retryCount + 1}), awaiting auth...`);
  });

  socket.on('ConnectionRejected', (data) => {
    console.log(`Connection rejected on attempt ${retryCount + 1}:`, data.message);
    
    // Clean up this socket
    cleanupSocket();
    
    if (retryCount < MAX_RETRIES) {
      retryCount++;
      console.log(`Will retry in ${RETRY_DELAY}ms (attempt ${retryCount} of ${MAX_RETRIES})`);
      retryTimer = setTimeout(tryConnection, RETRY_DELAY);
    } else {
      console.log('Max retries reached');
    }
  });

  // Handle other socket events...
  socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
  });

  socket.on('error', (error) => {
    console.log('Socket error:', error);
  });
}

// Start initial connection
tryConnection();

// Export the socket instance
export default socket;