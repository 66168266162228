import React from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { isMemberDrawerOpen as memberDrawerOpen, isChatSettingsOpen as chatSettingsOpen } from '../../actions/toggleAction';
import { notification } from 'antd';
import { updateQueryState } from '../../actions';
import { clearMessagesState } from '../../actions/messageAction';
import { setRoomId } from '../../actions/roomAction';
import { RESET_COUNTER, UPDATE_CURRENT_RECEIVER } from '../../actions/types/chat.types';
import { addChatRoom, updateChatRoomMessagesReadBy } from '../../actions/chatRoomAction';
import Icon from '../../components/UI/atoms/global/Icon';
import { FormattedMessage, useIntl } from 'react-intl';

const MemberDrawer = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chats, currentWorkspace } = props;
  const toggleState = useSelector((state) => state.toggleReducer);
  const {
    isChatSettingsOpen,
    isMemberDrawerOpen
  } = toggleState;

  const handleCloseMemberDrawer = () => {
    dispatch(memberDrawerOpen(false));
    //setTimeout(() => { dispatch(chatSettingsOpen(true, isChatSettingsOpen.chatId)) }, 300)
  };

  const member = isMemberDrawerOpen?.member
  if (!isMemberDrawerOpen?.show) return null;
  const openChat = async (userId) => {
    const existingChat = chats?.find(chat => chat?.roomType === 'direct' && chat?.members?.find(mem => mem?._id === userId));

    if (existingChat) {
      dispatch(updateQueryState(1));
      dispatch(clearMessagesState());
      dispatch(setRoomId(existingChat?._id));
      dispatch({ type: RESET_COUNTER, payload: existingChat?._id });
      navigate(`/chat/${existingChat?._id}`, { replace: true });
      dispatch({
        type: UPDATE_CURRENT_RECEIVER,
        payload: existingChat?.roomName,
      });

      dispatch(updateChatRoomMessagesReadBy(existingChat?._id, 'ChatsNavBar1'));
    } else {
      // create Chat
      const participants = [userId];
      dispatch(addChatRoom({ members: participants, roomName: null, workspaceId: currentWorkspace?._id }));
    }

    dispatch(memberDrawerOpen(false))
  }
  return (
    <div className="relative ">
      <div className='flex align-center sticky top-0 left-0 right-0 z-30 bg-white w-full p-3'>
        <Icon
          name='arrow-left.svg'
          className='mx-2 mr-4 md:size-7 size-5 hover:opacity-60'
          onClick={() => {
            handleCloseMemberDrawer();
          }}
        />
        <div className='font-18 font-bold'>
          <FormattedMessage id="USER_INFO" defaultMessage="User Info" />
        </div>
      </div>
      <div className='flex justify-center  flex-col items-center mt-10'>
        <img src={member?.member.avi} className='rounded-full sm:size-[150px] size-[90px] object-cover' />
        <div className='sm:text-2xl text-lg mt-4 font-bold text-center'>
          {member?.member?.fullName}
        </div>
        <div className='d-flex align-center pb-10 justify-center'>
          <div className='sm:text-lg text-md text-center mr-3'>
            {member?.member?.email}
          </div>
          <img
            onClick={() => {
              navigator.clipboard.writeText(member?.email || '');
              notification.success({
                message: intl.formatMessage({ id: 'EMAIL_COPIED_TO_CLIPBOARD', defaultMessage: 'Email copied to clipboard' }),
                placement: 'bottomRight',
              });
            }}
            src={'/images/icons/copy.svg'}
            className='sm:size-6 size-4'
            style={{ cursor: 'pointer' }}
            alt='icon'
          />
        </div>
        <div className='flex justify-center w-full'>
          <div className='btn-2 sm:p-5 p-2 sm:w-1/3 w-auto  mr-4 shadow-md sm:text-md text-sm' onClick={() => openChat(member?.member?._id)} >
            <FormattedMessage id="SEND_MESSAGE" defaultMessage="Send a message" />
          </div>
          <div className='btn-2 sm:p-5 p-2 sm:w-1/3 w-auto  shadow-md sm:text-md text-sm'>
            <FormattedMessage id="VIEW_FILES" defaultMessage="View Files" />
          </div>
        </div>
      </div>
    </div>
  );
};




function mapStateToProps(state) {
  return {
    chats: state.chatReducer.chats,
    drawerWidth: state.toggleReducer.drawerWidth,
    allUsers: state?.userReducer?.users,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
  };
}

export default connect(mapStateToProps)(MemberDrawer);
