
export const Mobile = () => {
  return (
    <div className="bg-white p-6 h-full w-full">
      <div className="mb-5 text-2xl">Productivity in Your Pocket</div>
      <img src='https://939023.p3cdn1.secureserver.net/wp-content/uploads/2024/11/Group-5141870.png' />
      <div className="bg-slate-200 h-[40vh] w-full mt-5 rounded-xl p-10 align-center flex flex-col">
        <div className="font-black text-lg">Download Workflo on your phone!</div>
        <div className="my-6">
          <img src='/images/icons/google.png' onClick={() =>  window.location = 'https://play.google.com/store/apps/details?id=com.veblocc.workflo&pcampaignid=web_share'} />
        </div>
        <div>
          <img src='/images/icons/apple-icon.png' onClick={() =>  window.location = 'https://apps.apple.com/ca/app/workflo-simple-tasks-chat/id6505021850'} />
        </div>
      </div>
    </div>
  )
}