import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Dropdown } from "antd";
import { useParams } from 'react-router-dom';

import { fieldTypes } from '../../../../js/constants';
import { getRandomColor } from '../../../../js/helper';

import Icon from '../../atoms/global/Icon';
import {
  addCustomFieldToProject,
  getProjectCustomField,
  updateProjectCustomField
} from '../../../../actions';
import '../../../../assets/style/modal.css';
import { FormattedMessage, useIntl } from 'react-intl';

const CustomFieldsModal = (props) => {
  const {
    isModalOpen,
    onClose,
    prefilledData,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState('1');
  const [selectedType, setSelectedType] = useState(prefilledData?.type || 'dropdown');

  const [options, setOptions] = useState(prefilledData?.possibleValues || []);
  const [optionInput, setOptionInput] = useState('');

  const [fieldName, setFieldName] = useState(prefilledData?.name || '');
  const [isUpdate, setIsUpdate] = useState(prefilledData?.isUpdate);

  const [suggestion, setSuggestion] = useState(prefilledData?.suggestion || '');

  const [multi, setMulti] = useState(prefilledData?.multi || false);

  useEffect(() => {
    setSelectedTab('1');
    setSelectedType(prefilledData?.type || 'dropdown');
    setFieldName(prefilledData?.name || '');
    setOptions(prefilledData?.possibleValues || []);
    setIsUpdate(prefilledData?.isUpdate);
    setSuggestion(prefilledData?.suggestion);
    setMulti(prefilledData?.multi);
  }, [prefilledData])

  const closeModal = () => {
    resetOptions();
    onClose();
  }

  const resetOptions = () => {
    setSelectedType('dropdown');
    setFieldName('');
    setOptionInput('');
    setIsUpdate();
    setOptions([]);
  }

  const isButtonDisabled = false;

  const footer = () => {
    return (
      <div
        className={`${isButtonDisabled && 'disabled'} text-center border-radius10`}
        onClick={() => {

          let newOptions = [];
          if (options) {
            newOptions = options.map((obj) => {
              delete obj.key;
              return obj;
            });
          }

          if (!!isUpdate) {
            dispatch(updateProjectCustomField(prefilledData?._id, {
              name: fieldName,
              possibleValues: newOptions,
              projectId: params.projectid,
              type: selectedType,
            }, async () => {
              await dispatch(getProjectCustomField(params.projectid))
              onClose();
            }))
          } else {
            dispatch(addCustomFieldToProject({
              name: fieldName,
              entityId: params.projectid,
              entityType: "project",
              type: selectedType,
              possibleValues: newOptions,
              suggestion,
              multi
            }, async () => {
              await dispatch(getProjectCustomField(params.projectid))
              resetOptions();
              onClose();
            }))
          }
        }}>
        <div className={`flex justify-center w-full p-2 rounded text-white bg-blue-400 hover:bg-blue-300 cursor-pointer`}>
          {isUpdate
            ? <FormattedMessage id="UPDATE" defaultMessage="Update" />
            : <FormattedMessage id="ADD" defaultMessage="Add" />
          }
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={`${isUpdate ? intl.formatMessage({ id: 'UPDATE_CUSTOM_FIELD', defaultMessage: 'Update Custom Field' }) : intl.formatMessage({ id: 'ADD_CUSTOM_FIELD', defaultMessage: 'Add Custom Field' })}`}
      open={isModalOpen}
      onCancel={closeModal}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        padding: '1rem',
      }}
      zIndex={999}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={selectedTab === '1' && footer()}>
      <div className={`flex flex-col justify-between transition duration-150 w-full`}>
        <div className='w-full overflow-y-scroll mt-3 px-4'>
          <div className='flex align-center justify-between mb-6'>
            <div className="space-y-3 w-2/3 mr-3">
              <label><FormattedMessage id="LABEL" defaultMessage="Label" /></label>
              <input
                type='text'
                value={fieldName}
                placeholder={intl.formatMessage({ id: 'NAME_YOUR_CUSTOM_FIELD', defaultMessage: 'Name your custom field' })}
                onChange={e => setFieldName(e?.target?.value)}
                className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
            </div>
            <div className="space-y-3 w-32">
              <label><FormattedMessage id="FIELD_TYPE" defaultMessage="Field Type" /></label>
              <Dropdown
                menu={{
                  items: fieldTypes?.map(item => ({
                    key: item?.key,
                    label: intl.formatMessage({ id: `${item?.label}`.toUpperCase(), defaultMessage: item?.label }),
                    onClick: () => {
                      setSelectedType(item?.key);
                      const predefinedOptions = fieldTypes?.find(f => f.key == item?.key);
                      if (item?.key === 'status' && predefinedOptions && !isUpdate) {
                        setOptions(predefinedOptions?.options || []);
                      }
                    }
                  }))
                }}
                trigger={['click']}
                placement='bottom'>
                <div className='w-full flex align-center justify-between bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                  <div className='font-14'><FormattedMessage id={`${selectedType}`.toUpperCase()} defaultMessage={selectedType} /></div>
                  <img
                    src={'/images/icons/show-more.svg'}
                    alt="icon"
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {(selectedType === 'dropdown' || selectedType === 'status') && <div className="mb-8">
            <div className='font-14 mb-3'><FormattedMessage id="OPTIONS" defaultMessage="Options" />:</div>

            <div style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
              {options && options.map((option, i) => {
                return (
                  <div className='option-field-row py-2 hover:bg-gray-100'>
                    <div className='option-field-row-label'>{option.label || option || ''}</div>
                    <div className='d-flex align-center'>
                      <Icon
                        name='delete-red.svg'
                        height='26px'
                        pl='10px'
                        onClick={() => {
                          const x = options.slice();
                          x.splice(i, 1);
                          setOptions(x || []);
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='d-flex align-center mt-1'>
              <input
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (!!optionInput) {
                      const x = options.slice();
                      x.push({
                        key: Math.random(),
                        label: optionInput || '',
                        metadata: {
                          color: getRandomColor(options.length || 0)
                        }
                      })
                      setOptions(x || []);
                      setOptionInput('');
                    }
                  }
                }}
                onChange={e => setOptionInput(e?.target?.value)}
                value={optionInput}
                placeholder={intl.formatMessage({ id: 'OPTION_NUMBER', defaultMessage: 'Option {number}'}, { number: options?.length + 1 })}
                className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />

              <Icon
                name='add-square.svg'
                height='40px'
                pl='10px'
                onClick={() => {
                  const x = options?.slice();

                  if (optionInput == '') {
                    return
                  }

                  x?.push({
                    key: Math.random(),
                    label: optionInput,
                    metadata: {
                      color: getRandomColor(options.length || 0)
                    }
                  })
                  setOptions(x || []);
                  setOptionInput('');
                }}
              />
            </div>
          </div>}
        </div>
      </div>
    </Modal>
  );
};


export default CustomFieldsModal;
