import React from "react";
import { FormattedMessage } from "react-intl";

const Plan = (props) => {
  const { title, description, color, price, cost, buttonAction, buttonLabel, features, image, isSetting } = props.plan;

  const getIntlLabel = text => `${text}`.replaceAll(' ', '_').toUpperCase();

  return (
    <div className={`${isSetting ? 'xl:w-[100px] w-[auto]' : 'w-[auto]'} pricing-column px-6`}>
      <div className="d-flex pt-10 h-[35%] justify-between" style={{ flexDirection: "column" }}>
        <div className="flex flex-row items-center">
          <div
            className="flex items-center justify-center size-4  rounded-full"
            style={{ backgroundColor: color }}
          >
            <img src={`/images/icons/${image}`} alt="Plan Icon" className={`${image === 'isotoxalStar.svg' ? 'w-3 h-3 ' : 'w-2 h-2'}`} />
          </div>
          <p className="text-lg ml-2 font-semibold">
            <FormattedMessage id={getIntlLabel(title)} defaultMessage={title} />
          </p>
        </div>
        <ul><FormattedMessage id={getIntlLabel(`${title}_DESCRIPTION`)} defaultMessage={description} /></ul>
        <p className="price">
          <FormattedMessage id={getIntlLabel(`${title}_PRICE`)} defaultMessage={price} values={{
            cost
          }} />
        </p>
        <div
          onClick={buttonAction}
          className="pricing-button-primary"
          style={{ marginTop: !price && 55 }}
        >
          <FormattedMessage id={getIntlLabel(buttonLabel)} defaultMessage={buttonLabel} />
        </div>
      </div>
      <img src="/images/icons/lineDash.svg" alt="dash" className=" mt-2" />
      <div className="h-[60%]">
        <p className='text-gray-500'>
          <FormattedMessage id="WHATS_INCLUDED" defaultMessage="What's Included" />
        </p>
        <ul
          className={`details-list pt-5 `}
        >
          {features.map((detail, index) => (
            <div className="flex flex-row gap-2 items-start">
              <div className="size-1 mt-2 flex rounded-full" style={{ backgroundColor: color }}>
              </div>
              <li key={index}>
                <FormattedMessage id={getIntlLabel(detail)} defaultMessage={detail} />
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Plan;
