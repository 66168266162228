import { Tooltip } from 'antd';
import React, { useMemo } from 'react';

const USER_COLORS_SET = new Map();

const UserAvatar = ({
    alt,
    classNames,
    fullName,
    userId,
    onClick,
    src,
    styles,
    tooltip,
}) => {
    const isDefaultAvatar = useMemo(
        () => !src || src?.includes('pmc/public/users/global/no_avi.png'),
        [src],
    );

    const defaultAvatarColor = useMemo(() => {
        const avatarColorFromMap = USER_COLORS_SET.get(userId);

        if (avatarColorFromMap) {
            return avatarColorFromMap;
        }

        const avatarColor =
            '#' +
            (
                (Number.parseFloat(`0.0${Math.trunc(Math.random() * 21)}`) *
                    0xffffff) <<
                0
            )
                .toString(16)
                .padStart(6, '0');

        USER_COLORS_SET.set(userId, avatarColor);

        return avatarColor;
    }, [userId]);

    const defaultAvatarStyles = useMemo(
        () => ({
            ...styles,
            backgroundColor: defaultAvatarColor,
        }),
        [defaultAvatarColor, styles],
    );

    const userInitials = useMemo(() => {
        if (!fullName) return;
        const splittedName = fullName?.split(' ');
        const result =
            splittedName.length > 1
                ? `${splittedName[0].slice(0, 1)}${splittedName[splittedName.length - 1].slice(0, 1)}`
                : splittedName[0].slice(0, 1);
        return result;
    }, [fullName]);

    const avatarRenderer = () =>
        src && isDefaultAvatar && userInitials ? (
            <div
                className={`d-flex align-center justify-center default-user-avatar ${classNames || ''}`}
                onClick={onClick}
                style={defaultAvatarStyles}
            >
                <div className="default-user-avatar-initials">
                    {userInitials}
                </div>
            </div>
        ) : (
            <div
                style={{ display: 'flex', flexShrink: 0, width: 'max-content', height: 'inherit' }}
            >
                <img
                    alt={alt || `${fullName} avatar`}
                    className={classNames}
                    onClick={onClick}
                    src={src}
                    style={styles}
                />
            </div>
        );

    return <Tooltip title={tooltip}>{avatarRenderer()}</Tooltip>;
};

export default UserAvatar;
