// Banner.jsx

import React from 'react';

const Banner = ({ message = '', onClose = () => {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        background: '#0074cc',
        color: '#fff',
        padding: '0.75rem 1.25rem',
        textAlign: 'center',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Lato',
      }}
    >
      {message}
    </div>
  );
};

export default Banner;
