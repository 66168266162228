import * as chatTypes from '../actions/types/chat.types.js';
import { filterMessage } from './common.js';

const initialState = {
  chats: [],
  loading: false,
  errors: [],
  singleMessage: {},
  currentReceiver: null,
  activeChat: null,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case chatTypes.GET_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
      };
    case chatTypes.GET_CHAT_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: false,
        error: [],
      };
    case chatTypes.GET_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        chats: [],
      };
    case chatTypes.UPDATE_SINGLE_CHAT:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat._id === action.payload._id) {
            return action.payload;
          } else return chat;
        }),
      };
    case chatTypes.UPDATE_SINGLE_MESSAGE:
      return {
        ...state,
        singleMessage: action.payload,
        loading: false,
      };
    case chatTypes.PUT_MESSAGE:
      return {
        ...state,
        singleMessage: filterMessage(state.chats, action),
      };
    case chatTypes.RESET_COUNTER:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat._id === action.payload) {
            chat.count = 0;
            return chat;
          } else {
            return chat;
          }
        }),
      };
    case chatTypes.INCREMENT_COUNTER:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat._id === action.payload) {
            chat.count = chat.count + 1;
            return chat;
          } else {
            return chat;
          }
        }),
      };

    case chatTypes.UPDATE_CURRENT_RECEIVER:
      return {
        ...state,
        currentReceiver: action.payload,
      };
    case chatTypes.ACTIVE_CHAT:
      return {
        ...state,
        activeChat: action.payload,
      };
    case chatTypes.UNSET_ACTIVE_CHAT:
      return {
        ...state,
        activeChat: null,
      };
    case chatTypes.INCREMENT_COUNTER:
      return {
        ...state,
        chats: state.chats.filter((chat) => {
          return chat._id !== action.payload;
        }),
      };
    default:
      return state;
  }
};

export default chatReducer;
