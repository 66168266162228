import React, { useState } from 'react';
import { getDateTextLocalized } from '../../../../js/functions';
import { useIntl } from 'react-intl';
import { getLocalizedTaskActivity } from '../../../../_locale/localesLookupTable';

const ActivityViewer = ({ chat, time }) => {
  const intl = useIntl();

  const truncatedContent = chat?.newValue ? (
    chat?.oldValue ? (
      `${
        chat.field === 'dueDate'
          ? getDateTextLocalized(chat?.oldValue, intl)
          : chat?.oldValue && intl.formatMessage({ id: `${chat?.oldValue}_TASK_ACTIVITY`.toUpperCase(), defaultMessage: chat?.oldValue })
      } → ${
        chat.field === 'dueDate'
          ? getDateTextLocalized(chat?.newValue, intl)
          : chat?.newValue && intl.formatMessage({ id: `${chat?.newValue}_TASK_ACTIVITY`.toUpperCase(), defaultMessage: chat?.newValue })
      }`
    ) : (
      chat?.field === 'isCompleted' || chat?.field === 'files' ?
        chat?.newValue && intl.formatMessage({ id: `${chat?.newValue}_TASK_ACTIVITY`.toUpperCase(), defaultMessage: chat?.newValue })
        : `${intl.formatMessage({ id: 'TO_TASK_ACTIVITY', defaultMessage: 'to' })} ${
          chat.field === 'assignee'
            ? intl.formatMessage({ id: 'ASSIGNEE_TASK_ACTIVITY', defaultMessage: 'Assignee' })
            : chat.field === 'dueDate'
              ? getDateTextLocalized(chat?.newValue, intl)
              : chat?.newValue && intl.formatMessage({ id: `${chat?.newValue}_TASK_ACTIVITY`.toUpperCase(), defaultMessage: chat?.newValue })
          }`
    )
  ) : '';

  return (
    <li className="relative pb-4 flex justify-between w-full">
      <div className="pl-6 w-full">
        <div className="text-sm font-medium text-indigo-600 mb-0.5 flex justify-between mb-3">
          <div className='uppercase'>
            {getLocalizedTaskActivity(chat?.action, intl, chat?.actor?.fullName)}
          </div>
          <div style={{ color: '#ADADAD' }}>{time}</div>
        </div>
        <div className="text-sm mb-2 font-medium text-slate-800 hover:text-slate-900">
          {truncatedContent}
        </div>
      </div>
      {/* Timeline element */}
      <div className="absolute top-0.5 -bottom-1 left-0.5 ml-px w-0.5 bg-slate-200" />
      <div className="absolute top-0.5 left-0 -ml-0.5 w-3 h-3 rounded-full bg-slate-400 border-2 border-slate-50" />
    </li>
  );
};

export default ActivityViewer;
