import { Input } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Icon from '../UI/atoms/global/Icon';
import { saveEmailLead } from '../../actions/signupAction';
import { isValidEmail } from '../../js/helper';
import { FormattedMessage, useIntl } from 'react-intl';

const GetStarted = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (!!email) {
      const isValid = isValidEmail(email);
      if (isValid) {
        setError(false);
        dispatch(saveEmailLead(email));
        navigate(`/signup?email=${email}`, { replace: true });
      } else {
        setError(true);
      }
    }
  }

  return (
    <div
      className='flex align-end justify-center h-[100vh] bg-indigo-500 overflow-hidden'>
      <img src={'/images/icons/BGSVG.svg'} style={{ filter: 'blur(1.5px)' }} />
      <div
        className='absolute w-[28vw] h-[28vh] px-20 bg-white top-[35%] shadow-2xl shadow-indigo-300 rounded-lg flex flex-col align-center justify-center'
        style={{ border: '1px indigo solid' }}
      >
        <div className='font-black text-3xl'>
          <FormattedMessage
            id="GET_STARTED_FOR_FREE"
            defaultMessage="Get started for <span>FREE</span>"
            values={{
              span: (msg) => (<span className='text-blue-600 font-black'>{msg}</span>)
            }}
          />
          
        </div>
        <div className='font-bold text-xl'>
          <FormattedMessage id="GET_PRODUCTIVE_RIGHT_AWAY" defaultMessage="and get productive right away!" />
        </div>
        <Input
          className={error ? 'mt-6 p-2 mb-2' : 'p-2 my-6'}
          placeholder={intl.formatMessage({ id: 'ENTER_YOUR_EMAIL', defaultMessage: 'Enter your e-mail' })}
          title='eqjkwndkjnqwkjdn'
          type="email"
          style={{ border: error && '2px red solid' }}
          onKeyDown={(e) => {
            e?.key == 'Enter' && handleSubmit()
          }}
          onChange={(e) => {
            setEmail(e.target?.value);
            setError(false);
            }} />
        {error && <div className='mb-2'>
          <FormattedMessage id="INVALID_EMAIL" defaultMessage="Invalid E-mail" />
          
        </div>}
        <div
          onClick={handleSubmit}
          className={`w-full text-center px-4 py-2 mb-5 bg-[#0074cc] ${!email && 'opacity-70'} font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
          <FormattedMessage id="NEXT" defaultMessage="Next" />
        </div>
        <Icon
          name='logo.svg'
          height='14px' />
      </div>
    </div>
  );
}

export default GetStarted;
