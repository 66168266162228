import { Switch } from 'antd';
import React from 'react';

export const SettingsCard = ({ options, header }) => {
  return (
    <div className="mb-3">
      <div className="account-card">
        <h2 className="p-4 font-semibold text-lg">{header}</h2>
        {options?.map((option, index) => (
          <div
            key={option?.label}
            onClick={option?.onPress}
            className="w-full cursor-pointer"
          >
            <div
              className={`account-card-row justify-between ${index !== options.length - 1 ? 'border-b-2 border-gray-200' : ''
                }`}
            >
              <div className="flex items-center gap-2">
                {option?.icon && <img src={`/images/icons/${option?.icon}`} className="mr-2" />}
                <span className="text-base text-[#002337]">{option?.label}</span>
              </div>
              {option?.rightComponent ? (
                <div>{option?.rightComponent}</div>
              ) : (
                <div className="form-switch">
                  {option?.toggle !== undefined ? (
                    <Switch
                      checked={option?.toggle}
                      onClick={option?.onSwitchChange}
                    />
                  ) : (
                    <img
                      src="/images/icons/chevron-right.svg"
                      alt="arrow-right"
                      className="w-3 h-3"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
