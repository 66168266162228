import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { useDispatch } from 'react-redux';
import { Dropdown } from 'antd';

import ProductNavBar from '../../UI/molecules/upper-navbar/ProductNavBar'

import '../../../assets/style/adminConsole.css';

import BillingInvoice from '../molecules/admin-console/BillingInvoice';
import MyPlans from '../molecules/admin-console/MyPlans';
import MyWorkspaces from '../molecules/admin-console/MyWorkspaces';
import MyAccount from '../molecules/admin-console/MyAccount';
import OrgDetails from '../molecules/admin-console/OrgDetails';

import {
  getOrganizationById,
} from '../../../actions';

const AdminConsole = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm m-10">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <Dashboard selectedIndex={selectedIndex} user={props.user} workspaces={props.workspaces} currentWorkspace={props?.currentWorkspace} />
        </div>
      </div>
    </>
  );
}

const Dashboard = ({ selectedIndex, user, workspaces, currentWorkspace }) => {
  const dispatch = useDispatch();
  const orgs = user?.organizations || [];

  const [selectedOrg, setSelectedOrg] = useState(orgs[0]);

  const onOrgChange = async (org) => {
    const orgById = await dispatch(getOrganizationById(org?._id));
    setSelectedOrg(orgById);
  }

  useEffect(() => {
    onOrgChange(orgs[0]);
  }, [user?.organizations])

  return (
    <div className="grow">
      <BillingInvoice selectedOrg={selectedOrg} />
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    user: state.userReducer.user,
    workspaces: state.workspaceReducer.getWorkspaces,
    currentWorkspace: state.workspaceReducer.currentWorkspace
  };
};

export default connect(mapStateToProp)(AdminConsole);