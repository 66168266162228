import React from "react";

import { BeatLoader, ClipLoader } from 'react-spinners'

import Icon from '../../atoms/global/Icon';

const Loader = (props) => {

  if (props?.newLoader) {
    return (
      <div 
      className="flex align-center justify-center h-full w-full"
      style={{
        background: props?.fullScreen && "#d5cfcf5c",
      }}>
        <div>
          <Icon
            name='logo.svg'
            height='30px' />
          <div className="container-loader mt-5 mx-2"></div>
        </div>
      </div>
    )
  }

  return (
    <>
      {
        props.isLoading &&
        <div className='loader-container'>
          {props.type === 'clip' ?
            <ClipLoader
              color={'black'}
              loading={props.isLoading}
              size={props.size}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> :
            <BeatLoader
              color={'black'}
              loading={props.isLoading}
              size={props.size}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          }
        </div>
      }
    </>
  );
};

export default Loader;
