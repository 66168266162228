import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteNotifications
} from "../../../../actions/notificationAction";

import EmptyState from '../../atoms/empty-state/EmptyState';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { activityNotificationsLookup, getLocalizedActivity } from '../../../../_locale/localesLookupTable';

import DashboardActivity from '../../../../src-new/components/dashboard/Activity';
import WorkspaceInvite from '../../../../src-new/components/invites/WorkspaceInvite';

const NotificationTooltip = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState(props.notifications);
  const [showUnread, setShowUnread] = useState(false);

  const user = useSelector((state) => state.userReducer.user);
  const pendingInvites = useSelector((state) => state.workspaceReducer.pendingWorkspaceInvites);

  useEffect(() => {
    setShowUnread(false);
  }, [])

  useEffect(() => {
    setNotifications(props.notifications);
  }, [props.notifications])

  const toggleReadUnread = () => {
    if (!showUnread) {
      const x = notifications.filter(notification => notification.assignee?.isViewed === showUnread);
      setNotifications(x);
    } else {
      setNotifications(props.notifications);
    }
    setShowUnread(!showUnread);
  }

  const noNotifcations = notifications == undefined || notifications?.length === 0;

  return (
    <div className="col-span-full xl:col-span-6 bg-white w-[20vw]">
      <header className="flex align-center justify-between px-5 py-4 border-b border-slate-100">
        <div>
          <h2 className="mr-5 font-20 font-light text-slate-800"><FormattedMessage id="RECENT_ACTIVITY" defaultMessage="Recent Activity" /></h2>
          <div
            className='font-14 text-blue-500 underline cursor-pointer hover:text-blue-600'
            onClick={() => {
              dispatch(deleteNotifications(user?._id))
            }}>
              <FormattedMessage id="CLEAR_ALL_NOTIFICATIONS" defaultMessage="Clear All Notifications" />
          </div>
        </div>
        <div className='flex align-center font-14'>
          <div className='mr-2'>{showUnread ? <FormattedMessage id="ALL" defaultMessage="All"/> : <FormattedMessage id="UNREAD" defaultMessage="Unread" />}</div>
          <Switch checked={showUnread} onChange={toggleReadUnread} />
        </div>
      </header>
      {/* Card content */}
      <ul className="my-1">
        {/* {pendingInvites?.map(invite => <WorkspaceInvite invite={invite} small />)} */}
        {!noNotifcations ? notifications?.length && notifications?.map(notification => <DashboardActivity notification={notification} />) :
          pendingInvites?.length <= 0 ? <div className='my-4'>
            <EmptyState
              header={intl.formatMessage({ id: 'YOU_ARE_ALL_CAUGHT_UP', defaultMessage: `You're All Caught Up!` })}
              description={intl.formatMessage({ id: 'SIT_AND_RELAX', defaultMessage: 'Sit back and relax, nothing to see here.' })}              
              src='/images/icons/no-mail.svg' />
          </div> : <></>
        }
      </ul>
    </div>
  );
};

export default NotificationTooltip;
