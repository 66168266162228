import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Input, Dropdown, Typography } from 'antd';

import {
  setDrawerWidth,
  isAiOpen,
  getMessageSummary,
  clearUnreadMessageSummary,
  getProjectById
} from '../../../actions';

import '../../../assets/style/taskDrawer.css';
import Icon from '../atoms/global/Icon';
import EmptyState from '../atoms/empty-state/EmptyState';
import { bulkAddTasks, getAiTasks } from '../../../actions/taskAction';
import { resetDrawer } from '../../../actions/toggleAction';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaskButtons } from '../atoms/buttons/TaskButtons';

const AiSidePanel = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { projects } = props;

  const { TextArea } = Input;

  const [data, setData] = useState([]);
  const [input, setInput] = useState('');
  const [toAction, setToAction] = useState('');
  const [fromAction, setFromAction] = useState('');
  const [error, setError] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedSection, setSelectedSection] = useState('');

  const handleAiSearch = async () => {
    const tasks = await dispatch(getAiTasks(input));
    tasks?.length > 0 && setData(tasks);
  }

  const height = window.screen.height;

  return (
    <div className='flex flex-col justify-between h-full overflow-hidden'>
      <div className='bg-[#0074cc] h-[15%] flex flex-col px-8' style={{ justifyContent: 'flex-end' }}>
        <div className='text-white font-black text-2xl mb-6'><FormattedMessage id="WORKFLO_AI" defaultMessage="Workflo AI" /></div>
      </div>
      <div className='h-[85%] flex flex-col justify-between'>
        <div className='flex flex-col justify-between h-full'>
          <div className='overflow-scroll m-5 mb-0 h-full'>
            {data?.map((name, i) => {
              return (
                <>
                  {i == 0 && (
                    <div>
                      <div className='font-16 font-bold'>
                        <FormattedMessage
                          id="SELECT_A_PROJECT_TO_ADD_TASKS"
                          defaultMessage="Select a <span>Project</span> to add tasks"
                          values={{
                            span: (msg) => (<span className='font-bold text-blue-600 px-1'>{msg}</span>)
                          }}
                        /></div>
                      <div className='flex align-center border rounded my-2'>
                        <Dropdown
                          menu={{
                            items: projects?.map((val) => ({
                              value: val?._id?.toString(),
                              label: (
                                <div className='d-flex p-1 align-center'>
                                  <div className={`font-14`}>{val?.title}</div>
                                </div>
                              ),
                              onClick: async () => {
                                setSelectedProject(val);
                                setError(false);
                                const x = await dispatch(getProjectById(val?._id));
                                await setSelectedSection(x?.sections?.[0]);
                              }
                            })),
                          }}
                          trigger={['click']}
                        >
                          <div className={`flex align-center justify-between text-center my-2 px-5 border-r-2 w-[50%] ${error && !selectedProject?._id && 'text-red-500'}`}>
                            <div className='font-14 truncate text-center capitalize'>
                              {selectedProject?.title || <FormattedMessage id="SELECT_PROJECT" defaultMessage="Select Project" />}
                            </div>
                            <img
                              src={'/images/icons/black-arrow-down.svg'}
                              style={{
                                cursor: 'pointer',
                                height: '10px',
                                width: '10px',
                              }}
                              alt='icon'
                            />
                          </div>
                        </Dropdown>
                        <Dropdown
                          menu={{
                            items: props?.selectedProject?.sections?.map((val) => ({
                              value: val?._id?.toString(),
                              label: (
                                <div className='d-flex p-1 align-center'>
                                  <div className={`font-14`}>
                                    <FormattedMessage id={`${val?.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={val?.title} />
                                  </div>
                                </div>
                              ),
                              onClick: () => {
                                setError(false);
                                setSelectedSection(val);
                              }
                            })),
                          }}
                          trigger={['click']}
                        >
                          <div className={`flex align-center justify-between text-center my-2 px-5 w-[50%] ${error && !selectedSection?._id && 'text-red-500'}`}>
                            <div className='font-14 truncate text-center capitalize'>
                              {selectedSection?.title
                                ? <FormattedMessage id={`${selectedSection.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedSection.title} />
                                : <FormattedMessage id="SELECT_SECTION" defaultMessage="Select Section" />
                              }
                            </div>
                            <img
                              src={'/images/icons/black-arrow-down.svg'}
                              style={{
                                cursor: 'pointer',
                                height: '10px',
                                width: '10px',
                              }}
                              alt='icon'
                            />
                          </div>
                        </Dropdown>
                      </div>
                      <div className='font-16 font-bold'><FormattedMessage id="RESULTS" defaultMessage="Results" />:</div>
                    </div>
                  )}
                  <div className='flex align-center p-1 my-2 border rounded-lg'>
                    <Icon
                      name='delete-red.svg'
                      className='ml-2'
                      height='24px'
                      width='24px'
                      onClick={() => {
                        let x = [...data];
                        x.splice(i, 1);
                        setData(x);
                      }}
                    />
                    <div className='w-[90%]'>
                      <Input
                        className='ml-2 font-black border-0 ative:border-0 focus:border-0'
                        value={name?.title}
                        style={{ outline: 'none', boxShadow: 'none' }}
                        onChange={(e) => {
                          let x = [...data];
                          x[i] = { title: e?.target?.value, description: x[i]?.description };
                          setData(x);
                        }} />
                      <Input
                        className='ml-2 border-0 ative:border-0 focus:border-0 text-sm font-light'
                        value={name?.description}
                        style={{ outline: 'none', boxShadow: 'none' }}
                        onChange={(e) => {
                          let x = [...data];
                          x[i] = { description: e?.target?.value, title: x[i]?.title };
                          setData(x);
                        }} />
                    </div>
                  </div>
                </>
              )
            })}
            {data?.length <= 0 && <div className='relative h-full'>
              <TextArea
                placeholder={intl.formatMessage({ id: 'PASTE_TEXT_HERE', defaultMessage: 'Paste your text here!' })}
                value={input}
                onChange={(e) => {
                  setInput(e?.target?.value)
                }}
                style={{
                  margin: 20,
                  padding: 10,
                  width: '90%',
                  minHeight: '90%',
                  maxHeight: '90%',
                  zIndex: 10,
                  backgroundColor: 'transparent'
                }}
              />
              {data?.length <= 0 && input?.length > 0 &&
                <div
                  style={{ zIndex: 11 }}
                  className='cursor-pointer h-12 w-12 absolute right-[15%] bottom-[8%] flex align-center justify-center'>
                  <TaskButtons onCancel={() => setInput('')} onHandle={handleAiSearch} />
                </div>}
              {data?.length == 0 && input?.length == 0 &&
                <div className='mx-20 absolute top-[20%]'>
                  <EmptyState
                    header={intl.formatMessage({ id: 'CONVERT_TEXT_TO_TASKS', defaultMessage: 'Convert text to tasks!' })}
                    description={intl.formatMessage({ id: 'CONVERT_TEXT_TO_TASKS_DESCRIPTION', defaultMessage: 'Dump your meeting notes or conversations here and convert them into tasks' })}                    
                    iconSize='h-60 w-60'
                    src='/images/icons/no-task.svg' />
                </div>}
            </div>}
          </div>

        </div>
        {data?.length > 0 && <div className='relative flex flex-col align-center'>
          {error && <div className='text-red-400'>{`Project ${!selectedSection?._id ? 'and section' : ''} cannot be blank!`}</div>}
          <div
            onClick={() => {
              if (selectedProject?._id && selectedSection?._id) {
                setError(false);
                dispatch(bulkAddTasks(data?.map(task => {
                  const data = {
                    taskTitle: task?.title,
                    taskDescription: task?.description,
                    isCompleted: false,
                    workspace: sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId'),
                    projects: [
                      {
                        projectId: selectedProject?._id,
                        section: selectedSection?._id,
                        position: 'top'
                      }
                    ],
                    dueDate: '',
                    addedTo: 'top'
                  };
                  return data;
                })));
                dispatch(resetDrawer());
              } else {
                setError(true);
              }
            }}
            className={`m-2 w-[95%] text-center px-4 py-2 bg-[#0074cc] font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
            <FormattedMessage id="CREATE_TASKS" defaultMessage="Create Tasks" />
          </div>
        </div>}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    summary: state.aiReducer.summary,
    projects: state.projectReducer.myProjects,
    selectedProject: state.projectReducer.getProjects,
  };
}

export default connect(mapStateToProps)(AiSidePanel);
