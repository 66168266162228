import React, { useEffect, useState } from "react";
import { Image, Progress, Tooltip } from "antd";

const TextAreaFileHolder = ({ fileImages, delAttachment, isLoading }) => {

  const [loadingProgress, setLoadingProgress] = useState(0);

  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.webm'];
  const unSupportedVideoExtensions = ['.wmv'];
  const imgExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
  const docExtensions = ['.doc', '.docx', '.txt', '.rtf'];
  const pptExtensions = ['.ppt', '.pptx'];
  const excelExtensions = ['.xls', '.xlsx'];


  const iconMap = {
    pdf: '/images/icons/pdf.svg',
    doc: '/images/icons/document-file.svg',
    video: '/images/icons/video-player.svg',
    csv: '/images/icons/excel.svg',
    ppt: '/images/icons/ppt.svg',
    excel: '/images/icons/excel.svg'
  }

  const LOADING_INTERVAL_TIME = 80;
  const LOADING_INCREMENT = 5;

  useEffect(() => {
    if (isLoading) {
      setLoadingProgress(0);
      const interval = setInterval(() => {
        setLoadingProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + LOADING_INCREMENT;
        });
      }, LOADING_INTERVAL_TIME);

      return () => clearInterval(interval);
    } else {
      setLoadingProgress(0);
    }
  }, [isLoading]);


  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {isLoading ?
        <div className="rounded-lg relative w-60 gap-2 py-3 px-3 flex flex-row items-center mr-3 border border-slate-300">
          <img
            src="/images/icons/document.svg"
            alt="PDF Icon"
            style={{
              width: 40,
              height: 40,
            }}
          />
          <div className="flex flex-col w-full">
            <div className="truncate text-sm text-gray-400 mt-1">Uploading...</div>
            <Progress percent={loadingProgress} showInfo={false} />
          </div>
        </div>
        :
        (fileImages
          ? fileImages.map((file, key) => {
            const fileName = file?.file?.name;
            const isVideo = videoExtensions.some(ext => fileName.includes(ext));
            const isImage = imgExtensions.some(ext => fileName.includes(ext));
            const isCsv = fileName.includes('.csv');
            const isPdf = fileName.includes('.pdf');
            const isDoc = docExtensions.some(ext => fileName.includes(ext));
            const isPpt = pptExtensions.some(ext => fileName.includes(ext));
            const isExcel = excelExtensions.some(ext => fileName.includes(ext));
            const isUnsupportedVideo = unSupportedVideoExtensions.some(ext => file?.name?.includes(ext));

            const icon = isVideo || isUnsupportedVideo ? iconMap.video : isCsv ? iconMap.csv : isPdf ? iconMap.pdf : isDoc ? iconMap.doc : isPpt ? iconMap.ppt : isExcel ? iconMap.excel : iconMap.doc;

            let fileType;

            switch (true) {
              case isVideo:
                fileType = "Video";
                break;
              case isUnsupportedVideo:
                fileType = "unsupportedVideo"
                break;
              case isPdf:
                fileType = "PDF";
                break;
              case isDoc:
                fileType = "Word";
                break;
              case isPpt:
                fileType = "PowerPoint";
                break;
              case isExcel:
                fileType = "Excel";
                break;
              case isCsv:
                fileType = "CSV";
                break;
              default:
                fileType = "Document";
            }


            return (
              <>
                {isImage ?
                  <div
                    key={key}
                    className="add-subject-img"
                    style={{ radius: 'none' }}
                  >
                    <Tooltip key={key} title={fileName}>
                      <div style={{ position: 'relative' }}>
                        <Image
                          width={'100%'}
                          height={'100%'}
                          style={{ marginRight: 20 }}
                          src={URL.createObjectURL(file?.file)}
                          preview={{ mask: '' }}
                        />
                        <div className="absolute right-1 top-1">
                          <img
                            className="cursor-pointer"
                            src="/images/icons/close.svg"
                            alt=""
                            style={{
                              height: '10px',
                              verticalAlign: 'text-top',
                            }}
                            onClick={(e) => {
                              delAttachment(file?.fileId);
                            }}
                          />
                        </div>
                      </div>
                    </Tooltip>
                  </div> :
                  <div className="rounded-lg relative w-60  py-3 px-3  mr-3 border border-slate-300">
                    <a
                      href={URL.createObjectURL(file?.file)}
                      download={fileName}
                      className="flex flex-row items-center gap-2"
                    >
                      <img
                        src={icon}
                        alt="PDF Icon"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                      <div className="flex truncate flex-col">
                        <div className="truncate text-sm">{fileName}</div>
                        <div className="text-xs text-gray-500">{fileType}</div>
                      </div>
                    </a>
                    <div className="absolute right-1 top-1">
                      <img
                        className="cursor-pointer"
                        src="/images/icons/close.svg"
                        alt=""
                        style={{
                          height: '6px',
                          width: '6px',
                          verticalAlign: 'text-top',
                          marginTop: '2px',
                          marginRight: '2px',
                          filter: 'invert(97%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(97%) contrast(100%)',
                        }}
                        onClick={(e) => {
                          delAttachment(file?.fileId);
                        }}
                      />
                    </div>
                  </div>
                }
              </>
            );
          })
          : ''
        )
      }
    </div>)
};

export default TextAreaFileHolder;
