import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TimeAgo from 'javascript-time-ago';

import {
  isChatOpen,
  msgId as messageId,
  isTicketOpen,
  isChatSettingsOpen,
  toggleNewTaskModal,
  isMemberDrawerOpen,
} from '../../../../actions';

import ReplyAvatar from '../../atoms/ticket-drawer/ReplyAvatar';
import Icon from '../../atoms/global/Icon';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';
import socket from '../../../../js/socket';
import { UPDATE_CURRENT_RECEIVER } from '../../../../actions/types/chat.types';

import {
  editMessageDetails,
  putMessage,
} from '../../../../actions/messageAction';

import FileHolder from '../../organisms/FileHolder';
import HtmlRenderer from '../../organisms/HtmlRenderer';
import TextEditor from '../editor/TextEditor';

import { Tooltip, notification, Dropdown } from 'antd';
import MessageActionsMenu from '../right-click-menu/MessageActionsMenu';
import UserAvatar from '../../atoms/ticket-drawer/UserAvatar';
import { FormattedMessage, useIntl } from 'react-intl';

const CommentSection = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    replies,
    comment,
    commentPadding,
    msgId,
    showName,
    chatId,
    senderId,
    files,
    replySenders,
    name,
    roomTitle,
    src,
    chats,
    sender,
    date
  } = props;

  const loggedUser = useSelector((state) => state.userReducer.user);

  const [reply, setReply] = useState(replies > 0 ? true : false);
  const [open, setOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [editedValue, setEditedValue] = useState(comment);
  const [isHovering, setIsHovering] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const editRef = useRef();

  const { users } = useSelector((state) => state.userReducer);
  const mentionOptions = users.map(user => ({
    id: user._id,
    value: user.fullName,
    icon: user.avi
  }));

  const paramsId = params.chatId || params.projectid;

  const openChat = () => {
    dispatch(messageId(msgId));
    dispatch(putMessage(paramsId, msgId));
    dispatch(isChatOpen(true, msgId));
    const params = new URLSearchParams(location.search);
    params.delete('selectedTask'); // Remove the specified parameter
    params.set('msgId', msgId)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

  };

  const closeEdit = () => {
    setIsHovering(false);
    setEditMessage(false);
  }

  const commentSectionRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        commentSectionRef.current &&
        !commentSectionRef.current.contains(e.target)
      ) {
        setReply(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      editRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100)
  };

  // Delete handler
  const deleteHandler = (messageId) => {
    socket.emit('delete-message', { messageId, userId: loggedUser?._id, context: 'chat' });
    notification.success({
      message: intl.formatMessage({ id: 'MESSAGE_DELETED', defaultMessage: 'Message deleted!' }),
      placement: 'bottomRight'
    })
    setOpen(false);
  };

  // Message Edit handler

  const messageEditHandler = (event, messageId) => {
    event.preventDefault();
    const messageDetails = {
      messageId, editedValue, roomId: chatId, userId: loggedUser?._id,
      context: 'chat'
    };

    dispatch(
      editMessageDetails({
        roomId: chatId,
        msgId: messageId,
        messageDescription: editedValue
      })
    );

    if (comment != editedValue) {
      notification.success({
        placement: 'bottomRight',
        message: intl.formatMessage({ id: 'MESSAGE_UPDATED', defaultMessage: 'Message updated!' })
      })
    }

    socket.emit('edit-message', messageDetails);
    closeEdit();
  };

  const timeAgo = new TimeAgo(intl.locale);
  const isMyMessage = senderId === localStorage.getItem('Id');

  const onMessageReply = () => {
    dispatch(isChatOpen(false));
    openChat();
    dispatch({
      type: UPDATE_CURRENT_RECEIVER,
      payload: roomTitle,
    });
  }

  const label = (label, desc, icon) => {
    return (
      <div className='flex align-center p-1'>
        <Icon
          className={`stroke-gray-500 hover-color`}
          height="25px"
          width="25px"
          name={icon}
        />
        <div className='ml-4'>
          <div className='font-14'>{label}</div>
          <div className='font-light font-12'>{desc}</div>
        </div>
      </div>
    )
  }

  const generateRightClickOptions = () => {
    const items = [
      {
        key: 1,
        label: label(
          intl.formatMessage({ id: 'COPY_MESSAGE', defaultMessage: 'Copy Message' }),
          intl.formatMessage({ id: 'COPY_MESSAGE_DESCRIPTION', defaultMessage: 'Copy this message to clipboard' }),
          'copyMessage.svg'
        ),
        onClick: () => {
          const plainString = comment.replace(/<[^>]+>/g, '');
          navigator.clipboard.writeText(plainString);
          notification.success({
            message: intl.formatMessage({ id: 'TEXT_COPIED', defaultMessage: 'Text copied!' }),
            placement: 'bottomRight'
          })
        }
      },
      {
        key: 2,
        label: label(
          intl.formatMessage({ id: 'REPLY', defaultMessage: 'Reply' }),
          intl.formatMessage({ id: 'REPLY_DESCRIPTION', defaultMessage: 'Start a reply thread to this message' }),
          'reply.svg'
        ),
        onClick: () => {
          const plainString = comment.replace(/<[^>]+>/g, '');
          if (replies?.length > 0) {
            onMessageReply();
          } else {
            props?.setReply({
              msgId: msgId,
              comment: plainString,
              sender: name,
              roomTitle: roomTitle
            });
          }
        }
      },
      {
        key: 3,
        label: label(
          intl.formatMessage({ id: 'CREATE_TASK', defaultMessage: 'Create Task' }),
          intl.formatMessage({ id: 'CREATE_TASK_DESCRIPTION', defaultMessage: 'Turn this message into a task' }),
          'addCircle.svg'
        ),
        onClick: () => {
          const plainString = comment?.replace(/<[^>]+>/g, '');
          dispatch(toggleNewTaskModal({
            value: true,
            title: plainString
          }));
        }
      },
      // {
      //   label: 'Quote message',
      //   onClick: async () => {
      //   }
      // },
    ];

    if (isMyMessage) {
      items.push({
        key: 4,
        label: label(
          intl.formatMessage({ id: 'DELETE_MESSAGE', defaultMessage: 'Delete Message' }),
          intl.formatMessage({ id: 'DELETE_MESSAGE_DESCRIPTION', defaultMessage: 'Remove message from everyone' }),
          'deleteMessageBin.svg'
        ),
        onClick: () => {
          setOpen(true);
        }
      });
      items.unshift({
        key: 5,
        label: label(
          intl.formatMessage({ id: 'EDIT_MESSAGE', defaultMessage: 'Edit Message' }),
          intl.formatMessage({ id: 'EDIT_MESSAGE_DESCRIPTION', defaultMessage: 'Update your initial message' }),
          'Edit.svg'
        ),
        onClick: () => {
          setEditMessage(true);
        }
      });
    }

    return items;
  }

  return (
    <>
      <ConfirmationModal
        title={intl.formatMessage({ id: 'DELETE_MESSAGE_CONFIRMATION', defaultMessage: 'Delete Message?' })}
        description={intl.formatMessage({ id: 'DELETE_MESSAGE_CONFIRMATION_DESCRIPTION', defaultMessage: 'Are you sure you want to delete this Message?' })}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={() => deleteHandler(msgId)}
      />


      <Dropdown
        menu={{
          items: generateRightClickOptions()
        }}
        trigger={['contextMenu']}
      >
        <div
          ref={commentSectionRef}
          className={`xl:px-8 relative hover:bg-[rgba(4,115,204,0.1)] border-l-4 hover:border-l-[#0473cc] ${clicked ? 'bg-[rgba(4,115,204,0.1)] border-l-4 border-l-[#0473cc]' : ''} ${showName ? 'pt-4 pb-2 px-3' : 'px-2 py-1'}`}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          onMouseOut={() => {
            setIsHovering(false);
          }}>
          {/* Header */}
          {showName && <header className="flex items-start mb-2">
            {/* Avatar */}
            <UserAvatar
              alt={name}
              classNames="rounded-full shrink-0 mr-3 h-12 w-12 object-cover font-bold"
              fullName={name}
              userId={senderId}
              src={src}
              styles={{ height: '50px', width: '50px' }}
            />
            {/* Meta */}
            <div className="grow">
              <div className="sm:flex items-start justify-between mb-0.5">
                {/* Message author */}
                <div className="xl:flex items-center mb-2 sm:mb-0">
                  <div
                    onClick={() => {
                      sender && dispatch(isMemberDrawerOpen(true, { member: sender }))
                    }}
                    className={`${!isMyMessage && 'hover:underline cursor-pointer'} font-18 font-semibold text-slate-800 text-left truncate`}>{name}</div>
                </div>
              </div>
              {/* Date */}
              <div className="text-xs font-medium text-slate-500">
                {date.toLocaleTimeString(intl.locale, { hour: 'numeric', minute: 'numeric' })}
              </div>
            </div>
          </header>
          }
          {isHovering && !editMessage && (
            <div className='commentBox' style={{ cursor: 'pointer' }}>
              <Tooltip title={intl.formatMessage({ id: 'REPLY', defaultMessage: 'Reply' })}>
                <>
                  <Icon
                    name='msg.svg'
                    height='20px'
                    className='hover:opacity-60'
                    pl='0px'
                    onClick={() => {
                      onMessageReply();
                    }}
                  />
                </>
              </Tooltip>
              {isMyMessage &&
                <>
                  <Tooltip title={intl.formatMessage({ id: 'EDIT', defaultMessage: 'Edit' })}>
                    <>
                      <Icon
                        name='Edit.svg'
                        height='20px'
                        className='hover:opacity-60'
                        pl='0px'
                        onClick={() => {
                          setEditMessage(!editMessage);
                        }}
                      />
                    </>
                  </Tooltip>

                  <Tooltip title={intl.formatMessage({ id: 'DELETE', defaultMessage: 'Delete' })}>
                    <>
                      <Icon
                        name='delete-red.svg'
                        height='20px'
                        className='hover:opacity-60'
                        pl='0px'
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </>
                  </Tooltip>
                </>}
            </div>
          )}


          {editMessage ? (
            <form
              onSubmit={(event) => messageEditHandler(event, msgId)}
              onKeyDown={(e) => {
                if (e?.code === 'Escape') {
                  messageEditHandler(e, msgId)
                }
              }}
            >
              <div ref={editRef} />
              <div className='chat-area'>
                <TextEditor
                  placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER', defaultMessage: 'Reply...' })}
                  onChange={(content) => {
                    setEditedValue(content)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent the default Enter behavior (submit)
                      messageEditHandler(e, msgId); // Call your save function here
                    }
                  }}
                  comment={editedValue}
                  mentionOptions={mentionOptions}
                />
                <div className='self-end flex'>
                  <div className='font-14 py-1.5 cursor-pointer px-3 hover:opacity-60' onClick={() => closeEdit()}><FormattedMessage id="CANCEL" defaultMessage="Cancel" /></div>
                  <div
                    onClick={(e) => messageEditHandler(e, msgId)}
                    className='font-14 py-1.5 cursor-pointer px-3 ml-2 bg-indigo-400 hover:bg-indigo-300 rounded text-white font-bold'><FormattedMessage id="SAVE" defaultMessage="Save" /></div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div
                style={{
                  marginTop: commentPadding,
                  textAlign: 'inherit',
                  cursor: 'pointer',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                }}
                onClick={async (e) => {

                  const url = e?.target?.href;

                  if (url) {
                    e.preventDefault();

                    let taskIdentifer = url.split('selectedTask=');

                    if (taskIdentifer.length > 1) {
                      const selectedTask = taskIdentifer[1];

                      const params = new URLSearchParams(location.search);
                      params.delete('msgId'); // Remove the specified parameter
                      params.set('selectedTask', selectedTask)
                      // Construct the new URL with updated parameters
                      const newUrl = `${location.pathname}?${params.toString()}`;

                      await dispatch(isChatOpen(false));

                      // Use navigate to replace the current URL
                      navigate(newUrl);

                      await dispatch(isTicketOpen(true));

                    } else {
                      window.open(url, '_blank').focus();
                    }
                  }

                  setReply(!reply);
                }}
                className='font-16 mx-12 my-2'
              >
                {/* Body */}
                {/* <div className="text-sm text-slate-800 mt-4 space-y-2" dangerouslySetInnerHTML={{ __html: comment }}></div> */}
                <HtmlRenderer html={comment} />
              </div>
              {!showName && isHovering && <div className="absolute top-1.5 left-2 text-xs font-medium mt-1 text-slate-500">
                {date.toLocaleTimeString(intl.locale, { hour: 'numeric', minute: 'numeric' })}
              </div>}
              <div className='d-flex ml-12'>
                {files &&
                  files.map((file) => {
                    return <FileHolder file={{ label: file?.name, url: file?.signedUrl }} />
                  })}
              </div>
            </>
          )}


          {replies.length > 0 && (
            <div className='reply-section ml-11 cursor-pointer' onClick={() => {
              onMessageReply();
            }}>
              <div className='d-flex align-center' style={{ marginTop: '8px' }}>
                <ReplyAvatar replySenders={replySenders} />
                <div
                  className='font-14'
                  color='#434343'
                  //onClick={openChat}
                  style={{
                    marginLeft: '6px',
                    marginRight: '6px',
                  }}
                >
                  {replies.length === 0
                    ? ''
                    : `${replies.length} ${intl.formatMessage({
                        id: `${intl.formatPlural(replies.length)}_REPLIES`.toUpperCase(),
                        defaultMessage: `${replies.length > 1 ? 'replies' : 'reply'}`
                      })}`
                  }
                </div>
                <div className='font-14' style={{ color: '#747474' }}>
                  {replies.length > 0 &&
                    `-  ${intl.formatMessage({
                        id: 'LAST_REPLY',
                        defaultMessage: 'Last reply'
                      })} ` + timeAgo.format(
                      new Date(
                        replies[replies.length - 1].createdAt
                      )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Dropdown>
    </>
  );
};

export default CommentSection;
