import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import 'antd/dist/reset.css';
import './assets/style/index.css';
import { Provider } from 'react-redux';
import configureStore from './js/store';
import Internationalization from './src-new/providers/InternationalizationProvider';

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/signin`,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      }}
    >
      <Internationalization>
        <App />
      </Internationalization>
    </Auth0Provider>
  </Provider>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js', {
      scope: './firebase-cloud-messaging-push-scope',
    })
    .then((registration) => {
      // Service worker registration was successful
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      // Service worker registration failed
      console.error('Service Worker registration failed:', error);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
