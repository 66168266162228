/* eslint-disable import/no-anonymous-default-export */
export default {
    DASHBOARD_SEARCH_PLACEHOLDER: 'Search   Tasks  |  Projects  |  Members',
    MARK_COMPLETE: 'Mark Complete',
    MARK_INCOMPLETE: 'Mark Incomplete',
    FILTERS: 'Filters',
    ASSIGNED_TO: 'Assigned to',
    DATE_RANGE: 'Date Range',
    TODAY: 'Today',
    FOR_TODAY: 'today',
    WEEK: 'Week',
    FOR_THIS_WEEK: 'Week',
    MONTH: 'Month',
    FOR_THIS_MONTH: 'month',
    INCOMPLETE: 'Incomplete',
    COMPLETED: 'Completed',
    UNASSIGNED: 'Unassigned',
    VIEW_TASKS: 'View Tasks',
    ADD_MEMBER: 'Add Member',
    LEAVE_FEEDBACK: 'Leave Feedback',
    JOIN_PROJECTS: 'Join Projects',
    TOTAL_TASKS: 'Total Tasks',
    TEAM_MEMBERS_AND_ROLES: 'Team Members and Roles',
    PROJECT_MEMBERS_AND_ROLES: 'Project Members and Roles',
    WORKSPACE_MEMBERS_AND_ROLES: 'Workspace Members and Roles',
    REMOVE_MEMBER_CONFIRMATION: 'Are you sure you want to remove a member?',
    REMOVE_FROM_PROJECT: 'Remove from project',
    REMOVE_FROM_TEAM: 'Remove from team?',
    REMOVE_FROM_WORKSPACE: 'Remove from workspace',
    JOIN_REQUESTS: 'Join Requests',
    NAME: 'Name',
    REQUEST_DATE: 'Request Date',
    ACTIONS: 'Actions',
    APPROVED: 'Approve',
    DENY: 'Deny',
    NO_NEW_JOINERS: 'No New Joiners!',
    SIT_AND_RELAX: 'Sit back and relax, nothing to see here.',
    PENDING_INVITES: 'Pending Invites',
    EMAIL: 'Email',
    INVITED_ON: 'Invited on',
    YOU_ARE_ALL_CAUGHT_UP: `You're All Caught Up!`,
    WANT_TO_INVITE_MORE: 'Want to invite more members to your project?',
    ROLE: 'Role',
    EMAIL_COPIED_TO_CLIPBOARD: 'Email copied to clipboard',
    GUEST: 'Guest',
    MEMBER: 'Member',
    ADMIN: 'Admin',
    RECENT_ACTIVITY: 'Recent Activity',
    CLEAR_ALL_NOTIFICATIONS: 'Clear All Notifications',
    ALL: 'All',
    UNREAD: 'Unread',
    FROM: 'From',
    VIEW_PLAN: 'View Plan',
    WORKSPACE_OVERVIEW: 'Workspace Overview',
    VIEWING_ALL_TASKS_FOR_SELECTED_DATE: `Viewing All {name}'s tasks for this {label}`,
    VIEWING_ALL_TASKS: `Viewing All {name}'s tasks`,
    NO_PRIORITY: 'No Priority',
    CRITICAL: 'Critical',
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
    NO_DUE_DATE: 'No Due Date',
    ON_TRACK: 'On Track',
    OVERDUE: 'Overdue',
    MAKE_PRIVATE: 'Make Private',
    NOTIFICATIONS: 'Notifications',
    CALENDAR: 'Calendar',
    BOARD: 'Board',
    LIST: 'List',
    SETTINGS: 'Settings',
    WORKSPACE_DASHBOARD: 'Workspace Dashboard',
    TEAM_DASHBOARD: 'Team Dashboard',
    WORKSPACE: 'Workspace',
    TEAM: 'Team',
    DASHBOARD: 'Dashboard',
    TEAMS_DIRECTORY: 'Teams Directory',
    UPGRADE: 'Upgrade',
    ALL_WORKSPACE_TEAMS: `All {workspaceTitle}'s teams`,
    OTHER_TASKS: '{value} Tasks',
    ZERO_TASKS: '{value} Tasks',
    ONE_TASK: '{value} Task',
    TWO_TASKS: '{value} Tasks',
    FEW_TASKS: '{value} Tasks',
    MANY_TASKS: '{value} Tasks',
    RESEND_EMAIL: 'Resend Email',
    VERIFY_EMAIL: 'Please verify your email address',
    VIEW_MEMBERS: 'View Members',
    CUSTOM_FIELDS: 'Custom Fields',
    TASKS: 'Tasks',
    HOME: 'Home',
    ACTIVITY: 'Activity',
    PROJECTS: 'Projects',
    CHATS: 'Chats',
    NEW_PROJECT: 'New Project',
    NEW_CHAT: 'New Chat',
    NEW_TASK: 'New Task',
    NEW_WORKSPACE: 'New Workspace',
    CREATE_NEW_TASK: 'Create new Task',
    SHOW_MENU: 'Show Menu',
    HIDE_MENU: 'Hide Menu',
    GO_TO_WORKSPACE_DASHBOARD: 'Go to workspace dashboard',
    INVITE_OTHERS_TO_WORKSPACE: 'Invite others to workspace',
    CREATE_NEW_WORKSPACE: 'Create new workspace',
    CHANGE_WORKSPACE: 'Change Workspace',
    WORKSPACE_SETTINGS: 'Workspace settings',
    YOU_WERE_ADDED_TO_WORKSPACE: 'You were added as a participant in {roomTitle} workspace',
    CONNECT_WITH_TEAM: 'Connect with the team!',
    FAMILIARIZE_YOURSELF: 'Familiarize yourself with the projects goals, scope, and team members.',
    COPY_PROJECT_LINK: 'Copy Project Link',
    PROJECT_LINK_COPIED: 'Project link copied to clipboard',
    UNMUTE_PROJECT: 'Unmute Project',
    MUTE_PROJECT: 'Mute Project',
    DUPLICATE_PROJECT: 'Duplicate Project',
    PROJECT_SETTINGS: 'Project Settings',
    CREATE_PROJECT: 'Create Project',
    RECENT_CHATS: 'Recent Chats',
    USER: 'User',
    NO_CHATS: 'No chats',
    ADD_PROJECT: 'Add Project',
    NO_PROJECTS: 'You Have No Projects!',
    CREATE_PROJECT_TO_GET_STARTED: 'Create a new project to get started.',
    ADDED_TO: 'Added to {value_1}',
    PROJECT: 'Project',
    ASSIGNED_TO_YOU: '{value_1}assigned to you',
    MARKED_DONE: '{value_1}marked Done',
    REOPENED: '{value_1}reopened',
    MOVED_TO: '{value_1}moved to {value_2}',
    MOVED_SMTH_TO: '{value_1}moved {value_2} to {value_3}',
    UPDATED: '{value_1}updated',
    UPDATED_SMTH: '{value_1}updated {value_2}',
    UPDATED_TO: '{value_1}updated {value_2} to {value_3}',
    ADDED_TO_BY: 'You were added to {value_1} by {value_2}',
    INVITE_MEMBERS: 'Invite Members',
    PROJECT_REQUESTS: 'Project Requests',
    PAST_INVITES: 'Past Invites',
    REQUESTED_DATE: 'Requested Date',
    SEND_INVITE: 'Send Invite',
    INVITE_TO_WORKSPACE: 'Invite to workspace',
    INVITE_FROM_WORKSPACE: 'Invite from workspace',
    EMAIL_INVITE_PLACEHOLDER: 'Email, comma separated',
    COLLABORATOR: 'Collaborator',
    COMMENTATOR: 'Commentator',
    REMOVE_THIS_MEMBER_CONFIRMATION: 'Are you sure you want to remove this member?',
    NO: 'No',
    YES: 'Yes',
    OWNER: 'Owner',
    NO_OPTIONS: 'No options',
    LETS_GET_STARTED: "Let's get started, what do you want to do first?",
    GO_TO_MY_PROJECT: 'Go to my project',
    CREATE_MY_FIRST_PROJECT: 'Create my first project',
    WELCOME_TO: 'Welcome to',
    MY_WORKSPACE: 'My workspace',
    CREATE_WORKSPACE: 'Create Workspace',
    OPEN_WORKSPACE: 'Open Workspace',
    WORKFLO_ADDVERTISEMENT_PHRASE_1: 'Workflo isn’t just another productivity tool—it’s a partner for your unique business needs.',
    TRY_DIFFERENT_EMAIL: 'Try a different email',
    NOT_SEEING_WORKSPACE: 'Not seeing your workspace?',
    WORKSPACE_INVITES: 'Workspace Invites',
    WORKSPACES_FOR: 'Workspaces for',
    NO_WORKSPACES_BUT_YOU_CAN_CREATE: 'You have no workspaces, but you can create one!',
    BACK: 'back',
    WELCOME_BACK: '👋 Welcome {fullName}! Wishing you a productive day ahead!',
    DELETE_WORKSPACE: 'Delete Workspace?',
    DELETE_WORKSPACE_CONFIRMATION: 'Are you sure you want to delete this workspace?',
    MY_WORKSPACES: 'My Workspaces',
    ADD_WORKSPACE: 'Add workspace',
    SHORT_FORM: 'Short Form: ',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SET_AS_WORKSPACE: 'Set as workspace',
    YOU: 'You',
    RESET_FILTERS: 'Reset Filters',
    SAVE: 'Save',
    SEARCH_FILTERS: 'Search Filters',
    SEARCH_PLACEHOLDER: 'Search…',
    INCOMPLETED_TASKS: 'Incompleted Tasks',
    COMPLETED_TASKS: 'Completed Tasks',
    COMPLETION: 'Completion',
    PRIORITY: 'Priority',
    ASSIGNEE: 'Assignee',
    CREATED_BY: 'Created by',
    SHOWING_COLUMNS: 'Showing columns:',
    ID: 'ID',
    TASK_NAME: 'Task Name',
    DATE: 'Date',
    CREATED: 'Created',
    UPDATED_FILTER: 'Updated',
    SECTION: 'Section',
    FILTERS_WERE_RESET: 'The filters were reset!',
    SET_DATE: 'Set Date',
    CANCEL: 'Cancel',
    UPDATE: 'Update',
    OCCURANCES: 'Occurances',
    AFTER: 'After',
    NEVER: 'Never',
    NOW: 'Now',
    UPON_COMPLETION: 'Upon Completion',
    ENDS: 'Ends',
    CREATE_NEW_TASKS: 'Create new tasks',
    RECURRING_TASK_SET_NOTIFICATION: 'Task set to recurring!',
    RECURRING: 'Recurring',
    EDIT_CAPITALIZED: 'EDIT',
    RECURRING_TASK: 'RECURRING TASK',
    REPEAT_EVERY: 'Repeat every',
    ADD_SUBTASK: 'Add Subtask',
    OPEN_SIDE_PANEL: 'Open Side Panel',
    OPEN_FULLSCREEN: 'Open Fullscreen',
    DELETE_TASK: 'Delete Task',
    COPY_TASK_LINK: 'Copy Task Link',
    LINK_COPIED: 'Link copied to clipboard',
    ATTACH_FILE: 'Attach File',
    MORE_OPTIONS: 'More options',
    MOVE_TO_SIDEVIEW: 'Move to sideview',
    CLOSE: 'Close',
    TASK_DESCRIPTION_UPDATED: 'Task Description Updated!',
    COMMENTS: 'Comments',
    CREATED_THIS_TASK: '{name} created this task.',
    CREATED_THIS_TASK_ACTIVITY: '<span>{name}</span> created this task.',
    SUBTASKS: 'Subtasks',
    NO_SUBTASKS: 'No Subtasks',
    FILES: 'Files',
    NO_FILES: 'No Files',
    ADD_TO_TASK: 'Add to task',
    CLICK_TO_EDIT: 'Click to edit',
    CREATED_BY_WHOM_AND_WHEN: 'Created by <span>{name}</span> on {date}',
    DUE_DATE: 'Due Date',
    RECURRING_TASKS: 'Recurring Tasks',
    SELECT_TASK: 'Select Task',
    SELECT_PROJECT: 'Select Project',
    ADD_TO_ANOTHER_PROJECT: 'Add to another project',
    ADD_NEW_FIELD: 'Add new field',
    DESCRIPTION: 'Description',
    ADD_DESCRIPTION: 'Add a description…',
    DROP_FILES_OR_BROWSE: 'Drop files to attach, or browse',
    ADD_ANOTHER_ASSIGNEE: 'Add another assignee',
    TOMORROW: 'Tomorrow',
    YESTERDAY: 'Yesterday',
    ADD_TO_COMMENTS: 'Add to comments',
    TASK_CHAT_PLACEHOLDER: 'Ask a question or post an update…',
    MESSAGE_DELETED: 'Message deleted!',
    MESSAGE_UPDATED: 'Message updated!',
    COPY_MESSAGE: 'Copy Message',
    COPY_MESSAGE_DESCRIPTION: 'Copy this message to clipboard',
    TEXT_COPIED: 'Text copied!',
    REPLY: 'Reply',
    REPLY_DESCRIPTION: 'Start a reply thread to this message',
    CREATE_TASK: 'Create Task',
    CREATE_TASK_DESCRIPTION: 'Turn this message into a task',
    DELETE_MESSAGE: 'Delete Message',
    DELETE_MESSAGE_DESCRIPTION: 'Remove message from everyone',
    EDIT_MESSAGE: 'Edit Message',
    EDIT_MESSAGE_DESCRIPTION: 'Update your initial message',
    DELETE_MESSAGE_CONFIRMATION: 'Delete Message?',
    DELETE_MESSAGE_CONFIRMATION_DESCRIPTION: 'Are you sure you want to delete this Message?',
    EDIT: 'Edit',
    DELETE: 'Delete',
    REPLY_PLACEHOLDER: 'Reply…',
    ADD_DUE_DATE: 'Add due date',
    ENTER_SUBRASK_TITLE: 'Enter subtask title',
    ADD_ASSIGNEE: 'Add Assignee',
    CURRENT_WORKSPACE: 'Current Workspace',
    SWITCH: 'Switch',
    LANGUAGE: 'Language',
    PREFERENCES: 'Preferences',
    ORGANIZATION: 'Organization',
    SUPPORT: 'Support',
    PRICING_AND_PLANS: 'Pricing & Plans',
    BILLING: 'Billing',
    MEMBERS: 'Members',
    OTHER_MEMBERS: '{value} Members',
    ZERO_MEMBERS: '{value} Members',
    ONE_MEMBER: '{value} Member',
    TWO_MEMBERS: '{value} Members',
    FEW_MEMBERS: '{value} Members',
    MANY_MEMBERS: '{value} Members',
    STORAGE_USED: 'Storage Used',
    'N/A': 'N/A',
    TERMS_AND_CONDITIONS: 'Terms & Conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    CONTACT_US: 'Contact Us',
    SIGN_OUT: 'Sign out',
    CHANGE_PROFILE_PIC: 'Change profile picture',
    ACTIVITY_TASK_CREATED: '{actor} created this task',
    ACTIVITY_TASK_UPDATE: '{actor} moved this task',
    ACTIVITY_TASK_MARK: '{actor} marked this task',
    ACTIVITY_TASK_CHANGED_CUSTOM_FIELD: '{actor} changed the {value_1}',
    ACTIVITY_TASK_UPLOADED: '{actor} uploaded',
    ACTIVITY_TASK_CHANGED_FIELD: '{actor} changed the {value_1}',
    ACTIVITY_TASK_CHANGED_FRIENDLY_FIELD: '{actor} changed {value_1}',
    ACTIVITY_TASK_TURNED_RECURRING: '{actor} turned recurring',
    ACTIVITY_TASK_ASSIGNMENT: '{actor} added {value_1} to assignees list',
    ACTIVITY_TASK_UNASSIGNMENT: '{actor} removed {value_1} from assignees list',
    ACTIVITY_TASK_FILE_DELETED: '{actor} deleted',
    ACTIVITY_TASK_USER_ADDED_TO_CHAT: '{actor} added',
    ACTIVITY_TASK_USER_DELETED_TO_CHAT: '{actor} removed',
    COMPLETE_TASK_ACTIVITY: 'complete',
    INCOMPLETE_TASK_ACTIVITY: 'incomplete',
    FROM_TASK_ACTIVITY: 'from',
    TO_TASK_ACTIVITY: 'to',
    OFF_TASK_ACTIVITY: 'off',
    ON_TASK_ACTIVITY: 'on',
    P1_TASK_ACTIVITY: 'critical',
    P2_TASK_ACTIVITY: 'high',
    P3_TASK_ACTIVITY: 'medium',
    P4_TASK_ACTIVITY: 'low',
    PRIORITY_TASK_ACTIVITY: 'priority',
    DUE_DATE_TASK_ACTIVITY: 'due date',
    DESCRIPTION_TASK_ACTIVITY: 'description',
    ASSIGNEE_TASK_ACTIVITY: 'Assignee',
    NILL_TASK_ACTIVITY: 'nill',
    BILLING_AND_INVOICES: 'Billing & Invoices',
    INVOICES: 'Invoices',
    PLAN: 'Plan',
    QUANTITY: 'Quantity',
    STATUS: 'Status',
    AMOUNT: 'Amount',
    NEXT_PAYMENT: 'Next Payment',
    PAYMENT_METHOD: 'Payment Method',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    LATEST_TASKS: 'Latest tasks',
    NOT_EXPECTED_RESULT: 'Not the results that you expected?',
    GIVE_FEEDBACK: 'Give Feedback',
    OOPS_NO_RESULTS: 'Oops, no results found!',
    NO_RESULTS_DESCRIPTION: 'Try searching with different keywords or adjust your filters to refine your search.',
    LEAVE_PROJECT_CONFIRMATION: 'Leave Project?',
    DELETE_PROJECT_CONFIRMATION: 'Delete Project?',
    LEAVE_PROJECT_CONFIRMATION_DESCRIPTION: 'Are you sure you want to leave {projectTitle}?',
    DELETE_PROJECT_CONFIRMATION_DESCRIPTION: 'Are you sure you want to delete {projectTitle}?',
    GENERAL: 'General',
    PROJECT_NAME: 'Project Name',
    RECEIVE_NOTIFICATIONS_DESCRIPTION: 'Receive notifications for project updates',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    PUBLIC_PRIVATE: 'Public / Private',
    PUBLIC_PRIVATE_DESCRIPTION: 'Anyone can join this project if marked public',
    PRIVATE: 'Private',
    PUBLIC: 'Public',
    CREATE_A_COPY_OF_PROJECT: 'Create a copy of project <span>{projectTitle}</span>',
    LEAVE_PROJECT: 'Leave Project',
    LEAVE_PROJECT_DESCTIPTION: 'Leave this project, you can always join again!',
    DELETE_PROJECT: 'Delete Project',
    DELETE_PROJECT_DESCRIPTION: 'Remove this project from your team and workspace',
    ADD_CUSTOM_FIELD: 'Add Custom Field',
    UPDATE_CUSTOM_FIELD: 'Update Custom Field',
    ADD: 'Add',
    LABEL: 'Label',
    NAME_YOUR_CUSTOM_FIELD: 'Name your custom field',
    FIELD_TYPE: 'Field Type',
    OPTIONS: 'Options',
    OPTION_NUMBER: 'Option {number}',
    TEXT: 'Text',
    DROPDOWN: 'Dropdown',
    NUMBER: 'Number',
    COST: 'Cost',
    MOVE_TASKS: 'Move Tasks',
    MOVE_TASKS_DESCRIPTION: 'Move existing project tasks into the new project',
    DUPLICATE_ALL_TASKS: 'Duplicate All Tasks',
    DUPLICATE_ALL_TASKS_DESCRIPTION: 'A copy of all tasks will be made into the new project',
    SET_NEW_DUE_DATE: 'Set new due date: {duration} {frequency} from now',
    SET_NEW_DUE_DATE_DESCRIPTION: 'Update all task due dates by this value',
    OTHER_DAY: 'days',
    ZERO_DAY: 'days',
    ONE_DAY: 'day',
    TWO_DAY: 'days',
    FEW_DAY: 'days',
    MANY_DAY: 'days',
    OTHER_WEEK: 'weeks',
    ZERO_WEEK: 'weeks',
    ONE_WEEK: 'week',
    TWO_WEEK: 'weeks',
    FEW_WEEK: 'weeks',
    MANY_WEEK: 'weeks',
    OTHER_MONTH: 'months',
    ZERO_MONTH: 'months',
    ONE_MONTH: 'month',
    TWO_MONTH: 'months',
    FEW_MONTH: 'months',
    MANY_MONTH: 'months',
    OTHER_YEAR: 'years',
    ZERO_YEAR: 'years',
    ONE_YEAR: 'year',
    TWO_YEAR: 'years',
    FEW_YEAR: 'years',
    MANY_YEAR: 'years',
    DAY: 'day',
    YEAR: 'year',
    DUPLICATING: 'Duplicating…',
    DUPLICATE: 'Duplicate',
    DELETE_FIELD: 'Delete Field',
    DELETE_FIELD_CONFIRMATION: 'Are you sure you want to delete {fieldName}?',
    NO_CUSTOM_FIELDS: 'You have no custom fields!',
    NO_CUSTOM_FIELDS_DESCRIPTION: 'Create one for this project, or change projects.',
    ADD_FIELD: 'Add Field',
    CUSTOM_FIELD_DELETED: 'Custom Field Deleted!',
    ADD_TO_PROJECT: 'Add to project',
    INVITE: 'Invite',
    DEFAULT: 'Default',
    ADD_TASK: 'Add Task',
    ADD_SECTION: 'Add Section',
    ALL_TASKS: 'All Tasks',
    LAST_UPDATED: 'Last Updated',
    ANY_TIME: 'Any time',
    THIS_WEEK: 'This week',
    THIS_MONTH: 'This month',
    EDIT_SECTION: 'Edit Section',
    DELETE_SECTION: 'Delete Section',
    UPDATED_SECTION_NOTIFICATION: "You've just updated a section",
    LOAD_MORE_TASKS: 'Load more tasks',
    TASK_TITLE: 'Task title',
    DELETE_SECTION_CONFIRMATION: 'Are you sure you want to delete this section',
    UPDATED_COLUMN_TITLE: 'Updated',
    DELETED_SECTION_NOTIFICATION: "You've just deleted a section",
    OOPS_SOMETHING_WRONG: 'Oops! something went wrong',
    SECTION_TITLE_IN_USE: "Section's Title already used",
    ADDED_SECTION_NOTIFICATION: "You've just added a new section",
    DUPLICATE_TASK: 'Duplicate Task',
    COPY_LINK: 'Copy Link',
    SECTION_NAME: 'Section Name',
    TASK_LINK_COPIED: 'Task link copied to clipboard',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    SUNDAY_SHORT: 'Sun',
    MONDAY_SHORT: 'Mon',
    TUESDAY_SHORT: 'Tue',
    WEDNESDAY_SHORT: 'Wed',
    THURSDAY_SHORT: 'Thu',
    FRIDAY_SHORT: 'Fri',
    SATURDAY_SHORT: 'Sat',
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    PREVIOUS_MONTH: 'Previous month',
    NEXT_MONTH: 'Next month',
    SUGGESTIONS: 'Suggestions',
    WORKSPACE_LIBRARY: 'Workspace Library',
    PROJECT_FIELDS: 'Project Fields',
    AI_SUGGESTED_FIELDS: 'AI Suggested Fields',
    SELECT_FIELD_TO_MODIFY: 'Select a field to modify it',
    SELECT_PROJECT_TO_VIEW_FIELDS: `Select a project to view it's custom fields.`,
    SAVE_FIELDS_TO_WORKSPACE_DESCRIPTION: 'Saving fields to workspace means you can reuse them across different projects!',
    FIELDS_GENERATED_BY_AI_DESCRIPTION: 'Fields automatically generated using A.I. based on your input',
    NO_CUSTOM_FIELDS_FOUND: 'No custom fields found, try to generate some more!',
    SEARCH: 'Search',
    INDUSTRY: 'Industry',
    STATUS_DESCRIPTION: 'Indicates current state of a project',
    ASSIGNEE_DESCRIPTION: 'Tag project members',
    DATE_DESCRIPTION: 'Add a custom date for tracking deadlines',
    TEXT_DESCRIPTION: 'Enter custom text to your workflow',
    DROPDOWN_DESCRIPTION: 'Select from custom predefined options',
    PRIORITY_DESCRIPTION: 'Set task importance levels',
    NUMBER_DESCRIPTION: 'Custom input of numerical values',
    COST_DESCRIPTION: 'Track expenses or costs related to tasks',
    SAVE_TO_WORKSPACE: 'Save to workspace',
    TO_DO: 'To Do',
    BLOCKED: 'Blocked',
    IN_PROGRESS: 'In Progress',
    IN_REVIEW: 'In Review',
    DONE: 'Done',
    INPUT_OPTION: 'Input Option',
    NAME_YOUR_PROJECT: 'Name your new project',
    TEMPLATE: 'Template',
    SELECT_TEMPLATE: 'Select Template',
    PREVIEW_OF_PROJECT_SECTIONS: 'Preview of project sections',
    MAKE_PRIVATE_DESCRIPTION: 'Private projects shield sensitive data from individuals who are not part of the project',
    TO_DO_DESCRIPTION: 'A list of tasks to be completed in the project.',
    IN_PROGRESS_DESCRIPTION: 'Tasks currently being worked on.',
    DONE_DESCRIPTION: 'Tasks that are completed.',
    SCRUM: 'Scrum',
    BACKLOG: 'Backlog',
    BACKLOG_DESCRIPTION: 'A list of user stories and tasks to be completed in the project.',
    SPRINT_PLANNING: 'Sprint Planning',
    SPRINT_PLANNING_DESCRIPTION: 'Planning the tasks and user stories for the upcoming sprint.',
    TASKS_IN_PROGRESS_DESCRIPTION: 'Tasks and user stories currently being worked on in the sprint.',
    CODE_REVIEW: 'Code Review',
    CODE_REVIEW_DESCRIPTION: 'Reviewing the completed tasks and user stories.',
    READY_FOR_TESTING: 'Ready for Testing',
    READY_FOR_TESTING_DESCRIPTION: 'Tasks and user stories that are ready for QA testing.',
    TASKS_DONE_DESCRIPTION: 'Tasks and user stories that are completed and ready for release.',
    STORY_POINTS: 'Story Points',
    SEVERITY: 'Severity',
    S1: 'S1',
    S2: 'S2',
    S3: 'S3',
    NOTES: 'Notes',
    BUSINESS: 'Business',
    LEADS: 'Leads',
    IN_TALKS: 'In Talks',
    FELL_THROUGH: 'Fell Through',
    SALES: 'Sales',
    "E-COMMERCE_ORDERS": 'E-commerce Orders',
    ORDER_RECEIVED: 'Order Received',
    PROCESSING: 'Processing',
    PACKED: 'Packed',
    SHIPPED: 'Shipped',
    DELIVERED: 'Delivered',
    RETURNED: 'Returned',
    ORDER_ID: 'Order ID',
    CUSTOMER_NAME: 'Customer Name',
    SHIPPING_METHOD: 'Shipping Method',
    EXPEDITED: 'Expedited',
    INTERNATIONAL: 'International',
    PAYMENT_STATUS: 'Payment Status',
    PAID: 'Paid',
    PENDING: 'Pending',
    REFUNDED: 'Refunded',
    REAL_ESTATE_DEALS: 'Real Estate Deals',
    NEW_LEADS: 'New Leads',
    PROPERTY_VIEWING: 'Property Viewing',
    OFFER_MADE: 'Offer Made',
    NEGOTIATION: 'Negotiation',
    CLOSED: 'Closed',
    ARCHIVED: 'Archived',
    PROPERTY_ID: 'Property ID',
    CLIENT_NAME: 'Client Name',
    DEAL_VALUE: 'Deal Value',
    AGENT_ASSIGNED: 'Agent Assigned',
    CONSTRUCTION_PROJECTS: 'Construction Projects',
    PLANNING: 'Planning',
    PERMITS_APPROVED: 'Permits Approved',
    GROUNDBREAKING: 'Groundbreaking',
    CONSTRUCTION_IN_PROGRESS: 'Construction in Progress',
    INSPECTION: 'Inspection',
    START_DATE: 'Start Date',
    ESTIMATED_COMPLETION: 'Estimated Completion',
    SITE_MANAGER: 'Site Manager',
    EVENTS: 'Events',
    CONCEPT: 'Concept',
    BUDGETING: 'Budgeting',
    VENDOR_BOOKING: 'Vendor Booking',
    PROMOTION: 'Promotion',
    EXECUTION: 'Execution',
    "WRAP-UP": 'Wrap-Up',
    EVENT_NAME: 'Event Name',
    EVENT_DATE: 'Event Date',
    VENUE: 'Venue',
    BUDGET: 'Budget',
    FUNDRAISERS: 'Fundraisers',
    DONOR_RESEARCH: 'Donor Research',
    OUTREACH: 'Outreach',
    PLEDGES_SECURED: 'Pledges Secured',
    CAMPAIGN_EXECUTION: 'Campaign Execution',
    "THANK-YOU_NOTES_SENT": 'Thank-You Notes Sent',
    CAMPAIGN_NAME: 'Campaign Name',
    FUNDRAISING_GOAL: 'Fundraising Goal',
    DONOR_NAME: 'Donor Name',
    DONATION_AMOUNT: 'Donation Amount',
    MARKETING_CAMPAIGNS: 'Marketing Campaigns',
    IDEATION: 'Ideation',
    CONTENT_CREATION: 'Content Creation',
    REVIEW: 'Review',
    LAUNCH: 'Launch',
    PERFORMANCE_ANALYSIS: 'Performance Analysis',
    TARGET_AUDIENCE: 'Target Audience',
    PLATFORM: 'Platform',
    SOCIAL_MEDIA: 'Social Media',
    PRINT: 'Print',
    HR_RECRUITMENT: 'HR Recruitment',
    APPLICATIONS_RECEIVED: 'Applications Received',
    SCREENING: 'Screening',
    INTERVIEWS_SCHEDULED: 'Interviews Scheduled',
    OFFER_EXTENDED: 'Offer Extended',
    HIRED: 'Hired',
    JOB_TITLE: 'Job Title',
    CANDIDATE_NAME: 'Candidate Name',
    INTERVIEW_STAGE: 'Interview Stage',
    PHONE: 'Phone',
    PANEL: 'Panel',
    FINAL: 'Final',
    RECRUITER_ASSIGNED: 'Recruiter Assigned',
    TASK_DETAILS: 'Task Details',
    PROJECT_DETAILS: 'Project Details',
    ADDITIONAL_DETAILS: 'Additional Details',
    SELECT_SECTION: 'Select Section',
    UPLOAD_IMAGE: 'Upload Image',
    REMOVE: 'Remove',
    WORKFLO_AI: 'Workflo AI',
    SELECT_A_PROJECT_TO_ADD_TASKS: 'Select a <span>Project</span> to add tasks',
    RESULTS: 'Results',
    CONVERT_TEXT_TO_TASKS: 'Convert text to tasks!',
    CONVERT_TEXT_TO_TASKS_DESCRIPTION: 'Dump your meeting notes or conversations here and convert them into tasks',
    PASTE_TEXT_HERE: 'Paste your text here!',
    CREATE_TASKS: 'Create Tasks',
    ADDED_TASKS: 'Added Tasks!',
    BETA: 'Beta',
    ENTERPRISE_PLAN: 'Enterprise Plan',
    BUSINESS_PLAN: 'Business Plan',
    FREE_PLAN: 'Free Plan',
    TEAM_PLAN: 'Team Plan',
    START_NEW_CHAT: 'Start a new chat',
    CHAT_NAME: 'Chat Name',
    NAME_NEW_CHAT_GROUP: 'Name your new chat group',
    SEARCH_MEMBERS: 'Search members', 
    CREATE_CHAT: 'Create Chat',
    REPLYING_TO: 'Replying to <span>{name}</span>',
    BE_FIRST_TO_SEND: 'Be the first to send <span>{roomTitle}</span> a message!',
    SEND_MESSAGE: 'Send a message',
    COMING_SOON: 'Coming soon!',
    TRY_OUT_AI_CHAT_SUMMARY: 'Try our AI chat summary!',
    LAST_REPLY: 'Last reply',
    REPLIES: 'Replies',
    OTHER_REPLIES: 'replies',
    ZERO_REPLIES: 'replies',
    ONE_REPLIES: 'reply',
    TWO_REPLIES: 'replies',
    FEW_REPLIES: 'replies',
    MANY_REPLIES: 'replies',
    ATTACH_FILE_TO_REPLY: 'Attach file to reply',
    ATTACH_FILE_TO_THREAD: 'Attach file to thread',
    ADD_TO_REPLIES: 'Add to replies…',
    ALSO_SEND_TO_CHAT: 'Also send to #{roomName}',
    USER_INFO: 'User Info',
    VIEW_FILES: 'View Files',
    GROUP_CHAT_TYPE: 'Group',
    DIRECT_CHAT_TYPE: 'Direct',
    PROJECT_CHAT_TYPE: 'Project',
    CHAT_SETTINGS: 'Chat - Settings',
    ME: 'Me',
    VIEW_MEMBERS_DESCRIPTION: 'View members from your chat',
    ADD_MEMBERS: 'Add Members',
    ADD_MEMBERS_DESCRIPTION: 'Add members from your workspace to this chat',
    EXIT_CHAT: 'Exit Chat',
    EXIT_CHAT_DESCRIPTION: 'Remove this chat from your workspace',
    MEMBERS_NOT_IN_CHAT: 'Members not in chat',
    SELECT_MEMBERS_TO_ADD: 'Select individual members to add to chat',
    SELECT_MEMBERS_FOR_MORE_DETAILS: 'Select individual members for more details',
    DELETE_TASK_CONFIRMATION: 'Are you sure you want to delete this task?',
    EXIT_CHAT_CONFIRMATION: 'Are you sure you want to exit this chat?',
    MUTE: 'Mute',
    UNMUTE: 'Unmute',
    ADD_TO_CHAT: 'Add to chat…',
    MESSAGE: 'Message',
    COPY_EMAIL: 'Copy Email',
    COPY_EMAIL_DETAILS: 'Copy {email} to clipboard',
    REMOVE_MEMBER_DETAILS: 'Remove {email} from Chat',
    SEND_DM_DETAILS: 'Send a direct message to {name}',
    CHANGE_ROOM_PICTURE: 'Change Room Picture',
    NEW_MEMBER_IN_WORKSPACE: 'New Member in Workspace!',
    GO_TO_COMMENT: 'Go to Comment',
    OPEN_IN_PROJECT: 'Open in Project',
    OPEN_IN_THREAD: 'Open in Thread',
    ADD_A_REPLY: 'Add a reply…',
    WORK_STYLE: 'Work style',
    MESSAGES: 'Messages',
    SEE_MORE: '…see more',
    CHOOSE_A_PLAN: 'Choose A Plan That Suits You',
    GO_TO_CHECKOUT_NOTE: "By clicking 'Go to Checkout' you agree to our <a>Terms of Service</a>",
    GO_TO_CHECKOUT: 'Go to Checkout',
    SEATS_NUMBER: 'Seats',
    SELECT_AMOUNT_OF_SEATS: 'Select the amount of Seats',
    SELECT_AMOUNT_OF_SEATS_DESCRIPTION: 'Seats are the amount of people who you are part of your company',
    PER_MEMBER_PER_MONTH: 'Per member, per month (before tax)',
    PER_MEMBER_PER_YEAR: 'Per member, per year (before tax)',
    CURRENT_LICENSE: 'Current License',
    DOWNGRADE: 'Downgrade',
    PURCHASE: 'Purchase',
    CONTACT_SALES: 'Contact Sales',
    BASIC_PLAN: 'Basic Plan',
    BASIC_PLAN_DESCRIPTION: 'Tailored for small teams with big needs',
    BASIC_PLAN_PRICE: '$0.00',
    "10MB_FILE_UPLOAD_LIMIT": "10MB/file upload limit",
    "200MB_TOTAL_STORAGE_LIMIT": "200MB total storage limit per workspace",
    UNLIMITED_WORKSPACES: "Unlimited Workspaces",
    "100_TASK_LIMIT": "100 task limit",
    "2_TEAMS_LIMIT": "2 teams limit",
    "5_PROJECTS_PER_TEAM": "5 Projects per team",
    CHAT_HISTORY_FOR_90_DAYS: "Chat history for 90 days",
    "3_CUSTOM_FIELDS": "3 Custom Fields",
    TEAM_PLAN_DESCRIPTION: 'For mid-sized teams managing diverse projects efficiently',
    TEAM_PLAN_PRICE: '$ {cost} / per user',
    ALL_OF_FREE_LICENSE: 'All of Free License',
    "1GB_FILE_UPLOAD_LIMIT": '1GB/file upload limit',
    "25GB_TOTAL_STORAGE_LIMIT": '25GB total storage limit per workspace',
    UNLIMITED_TEAMS: 'Unlimited teams',
    UNLIMITED_PROJECTS_PER_TEAM: 'Unlimited Projects per team',
    CHAT_HISTORY_FOREVER: 'Chat history forever',
    UP_TO_10_CUSTOM_FIELDS: 'Up to 10 custom fields',
    ENTERPRISE_PLAN_DESCRIPTION: 'Best for large scale needs',
    ENTERPRISE_PLAN_PRICE: 'Custom Pricing',
    ALL_OF_TEAM_PLAN: 'All of Team Plan',
    UNLIMITED_STORAGE_WORKSPACED: 'Unlimited storage workspaced',
    UNLIMITED_CUSTOM_FIELDS: 'Unlimited custom fields',
    VIDEO_CALLING: 'Video calling',
    AUTOMATIONS: 'Automations',
    STORAGE_LIMIT_YOU_DECIDE_ON: 'Storage limit you decide on',
    AUDIO_AND_VIDEO_CALLS: 'Audio and Video calls',
    "CUSTOM_AUTOMATIONS_&_INTEGRATIONS": 'Custom Automations & Integrations',
    "A.I._ASSISTANT": 'A.I. assistant',
    CUSTOM_DASHBOARDS: 'Custom dashboards',
    "SINGLE_SIGN-ON_(SSO)": 'Single Sign-on (SSO)',
    CRM: 'CRM',
    DOCUMENTS: 'Documents',
    WHATS_INCLUDED: "What's Included",
    LAUNCH_WORKFLO: 'Launch Workflo',
    BACK_TO_WORKFLO: 'Back to Workflo',
    NAME_YOUR_WORKSPACE: 'Name your new workspace',
    NAME_APPEARS_HERE: 'Name appears here',
    NEXT: 'Next',
    SKIP: 'Skip',
    GO_BACK: 'Go Back',
    WELCOME: 'Welcome to Workflo 🎉',
    EH_OH: 'Eh oh!',
    ALL_PROJECTS_WILL_BE_SHOWN_HERE: 'All your projects will be shown here',
    REDIRECTING_TO_WORKFLO: 'Redirecting to Workflo in {count}',
    REDIRECTING_TO_WORKFLO_PRICING: 'Redirecting to Workflo Pricing in {count}',
    PAYMENT_SUCCESS: 'Your payment was successful!',
    PAYMENT_ERROR: 'Your payment failed!',
    ONBOARDING_STEP_2_1: 'Tell us about your workspace',
    ONBOARDING_STEP_2_2: 'This workspace is for the film industry…',
    ONBOARDING_STEP_2_3: 'Why is this needed?',
    ONBOARDING_STEP_2_4: "We'd like to learn more about how you use your workspace so we can make it better suited to your needs and preferences!",
    ONBOARDING_STEP_3_1: "Let's create your first project!",
    PUBLIC_PROJECT: 'Public Project',
    PUBLIC_PROJECT_DESCRIPTION: 'Anyone from workspace can join this project',
    PRIVATE_PROJECT: 'Private Project',
    PRIVATE_PROJECT_DESCRIPTION: 'Only those invited can join this project',
    SWITCH_LABEL: 'Switch label',
    START_MY_WORKFLO: 'Start my Workflo!',
    SELECT_PROJECT_TEMPLATE: 'Select project template!',
    TEMPLATES_DESCRIPTION: 'Templates are sections within your projects where you can categorize tasks. Choose from our predefined templates, you can edit them after!',
    CREATE_WORKPLACE: 'Create Workplace',
    NICE_TO_HAVE_YOU: 'Nice to have you, {name} 🙌',
    INVALID_EMAIL: 'Invalid E-mail',
    ENTER_YOUR_EMAIL: 'Enter your e-mail',
    GET_PRODUCTIVE_RIGHT_AWAY: 'and get productive right away!',
    GET_STARTED_FOR_FREE: 'Get started for <span>FREE</span>',
    SELECT_PLACEHOLDER: 'Select…',
    ADD_TASK_PLACEHOLDER: 'Add Task…',
    SAVE_TO_WORKSPACE_LIBRARY: 'Save to workspace library',
    UPDATE_FIELD: 'Update Field',
    ZERO_SAVED_FIELDS_TO_WORKSPACE_DESCRIPTION: 'You have 0 saved workspace custom fields',
    TRY_CHANGING_INDUSTRY: 'Try changing industry to something like Tech',
    WORKFLO_ADDVERTISEMENT_PHRASE_2: 'Productivity Tailored To You',
    VIEW_REQUESTS: 'View Requests!',
    ADD_TO_PROJECTS: 'Add to Projects',
    EMAIL_ADDRESSES: 'Email Addresses',
    ADD_DESCRIPTION_NEW: 'Add description',
    REMOVE_PROJECT_FROM_TASK: 'Remove Project From Task',
    OPEN_LINK: 'Open Link',
    ENTER: 'Enter',
    ENTER_FIELD: 'Enter {field}',
    SELECT: 'Select',
    ENTER_NAME_FOR_FIELD: 'Enter name for {field}',
    LINK: 'Link',
    LINK_DESCRIPTION: 'Link to external page',
    WELCOME_BACK_SIGN_IN: 'Welcome back!',
    CONTINUE_GOOGLE: 'Continue with Google',
    CONTINUE_APPLE: 'Continue with Apple',
    SSO_LOGIN_FAILED: 'SSO Login Failed',
    EMAIL_REQUIRED: 'Email is required!',
    USER_NOT_EXIST: "This user doesn't exist. Please verify the email filled. Try another account or Sign up instead.",
    EMAIL_INVALID: 'This email is invalid!',
    EMAIL_ADDRESS: 'Email address',
    PASSWORD: 'Password',
    ENTER_PASSWORD: 'Enter Password',
    PASSWORD_REQUIRED: 'Password is required!',
    PASSWORD_INVALID: 'This password is invalid!',
    REMEMBER_PASSWORD: 'Remember password',
    FORGOTTEN_PASSWORD: 'Forgotten password?',
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    SIGN_UP: 'Sign up',
    NAME_BLANK: 'Name cannot be blank',
    EMAIL_BLANK: 'Email cannot be blank',
    PASSWORD_BLANK: 'Password cannot be blank',
    CONFIRM_PASSWORD_BLANK: 'Confirm Password cannot be blank',
    YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS: 'You must accept the Terms and Conditions',
    PASSWORDS_DONT_MATCH: "Passwords don't match",
    FULL_NAME: 'Full name',
    CONFIRM_PASSWORD: 'Confirm password',
    REENTER_PASSWORD: 'Re Enter Password',
    ACCEPT_TERMS_AND_CONDITIONS: "I accept Workflo's <link>Terms and Conditions</link>",
    HAVE_ACCOUNT: 'Have an account?',
    EMAIL_INVALID_FORMAT: 'Invalid email format.',
    EMAIL_VALID: 'Email is valid.',
    PASSWORD_VALID: 'Password is valid.',
    PASSWORD_MIN_LENGTH_LONG: 'Password must be at least {minLength} characters long.',
    PASSWORD_UPPERCASE_LETTER: 'Password must contain at least one uppercase letter.',
    PASSWORD_LOWERCASE_LETTER: 'Password must contain at least one lowercase letter.',
    PASSWORD_NUMERIC_DIGIT: 'Password must contain at least one numeric digit.',
    PASSWORD_SPECIAL_CHARACTER: 'Password must contain at least one special character.',
    FULL_NAME_BLANK: 'Full Name cannot be blank!',
    NAME_VALID: 'Name is valid.',
    ONLY_ALPHABETIC: 'Name must contain only alphabetic characters.'
}
