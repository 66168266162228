import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import socket from '../../../../js/socket';
import { Dropdown } from 'antd';

import {
  editUser,
  getUser,
  openInvitePanel,
  resendConfirmEmail,
  signedUploadUrl,
  uploadUserAvi,
} from '../../../../actions';
import { useAuth0 } from '@auth0/auth0-react';
import { logout } from '../../../../actions/logoutAction';
import { SmallCircularButton } from '../../../../src-new/components/buttons/SmallCircularButton';
import { useNavigate } from 'react-router-dom';
import { SettingsCard } from '../../../../src-new/components/cards/SettingsCard';
import { getUserLicense } from '../../../../js/functions';
import { isUserDrawerOpen } from '../../../../actions/toggleAction';
import { languageLookup, SUPPORTED_LOCALES } from '../../../../_locale/localesLookupTable';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import WorkspaceInvite from '../../../../src-new/components/invites/WorkspaceInvite';
import UserAvatar from '../../atoms/ticket-drawer/UserAvatar';
import Banner from '../../organisms/Banner';

function MyAccount({ user, currentWorkspace, pendingInvites }) {
  const intl = useIntl();
  const [notifications, setNotifications] = useState(
    user?.preferences?.notifications
  );
  const { logout: auth0Logout } = useAuth0();
  const [newPicture, setNewPicture] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(user?.locale || navigator.language || 'en');
  const [showEmailBanner, setShowEmailBanner] = useState(
    user && user?._id && !user?.isVerified
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const license = getUserLicense(
    user,
    sessionStorage.getItem('organization') ||
    localStorage.getItem('organization')
  );

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('locale.change', { detail: selectedLocale }));
  }, [selectedLocale])

  useEffect(() => {
    socket.on('user-updated', (id) => {
      dispatch(getUser(id));
    });

    dispatch(getUser(user._id));
  }, [dispatch]);

  const handlePreferencesUpdate = (e) => {
    setNotifications(!notifications);
    dispatch(
      editUser(
        {
          _id: user._id,
          preferences: {
            notifications: !notifications,
          },
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  };

  const handleResendEmailClick = () => {
    // Dispatch the action when "Resend Email" is clicked
    dispatch(resendConfirmEmail(user));
    setShowEmailBanner(false);
  };

  const handleLocaleUpdate = (locale) => {
    setSelectedLocale(locale);

    dispatch(
      editUser(
        {
          _id: user._id,
          preferences: {
            notifications: notifications,
          },
          locale
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  }

  const PreferencesOptions = [
    {
      label: intl.formatMessage({ id: 'NOTIFICATIONS', defaultMessage: 'Notifications' }),
      icon: 'bell-notifications.svg',
      toggle: notifications,
      onSwitchChange: handlePreferencesUpdate,
    },
    {
      label: intl.formatMessage({ id: 'LANGUAGE', defaultMessage: 'Language' }),
      rightComponent: <Dropdown
        className={`flex items-center space-x-2 hover:bg-slate-50 px-1 py-2 h-100 cursor-pointer`}
        trigger={['click']}
        menu={{
          items: SUPPORTED_LOCALES?.map(locale => ({
            key: locale,
            label: languageLookup[locale],
            onClick: () => handleLocaleUpdate(locale)
          })),
          style: {
            overflow: 'scroll'
          }
        }}
        placement='bottomCenter'
      >
        <div className='w-full flex align-center justify-between bg-white text-xs font-medium text-slate-800 px-4 py-2 rounded-lg h-10 border  border-gray-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
          <div className='font-14'>{languageLookup[selectedLocale]}</div>
          <img
            src={'/images/icons/show-more.svg'}
            alt="icon"
          />
        </div>
      </Dropdown>
    },
  ];

  const PlanOptions = [
    {
      label: (
        <div>
          <p><FormattedMessage id="PRICING_AND_PLANS" defaultMessage="Pricing & Plans" /></p>
          <p style={{ fontSize: 12 }}>
            {`${intl.formatMessage({ id: `${license}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: license })}`.toUpperCase()}
          </p>
        </div>
      ),
      icon: 'pricing.svg',
      onPress: () => navigate('/pricing'),
    },
    {
      label: intl.formatMessage({ id: 'BILLING', defaultMessage: 'Billing' }),
      icon: 'billing.svg',
      onPress: () => {
        navigate('/admin-console');
      },
    },
    {
      label: intl.formatMessage({ id: 'MEMBERS', defaultMessage: 'Members' }),
      icon: 'seat.svg',
      rightComponent: (
        <p className="mr-2">
          <FormattedPlural
            value={currentWorkspace?.members?.length || 0}
            other={
              intl.formatMessage({ id: 'OTHER_MEMBERS', defaultMessage: '{value} Members' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
            zero={
              intl.formatMessage({ id: 'ZERO_MEMBERS', defaultMessage: '{value} Members' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
            one={
              intl.formatMessage({ id: 'ONE_MEMBER', defaultMessage: '{value} Member' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
            two={
              intl.formatMessage({ id: 'TWO_MEMBERS', defaultMessage: '{value} Members' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
            few={
              intl.formatMessage({ id: 'FEW_MEMBERS', defaultMessage: '{value} Members' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
            many={
              intl.formatMessage({ id: 'MANY_MEMBERS', defaultMessage: '{value} Members' },
                { value: currentWorkspace?.members?.length || 0 }
              )
            }
          />
        </p>
      ),
      onPress: () => {
        dispatch(isUserDrawerOpen(false));
        dispatch(
          openInvitePanel(true, {
            pastInvites: currentWorkspace?.pendingInvites || [],
            existingMembers: currentWorkspace?.members || [],
            selectWorkspaceMembers: false,
            type: 'Workspace',
          })
        );
      },
    },
    {
      label: intl.formatMessage({ id: 'STORAGE_USED', defaultMessage: 'Storage Used' }),
      icon: 'storage.svg',
      rightComponent: <p className="mr-2"><FormattedMessage id="N/A" defaultMessage="N/A" /></p>,
      onPress: () => {
        navigate('/admin-console');
      },
    },
    {
      label: intl.formatMessage({ id: 'ADD_WORKSPACE', defaultMessage: 'Add workspace' }),
      icon: 'addblack.svg',
      onPress: () => navigate('/create-workspace'),
    },
  ];

  const SupportOptions = [
    {
      label: intl.formatMessage({ id: 'TERMS_AND_CONDITIONS', defaultMessage: 'Terms & Conditions' }),
      icon: 'terms-conditions.svg',
      onPress: () => {
        window.open('https://workflo.com/terms-and-conditions/', '_blank');
      },
    },
    {
      label: intl.formatMessage({ id: 'PRIVACY_POLICY', defaultMessage: 'Privacy Policy' }),
      icon: 'privacy-policy.svg',
      onPress: () => {
        window.open('https://workflo.com/privacy/', '_blank');
      },
    },
    {
      label: intl.formatMessage({ id: 'LEAVE_FEEDBACK', defaultMessage: 'Leave Feedback' }),
      icon: 'star-feedback.svg',
      onPress: () => {
        window.open(
          'https://workflo.canny.io/bugs-and-feature-requests',
          '_blank'
        );
      },
    },
    {
      label: intl.formatMessage({ id: 'CONTACT_US', defaultMessage: 'Contact Us' }),
      icon: 'contact-mail.svg',
      onPress: () => {
        window.open('https://workflo.com/contact/', '_blank');
      },
    },
  ];
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const newFile = await toBase64(event.target.files[0]);
    setNewPicture(newFile);
    const { signedUrl, path } = await dispatch(
      uploadUserAvi({
        fileName: file?.name,
        type: file?.type,
        fileSize: file?.size,
      })
    );
    if (signedUrl) {
      await dispatch(signedUploadUrl(signedUrl, file));
    }
    await dispatch(
      editUser(
        {
          _id: user._id,
          avi: path,
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  };

  return (
    <div className="p-6 space-y-6 bg-[#F9F9F9]">
      <section>
        <div className="account-card">
          <div className="account-card-row">
            <UserAvatar
              tooltip={intl.formatMessage({ id: 'CHANGE_PROFILE_PIC', defaultMessage: 'Change profile picture' })}
              alt="User upload"
              classNames="md:size-16 size-12 rounded-full cursor-pointer"
              fullName={user?.fullName}
              userId={user?._id}
              onClick={() => {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/jpeg,image/png';
                fileInput.onchange = handleFileChange;
                fileInput.click();
              }}
              src={newPicture || user?.avi}
              styles={{
                height: '50px',
                width: '50px',
                objectFit: 'cover',
              }}
            />
            <div>
              <p className="md:text-2xl text-lg font-semibold">
                {user?.fullName}
              </p>
              <p className="md:text-sm text-xs text-[rgba(0,35,55,0.4)]">
                {user?.email}
              </p>
            </div>
          </div>
          {showEmailBanner && (
            <Banner
              onClose={() => setShowEmailBanner(false)}
              message={
                <div>
                  <FormattedMessage id="VERIFY_EMAIL" defaultMessage="Please verify your email address" />
                  <a
                    style={{
                      color: 'white',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                    onClick={handleResendEmailClick}
                  >
                    <FormattedMessage id="RESEND_EMAIL" defaultMessage="Resend Email" />
                  </a>
                </div>
              }
            />
          )}
          <div className="account-card-row justify-between">
            <div>
              <p className="md:text-lg text-md font-semibold">
                {currentWorkspace.title}
              </p>
              <p className="md:text-sm text-xs text-[rgba(0,35,55,0.4)]">
                <FormattedMessage id="CURRENT_WORKSPACE" defaultMessage="Current Workspace" />
              </p>
            </div>
            <div>
              <SmallCircularButton
                onClick={() => {
                  navigate('/select-workspace');
                }}
                text={intl.formatMessage({ id: 'SWITCH', defaultMessage: 'Switch' })}
              />
            </div>
          </div>
          {/* <div className='my-2'>
            {pendingInvites?.map(invite => <WorkspaceInvite invite={invite} />)}
          </div> */}
        </div>
      </section>
      <section>
        <SettingsCard options={PreferencesOptions} header={intl.formatMessage({ id: 'PREFERENCES', defaultMessage: 'Preferences' })} />
        <SettingsCard options={PlanOptions} header={intl.formatMessage({ id: 'ORGANIZATION', defaultMessage: 'Organization' })} />
        <SettingsCard options={SupportOptions} header={intl.formatMessage({ id: 'SUPPORT', defaultMessage: 'Support' })} />
      </section>
      <section>
        <div
          onClick={() => {
            auth0Logout({
              logoutParams: { returnTo: `${window.location.origin}/signin` },
            });
            dispatch(logout());
          }}
          className="p-2 text-base bg-[#FF5353] shadow-[rgba(30,30,30,0.08)] shadow-lg text-white rounded-md w-full text-center hover:bg-red-300 cursor-pointer"
        >
          <FormattedMessage id="SIGN_OUT" defaultMessage="Sign out" />
        </div>
      </section>
    </div>
  );
}

export default MyAccount;
