import React from 'react';
import { FormattedMessage } from 'react-intl';

function LastStep({ handleClick, name }) {
  return (
    <div className="px-4 py-8">
    <div className="max-w-md mx-auto">

      <div className="text-center">
        <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
          <circle className="text-emerald-100" cx="32" cy="32" r="32" />
          <path className="text-emerald-500" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
        </svg>
        <h1 className="text-3xl text-slate-800 font-bold mb-8">
          <FormattedMessage
            id="NICE_TO_HAVE_YOU"
            defaultMessage="Nice to have you, {name} 🙌"
            values={{
              name
            }}
          />
        </h1>
        <div className="btn bg-blue-500 hover:bg-blue-600 text-white py-2 cursor-pointer" onClick={handleClick}>
          <FormattedMessage id="START_MY_WORKFLO" defaultMessage="Start my Workflo!" />
        </div>
      </div>

    </div>
  </div>
  );
}

export default LastStep;