import React, { useState, useEffect } from "react";
import { Switch, Dropdown } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { getWorkspaceById, getProjectTemplates, addProject } from "../../actions";
import { getSectionColor } from "../../js/helper";
import { resetDrawer } from "../../actions/toggleAction";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';


const AddProject = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    templates,
    workspaceId,
  } = props;

  const [selectedTemplate, setSelectedTemplate] = useState(templates?.find(t => t?.default));
  const [projectName, setProjectName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const isButtonDisabled = !selectedTemplate || !projectName;

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProjectTemplates());
  }, []);

  useEffect(() => {
    setSelectedTemplate(templates?.find(t => t?.default))
  }, [templates]);

  const createProject = async (name, templateId, isPrivate) => {

    if (isButtonDisabled) {
      return;
    }

    const project = await dispatch(
      addProject(
        {
          title: name,
          workspace: workspaceId,
          template: templateId,
          access: isPrivate ? 'private' : 'public'
        },
        async () => {
          setSelectedTemplate({ title: 'Select Team' });
          setSelectedTemplate(undefined);
          setProjectName('');
          dispatch(getWorkspaceById());
          dispatch(resetDrawer());
        }
      )
    );
    
    if (project?._id) {
      const url = `/${project?._id?.toString()}/board`;
      navigate(url);
    }

  };

  return (
    <div className='h-[83vh] w-full'>
      <div className='bg-[#0074cc] h-40 flex flex-col p-8' style={{ justifyContent: 'flex-end' }}>
        <div className='text-white font-black text-2xl'>
          <FormattedMessage id="CREATE_PROJECT" defaultMessage="Create Project" />
        </div>
      </div>
      <div className='p-4 overflow-hidden h-full flex flex-col align-center justify-between w-full'>
        <div className='px-2 w-full'>
          <div className='flex-col align-center justify-between mb-6'>
            <div className="space-y-3 w-full">
              <label><FormattedMessage id="PROJECT_NAME" defaultMessage="Project Name" /></label>
              <input
                type='text'
                value={projectName}
                placeholder={intl.formatMessage({ id: 'NAME_YOUR_PROJECT', defaultMessage: 'Name your new project' })}
                onChange={e => setProjectName(e?.target?.value)}
                className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
            </div>
            <div className="p-2 bg-slate-200 mt-8 pb-5 rounded-xl">
              <div className="w-full py-4 flex align-center justify-center">
                <div className="w-full px-2 space-y-3">
                  <label><FormattedMessage id="TEMPLATE" defaultMessage="Template" /></label>
                  <Dropdown menu={{
                    items: templates.map(template => {
                      return ({
                        key: template?._id,
                        label: intl.formatMessage({ id: `${template?.name}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: template?.name }),
                        onClick: () => setSelectedTemplate(template)
                      })
                    })
                  }}
                    trigger={'click'}>
                    <div className={`cursor-pointer w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
                      <div>{
                        !!selectedTemplate
                          ? <FormattedMessage id={`${selectedTemplate.name}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedTemplate.name} />
                          : <FormattedMessage id="SELECT_TEMPLATE" defaultMessage="Select Template" />
                      }</div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              {selectedTemplate &&
                <div className="px-3 w-full">
                  <label className='font-16 font-bold'><FormattedMessage id="PREVIEW_OF_PROJECT_SECTIONS" defaultMessage="Preview of project sections" /></label>
                <div className="d-flex mt-4 overflow-scroll w-full no-scrollbar">
                    {selectedTemplate.sections?.map((section, i) => {
                      const color = getSectionColor(i);
                      return (
                        <div style={{
                          marginRight: 15,
                          background: color?.bg,
                          borderRadius: 12,
                          padding: 10,
                          border: `1px solid ${color?.border}`,
                          height: 160,
                        }}>
                          <div style={{  minWidth: 80 }} className="mini-section">
                            <FormattedMessage id={`${section.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={section.title} />
                          </div>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="px-2 py-4 mt-2 flex align-center justify-between">
            <div className="">
              <div className="font-18 font-black">
                <FormattedMessage id="MAKE_PRIVATE" defaultMessage="Make private" />?
              </div>
              <div className="pr-20 font-14 mt-2">
                <FormattedMessage
                  id="MAKE_PRIVATE_DESCRIPTION"
                  defaultMessage="Private projects shield sensitive data from individuals who are not part of the project"
                />
              </div>
            </div>
            <Switch checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
          </div>
        </div>
        <div
          onClick={(e) => {
            createProject(projectName, selectedTemplate?._id, isPrivate);
            setProjectName('');
          }}
          className={`w-full text-center px-4 py-2 bg-[#0074cc] ${isButtonDisabled && 'opacity-70'} font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
          <FormattedMessage id="CREATE_PROJECT" defaultMessage="Create Project" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  templates: state.projectReducer.publicTemplates,
});

export default connect(mapStateToProps)(AddProject);
