import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'
import { FormattedMessage } from 'react-intl';

const PaymentError = () => {

  const [count, setCount] = useState(8);
  const navigate = useNavigate();

  if (count > 0) {
    setInterval(() => {
      setCount(count - 1);
    }, 1000);
  } else {
    navigate('/pricing', { replace: true })
  }

  return (
    <div
      className='container'
      style={{ background: '#fafafa', flexDirection: 'column' }}>
      <ProductNavBar button='Back to Workflo' />
      <div className='pricing-container' style={{ flexDirection: 'column', overflowY: 'scroll' }}>
        <h3 className="pricing-title">
        <FormattedMessage id="PAYMENT_ERROR" defaultMessage="Your payment failed!" />
        </h3>
        <div className='font-16'>
          <FormattedMessage
            id="REDIRECTING_TO_WORKFLO_PRICING"
            defaultMessage="Redirecting to Workflo Pricing in {count}"
            values={{ count }}  
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentError;

