import React, { useEffect, useState } from 'react';
import { Dropdown, Tag, Table, Tooltip } from 'antd';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  editSection,
  getSectionTasks,
  isTicketOpen,
  setTaskProperties,
  updateQueryState,
  deleteTask,
  addTask,
  editTask,
  isChatOpen,
  clearSingleTask
} from '../../../../actions';

import Icon from '../../atoms/global/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';

import PriorityDropdown from '../task-components/PriorityDropdown.jsx';
import CalendarDropdown from '../task-components/CalendarDropdown.jsx';
import AssigneeSelect from '../assignee/AssigneeSelect.jsx';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';
import { getSectionColor } from '../../../../js/helper';
import { setTeamsState } from '../../../../actions/toggleAction';
import TaskContextMenu from '../../atoms/menu/TaskContextMenu.jsx';
import EditTask from '../../atoms/input/EditTask.jsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDateTextLongFormat } from '../../../../js/functions.js';

const columns = [
  {
    title: <div className='text-center'><FormattedMessage id="ID" defaultMessage="ID" /></div>,
    width: 100,
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
  },
  {
    title: <div className='text-center'><FormattedMessage id="TASK_NAME" defaultMessage="Task Name" /></div>,
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
    render: (text) => (
      <div style={{ maxWidth: 450 }}>
        {text}
      </div>
    ),
  },
  {
    title: <div className='text-center'><FormattedMessage id="ASSIGNEE" defaultMessage="Assignee" /></div>,
    dataIndex: 'assignee',
    key: '1',
    width: 100,
  },
  {
    title: <div className='text-center'><FormattedMessage id="DATE" defaultMessage="Date" /></div>,
    dataIndex: 'date',
    key: '2',
  },
  {
    title: <div className='text-center'><FormattedMessage id="PRIORITY" defaultMessage="Priority" /></div>,
    dataIndex: 'priority',
    key: '3',
    width: 120,
  },
  {
    title: <div className='text-center'><FormattedMessage id="CREATED" defaultMessage="Created" /></div>,
    dataIndex: 'created', key: '12', width: 120,
    sorter: (a, b) => {
      const aa = a?.created?.props?.children;
      const bb = b?.created?.props?.children;

      const dateA = new Date(aa); // Parse with Day.js
      const dateB = new Date(bb);
      return dateA - dateB;
    },
  },
  {
    title: <div className='text-center'><FormattedMessage id="UPDATED_COLUMN_TITLE" defaultMessage="Updated" /></div>,
    dataIndex: 'updated', key: '13', width: 120,
    sorter: (a, b) => {
      const aa = a?.updated?.props?.children;
      const bb = b?.updated?.props?.children;

      const dateA = new Date(aa); // Parse with Day.js
      const dateB = new Date(bb);
      return dateA - dateB;
    },
  },
  {
    title: <div className='text-center'><FormattedMessage id="COMPLETED" defaultMessage="Completed" /></div>,
    dataIndex: 'action',
    key: 'operation',
    width: 120,
  }
];

const ListView = (props) => {
  const navigate = useNavigate();

  const {
    query,
    taskFilters,
    sectionHandler,
    setphase,
    phase,
    setSectionTitle,
    projectId,
    sectionTitle,
    sectionKey,
    editSectionByProjectId,
    sectionCounts,
    section,
    tasks,
    customFields
  } = props;

  const {
    _id: id,
    title,
    tasks: sectionTasks
  } = section || {};
  const number = sectionCounts && sectionCounts[id] || 0;

  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const [showLess, setShowLess] = useState(false);

  const [hasMoreData, setHasMoreData] = useState(number > 9);
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState()
  const [taskName, setTaskName] = useState('');

  const loadTasks = async () => {
    // Prevent loading if already loading or all notifications are loaded
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    // Make your API call to fetch more notifications
    const sectionTasks = await dispatch(getSectionTasks(id, query[id] || 1, taskFilters));
    if (sectionTasks?.length === 0) {
      // If the API returns an empty array, there is no more data
      setHasMoreData(false);
    } else {
      setHasMoreData(sectionTasks?.length > 18);
      dispatch(updateQueryState(id));
    }

    // if (sectionTasks.length === taskCount) {
    //   setHasMoreData(false);
    // }

    setIsLoading(false);
  };

  const handleTaskClick = (task) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
    dispatch(isChatOpen(false));
    dispatch(setTeamsState(false));
  }

  const handleTaskComplete = (task) => {
    dispatch(editTask({
      _id: task._id,
      isCompleted: !task?.isCompleted,
    }))
  }

  const workspaceCustomFields = customFields;

  useEffect(() => {
    if (number > 9) {
      setHasMoreData(true);
    }

    // workspaceCustomFields.map(field => {
    //   columns.push({
    //     title: <div className='text-center'>{field?.name}</div>,
    //     width: 140,
    //     dataIndex: field?._id,
    //     key: field?._id,
    //   })
    // })

    // // columns.push({
    // //   title: <div className='text-center'>Completed</div>,
    // //   dataIndex: 'action',
    // //   key: 'operation',
    // //   fixed: 'right',
    // //   width: 120,
    // // })

  }, [sectionCounts]);

  const enableEditting = () => {
    setSectionTitle(title);
    setphase(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      _id: id,
      title: sectionTitle,
    };
    dispatch(
      editSection(obj, () => {
        setSectionTitle('');
        setphase('display');
        editSectionByProjectId();
      }, intl)
    );
  };

  const deleteHandler = () => {
    setOpen(false);
    sectionHandler('deleteSection', id);
    dispatch(isTicketOpen(false));
    navigate(`/${projectId}/list`);
  };

  const handleAddTask = async (id) => {
    taskName && await dispatch(addTask({
      taskTitle: taskName,
      taskDescription: '',
      // section: Section,
      isCompleted: false,
      // projectId: Project,
      projects: [
        {
          projectId: projectId,
          section: id,
          position: 'top'
        }
      ],
      dueDate: '',
      addedTo: 'top'
    }, () => {
      dispatch(clearSingleTask());
    }));
    setTaskName('');
  }

  const items = [
    {
      key: '1',
      label: <div className='list-section-dropdown-item' onClick={enableEditting}>
        <FormattedMessage id="EDIT_SECTION" defaultMessage="Edit Section" />
      </div>,
    },
    {
      key: '2',
      label: <div className='list-section-dropdown-item' onClick={() => setOpen(true)}>
        <FormattedMessage id="DELETE_SECTION" defaultMessage="Delete Section" />
      </div>,
    },
  ];

  const x = tasks?.filter((task) => {
    const sectionId = task?.sectionId;
    return sectionId === id;
  })
    .sort((a, b) => b.position - a.position)
    .map((task, taskKey) => task)


  const taskMap = new Map();
  x?.forEach(task => {
    taskMap.set(task?.titleIdentifier, task);
  });

  const dataSource = x?.map((task, i) => {

    const customKeys = {}
    task?.customFields.map(x => {
      const field = workspaceCustomFields?.find(y => x?._id == y?._id);
      const value = field?.possibleValues?.find(v => v?._id == x?.value);
      customKeys[x?._id] = <div className='text-center text-sm'>{value?.label}</div>
    })

    const time = getDateTextLongFormat(new Date(task?.createdAt), intl);
    const timeUpdated = getDateTextLongFormat(new Date(task?.updatedAt), intl);

    return {
      key: task?.titleIdentifier,
      id: <div className='text-xs text-center'>{task?.titleIdentifier}</div>,
      title: isEdit && isEdit === task._id ? (
        <EditTask
          taskTitle={props?.taskProperties?.taskTitle}
          setEditTaskVisible={setIsEdit}
          task={task}
        />
      ) : (
        <div
          onClick={() => handleTaskClick(task)}
          className='text-sm cursor-pointer hover:underline px-1'
        >
          {task?.taskTitle}
        </div>
      ),
      priority: <PriorityDropdown task={task} />,
      action: (
        <div
          onClick={() => handleTaskComplete(task)}
          className='flex align-center justify-center cursor-pointer'>
          <Tooltip title={
            task?.isCompleted
              ? intl.formatMessage({ id: 'MARK_INCOMPLETE', defaultMessage: 'Mark Incomplete' })
              : intl.formatMessage({ id: 'MARK_COMPLETE', defaultMessage: 'Mark Complete' })
            }>
            <img
              src='/images/icons/completedSubtask.svg'
              style={{
                objectFit: 'contain',
                width: 22,
                height: 22,
                opacity: task?.isCompleted ? 1 : 0.2
              }} />
          </Tooltip>
        </div>
      ),
      project: <>{task?.projects?.map((tag, i) => {
        let color = getSectionColor(i)
        return (
          <Tag color={color?.border} key={tag?._id} style={{ color: 'black' }}>
            {tag?.projectTitle}
          </Tag>
        );
      })}
      </>,
      section: <>{task?.projects?.map((tag, i) => {
        let color = getSectionColor(i + 2)
        return (
          <Tag color={color?.border} key={tag?._id} style={{ color: 'black' }}>
            {tag?.sectionTitle}
          </Tag>
        );
      })}
      </>,
      created: <div className='text-xs text-center'>{time}</div>,
      updated: <div className='text-xs text-center'>{timeUpdated}</div>,
      assignee: <div className='flex justify-center'><AssigneeSelect task={task} iconSize={26} count={1} /></div>,
      date: <div className='relative px-3 flex justify-center'><CalendarDropdown task={task} isLarge /></div>,
      ...customKeys
    }
  });

  return (
    <div className='list-view-container'>
      <ConfirmationModal
        title={`${intl.formatMessage({ id: 'DELETE_SECTION', defaultMessage: 'Delete Section' })}?`}
        description={intl.formatMessage({ id: 'DELETE_SECTION_CONFIRMATION', defaultMessage: 'Are you sure you want to delete this section?' })}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={deleteHandler}
      />
      {dropdownVisible && selectedTask && (
        <Dropdown
          menu={{ items: TaskContextMenu({ task: selectedTask, setIsEdit, props, dispatch, isWorkspaceTasksScreen: false }) }}
          open={dropdownVisible}
          onOpenChange={(visible) => setDropdownVisible(visible)}
          trigger={['contextMenu']}
        >
          <div
            style={{
              position: 'absolute',
              left: dropdownPosition.x,
              top: dropdownPosition.y,
              zIndex: 1000
            }}
          />
        </Dropdown>
      )}
      {/* HEADER */}
      <div
        className='d-flex justify-content-between'
        style={{ alignItems: 'center' }}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className='d-flex align-center justify-between duration-150 ease-in-out'
          style={{
            background: getSectionColor(props?.sectionKey)?.border,
            padding: phase === id ? 8 : '15px 10px 10px 10px',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            width: isHover ? 400 : 350
          }}>
          {phase === id ? (
            <form onSubmit={handleSubmit} onBlur={handleSubmit}>
              <input
                autoFocus
                value={props.sectionTitle}
                onChange={(e) => props.setSectionTitle(e.target.value)}
                className='font-14'
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'transparent',
                }}
                type='text'
              />
            </form>
          ) : (
            <div className='flex align-center'>
              <div className='card-number d-flex' style={{ background: getSectionColor(sectionKey)?.bg }}>
                <div>{number}</div>
              </div>
              <div className='font-14 truncate' onClick={enableEditting}>
                <FormattedMessage id={`${title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={title} />
              </div>
            </div>
          )}
          {isHover && <div className='d-flex align-center pr-2'>

            <Icon
              name='showLess.svg'
              height='14px'
              pr='20px'
              onClick={() => {
                setShowLess(!showLess);
              }}
            />
            {/* <Icon
              name='addblack.svg'
              height='14px'
              pr='20px'
              onClick={() => {
                setAddTaskVisible(!isAddTaskVisible);
              }}
            /> */}
            <Dropdown
              menu={{
                items,
              }}
              placement='bottom'
              arrow
            >
              <div>
                <img
                  src={'/images/icons/threeDots.svg'}
                  style={{
                    cursor: 'pointer',
                    height: '16px',
                    width: '16px',
                  }}
                  alt='icon'
                />
              </div>
            </Dropdown>
          </div>
          }
        </div>
      </div>

      {/* // ............................................? */}
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div className='p-2' style={{ background: getSectionColor(sectionKey)?.border, borderTopRightRadius: 10 }}>
            {!showLess && <Table
              columns={columns.map((col) => ({
                ...col,
                onHeaderCell: () => ({
                  style: {
                    background: getSectionColor(sectionKey)?.bg
                  }
                }),
              }))}
              dataSource={dataSource}
              pagination={false}
              size={'small'}
              scroll={{ x: 'max-content' }}
              onRow={(record) => ({
                onContextMenu: (event) => {
                  event.preventDefault();
                  const task = taskMap.get(record.key);
                  if (!task) return;
                  setSelectedTask(task);
                  setDropdownVisible(true);
                  setDropdownPosition({ x: event.clientX, y: event.clientY });
                },
              })}
            // footer={() => <div  className='p-1 text-center'>Load more tasks</div>}
            />}
            <div className='flex align-center justify-center mt-2 mb-1'>
              <input
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    handleAddTask(section?._id)
                  }
                }}
                className='hover:opacity-90 w-full'
                placeholder={intl.formatMessage({ id: 'ADD_TASK_PLACEHOLDER', defaultMessage: 'Add Task…' })}
                style={{
                  backgroundColor: getSectionColor(sectionKey)?.bg,
                  border: `2px solid ${getSectionColor(sectionKey)?.border}`,
                  padding: "8px 15px",
                  fontWeight: "bold",
                  borderRadius: 10,
                  fontSize: 14,
                }}
                value={taskName}
                onChange={(e) => {
                  setTaskName(e?.target?.value)
                }}
              />
              <div
                onClick={() => {
                  handleAddTask(section?._id)
                }}
                className='mx-2 px-4 py-2 rounded-lg font-14 cursor-pointer bg-blue-500 text-white hover:bg-blue-400'>
                <FormattedMessage id="ADD" defaultMessage="Add" />
              </div>
            </div>
            {x?.length > 9 && hasMoreData && <div
              className='hover:opacity-50 cursor-pointer mt-2'
              onClick={() => loadTasks()}
              style={{
                backgroundColor: getSectionColor(sectionKey)?.bg,
                border: `2px solid ${getSectionColor(sectionKey)?.border}`,
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
                borderRadius: 10
              }}
            >
              <FormattedMessage id="LOAD_MORE_TASKS" defaultMessage="Load more tasks" />
            </div>}
          </div>
        )}
      </Droppable>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isTicketOpen: state.toggleReducer.isTicketOpen,
    tasks: state.taskReducer.tasks,
    taskProperties: state.taskReducer.taskProperties,
    projectData: state.projectReducer.getProjects,
    getTask: state.taskReducer.tasks,
    query: state.taskReducer.query,
    sectionCounts: state.taskReducer.sectionCounts,
    taskFilters: state.taskReducer.filters,
    customFields: state.projectReducer.projectCustomFields,
  };
}

export default connect(mapStateToProps)(ListView);
