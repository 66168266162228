import * as togglerTypes from './types/toggler.types';

export const collapse = (val) => {
  return {
    type: togglerTypes.COLLAPSE,
    payload: val,
  };
};

export const isTicketOpen = (val) => {
  return {
    type: togglerTypes.TICKET,
    payload: val,
  };
};

export const isAiOpen = (val) => {
  return {
    type: togglerTypes.AI,
    payload: val,
  };
};

export const isChatOpen = (val, msgId) => {
  return {
    type: 'chat',
    payload: { val, msgId },
  };
};

export const isTaskModalOpen = (val) => {
  return {
    type: 'isTaskModalOpen',
    payload: val,
  };
};

export const isListView = (val) => {
  return {
    type: 'isListView',
    payload: val,
  };
};


export const isChatSettingsOpen = (val, id = '') => {
  if (val) {
    showTaskAsModal(false);
    setTeamsState(false);
    isChatOpen(false);
    isTicketOpen(false);
  }

  return {
    type: 'isChatSettings',
    payload: {
      show: val,
      chatId: id
    },
  };
};

export const showTaskAsModal = (val) => {
  return {
    type: 'showTaskAsModal',
    payload: val,
  };
};


export const setDrawerWidth = (val) => {
  return {
    type: 'setDrawerWidth',
    payload: val,
  };
};

export const setIsMobile = (val) => {
  return {
    type: 'set-mobile',
    payload: val,
  };
};

export const setTeamsState = (val) => {
  return {
    type: togglerTypes.TEAMS,
    payload: val,
  };
};

export const isUserDrawerOpen = (val) => {
  return {
    type: togglerTypes.USER,
    payload: val,
  };
};

export const isAIDrawerOpen = (val) => {
  return {
    type: togglerTypes.AI,
    payload: val,
  };
};

export const isMemberDrawerOpen = (val,member={}) => {
  return {
    type: togglerTypes.MEMBER,
    payload: {
      show: val,
      member
    },
  };
};

export const isJoinProjectsOpen = (val,member={}) => {
  return {
    type: togglerTypes.PROJECTS_JOIN,
    payload: val,
  };
};

export const resetDrawer = (val) => {
  return {
    type: 'reset-drawer',
    payload: val,
  };
};
