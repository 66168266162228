import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import moment from 'moment';

import {
  getOrganizationSubscription
} from '../../../../actions';
import Icon from '../../atoms/global/Icon';
import { Button } from 'antd';
import { cancelSubscription } from '../../../../actions/paymentAction';
import { FormattedMessage, useIntl } from 'react-intl';

function BillingInvoice({ selectedOrg }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [subscription, setSubscription] = useState();

  const getOrgSubscription = async () => {
    const subscription = await dispatch(getOrganizationSubscription(selectedOrg?._id));
    setSubscription(subscription);
  }

  useEffect(() => {
    getOrgSubscription();
  }, [selectedOrg])

  const cancelSub = (subscription) => {
    dispatch(cancelSubscription({
      organizationId: selectedOrg?._id,
      subscriptionId: subscription?.subscriptionId,
      cancelAtPeriodEnd: true
    }, () => {
      dispatch(getOrganizationSubscription(selectedOrg?._id));
    }))
  }


  const SUB = [
    {
      "id": "sub_1OcwZVBKQUtt8XniiMGcQvIT",
      "created": "2024-01-26T21:04:21.000Z",
      "currency": "usd",
      "currentPeriodStart": "2024-01-26T21:04:21.000Z",
      "currentPeriodEnd": "2025-01-26T21:04:21.000Z",
      "description": null,
      "discount": null,
      "paymentSettings": {},
      "status": "active",
      "quantity": 1,
      "startDate": "2024-01-26T21:04:21.000Z",
      "plan": {
        "id": "price_1OcdDzBKQUtt8XniAuFYHW9p",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 5988,
        "amount_decimal": "5988",
        "billing_scheme": "per_unit",
        "created": "2024-01-26T00:24:51.000Z",
        "currency": "usd",
        "interval": "year",
        "interval_count": 1,
        "livemode": false,
        "metadata": {},
        "nickname": null,
        "product": "prod_PRWFvs6lBMjRaR",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "nextInvoice": "2024-01-26T21:04:21.000Z"
    },
    {
      "id": "sub_1Ocw3ZBKQUtt8XniXjRaN0Hf",
      "created": "2024-01-26T20:31:21.000Z",
      "currency": "usd",
      "currentPeriodStart": "2024-01-26T20:31:21.000Z",
      "currentPeriodEnd": "2025-01-26T20:31:21.000Z",
      "description": null,
      "discount": null,
      "paymentSettings": {},
      "status": "active",
      "quantity": 1,
      "startDate": "2024-01-26T20:31:21.000Z",
      "plan": {
        "id": "price_1OcdDzBKQUtt8XniAuFYHW9p",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 5988,
        "amount_decimal": "5988",
        "billing_scheme": "per_unit",
        "created": "2024-01-26T00:24:51.000Z",
        "currency": "usd",
        "interval": "year",
        "interval_count": 1,
        "livemode": false,
        "metadata": {},
        "nickname": null,
        "product": "prod_PRWFvs6lBMjRaR",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "nextInvoice": "2024-01-26T20:31:21.000Z"
    },
    {
      "id": "sub_1OqLN8BKQUtt8XniTAkZnjYu",
      "created": "2024-03-03T20:10:58.000Z",
      "currency": "usd",
      "currentPeriodStart": "2024-03-03T20:10:58.000Z",
      "currentPeriodEnd": "2024-04-03T20:10:58.000Z",
      "description": null,
      "discount": null,
      "paymentSettings": {
        "card": {
          "network": null,
          "request_three_d_secure": "automatic"
        }
      },
      "status": "active",
      "quantity": 1,
      "startDate": "2024-03-03T20:10:58.000Z",
      "plan": {
        "id": "price_1OcdCoBKQUtt8XniMCArT4A6",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 699,
        "amount_decimal": "699",
        "billing_scheme": "per_unit",
        "created": "2024-01-26T00:23:38.000Z",
        "currency": "usd",
        "interval": "month",
        "interval_count": 1,
        "livemode": false,
        "metadata": {},
        "nickname": null,
        "product": "prod_PRWFvs6lBMjRaR",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "nextInvoice": "2024-03-03T20:10:58.000Z"
    }
  ]

  return (
    <div className="p-6 space-y-6">
      <div>
        <h2 className="text-2xl text-slate-800 font-bold mb-4"><FormattedMessage id="BILLING_AND_INVOICES" defaultMessage="Billing & Invoices" /></h2>
        {/* <div className="text-sm">This workspace’s Basic Plan is set to <strong className="font-medium">$34</strong> per month and will renew on <strong className="font-medium">July 9, 2021</strong>.</div> */}
      </div>

      {/* Billing Information */}
      {/* <section>
        <h3 className="text-xl leading-snug text-slate-800 font-bold mb-1">Billing Information</h3>
        <ul>
          <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200">
            <div className="text-sm text-slate-800 font-medium">Payment Method</div>
            <div className="text-sm text-slate-600 ml-4">
              <span className="mr-3">--</span>
              <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">Edit</a>
            </div>
          </li>
          <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200">
            <div className="text-sm text-slate-800 font-medium">Billing Interval</div>
            <div className="text-sm text-slate-600 ml-4">
              <span className="mr-3">--</span>
              <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">Edit</a>
            </div>
          </li>
        </ul>
      </section> */}

      {/* Invoices */}
      <section>
        <h3 className="text-xl leading-snug text-slate-800 font-bold mb-1"><FormattedMessage id="INVOICES" defaultMessage="Invoices" /></h3>
        {/* Table */}
        <table className="table-auto w-full">
          {/* Table header */}
          <thead className="text-xs uppercase text-slate-400">
            <tr className="flex flex-wrap md:table-row md:flex-no-wrap">
              <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="PLAN" defaultMessage="Plan" /></div>
              </th>
              <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="QUANTITY" defaultMessage="Quantity" /></div>
              </th>
              <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="STATUS" defaultMessage="Status" /></div>
              </th>
              <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="AMOUNT" defaultMessage="Amount" /></div>
              </th>
              <th className="w-full block md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="NEXT_PAYMENT" defaultMessage="Next Payment" /></div>
              </th>
              <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-left"><FormattedMessage id="PAYMENT_METHOD" defaultMessage="Payment Method" /></div>
              </th>
              {/* <th className="w-full hidden md:w-auto md:table-cell py-2">
                <div className="font-semibold text-right"></div>
              </th> */}
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm">
            {/* Row */}
            {subscription?.map(item => {

              const startDate = moment(new Date(item?.currentPeriodStart)).format('MM/DD/YYYY');
              const endDate = moment(new Date(item?.currentPeriodEnd)).format('MM/DD/YYYY');

              return (
                <tr className="flex flex-wrap md:table-row md:flex-no-wrap border-b border-slate-200 border-slate-700 py-2 md:py-0">
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left">{`${item?.plan?.name} ${
                      item?.plan?.interval === 'month'
                      ? '(' + <FormattedMessage id="MONTHLY" defaultMessage="Monthly" /> + ')'
                      : item?.plan?.interval === 'year'
                        ? '(' + <FormattedMessage id="YEARLY" defaultMessage="Yearly" /> + ')'
                        : ''
                      }`}
                    </div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left">{item?.quantity}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left">{item?.plan?.active
                      ? <FormattedMessage id="ACTIVE" defaultMessage="Active" />
                      : <FormattedMessage id="INACTIVE" defaultMessage="Inactive" />
                      }
                    </div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left font-medium">${item?.plan?.amount}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left font-medium text-slate-800">{new Date(endDate).toLocaleDateString(intl.locale, {month: 'long', day: 'numeric', year: 'numeric'})}</div>
                  </td>
                  <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-left">
                      {item?.brand === 'Visa' ? (
                        <span><Icon name='visa.svg' height='10px' pr='10px' /> &nbsp; •••• •••• •••• {item?.last4}</span>
                      ) : item?.brand === 'Mastercard' ? (
                        <span><Icon name='mastercard.svg' height='10px' pr='10px' /> &nbsp; •••• •••• •••• {item?.last4}</span>
                      ) : (
                        <span>{item?.brand} •••• •••• •••• {item?.last4}</span>
                      )}
                    {/* Don't uncomment the line below without asking Umair */}
                    {/* <Button onClick={() => cancelSub(item)}>Cancel</Button> */}
                    </div>
                  </td>
                  {/* <td className="w-full block md:w-auto md:table-cell py-0.5 md:py-2">
                    <div className="text-right flex items-center md:justify-end">
                      <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">HTML</a>
                      <span className="block w-px h-4 bg-slate-200 mx-2" aria-hidden="true"></span>
                      <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">PDF</a>
                    </div>
                  </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default BillingInvoice;