import { Avatar, Tooltip } from 'antd';
import React from 'react';
import UserAvatar from './UserAvatar';

const ReplyAvatar = ({ replySenders }) => {
    const aviEls = [];
    return (
        <>
            <Avatar.Group
                maxCount={2}
                maxPopoverTrigger="click"
                size="small"
                maxStyle={{
                    color: 'white',
                    backgroundColor: '#4077EE',
                    cursor: 'pointer',
                    fontSize: '12px',
                }}
            >
                {replySenders &&
                    replySenders.forEach((replySender) => {
                        aviEls.push(
                            <Tooltip placement="top">
                                <Avatar
                                    style={{
                                        backgroundColor: 'unset',
                                    }}
                                    icon={
                                        <UserAvatar
                                            alt="icon"
                                            fullName={replySender?.fullName}
                                            userId={replySender?._id}
                                            src={replySender?.avi}
                                        />
                                    }
                                />
                            </Tooltip>,
                        );
                    })}
                {aviEls}
            </Avatar.Group>
        </>
    );
};
export default ReplyAvatar;
