import React from 'react'
import { Tooltip as Toolp } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import PriorityDropdown from '../../../components/UI/molecules/task-components/PriorityDropdown';
import AssigneeSelect from '../../../components/UI/molecules/assignee/AssigneeSelect';
import CalendarDropdown from '../../../components/UI/molecules/task-components/CalendarDropdown';

import { editTask } from '../../../actions';

const DashboardTask = (props) => {
  const { task, index, onClick, isSearch = false, toggle = false } = props;

  const data = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const projectUsers = data.users || []

  const editHandler = async (data, callback) => {
    await dispatch(editTask(data));

    if (typeof callback === 'function') callback();
  };

  return (
    <div className={` ${isSearch ? 'hover:bg-gray-100 shadow-sm' : toggle ? 'xl:border-2  ' : 'lg:hover:border-blue-400 lg:border-2 '}  pt-5 bg-white mb-3 rounded-xl w-full flex flex-col cursor-pointer`}
      key={task?._id + index} onClick={onClick}>
      <div className='px-4'>
        <div className='flex md:flex-row flex-col justify-between '>
          {/* <div className='bg-red-400 h-2 w-8 border-radius10'></div> */}
          <div className='flex '>
            <div className=' flex-row flex justify-center truncate font-light text-[rgba(39,40,45,0.7)] font-12'>
              <span className='text-[rgba(39,40,45,0.7)] font-light font-12'>{task?.titleIdentifier}</span>
              <img
                src='/images/icons/greaterThan.svg'
                alt=''
                className='h-2 mt-1 ml-2'
              />
              <img
                src='/images/icons/greaterThan.svg'
                alt=''
                className='h-2 mt-1 mr-2'
              />  {task?.projects[0]?.sectionTitle}</div>
          </div>
          <CalendarDropdown task={task} x={0} left={true} />
        </div>
        <Toolp title={task?.taskTitle}>
          <div className='flex md:flex-row flex-col md:items-center items-start'>
            <div className='font-14 my-3 font-semibold  md:truncate '>{task?.taskTitle}</div>
          </div>
        </Toolp>
        <div className={`flex ${isSearch ? 'flex-row':'md:flex-row flex-col'} justify-between ${props?.isTicketOpen ? 'hidden lg:flex' : ''}`}>
          <div className='flex align-center md:mb-0 mb-2'>
            {/* <div className={`mr-3 font-12 px-3 py-1 ${task?.isCompleted ? 'bg-green-600 text-white' : 'bg-slate-200'} rounded`}>{task?.isCompleted ? 'Complete' : 'Mark Complete'}</div> */}
            <PriorityDropdown onClick={() => { }} task={task} />
          </div>
          <div className='flex flex-row  gap-1'>
            <AssigneeSelect
              projectUsers={projectUsers || []}
              task={task}
              iconSize={28}
            />
            {!isSearch && <div className='bg-[rgba(249,249,249,1)] ml-2 flex md:flex-row  flex-col  rounded-full place-items-center gap-1 px-4'>
              <p className='font-12'>{task?.projects[0]?.projectTitle}</p>
            </div>}
          </div>
        </div>
        <div className='w-full px-3 h-[1px] mt-3 rounded-md' />
      </div>
    </div>
  )
}

export default DashboardTask;
