import axios from "axios";
import { notification } from "antd";
import { get } from "lodash";
import * as chatTypes from "./types/chat.types.js";
import { UnauthorizedHandler } from "../js/helper/UnauthorizedHandler.js";
import { getTeamsByWorkspaceId } from "./teamAction.js";
import { getChatRooms } from "./chatRoomAction.js";
import { clearSingleTask, getUserTasks, setUserTaskFilters } from "./taskAction.js";
import { getWorkspaceNotifications } from "./notificationAction.js";

const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: "" },
};

export const getWorkspaces = (response) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const queryString = response ? `?response=${response}` : '';
      const res = await axios.get(`${baseUrl}/workspaces${queryString}`, config);
      dispatch({
        type: "get-workspaces",
        payload: res.data.data,
      });
      return res.data.data;
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getActiveWorkspaceDetails = () => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const workspaceId = sessionStorage.getItem('workspaceId');
      const res = await axios.get(`${baseUrl}/workspaces/${workspaceId}/details`, config);
      dispatch({
        type: "set-current-workspace",
        payload: res.data.data,
      });
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getWorkspaceDashboardData = (assignedTo, dueDate) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const workspaceId = localStorage.getItem('workspaceId');
      const res = await axios.post(`${baseUrl}/workspaces/${workspaceId}/dashboard`, {
        charts: {
          filters: {
            assignedTo: assignedTo,
            dueDate: dueDate
          }
        }
      }, config);

      dispatch({
        type: "dashboard-loading",
        payload: true,
      });

      dispatch({
        type: "workspaceDashboard",
        payload: res.data.data,
      });

      const workspace = res.data.data?.workspace;
      const organization = res.data.data?.organization;
      const members = res.data.data?.members || [];
      const pendingInvites = res.data.data?.pendingInvites || [];
      const workspaceTasks = res.data.data?.workspaceTasks || [];
      const allProjects = res.data.data?.allProjects || [];
      const myProjects = res.data.data?.myProjects || [];
      const customFields = res.data.data?.customFields || [];
      const charts = res.data.data?.charts || {};

      dispatch({
        type: "set-current-workspace",
        payload: {...workspace, organization, members, pendingInvites, projects: allProjects, myProjects}
      });
      dispatch({
        type: "set-workspace-fields",
        payload: customFields
      });
      dispatch({
        type: 'get-dashboard-tasks',
        payload: workspaceTasks
      });
      dispatch({
        type: "workspace-projects",
        payload: allProjects
      });
      dispatch({
        type: "get-users-projects",
        payload: myProjects
      });
      dispatch({
        type: 'success-get',
        payload: members
      });
      dispatch({
        type: "workspaceCharts",
        payload: charts
      })
      dispatch({
        type: "dashboard-loading",
        payload: false,
      });
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
      dispatch({
        type: "dashboard-loading",
        payload: false,
      });
    }
  };
};


export const getWorkspaceProjects = (wsId) => {
  return async (dispatch) => {
    try {
      const workspaceId = wsId || sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/workspaces/${workspaceId}/projects`, config);
      dispatch({
        type: "workspace-projects",
        payload: res.data.data,
      });
      dispatch({
        type: "get-users-projects",
        payload: res.data.data,
      });

      return res.data.data;
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getWorkspaceById = (callBack) => {
  return async (dispatch) => {
    try {
      const workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');

      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/workspaces/${workspaceId}`, config);
      dispatch({
        type: "get-workspace",
        payload: res.data.data?.workspace,
      });
      dispatch({
        type: "set-workspace-fields",
        payload: res.data.data?.customFields,
      });
      dispatch({
        type: chatTypes.GET_CHAT_SUCCESS,
        payload: res.data.data?.chatRooms,
      });
      dispatch({
        type: "get-teams",
        payload: res.data.data?.teams,
      });
      callBack && callBack(res.data.data.title);
    } catch (error) {
      console.log("----- error",error)
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const addWorkspace = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/workspaces`, data, config);

      const { invitees, title } = data;

      await dispatch({
        type: "workspace-success",
        payload: res.data.data,
      });
      notification.success({
        message: title ? `${title} created` : "Workspace created",
        placement: "bottomRight",
      });
      invitees && invitees.length > 0 && notification.success({
        message: "Email invitations sent to team members",
        placement: "bottomRight",
      });
      callback && callback(res.data.data);
      return res.data.data;
    } catch (error) {
      const isDuplicate = get(error, "response.data.isDuplicateField");
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      if (isDuplicate) {
        notification.error({
          message: "Workspace title already used",
          placement: "bottomRight",
        });
      }
    }
  };
};

export const deleteWorkspace = (id) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      await axios.delete(`${baseUrl}/workspaces/${id}`, config);
      dispatch({
        type: "workspace-delete",
        payload: id,
      });
      notification.success({
        message: "You just deleted a workspace",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const switchWorkspace = (workspace, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "set-current-workspace",
        payload: workspace,
      });
      dispatch({
        type: 'clearUserTasks'
      })
      callback && callback();
    } catch (e) {
      UnauthorizedHandler(dispatch, e);
      notification.error({
        message: "Cannot switch workspace, please try again!",
        placement: "bottomRight",
      });
    }
  };
};

export const inviteToWorkspace = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/workspaces/invite`, data, config);
      callback && callback();
      notification.success({
        message: "Email invitations sent to workspace members",
        placement: "bottomRight",
      });
    } catch (e) {
      notification.error({
        message: e?.response?.data?.message || "Cannot invite to workspace!",
        placement: "bottomRight",
      });
    }
  };
};

export const updateWorkspaceUsers = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/workspaces/updateWorkspaceUsers`, data, config);
      callback && callback();
      notification.success({
        message: "User Role Updates",
        placement: "bottomRight",
      });
    } catch (e) {
      notification.error({
        message: e?.response?.data?.message || "Cannot update role!",
        placement: "bottomRight",
      });
    }
  };
};

export const acceptWorkspaceInvite = (inviteId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      const res = await axios.post(`${baseUrl}/workspaces/invite/${inviteId}/accept`, {}, config);
      // notification.success({
      //   message: res?.data?.message || "Joined new workspace!",
      //   placement: "bottomRight",
      // });
      dispatch({
        type: "workspace-remove-invite",
        payload: inviteId,
      });
      const workspace = res?.data?.data;
      dispatch(switchWorkspace(workspace, () => {
        sessionStorage.setItem("workspaceId", workspace._id);
        sessionStorage.setItem("organization", workspace.organization);

        localStorage.setItem("workspaceId", workspace._id);
        localStorage.setItem("organization", workspace.organization);

        dispatch(getTeamsByWorkspaceId(workspace._id));
        // dispatch(getChatRooms())
        dispatch(getWorkspaceById());
        dispatch(getUserTasks());
        dispatch(getWorkspaceNotifications())
        dispatch(clearSingleTask());
        dispatch(setUserTaskFilters({}, true))
      }));
      callback && callback();
      return workspace;

    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Unable to accept workspace invite",
        placement: "bottomRight",
      });
    }
  };
};

export const declineWorkspaceInvite = (inviteId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      await axios.post(`${baseUrl}/workspaces/invite/${inviteId}/decline`, {}, config);
      callback && callback();
      notification.success({
        message: "Declined invite!",
        placement: "bottomRight",
      });
      dispatch({
        type: "workspace-remove-invite",
        payload: inviteId,
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const getWorkspaceInvites = () => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/user/workspaceInvites/pending`, config);
      dispatch({
        type: "get-workspace-invites",
        payload: res.data.data,
      });
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const searchWorkspace = (query) => {
  return async (dispatch) => {
    try {
      const workspaceId = sessionStorage.getItem('workspaceId');
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/workspaces/${workspaceId}/search?query=${query}`, config);
      dispatch({
        type: "search-workspace",
        payload: res.data.data,
      });
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };

}

export const getWorkspaceDefaultFilter = (workspaceId) => {
  return async (dispatch) => {
    try {
      if (!workspaceId) {
        workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      }

      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/filter/workspace/${workspaceId}`, config);
      dispatch({
        type: "workspaceFilter",
        payload: res.data.data || {},
      });
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
}

export const removeMemberFromWorkspace = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/workspaces/removeMembers`, data, config);
      dispatch({
        type: "remove-workspace-user",
        payload: data?.members?.[0] || ''
      });
      callback && callback();
      notification.success({
        message: "Member removed from workspace!",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};