import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

const AddSection = (props) => {
  const intl = useIntl();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.sectionHandler("addSection");
  };

  return (
    <form onSubmit={handleSubmit} onBlur={handleSubmit} style={{ width: '100%' }}>
      <div
      className="border"
        style={{ borderTop: `2px solid ${props.color}`, borderRadius: "6px"}}
      >
        <input
          autoFocus
          value={props.sectionTitle}
          onChange={(e) => props.setSectionTitle(e.target.value)}
          placeholder={intl.formatMessage({ id: 'SECTION_NAME', defaultMessage: 'Section Name' })}
          required
          className={`${props.isListView ? 'list-view-card' : 'heading-card'} px-3 font-16 pt-2`}
          type="text"
          style={{ outline: "none", border: "none", height: "2rem" }}
        />
      </div>
    </form>
  );
};
const mapStateToProps = (state) => {
  return {
    isDuplicateField: state.sectionReducer.isDuplicateField,
  };
};
export default connect(mapStateToProps)(AddSection);
