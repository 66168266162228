import React from 'react';

export const SmallCircularButton = ({ text, onClick }) => {
  return (
    <button
      className="small-circular-button"
      onClick={onClick}
    >
      {text}
    </button>
  );
};
