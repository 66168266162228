export const localesLookup = {
    "en-US": {
        messages: 'en-US',
        dates: 'en_US'
    },
    en: {
        messages: 'en-US',
        dates: 'en_US'
    },
    ru: {
        messages: 'ru-RU',
        dates: 'ru_RU'
    },
};

export const activityNotificationsLookup = {
    MARKED_DONE: /(.+\s+)marked Done/,
    REOPENED: /(.+\s+)reopened/,
    ASSIGNED_TO_YOU: /(.+\s+)assigned to you/,
    MOVED_TO: /(.+\s+)moved to (\s*.+)/,
    MOVED_SMTH_TO: /(.+\s+)moved (\s*.+) to (\s*.+)/,
    UPDATED: /(.+\s+)updated$/,
    UPDATED_SMTH: /(.+\s+)updated\s*(\w+\s+(?!to\s).*)/,
    UPDATED_TO: /(.+\s+)updated (\s*.+) to (\s*.+)$/,
    ADDED_TO: /Added to (\s*.+)/,
    ADDED_TO_BY: /You were added to (\s*.+) by (\s*.+)/,
    NEW_MEMBER_IN_WORKSPACE: /New Member in Workspace!/
};

export const getLocalizedActivity = (message, intl) => {
    let localizedMessage = message;
    for (let activityLookupMatch in activityNotificationsLookup) {
        const activityRegexp = activityNotificationsLookup[activityLookupMatch];
        const intlParams = `${message}`.match(activityRegexp);
        
        if (intlParams) {
        localizedMessage = intl.formatMessage({
            id: activityLookupMatch,
            defaultMessage: message
        }, intlParams.reduce((result, currentValue, currentIndex) => {
            if (currentIndex === 0 || !currentValue) return result;

            const trimmedValue = `${currentValue}`.trim().replaceAll(' ', '_');

            return {
            ...result,
            [`value_${currentIndex}`]: intl.formatMessage({
                id: `${trimmedValue}`.toUpperCase(),
                defaultMessage: currentValue
            })
            };
        }, { value_1: '', value_2: '', value_3: '' }));
        break;
        }
    }
    return localizedMessage;
}

export const SUPPORTED_LOCALES = ['en', 'ru'];

export const languageLookup = {
    en: 'English',
    ['en-CA']: 'English',
    ['en-US']: 'English',
    ru: 'Русский',
};


export const taskSctivitiesLookup = {
    ACTIVITY_TASK_CREATED: /created this task/,
    ACTIVITY_TASK_UPDATE: /moved this task/,
    ACTIVITY_TASK_MARK: /marked this task/,
    ACTIVITY_TASK_CHANGED_CUSTOM_FIELD: /changed the ([\s\w]+)$/,
    ACTIVITY_TASK_UPLOADED: /uploaded/,
    ACTIVITY_TASK_CHANGED_FIELD: /changed the ([\s\w]+)$/,
    ACTIVITY_TASK_CHANGED_FRIENDLY_FIELD: /changed ((?!the\s).*)$/,
    ACTIVITY_TASK_TURNED_RECURRING: /turned recurring/,
    ACTIVITY_TASK_ASSIGNMENT: /added ([\w\s]+) to assignees list/,
    ACTIVITY_TASK_UNASSIGNMENT: /removed ([\w\s]+) from assignees list/,
    ACTIVITY_TASK_FILE_DELETED: /deleted/,
    ACTIVITY_TASK_USER_ADDED_TO_CHAT: /added $/,
    ACTIVITY_TASK_USER_DELETED_TO_CHAT: /removed $/,
};


export const getLocalizedTaskActivity = (message, intl, actor) => {
    let localizedMessage = message;
    for (let taskActivityLookupMatch in taskSctivitiesLookup) {
        const activityRegexp = taskSctivitiesLookup[taskActivityLookupMatch];
        const intlParams = `${message}`.match(activityRegexp);
        
        if (intlParams) {
        localizedMessage = intl.formatMessage({
            id: taskActivityLookupMatch,
            defaultMessage: message
        }, {
            actor,
            span: (msg) => (<span style={{ fontFamily: 'Lato-Bold' }}>{msg}</span>),
            ...intlParams.reduce((result, currentValue, currentIndex) => {
            if (currentIndex === 0 || !currentValue) return result;

            const trimmedValue = `${currentValue}`.trim().replaceAll(' ', '_');

            return {
            ...result,
            [`value_${currentIndex}`]: intl.formatMessage({
                id: `${trimmedValue}_TASK_ACTIVITY`.toUpperCase(),
                defaultMessage: currentValue
            })
            };
        }, { value_1: '' })});
        break;
        }
    }
    return localizedMessage;
}
