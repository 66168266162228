import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from 'antd';
import {
  FilterOutlined
} from '@ant-design/icons';
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { getWorkspaceDashboardData } from '../../../actions/WorkspaceActions';

const DashboardPriorityChart = ({
  currentWorkspace,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Register necessary chart components
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const charts = useSelector((state) => state.workspaceReducer.charts);

  const [selectedMember, setSelectedMember] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  const [data, setData] = useState(charts);

  useEffect(() => {
    const localizedCharts = {
      ...charts,
      labels: charts?.labels?.map(label => intl.formatMessage({ id: label?.toUpperCase().replaceAll(' ', '_'), defaultMessage: label })),
      datasets: charts?.datasets?.map(dataset => ({
        ...dataset,
        label: intl.formatMessage({ id: dataset?.label?.toUpperCase().replaceAll(' ', '_'), defaultMessage: dataset?.label })
      }))
    };
    setData(localizedCharts);
  }, [charts]);

  // Options for the chart
  const options = {
    indexAxis: "y", // This makes the bars horizontal
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        font: {
          size: 18, // Font size for the axis title
        },
        text: selectedMember?._id ?
          intl.formatMessage({
            id: selectedDate?.label ? 'VIEWING_ALL_TASKS_FOR_SELECTED_DATE' : 'VIEWING_ALL_TASKS',
            defaultMessage: `Viewing All ${selectedMember?.fullName}'s tasks ${selectedDate?.label ? 'for this ' + selectedDate?.label?.toLowerCase() : ''}`
          }, { name: selectedMember?.fullName, label: intl.formatMessage({ id: `FOR_${selectedDate?.key?.toUpperCase().replace('-', '_')}`, defaultMessage: selectedDate?.label?.toLowerCase() }) }) :
          intl.formatMessage({ id: 'WORKSPACE_OVERVIEW', defaultMessage: 'Workspace Overview' }),
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const getMembers = () => {
    const members = [];
    currentWorkspace?.members?.map(member => {
      members.push({
        keu: member?._id,
        label: member?.fullName,
        onClick: () => {
          dispatch(getWorkspaceDashboardData(member?._id, ''));
          setSelectedMember(member)
          setSelectedDate({})
        }
      })
    })
    return members;
  }

  const items = [
    {
      key: '1',
      type: 'group',
      label: intl.formatMessage({ id: 'FILTERS', defaultMessage: 'Filters' }),
    },
    {
      key: '2',
      label: intl.formatMessage({ id: 'ASSIGNED_TO', defaultMessage: 'Assigned to' }),
      children: getMembers(),
    },
    {
      key: '3',
      label: intl.formatMessage({ id: 'DATE_RANGE', defaultMessage: 'Date Range' }),
      children: [
        {
          key: 'today',
          label: intl.formatMessage({ id: 'TODAY', defaultMessage: 'Today' }),
          onClick: () => {
            setSelectedDate({
              key: 'today',
              label: intl.formatMessage({ id: 'TODAY', defaultMessage: 'Today' }),
            })
            dispatch(getWorkspaceDashboardData(selectedMember?._id, 'today'));
          }
        },
        {
          key: 'this-week',
          label: intl.formatMessage({ id: 'WEEK', defaultMessage: 'Week' }),
          onClick: () => {
            setSelectedDate({
              key: 'this-week',
              label: intl.formatMessage({ id: 'WEEK', defaultMessage: 'Week' }),
            })
            dispatch(getWorkspaceDashboardData(selectedMember?._id, 'this-week'));
          }
        },
        {
          key: 'this-month',
          label: intl.formatMessage({ id: 'MONTH', defaultMessage: 'Month' }),
          onClick: () => {
            setSelectedDate({
              key: 'this-month',
              label: intl.formatMessage({ id: 'MONTH', defaultMessage: 'Month' }),
            })
            dispatch(getWorkspaceDashboardData(selectedMember?._id, 'this-month'));
          }
        },
      ],
    },
  ];

  return (
    <div className='h-[90%] pb-4 w-full flex flex-col align-center'>
      <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
      >
        <div className='h-10 p-2' style={{ alignSelf: 'flex-start' }}>
          <div className='flex w-20 align-center cursor-pointer bg-gray-200 hover:bg-gray-100 p-1 pr-3 pl-2 rounded-md' onClick={() => { }} >
            <FilterOutlined className='mr-2 size-3' />
            <div className='font-14'><FormattedMessage id="FILTERS" defaultMessage="Filters" /></div>
          </div>
        </div>
      </Dropdown>
      <Bar data={data} options={options} style={{ height: '32vh' }} />
    </div>
  );
};

export default DashboardPriorityChart;



