import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NoSearchResultsFound = () => {
  return (
    <div className="text-center py-10 mt-50">
      <h2 className="text-xl font-bold text-gray-700">
        <FormattedMessage id="OOPS_NO_RESULTS" defaultMessage="Oops, no results found!" />
      </h2>
      <p className="text-lg text-gray-500 lg:px-20 px-2 mt-2">
      <FormattedMessage id="NO_RESULTS_DESCRIPTION" defaultMessage="Try searching with different keywords or adjust your filters to refine your search." />
        
      </p>
    </div>
  )
}

