import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Dropdown, Tooltip } from "antd";

import Button from "../../atoms/global/Button";
import Loader from '../../atoms/global/Loader';

import { fieldTypes } from '../../../../js/constants';

import {
  toggleWorkspaceFieldsModal,
  getCustomFieldsByAI,
  deleteWorkspaceCustomField
} from '../../../../actions';

import CustomFieldPanel from '../custom-field-panel/CustomFieldPanel';
import ProjectCustomFields from '../../molecules/project-dashboard/ProjectCustomFields';
import EmptyState from '../../atoms/empty-state/EmptyState';
import { FormattedMessage, useIntl } from 'react-intl';

const WorkspaceFieldsModal = (props) => {
  const {
    isModalOpen,
    workspace,
    fields,
    workspaceCustomFields
  } = props;

  const intl = useIntl();
  const workspaceProjects = workspace?.myProjects;

  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedField, setSelectedField] = useState({});
  const [showField, setShowField] = useState(false);

  const [selectedProject, setSelectedProject] = useState();
  const [customFields, setCustomFields] = useState(workspaceCustomFields || []);
  const [industry, setIndustry] = useState(workspace?.industry || workspace?.description);

  useEffect(() => {
    setCustomFields(workspaceCustomFields || []);
  }, [workspaceCustomFields])

  useEffect(() => {
    generateCustomFields();
  }, [workspace?.industry, workspace?.description])

  const closeModal = () => {
    setSelectedField({});
    setShowField(false);
    setSelectedIndex(0);
    dispatch(toggleWorkspaceFieldsModal(false, ''));
  }

  const generateCustomFields = () => {
    if (industry) {
      setIsLoading(true);
      dispatch(getCustomFieldsByAI({
        industry,
        categories: ['essentials'],
        entityId: workspace?._id,
        entityType: 'workspace'
      }, () => setIsLoading(false)));
    }
  }

  const options = ['Suggestions', 'Workspace Library', 'Project Fields', 'AI Suggested Fields']

  return (
    <Modal
      title={
        <div className='flex align-center'>
          <img
            src={'/images/icons/custom-fields.svg'}
            style={{
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              marginRight: '10px'
            }}
            alt='icon'
          />
          <div><FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" /></div>
        </div>}
      open={isModalOpen}
      onCancel={closeModal}
      width={window?.screen?.width / 1.7 || 800}
      centered
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 0,
        height: window?.screen?.height / 1.7 || 500,
        overflow: 'hidden',

      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}>
      <div className='flex  w-full h-full'>
        <div className={`${showField ? 'w-[55%]' : 'w-full '} flex  flex-col  duration-150 ease-in-out"`}>
          <ul className="mb-2 flex flex-row">
            {options?.map((option, i) => (
              <li key={option}>
                <button
                  onClick={() => { setShowField(false); setSelectedIndex(i) }}
                  className={`flex items-center justify-between w-full p-2  ${selectedIndex === i ? ' border-b-2 border-blue-600' : 'bg-white '}`}>
                  <div className={`text-md  ${selectedIndex === i ? 'text-[#002337]' : 'text-[#ccc]'}`}><FormattedMessage id={`${option}`.toUpperCase().replaceAll(' ', '_')} defaultMessage={option} /></div>
                </button>
              </li>
            ))}
          </ul>
          <div className={` h-full w-full pr-2`}>
            {selectedIndex === 0 && (
              <section className='h-full flex flex-col flex-1'>
                <div className="flex scroll-y flex-col pb-14  ">
                  {fieldTypes?.map(ftype => {
                    const isSelected = selectedField?.type === ftype?.key;
                    return (
                      <div
                        key={ftype?.key}
                        onClick={() => {
                          setSelectedField({
                            type: ftype?.key,
                            name: '',
                            possibleValues: ftype?.possibleValues,
                            _id: ftype?._id,
                            isUpdate: false,
                            multi: ftype?.multi
                          });
                          setShowField(true);
                        }}
                        className={`flex align-center cursor-pointer mb-2 justify-between    rounded-lg bg-slate-100  hover:bg-slate-50 hover:border-blue-500 border ${isSelected && showField ? ' border-blue-500' : 'border-transparent  '} `}>
                        <div className='flex p-2 w-[90%] align-center'>
                          <div style={{ backgroundColor: ftype?.color }} className='rounded-lg'>
                            <img
                              src={`/images/icons/${ftype?.icon || 'custom-fields.svg'}`}
                              style={{
                                cursor: 'pointer',
                                height: '30px',
                                width: '30px',
                                margin: '10px',
                                filter: 'invert(1) brightness(180%)'
                              }}
                              alt='icon'
                            />
                          </div>
                          <div className='ml-3 flex flex-col'>
                            <div className='font-14 font-bold'>
                              <FormattedMessage id={`${ftype?.label}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={ftype?.label} />
                            </div>
                            <div>
                              <FormattedMessage id={`${ftype?.label}_DESCRIPTION`.replaceAll(' ', '_').toUpperCase()} defaultMessage={ftype?.description} />
                            </div>
                          </div>
                        </div>
                        <div
                          className='h-full w-[10%] cursor-pointer flex  justify-center items-center p-1 rounded-tr-lg rounded-br-lg  bg-slate-200'>
                          <img
                            src="/images/icons/addblack.svg" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            )}

            {selectedIndex === 1 && (
              <div className='h-full w-full flex-1'>
                <div className="flex flex-col w-full scroll-y gap-2 pb-14 ">
                  {customFields?.map((item, i) => {
                    const icon = fieldTypes?.find(f => f.key == item?.type)?.icon || 'custom-fields.svg';
                    const color = fieldTypes?.find(f => f.key == item?.type)?.color || '#33fff8';
                    const isSelected = selectedField?._id === item?._id;
                    return (
                      <div
                        key={item?._id}
                        className={`flex align-center justify-between  rounded-lg p-2 cursor-pointer 
                        hover:border-blue-500 hover:bg-slate-50 bg-slate-100 border
                        ${isSelected && showField ? 'border-blue-500' : 'border-transparent'}`}
                        onClick={() => {
                          setSelectedField({
                            type: item?.type,
                            name: item?.name,
                            possibleValues: item?.possibleValues,
                            _id: item?._id,
                            isUpdate: false,
                            isWorkspace: true
                          });
                          setShowField(true);
                        }}>
                        <div className='flex align-center'>
                          <div style={{ backgroundColor: color }} className='rounded-lg'>
                            <img
                              src={`/images/icons/${icon}`}
                              style={{
                                cursor: 'pointer',
                                height: '30px',
                                width: '30px',
                                margin: '10px',
                                filter: 'invert(1) brightness(180%)'
                              }}
                              alt='icon'
                            />
                          </div>
                          <div className='ml-3'>
                            <div className='font-14 font-bold'>
                              {item?.name}
                            </div>
                            <div>
                              <FormattedMessage id="FIELD_TYPE" defaultMessage="Field Type" />: {item?.type}
                            </div>
                          </div>
                        </div>
                        <div className='flex align-center'>
                          <Tooltip title={intl.formatMessage({ id: 'REMOVE_FROM_PROJECT', defaultMessage: 'Remove from Project' })}>
                            <div
                              onClick={() => {
                                dispatch(deleteWorkspaceCustomField(item?._id, workspace?._id, () => {}, intl));
                              }}
                              className='pr-2 cursor-pointer hover:underline text-red-500'>
                              <img src="/images/icons/delete-red.svg" height={25} width={25} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })}
                  {customFields?.length == 0 && <EmptyState
                    header={intl.formatMessage({ id: 'SAVE_FIELDS_TO_WORKSPACE_DESCRIPTION', defaultMessage: 'Saving fields to workspace means you can reuse them across different projects!' })}
                    description={intl.formatMessage({ id: 'ZERO_SAVED_FIELDS_TO_WORKSPACE_DESCRIPTION', defaultMessage: 'You have 0 saved workspace custom fields' })}
                    iconSize='h-60 w-60 mt-20'
                    src='/images/icons/no-task.svg' />}
                </div>
              </div>
            )}
            {selectedIndex === 2 && (
              <div className='h-full flex flex-col flex-1'>
                <div className="flex flex-row align-center justify-between ">
                  <div className='font-16'>{
                    selectedProject
                      ? <FormattedMessage id="SELECT_FIELD_TO_MODIFY" defaultMessage="Select a field to modify it" />
                      : <FormattedMessage id="SELECT_PROJECT_TO_VIEW_FIELDS" defaultMessage="Select a project to view it's custom fields." />
                  }</div>
                  <Dropdown
                    className={`flex items-center space-x-4 cursor-pointer px-2 min-w-36 p-1`}
                    trigger={['click']}
                    menu={{
                      items: workspaceProjects?.map(project => ({
                        key: project?._id,
                        label: project?.title,
                        onClick: async () => {
                          setSelectedProject(project);
                          // await dispatch(getProjectById(project?._id, (newProject) => {
                          //   setSelectedProject(newProject);
                          // }));
                        }
                      })),
                      style: {
                        height: 200,
                        overflow: 'scroll',
                      }
                    }}
                    placement='bottom'
                  >
                    <div className=' flex align-center justify-between bg-white text-sm font-medium text-slate-800 px-2 py-1 rounded-lg border border-slate-400 hover:border-blue-400   shadow-sm duration-150 ease-in-out"'>
                      <div className='font-14'>{
                        selectedProject
                          ? selectedProject?.title
                          : <FormattedMessage id="SELECT_PROJECT" defaultMessage="Select Project" />
                      }</div>
                      <img
                        src={'/images/icons/show-more.svg'}
                        className='size-2'
                        alt="icon"
                      />
                    </div>
                  </Dropdown>
                </div>
                <div className='scroll-y pb-12 mt-2'>
                  {selectedProject ?
                    <ProjectCustomFields
                      project={selectedProject}
                      editOverride={(item) => {
                        setSelectedField({
                          type: item?.type,
                          name: item?.name,
                          possibleValues: item?.possibleValues,
                          _id: item?._id,
                          isUpdate: item?.isUpdate,
                          multi: item?.multi,
                          project: selectedProject
                        });
                        setShowField(true);
                      }}
                      selectedFieldType={selectedField?._id}
                      workspace={workspace}
                      showField={showField}
                      customFields={props?.customFields} />
                    :
                    <></>
                  }
                </div>
              </div>
            )}
            {selectedIndex === 3 && (
              <div className='h-full flex flex-col flex-1'>
                <div className="justify-between align-center  mb-5">
                  <div className='flex align-center'>
                    <div className='font-16'>
                      <FormattedMessage id="FIELDS_GENERATED_BY_AI_DESCRIPTION" defaultMessage="Fields automatically generated using A.I. based on your input" />
                    </div>
                    <div className='flex align-center'>
                      <input
                        value={industry}
                        onChange={(e) => setIndustry(e?.target?.value)}
                        placeholder={industry || intl.formatMessage({ id: 'INDUSTRY', defaultMessage: 'Industry' })}
                        className='border p-1 px-2 border-slate-400 rounded-lg ml-5' />
                      <div
                        onClick={() => {
                          generateCustomFields();
                        }}
                        className='ml-2 font-14 text-indigo-400 font-black cursor-pointer inline-flex items-center justify-center text-sm font-medium leading-5 rounded-lg py-1 px-3 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out'>
                        <FormattedMessage id="SEARCH" defaultMessage="Search" />
                      </div>
                    </div>
                  </div>
                </div>
                {!isLoading ? <div className="flex flex-col scroll-y pb-20" >
                  {fields?.length === 0 && <EmptyState
                    header={intl.formatMessage({ id: 'NO_CUSTOM_FIELDS_FOUND', defaultMessage: 'No custom fields found, try to generate some more!' })}
                    description={intl.formatMessage({ id: 'TRY_CHANGING_INDUSTRY', defaultMessage: 'Try changing industry to something like Tech' })}
                    iconSize='h-60 w-60 mt-20'
                    src='/images/icons/no-task.svg' />}
                  {fields && fields?.map(customField => {
                    const icon = fieldTypes?.find(f => f.key == customField?.type)?.icon || 'custom-fields.svg';
                    const color = fieldTypes?.find(f => f.key == customField?.type)?.color || '#33fff8';
                    const isSelected = selectedField?._id === customField?._id;
                    return (
                      <div
                        onClick={() => {
                          setSelectedField({
                            type: customField?.type || 'text',
                            name: customField?.name,
                            possibleValues: customField?.possibleValues,
                            _id: customField?._id,
                            multi: customField?.multi
                          });
                          setShowField(true);
                        }}
                        className={`flex align-center cursor-pointer mb-2 justify-between  col-span-1  rounded-lg bg-slate-100  hover:bg-slate-50 ${isSelected && showField ? 'border-blue-500' : 'border-transparent'} border hover:border-blue-500`} >
                        <div className='flex p-2 w-[90%] align-center'>
                          <div className='flex align-center'>
                            <div style={{ backgroundColor: color }} className='rounded-lg'>
                              <img
                                src={`/images/icons/${icon}`}
                                style={{
                                  cursor: 'pointer',
                                  height: '30px',
                                  width: '30px',
                                  margin: '10px',
                                  filter: 'invert(1) brightness(180%)'
                                }}
                                alt='icon'
                              />
                            </div>
                            <div className='ml-3'>
                              <div className='font-14 font-bold'>
                                {customField?.name}
                              </div>
                              <div>
                                {customField?.description
                                  ? customField?.description
                                  : `${intl.formatMessage({ id: 'FIELD_TYPE', defaultMessage: 'Field Type' })}: ${customField?.type}`
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div> : <Loader isLoading newLoader className={'left-[40%] mt-[50px]'} />}
              </div>
            )}

          </div>
        </div>
        <CustomFieldPanel
          showField={showField}
          field={selectedField}
          setShowField={setShowField}
          workspaceProjects={workspaceProjects}
          workspace={workspace}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.getProjects,
  user: state.userReducer.user,
  fields: state.aiReducer.customFieldSuggestions,
  workspace: state.workspaceReducer.currentWorkspace,
  workspaceCustomFields: state.workspaceReducer.workspaceCustomFields,
  customFields: state.projectReducer.projectCustomFields,
});

export default connect(mapStateToProps)(WorkspaceFieldsModal);
