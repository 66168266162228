import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import DashboardMembers from '../components/dashboard/Members';
import TeamDrawer from '../../components/UI/organisms/TeamDrawer';
import { approveAccessRequest, denyAccessRequest } from '../../actions';
import moment from 'moment';


const InviteMembersDrawer = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    user,
    currentWorkspace,
    chats,
    project,
    isWorkspaceInvites
  } = props;

  const isProject = !isWorkspaceInvites

  const [tab, setTab] = useState(isProject && project?.accessRequests?.length > 0 ? 2 : 1);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    setMembers(isProject ? project?.participants : currentWorkspace?.members)
  }, [currentWorkspace?.members, project?.participants])

  const handleSubmit = (request) => {
    dispatch(approveAccessRequest(request))
  }

  const closeHandler = (request) => {
    dispatch(denyAccessRequest(request))
  }

  return (
    <div className='h-[83vh]'>
      <div className='bg-[#0074cc] h-40 flex flex-col px-8' style={{ justifyContent: 'flex-end' }}>
        <div className='text-white font-black text-2xl'>{isProject ? project?.title : currentWorkspace?.title}</div>
        <Tabs
          defaultActiveKey={tab}
          size='large'
          style={{ color: 'white', margin: 0, padding: 0 }}
          onChange={(t) => {
            setTab(t);
          }}
          items={[
            {
              label: intl.formatMessage({ id: 'VIEW_MEMBERS', defaultMessage: 'View Members' }),
              key: 0,
              children: <></>
            },
            {
              label: intl.formatMessage({ id: 'INVITE_MEMBERS', defaultMessage: 'Invite Members' }),
              key: 1,
              children: <></>
            },
            {
              label: isProject
                ? intl.formatMessage({ id: 'PROJECT_REQUESTS', defaultMessage: 'Project Requests' })
                : intl.formatMessage({ id: 'PAST_INVITES', defaultMessage: 'Past Invites' }),
              key: 2,
              children: <></>
            },
          ]}
        />
      </div>
      <div className='m-2 overflow-scroll h-full'>
        {tab == 0 ? members?.map(member => {
          return (
            <DashboardMembers
              me={user?._id}
              member={member}
              chats={chats}
              showRole
              isProject={isProject && project?._id}
              workspaceId={currentWorkspace?._id} />
          )
        }) : (isProject && tab == 2) ?
          <div className='p-2'>
            <div className='mb-4 mt-2'>Approve or deny the following members from joining the project</div>
            {project?.accessRequests?.map(request => {
              return (
                <div className='flex align-center justify-between'>
                  <div className='flex align-center'>
                    <img src={request?.userId?.avi} alt='' className='card-image mr-4 object-cover h-10 w-10' />
                    <div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: '600'
                        }}>
                        {request?.userId?.fullName || ''}
                      </div>
                      <div style={{ fontSize: 12 }}><FormattedMessage id="REQUESTED_DATE" defaultMessage="Requested Date" />: <FormattedDate value={request?.createdAt} /></div>
                    </div>
                  </div>
                  <div className='flex align-center'>
                    <div className='bg-[#0473cc] size-8 mr-1 rounded-full flex justify-center items-center cursor-pointer' onClick={() => handleSubmit(request)}>
                      <img src='/images/icons/thick-white.svg' className='size-3' />
                    </div>
                    <div className='bg-red-500 size-8 rounded-full flex justify-center items-center cursor-pointer' onClick={() => closeHandler(request)}>
                      <img src='/images/icons/close.svg' className='size-2'
                        style={{ filter: 'invert(1) brightness(2)' }} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          :
          <TeamDrawer showInvites={tab == 2} projectData={project} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  currentWorkspace: state.workspaceReducer.currentWorkspace,
  chats: state.chatReducer.chats,
  project: state.projectReducer.getProjects
});

export default connect(mapStateToProps)(InviteMembersDrawer);
