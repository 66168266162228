import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import CommentSection from '../molecules/global/CommentSection';
import ChatTextArea from '../molecules/global/ChatTextArea';
import { useParams } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import {
  deleteChatFile,
  getChatRooms,
  updateChatRoomMessagesReadBy,
  uploadChatUrl
} from '../../../actions/chatRoomAction';
import { APPLICATION_NAME } from '../../../js/constants';
import {
  getMessages,
  updateQueryState,
} from '../../../actions/messageAction';
import {
  getWorkspaceById,
  isAiOpen,
  signedUploadUrl,
} from '../../../actions'
import Loader from '../atoms/global/Loader';
import { getDateTextLongFormat } from '../../../js/functions';

import Icon from '../atoms/global/Icon';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatRoom = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const param = useParams();

  const paramsId = param.chatId || param.projectid

  const { chats } = useSelector((state) => state.chatReducer);
  const { singleMessage } = useSelector((state) => state.messageReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { users } = useSelector((state) => state.userReducer);
  const [messages, setMessages] = useState(
    props?.activeChatMessages[paramsId]
  );
  const [draft, setDraft] = useState(props?.activeChatMessages[paramsId]?.find(m => m?.isDraft && m?.sender?._id?.toString() === user?._id?.toString()))
  const [page, setPage] = useState(2);

  const [chatAreaHeight, setChatAreaHeight] = useState(70);
  const [isLoading, setIsLoading] = useState(true);
  const [reply, setReply] = useState('');
  const commentsSectionRef = useRef(null);
  const chatTextAreaDivRef = useRef(null);

  let roomTitle;

  useEffect(() => {
    setMessages(props?.activeChatMessages[paramsId]);
    setDraft(props?.activeChatMessages[paramsId]?.find(m => m?.isDraft && m?.sender?._id?.toString() === user?._id?.toString()));
  }, [props?.activeChatMessages, dispatch, paramsId]);

  // Implementing pagination using ref

  const adjustScroll = () => {
    commentsSectionRef.current?.scroll({
      top: 200,
      behavior: 'smooth',
    });
  };

  // ScrollTOBottom

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }, [
    isLoading,
    props?.scrollPosition,
    dispatch,
  ]);
  
  const clearDraft = () => {
    setDraft(null);
  }

  const setDraftVal = (draft) => {
    setDraft(draft);
  }

  const scrollToBottom = () => {
    commentsSectionRef.current?.scroll({
      top: commentsSectionRef?.current?.scrollHeight || 0,
      // behavior: 'smooth',
    });
  };

  const setImageUrl = async (file) => {
    const chatIdPattern = /\/(?:chat\/)?([^/]+)/;
    const match = window.location.pathname.match(chatIdPattern);
    let roomId;
    if (match && match.length > 1) {
      roomId = match[1];
    }

    const uploadChatResponse = await dispatch(uploadChatUrl({
      fileName: file?.name,
      chatId: props?.roomId || roomId,
      fileSize: file?.size,
      type: file?.type
    }));
    const signedUrl = uploadChatResponse?.signedUrl;
    const fileId = uploadChatResponse?.fileId;
    if (signedUrl) {
      await dispatch(signedUploadUrl(signedUrl, file));
      return fileId;
    }
  }

  const delAttachment = async (fileId) => {
    dispatch(deleteChatFile(fileId));
  }

  const openAiPanel = () => {
    dispatch(isAiOpen(true));
  }

  const handleScroll = () => {
    const scrollableElement = commentsSectionRef.current;
    // Check if the scrollbar is close to the top
    if (
      scrollableElement.scrollTop == 0 &&
      messages?.length < props.totalMessages
    ) {
      // Make your API call here
      dispatch(
        getMessages(paramsId, { page }, (data) => {
          // dispatch(addChatMessage(paramsId, data?.messages));
          const nextPage = page + 1;
          setPage(nextPage);
          dispatch(updateQueryState(nextPage));
          adjustScroll();
        })
      );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // dispatch(getChatRooms());
    dispatch(
      getMessages(paramsId, { page: 1 }, async () => {
        const nextPage = 2;
        setPage(nextPage);
        await dispatch(updateQueryState(nextPage));
        // await dispatch(updateQueryState(props?.queryState.page + 1));
        setIsLoading(false);
      })
    );
  }, [paramsId]);

  const [comments, setComments] = useState({ res: { body: { data: [] } } });

  const filterChat = chats && chats?.find((chat) => chat._id === paramsId);
  if (filterChat && filterChat.roomName) {
    roomTitle = filterChat.roomName;
    document.title = `${filterChat.roomName} - ${APPLICATION_NAME}`;
  } else {
    const otherUser =
      filterChat &&
      filterChat.members &&
      filterChat.members.find((mem) => mem._id != user._id);
    if (otherUser && otherUser.fullName) {
      roomTitle = otherUser.fullName;
      document.title = `${otherUser.fullName} - ${APPLICATION_NAME}`;
    }
  }
  let lastSenderId = undefined;

  // messages?.length, isLoading

  const handleChatAreaFocus = () => {
    dispatch(updateChatRoomMessagesReadBy(paramsId, 'ChatRoom1')).then(() =>
      // dispatch(getChatRooms())
      dispatch(getWorkspaceById())
    );
  };

  useEffect(() => {
    return () => {
      // Place your cleanup code or function call here
      // Call your function here
      handleChatAreaFocus();
      clearDraft()
    };
  }, [paramsId]);

  let messageDate = '';

  return (
    <div className='d-flex' style={{ height: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
      <div
        className='chat-room-messages'
        ref={commentsSectionRef}
        id='scrollable'
        style={{
          overflowY: 'scroll',
        }}
        onScroll={handleScroll}
      >
        {isLoading ? (
          <Loader newLoader isLoading={isLoading} className={'left-[20%] lg:left-[50%]'} />
        ) : (
          <>
            {messages?.map((chat, index) => {
              if(chat?.isDraft) return;
              const lastSenderIndex = index > 0 ? index - 1 : -1;
              lastSenderId =
                messages?.length > 0 && lastSenderIndex >= 0
                  ? messages[lastSenderIndex].sender._id
                  : null;
              let showName = !lastSenderId || chat?.sender?._id !== lastSenderId;

              lastSenderId = chat?.sender?._id;
              let lastReply = chat?.replies?.length - 1;
              let lastReplyTime = chat?.replies[lastReply];
              const date = new Date(chat?.createdAt);
              const timeAgo = new TimeAgo(intl.locale);
              const time = timeAgo.format(date);
              const replySenders = uniqBy(chat?.replies?.map((reply) => reply?.sender), '_id');

              const senderDetails =
                users &&
                users.find((user) => user && user._id == chat?.sender?._id);
              if (senderDetails) {
                chat.sender.avi = senderDetails.avi;
              }

              const isMyMessage = chat?.sender?._id === localStorage.getItem('Id');

              let showDateRow = false;
              const currentMessageDate = new Date(chat?.createdAt).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });

              if (currentMessageDate !== messageDate) {
                showDateRow = true;
                messageDate = currentMessageDate;
              }

              return (
                <div
                  key={chat._id}
                  style={{}}>
                  {showDateRow && (
                    <div className='date-row'>
                      <div className='date-row-pill'>
                        {getDateTextLongFormat(new Date(chat?.createdAt), intl)}
                      </div>
                      <div className='date-row-line' />
                    </div>)}
                  <CommentSection
                    chats={chats}
                    key={chat._id}
                    chatId={chat?.roomId}
                    senderId={chat?.sender?._id}
                    msgId={chat._id}
                    height='30px'
                    width='30px'
                    commentPadding={showName ? '8px' : '2px'}
                    containerWidth='100vW-335px'
                    name={chat?.sender?.fullName}
                    sender={chat?.sender}
                    replies={chat?.replies}
                    lastReplyTime={
                      lastReplyTime ? new Date(lastReplyTime.createdAt) : new Date()
                    }
                    nameClass='font-16-600'
                    commentClass='font-14'
                    showName={showDateRow || showName}
                    src={chat?.sender?.avi}
                    hours={time}
                    date={date}
                    comment={chat?.messageDescription}
                    comments={comments}
                    replySenders={replySenders}
                    roomTitle={roomTitle}
                    attachments={chat?.attachments}
                    files={chat?.files}
                    setReply={setReply}
                  />
                </div>
              );
            })}
            {messages && messages.length == 0 && (
              <>
                <div className='date-row'>
                  <div className='date-row-pill'>
                    {new Date().toLocaleDateString(intl.locale, { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                  </div>
                  <div className='date-row-line' />
                </div>
                <div className='no-chat-text'>
                  <FormattedMessage
                    id="BE_FIRST_TO_SEND"
                    defaultMessage="Be the first to send <span>{roomTitle}</span> a message!"
                    values={{
                      span: (msg) => (<span style={{ fontWeight: 600 }}>{msg}</span>),
                      roomTitle
                    }}
                  />
                  
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className={`${reply?.msgId ? 'max-h-40 p-4 bg-white border-slate-200 border-2 border-b-0' : 'h-0'} mx-4 ransition duration-150`}>
        <div className='flex align-center justify-between mb-2'>
          <div className='font-14'>
            <FormattedMessage
              id="REPLYING_TO"
              defaultMessage="Replying to {name}"
              values={{
                span: (msg) => (<span style={{ color: '#0074cc', marginLeft: '5px' }}>{msg}</span>),
                name: reply?.sender
              }}
            />
          </div>
          <Icon
            name='close.svg'
            height='14px'
            onClick={() => {
              setReply({});
            }}
          />
        </div>
        <div
          style={{
            borderRadius: '4px',
            borderLeft: '1px solid var(--blue-blue-100, #07E)',
            background: 'rgba(0, 119, 238, 0.10)',
            padding: '8px 12px',
            width: '100%',
          }}
          className='font-16 truncate'
        >
          {reply?.comment}
        </div>
      </div>
      <div ref={chatTextAreaDivRef} style={{ marginBottom: '0.5rem', maxHeight: '70%', height: 'fit-content' }}>
        <ChatTextArea
          commentsSectionRef={commentsSectionRef}
          chatTextAreaDivRef={chatTextAreaDivRef}
          setChatAreaHeight={setChatAreaHeight}
          handleChatAreaFocus={handleChatAreaFocus}
          setImageUrl={setImageUrl}
          roomId={props.roomId}
          delAttachment={delAttachment}
          openAiPanel={openAiPanel}
          reply={reply}
          setReply={setReply}
          draft={draft}
          clearDraft={clearDraft}
          setDraftVal={setDraftVal}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    roomId: state.roomReducer.roomId,
    activeChatMessages: state.messageReducer.messages,
    activities: state.messageReducer.activities,
    totalMessages: state.messageReducer.totalMessages,
    scrollPosition: state.messageReducer.scrollPosition,
    summary: state.aiReducer.summary
  };
}

export default connect(mapStateToProps)(ChatRoom);
