import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';

import { addChatRoom } from "../../actions";
import DashboardMembers from "../components/dashboard/Members";
import { resetDrawer } from "../../actions/toggleAction";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

const AddChatDrawer = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    currentWorkspace,
    user,
    chats
  } = props;

  const [chatName, setChatName] = useState('');
  const [members, setMembers] = useState(currentWorkspace?.members);
  const [assigneeValue, setAssigneeValue] = useState([]);

  const isButtonDisabled = 
  (assigneeValue.length === 0 && !chatName) || // Disabled if both chatName is null and assigneeValue is empty
  (assigneeValue.length > 1 && !chatName);    // Disabled if multiple assignees but no chatName
  // const isButtonDisabled = !chatName || assigneeValue?.length == 0;

  const formatOptionLabel = ({ label, avi }) => (
    <div className='flex align-center'>
      {avi && (
        <img
          className="h-6 w-6 rounded-full mr-3"
          src={avi} />
      )}
      <div className='font-weight-500'>{label}</div>
    </div>
  );

  const createChatRoom = async () => {
    if (isButtonDisabled) {
      return;
    }

    const createdChat = await dispatch(addChatRoom({ members: assigneeValue?.map(x => x?.value), roomName: chatName, workspaceId: currentWorkspace?._id }));
    dispatch(resetDrawer());
    setChatName('');
    setTimeout(() => {
      navigate(`/chat/${createdChat._id.toString()}`);
    }, 100);
  };

  return (
    <div className='h-full flex flex-col align-center justify-between w-full'>
      <div className="w-full overflow-hidden h-full">
        <div className='bg-[#0074cc] h-40 flex flex-col p-8' style={{ justifyContent: 'flex-end' }}>
          <div className='text-white font-black text-2xl'>
            <FormattedMessage id="START_NEW_CHAT" defaultMessage="Start a new chat" /></div>
        </div>
        <div className='flex-col w-full align-center justify-between p-4 h-full'>
          <div className="space-y-3">
            <label><FormattedMessage id="CHAT_NAME" defaultMessage="Chat Name" /></label>
            <input
              type='text'
              value={chatName}
              placeholder={intl.formatMessage({ id: 'NAME_NEW_CHAT_GROUP', defaultMessage: 'Name your new chat group' })}
              onChange={e => setChatName(e?.target?.value)}
              className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
          </div>
          <div>
            <div className="mt-5 mb-2">
              <FormattedMessage id="SEARCH_MEMBERS" defaultMessage="Search members" /></div>
            <Select
              value={assigneeValue}
              isMulti
              isClearable={false}
              options={members
                ?.filter(
                  (newUser) =>
                    !assigneeValue?.some(
                      (existingUser) => existingUser?._id === newUser?._id
                    )
                )
                ?.map((participant) => {
                  return {
                    value: participant?._id,
                    label: participant?.fullName,
                    avi: participant?.avi,
                  };
                })}
              placeholder={intl.formatMessage({ id: 'SELECT_PLACEHOLDER', defaultMessage: 'Select…' })}
              formatOptionLabel={formatOptionLabel}
              onChange={(participants) => {
                const x = currentWorkspace?.members?.filter(user => {

                  if (participants?.find(p => p?.value == user?._id)) {
                    return false
                  }
                  return true;
                });
                setMembers(x);
                setAssigneeValue(participants);
              }}
              autoFocus={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="overflow-scroll mt-2 h-2/4" style={{  }}>
            {members?.map(member => {
              return (
                <DashboardMembers
                  me={user?._id}
                  member={member}
                  chats={chats}
                  callback={(participant) => {
                    setAssigneeValue([...assigneeValue, {
                      value: participant?._id,
                      label: participant?.fullName,
                      avi: participant?.avi,
                    }]);
                   setMembers(members?.filter(user => user?._id  != participant?._id));
                   }}
                  workspaceId={currentWorkspace?._id} />
              )
            })}
          </div>
        </div>
      </div>
      <div className="px-4 pb-4 w-full">
        <div
          onClick={(e) => {
            createChatRoom();
          }}
          className={`w-full text-center px-4 py-2 bg-[#0074cc] ${isButtonDisabled && 'opacity-70'} font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
          <FormattedMessage id="CREATE_CHAT" defaultMessage="Create Chat" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  currentWorkspace: state.workspaceReducer.currentWorkspace,
  chats: state.chatReducer.chats,
});

export default connect(mapStateToProps)(AddChatDrawer);
