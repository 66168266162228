import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Checkbox } from "antd";

import { fieldTypes } from '../../../../js/constants';
import { getRandomColor } from '../../../../js/helper';

import Icon from '../../atoms/global/Icon';
import {
  addCustomFieldToProject,
  addCustomFieldToWorkspace,
  deleteProjectCustomField,
  getProjectCustomField,
  updateProjectCustomField
} from '../../../../actions';
import '../../../../assets/style/modal.css';
import { FormattedMessage, useIntl } from 'react-intl';

const CustomFieldPanel = (props) => {
  const {
    setShowField,
    showField,
    field,
    workspaceProjects,
    workspace,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(field?.type || 'dropdown');

  const predefinedOptions = fieldTypes?.find(f => f.key == field?.type);

  const [options, setOptions] = useState(field?.possibleValues || predefinedOptions?.options || []);
  const [optionInput, setOptionInput] = useState('');

  const [fieldName, setFieldName] = useState('');
  const [isUpdate, setIsUpdate] = useState(field?.isUpdate);

  const [selectedProject, setSelectedProject] = useState();
  const [suggestion, setSuggestion] = useState(field?.suggestion || '');

  const [multi, setMulti] = useState(field?.multi || false);
  const [saveToWorkspace, setSaveToWorkspace] = useState(false);

  const projectid = selectedProject?._id;

  useEffect(() => {
    dispatch(getProjectCustomField(projectid));
  }, []);

  useEffect(() => {
    setSelectedType(field?.type || 'dropdown');
    setFieldName('');
    setOptions(field?.possibleValues || predefinedOptions?.options || []);
    setIsUpdate(field?.isUpdate);
    setSuggestion(field?.suggestion);
    setMulti(field?.multi);
    setSelectedProject(field?.project);
  }, [field])

  useEffect(() => {
    if (field?.name) {
      setFieldName(field.name);
    }
  }, [field?.name]);

  const resetOptions = () => {
    setSelectedType(field?.type || 'dropdown');
    setSaveToWorkspace(false);
    setFieldName('');
    setOptionInput('');
    setIsUpdate();
    setOptions([]);
    setSelectedProject();
  }

  const onClose = () => {
    resetOptions();
    setShowField(false);
  };


  const footer = () => {
    const requiresOptions = selectedType === "dropdown" || selectedType === "status";

    const isAddDisabled = !fieldName || (requiresOptions && options.length === 0) || !selectedProject;
    const isUpdateDisabled = !fieldName || (requiresOptions && options.length === 0);
    const isDisabled = isUpdate ? isUpdateDisabled : isAddDisabled;

    return (
      <div>
        {!field?.isWorkspace && !isUpdate && <div className='flex align-center p-2 cursor-pointer' onClick={() => setSaveToWorkspace(!saveToWorkspace)}>
          <Checkbox checked={saveToWorkspace} />
          <div className='font-14 ml-2'><FormattedMessage id="SAVE_TO_WORKSPACE_LIBRARY" defaultMessage="Save to workspace library" /></div>
        </div>}
        <div
          className={`m-2 text-center border-radius10 ${isDisabled ? 'opacity-50 ' : ''}`}
          onClick={() => {
            if (isDisabled) return;
            let newOptions = [];
            if (options) {
              newOptions = options.map((obj) => {
                delete obj.key;
                return obj;
              });
            }

            if (!!isUpdate) {
              dispatch(updateProjectCustomField(field?._id, {
                name: fieldName,
                possibleValues: newOptions,
                projectId: projectid
              }, async () => {
                await dispatch(getProjectCustomField(projectid))
                onClose();
              }))
            } else {
              dispatch(addCustomFieldToProject({
                name: fieldName,
                entityId: projectid,
                entityType: "project",
                type: selectedType,
                possibleValues: newOptions,
                suggestion,
                multi
              }, async () => {
                if (saveToWorkspace) {
                  dispatch(addCustomFieldToWorkspace({
                    name: fieldName,
                    entityId: workspace?._id,
                    entityType: "workspace",
                    type: selectedType,
                    possibleValues: newOptions,
                    suggestion,
                    multi
                  }));
                }
                await dispatch(getProjectCustomField(projectid))
                resetOptions();
                onClose();
              }))
            }
          }}>
          <div className={`flex justify-center w-full p-2 rounded-lg bg-blue-400 text-white ${!isDisabled && 'hover:bg-blue-300 cursor-pointer'}`}>
            {isUpdate
              ? <FormattedMessage id="UPDATE_FIELD" defaultMessage="Update Field" />
              : <FormattedMessage id="ADD_FIELD" defaultMessage="Add Field" />
            }
          </div>
        </div>
      </div>

    );
  };

  return (
    <div className={` pl-5 flex flex-col h-full flex-1 justify-between ${showField ? 'w-[40%]' : 'w-0 pl-0 opacity-0'}`}>
      <div className='flex flex-col bg-slate-100 rounded-md h-full flex-1 p-2 '>
        <div className='w-full flex justify-between mb-2'>
          <div className='flex align-center px-2 pt-1'>
            <img src='/images/icons/chevron-left.svg' className='size-6 cursor-pointer' onClick={() => {
              onClose();
            }} />
            <div className='font-16 ml-1 font-bold'>{
              isUpdate
                ? <FormattedMessage id="UPDATE_CUSTOM_FIELD" defaultMessage="Update Custom Field" />
                : <FormattedMessage id="ADD_CUSTOM_FIELD" defaultMessage="Add Custom Field" />
            }</div>
          </div>
          {isUpdate &&
            <div
              onClick={() => {
                dispatch(deleteProjectCustomField(field?._id, projectid, async () => {
                  await dispatch(getProjectCustomField(projectid))
                  onClose();
                }, intl));
              }}
              className='flex align-center mt-2 mr-2 cursor-pointer'>
              <div className='pr-2 text-red-400'><FormattedMessage id="DELETE" defaultMessage="Delete" /></div>
              <Icon
                name='delete-red.svg'
                height='24px'
              />
            </div>
          }
          {/* {!field?.isWorkspace && !isUpdate && <div className='flex align-center  cursor-pointer' onClick={() => setSaveToWorkspace(!saveToWorkspace)}>
            <Checkbox checked={saveToWorkspace} />
            <div className='font-14 ml-1'>Save to workspace</div>
          </div>} */}
        </div>
        <div className='w-full scroll-y h-full  px-2'>
          <div className="space-y-1 w-full mb-2">
            <label><FormattedMessage id="PROJECT" defaultMessage="Project" /></label>
            <Dropdown
              className={`flex items-center space-x-2 hover:bg-slate-50 px-1 py-2 h-100 cursor-pointer`}
              trigger={['click']}
              menu={{
                items: workspaceProjects?.map(project => ({
                  key: project?._id,
                  label: project?.title,
                  onClick: async () => {
                    setSelectedProject(project);
                    // await dispatch(getProjectById(project?._id, (newProject) => {
                    //   setSelectedProject(newProject);
                    // }));
                  }
                })),
                style: {
                  height: 300,
                  overflow: 'scroll'
                }
              }}
              placement='bottom'
            >
              <div className='w-full flex align-center justify-between bg-white text-xs font-medium text-slate-800 px-2 py-2 rounded-lg h-10 border  border-gray-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                <div className='font-14'>{selectedProject
                  ? selectedProject?.title
                  : <FormattedMessage id="SELECT_PROJECT" defaultMessage="Select Project" />}</div>
                <img
                  src={'/images/icons/show-more.svg'}
                  alt="icon"
                />
              </div>
            </Dropdown>
          </div>
          <div className='flex align-center justify-between '>
            <div className="space-y-1 w-2/3 mr-3">
              <label><FormattedMessage id="LABEL" defaultMessage="Label" /></label>
              <input
                type='text'
                value={fieldName}
                placeholder={intl.formatMessage({
                  id: 'ENTER_NAME_FOR_FIELD',
                  defaultMessage: 'Enter name for {field}'
                }, {
                  field: intl.formatMessage({ id: `${selectedType}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: selectedType})
                })}
                onChange={e => setFieldName(e?.target?.value)}
                className='w-full  h-10 flex align-center justify-between bg-white text-xs font-medium text-slate-800 px-2 py-2 rounded-lg border  border-gray-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"' />
            </div>
            <div className="space-y-1 w-32">
              <label ><FormattedMessage id="FIELD_TYPE" defaultMessage="Field Type" /></label>
              <Dropdown
                menu={{
                  items: fieldTypes?.map(item => ({
                    key: item?.key,
                    label: intl.formatMessage({ id: `${item?.label}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: item?.label }),
                    onClick: () => setSelectedType(item?.key)
                  }))
                }}
                trigger={['click']}
                placement='bottom'>
                <div className='w-full h-10 cursor-pointer flex align-center justify-between bg-white text-xs font-medium text-slate-800 px-2 py-2 rounded-lg border  border-gray-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                  <div className='font-14 pl-1'>
                    <FormattedMessage id={`${selectedType}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedType} /></div>
                  <img
                    src={'/images/icons/show-more.svg'}
                    alt="icon"
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {(selectedType === 'dropdown' || selectedType === 'status') && <div className="mb-8 mt-2">
            <div className='py-1'><FormattedMessage id="OPTIONS" defaultMessage="Options" />:</div>

            <div className='mb-2'>
              {options && options.map((option, i) => {
                return (
                  <div className='option-field-row p-2 hover:bg-gray-50'>
                    <input
                      className='py-1 px-2 w-full'
                      style={{ background: 'transparent' }}
                      value={option.label || ''}
                      onChange={(e) => {
                        const x = options.slice();
                        x[i] = { ...x[i], label: e?.target?.value || '' };
                        // <FormattedMessage id={`${option.label || option || ''}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={option.label || option || ''} />
                        setOptions(x || []);
                      }}
                    />
                    <div className='d-flex align-center'>
                      <Icon
                        name='delete-red.svg'
                        height='26px'
                        pl='10px'
                        onClick={() => {
                          const x = options.slice();
                          x.splice(i, 1);
                          setOptions(x || []);
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='d-flex align-center mt-1'>
              <input
                onBlur={() => {
                  if (!!optionInput) {
                    const x = options.slice();
                    x.push({
                      key: Math.random(),
                      label: optionInput || '',
                      metadata: {
                        color: getRandomColor(options.length || 0)
                      }
                    })
                    setOptions(x || []);
                    setOptionInput('');
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (!!optionInput) {
                      const x = options.slice();
                      x.push({
                        key: Math.random(),
                        label: optionInput || '',
                        metadata: {
                          color: getRandomColor(options.length || 0)
                        }
                      })
                      setOptions(x || []);
                      setOptionInput('');
                    }
                  }
                }}
                onChange={e => setOptionInput(e?.target?.value)}
                value={optionInput}
                placeholder={`${intl.formatMessage({ id: 'INPUT_OPTION', defaultMessage: 'Input Option'})} ${options?.length + 1}`}
                className="w-full h-10 flex items-center bg-white text-xs  text-slate-800 px-2 py-2.5 rounded-lg border  border-gray-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
              <div
                onClick={() => {
                  const x = options?.slice();

                  if (optionInput == '') {
                    return
                  }

                  x?.push({
                    key: Math.random(),
                    label: optionInput,
                    metadata: {
                      color: getRandomColor(options.length || 0)
                    }
                  })
                  setOptions(x || []);
                  setOptionInput('');
                }}
                className='ml-2 h-10 font-14 text-indigo-400 font-black cursor-pointer inline-flex items-center justify-center text-sm font-medium leading-5 rounded-lg py-2 px-3 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out'>
                <Icon
                  name='add.svg'
                  height='14px'
                />
              </div>
            </div>
          </div>}
        </div>
        {footer()}
      </div>
    </div>
  );
};


export default CustomFieldPanel;
