import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Plan from '../UI/molecules/pricing/Plan';
import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'

import { APPLICATION_NAME } from '../../js/constants';
import { getPlanDetails } from '../UI/molecules/pricing/data';
import { getUserLicense } from '../../js/functions';

const Pricing = ({ hideHeader = false }) => {

  const navigate = useNavigate();
  document.title = `Pricing - ${APPLICATION_NAME}`
  const dispatch = useDispatch();

  const [isMonthly, setIsMonthly] = useState(true);

  let price = 9.99;
  const planPrice = price - (isMonthly ? 0 : 2);
  const loggedInUser = useSelector(state => state.userReducer.user);
  const license = getUserLicense(loggedInUser, sessionStorage.getItem("organization") || localStorage.getItem("organization"))

  const {
    monthlyStarterPlanDetails,
    teamPlanDetails,
    enterprisePlanDetails
  } = getPlanDetails(dispatch, navigate, isMonthly, planPrice, license);



  const priceTable = () => (
    <div className="pricing-table" style={{ color: hideHeader && 'black' }}>
      <Plan plan={monthlyStarterPlanDetails} />
      <Plan plan={teamPlanDetails} />
      <Plan plan={enterprisePlanDetails} />
    </div>
  )

  if (hideHeader) {
    return priceTable();
  }

  return (
    <div
      className='grow'
      style={{ background: '#fafafa', flexDirection: 'column', height: '100vh' }}>
      <ProductNavBar />
      <div className='pricing-container' style={{
        flexDirection: 'column', overflowY: 'scroll',
        background: 'linear-gradient(to bottom, rgba(4, 115, 204, 0.08), rgba(4, 115, 204, 0))'
      }}>
        <h3 className="pricing-title mt-1">
          <FormattedMessage id="CHOOSE_A_PLAN" defaultMessage="Choose A Plan That Suits You" />
        </h3>
        <div className="d-flex mb-2 pricing-toggle-container">
          <div
            className={`price-toggle ${isMonthly ? 'selected' : ''}`}
            onClick={() => setIsMonthly(true)}
          >
            <FormattedMessage id="MONTHLY" defaultMessage="Monthly" />
          </div>
          <div
            className={`price-toggle ${!isMonthly ? 'selected' : ''}`}
            style={{ borderLeft: 'none' }}
            onClick={() => setIsMonthly(false)}
          >
            <FormattedMessage id="YEARLY" defaultMessage="Yearly" />
          </div>
        </div>
        {priceTable()}
      </div>
    </div>
  );
};

export default Pricing;