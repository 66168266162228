import React, { useState } from 'react';
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';

import RoleSelect from '../role-select/RoleSelect';
import UserSelect from '../../atoms/input/UserSelect';

import moment from 'moment';

import { addProjectUser } from '../../../../actions/projectUserActions';
import {
  getProjectById,
  inviteToWorkspace,
  getTeamInvites,
  inviteToTeam
} from '../../../../actions';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

const TeamDrawerSearchBar = (props) => {
  const intl = useIntl();
  const [assigneeValue, setAssigneeValue] = useState([]);
  const [clearInput, setClearInput] = useState(false);

  const dispatch = useDispatch();

  const { inviteDetails, showInvites = false } = props;

  const formatOptionLabel = ({ value, label, email, avi }) => (
    <div className='d-flex'>
      {avi && <img
        src={avi}
        alt=''
        style={{ height: '20px', 'padding-right': '10px' }}
      />}
      <div className='d-flex justify-content-between flex-grow1' style={{ alignItems: 'center' }}>
        <div
          className='font-weight-500 '
          style={{ lineHeight: '16px', paddingTop: '2px' }}
        >
          {label}
        </div>
        <div
          className='font-11'
          style={{ lineHeight: '13px', paddingTop: '2px', marginLeft: '5px' }}
        >
          {email}
        </div>
      </div>
    </div>
  );

  const { type, teamId } = inviteDetails;
  const isWorkspace = type && type === 'Workspace';
  const isTeams = type && type === 'Teams';
  const isProjects = type && type == 'Project';

  const handleProjectAdd = () => {
    const users = assigneeValue?.map((user) => {
      return {
        userId: user.value,
        email: user.email,
        role: user.role
      }
    });
    if (users?.length) {
      props?.addProjectUser(
        { invitees: users, projectId: props?.projectId, workspaceId: props?.workspaceId },
        () => {
          props?.getProject(props?.projectId);
          setAssigneeValue([]);
        }
      );
    }
  }

  const handleTeamAdd = async () => {
    const res = await dispatch(inviteToTeam({
      teamId: teamId,
      invitees: assigneeValue
    }));

    if (res < 400) {
      await setAssigneeValue([]);
      await dispatch(getTeamInvites(teamId));
    }
  }

  const handleWorkspaceAdd = () => {
    dispatch(inviteToWorkspace({
      workspaceId: props?.workspaceId || '',
      invitees: assigneeValue
    }));
    setClearInput(true);
    setAssigneeValue([]);
  }

  return (
    <div>
      {!showInvites && <>
        <div className='font-16 mb-2'>
        {isWorkspace
            ? <FormattedMessage id="EMAIL_ADDRESSES" defaultMessage="Email Addresses" />
            : <FormattedMessage id="INVITE_FROM_WORKSPACE" defaultMessage="Invite from workspace" />
          }
        </div>
        {!isWorkspace ? <Select
          value={assigneeValue}
          isMulti
          isClearable={false}
          // Only users that are not already in team
          options={props?.allUsers
            ?.filter(
              (newUser) =>
                !props?.participants?.some(
                  (existingUser) => existingUser?._id === newUser?._id
                )
            )
            ?.map((participant) => {
              return {
                value: participant?._id,
                label: participant?.fullName,
                email: participant?.email,
                avi: participant?.avi,
                role: 'collaborator'
              };
            })}
          formatOptionLabel={formatOptionLabel}
          onChange={(participants) => setAssigneeValue(participants)}
          autoFocus={true}
          classNames={{
            menuList: () => 'scroll-y',
            control: () => 'mb-8 w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out',
            container: () => 'search-container'
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        /> : (
          <UserSelect placeholder={'hello@hotmail.com, user@gmail.com,…'} setEmails={setAssigneeValue} clearInput={clearInput} />
        )}
      </>}
      {assigneeValue.length > 0 ? (
        <div>
          <div className='members-section'>
            <label className='inp-lbl' style={{ color: '#434343' }}><FormattedMessage id="INVITE_MEMBERS" defaultMessage="Invite Members" />:</label>
            <RoleSelect members={assigneeValue} setMembers={setAssigneeValue} roles={['admin', 'collaborator', 'commentator']} />
          </div>
          <button
            className='butn-primary'
            onClick={(e) => {
              e.preventDefault();

              if (isWorkspace) {
                handleWorkspaceAdd();
              } else if (isTeams) {
                handleTeamAdd();
              } else {
                handleProjectAdd();
              }
            }}>
            <FormattedMessage id="SEND_INVITE" defaultMessage="Send Invite" />
          </button>
        </div>
      ) : (
        // <ParticipantCardsWrapper
        //   participants={props?.participants}
        //   projectId={props?.projectId}
        //   phase={type}
        //   teamId={inviteDetails?.teamId}
        //   workspaceId={props?.workspaceId}
        // />
        <></>
      )}
      {showInvites && inviteDetails?.pastInvites &&
        <div className='mt-3'>
          <label className='inp-lbl' style={{ color: '#434343' }}>
            {isProjects
              ? <FormattedMessage id="PROJECT_REQUESTS" defaultMessage="Project Requests" />
              : <FormattedMessage id="PENDING_INVITES" defaultMessage="Pending Invites" />
            }
          </label>
          <ul className="space-y-3 mt-3">
            {inviteDetails?.pastInvites?.map(invitee => (
              <li>
                <div className="flex items-center justify-between">
                  <div className="grow flex items-center">
                    <div className="truncate">
                      <span className="text-sm font-medium text-slate-800 ">{invitee?.inviteeEmail}</span>
                    </div>
                  </div>
                  <div
                    className="text-xs inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">
                    <FormattedDate value={invitee?.createdAt} />
                    {/* {moment(new Date(invitee?.createdAt)).format('DD MMM, YYYY')} */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>}
    </div >
  );
};

const mapDispatchToProps = (dispatch) => ({
  addProjectUser: (data, callback) =>
    dispatch(addProjectUser(data), callback()),
  getProject: (projectId) => dispatch(getProjectById(projectId)),
});

export default connect('', mapDispatchToProps)(TeamDrawerSearchBar);
