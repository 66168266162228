import React from 'react';
import UserAvatar from './UserAvatar';
const AssigneeOptionLabel = ({ value, label, email, avi }) => {
    return (
        <div className="flex align-center">
            <UserAvatar
                fullName={label}
                userId={value}
                src={avi}
                styles={{
                    height: '24px',
                    width: '24px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                }}
            />
            <div className="font-bold font-16 py-1 px-2">{label}</div>
        </div>
    );
};
export default AssigneeOptionLabel;
