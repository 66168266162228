import React, { useEffect } from 'react';
import { Dropdown } from 'antd';

import '../../../../assets/style/global.css';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

const RoleSelect = (props) => {
  const intl = useIntl();
  const setNewMemberRole = (role, i) => {
    const newMembers = [...props.members];
    if (newMembers[i] !== undefined) {
      newMembers[i].role = role;
      props.setMembers(newMembers);
    }
  };

  const getRole = (role) => {
    if (role == 'admin') {
      return 'admin';
    } else if (role == 'commentator') {
      return 'guest';
    } else {
      return 'member';
    }
  }

  const getMenuItems = (index) => {
    const items = [];
    props.roles.map(role => {
      items.push({
        key: index + role,
        label: <div className='capitalize' onClick={() => setNewMemberRole(role, index)}>
          {intl.formatMessage({ id:`${getRole(role)}`.toUpperCase(), defaultMessage: getRole(role) }).toLowerCase()}
        </div>,
      })
    });
    return items;
  };

  useEffect(() => {
    if (isEmpty(props.members) || isEmpty(props.roles)) return;

    (props.members || []).forEach((member, i) => {
      if (!(props.roles || []).includes(member?.role)) {
        setNewMemberRole(props.roles?.[props.roles?.length - 1], i)
      }
    });
  }, [props.members, props.roles, setNewMemberRole])

  return (
    <div style={{ maxHeight: props.maxHeight || 'auto', overflowY: 'scroll' }}>
      {props.members.map((data, i) => {
        const name = data.fullName || data.label || '';
        return (
          <div className='roles-row d-flex'>
            <div className='card-left-flex'>
              {data?.avi ?
                <img src={data?.avi} alt='' className='card-image object-cover h-10 w-10' /> :
                <> </>}
              <div className='card-text'>
                <div style={{ color: '#434343', fontSize: '18px' }}>{name}</div>
                <div style={{ color: '#747474', fontSize: '14px' }} >{data?.email}</div>
              </div>
            </div>
            <Dropdown
              trigger={['click']}
              menu={{
                items: getMenuItems(i),
              }}
              placement='bottom'
              arrow
            >
              <div className='flex align-center justify-center'>
                <div
                  className='mr-2 capitalize'
                  style={{
                    color: '#747474',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}>{intl.formatMessage({ id:`${getRole(data?.role)}`.toUpperCase(), defaultMessage: getRole(data?.role) }).toLowerCase()}</div>
                {data?.role !== 'owner' && <img
                  src={'/images/icons/show-more.svg'}
                  alt="icon"
                />}
              </div>
            </Dropdown>
          </div>
        );
      })}
    </div>
  );
};


export default RoleSelect;
