import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Resizable } from 're-resizable';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modal } from 'antd';

import {
  isTicketOpen,
  deleteTask,
  setDrawerWidth,
  getTaskByIdentifier
} from '../../../actions';

import ConfirmationModal from '../molecules/modals/ConfirmationModal';
import TaskNavSection from '../molecules/task-side-panel/TaskNavSection';
import TaskDetailSection from '../molecules/task-side-panel/TaskDetailSection';

import '../../../assets/style/taskDrawer.css';

import TaskPanelSkeleton from './skeletons/TaskPanelSkeleton';

const TaskSidePanel = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isModalDefaultView = useSelector(state => state.toggleReducer.showTaskAsModal);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [task, setTask] = useState(props?.singleTask);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalView, setIsModalView] = useState(isModalDefaultView);

  const selectedTaskParam = new URLSearchParams(location.search).get('selectedTask');

  const resizableRef = useRef(null);
  const ticketDrawerRef = useRef(null);

  const deleteHandler = async () => {
    await dispatch(
      deleteTask(task._id, () => {
        dispatch(isTicketOpen(false));
        const params = new URLSearchParams(location.search);
        params.delete('selectedTask'); // Remove the specified parameter
        const newUrl = `${location.pathname}?${params.toString()}`;
        navigate(newUrl, { replace: true });
      })
    );
  };

  useEffect(() => {
    return () => {
      setTask({});
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);
    setTask(props?.singleTask);
  }, [props?.singleTask])

  useEffect(() => {
    if (selectedTaskParam && selectedTaskParam != 'loading') {
      setIsLoading(true);
      dispatch(isTicketOpen(true));
      if(props?.singleTask?.titleIdentifier != selectedTaskParam) {
        dispatch(getTaskByIdentifier(selectedTaskParam, 'TaskSidePanel', false, () => {
          // const params = new URLSearchParams(location.search);
          // params.delete('selectedTask'); // Remove the specified parameter
          // const newUrl = `${location.pathname}?${params.toString()}`;
          // navigate(newUrl, { replace: true });
        }));
        // dispatch(getTaskByIdentifier(selectedTaskParam, 'TaskSidePanel'));
      } else {
        setTask(props?.singleTask);
        setIsLoading(false);
      }
    } else if(selectedTaskParam != 'loading') {
      dispatch(isTicketOpen(false));
    }
  }, [selectedTaskParam]);

  const [selectedTab, setSelectedTab] = useState('1');

  const taskComponent = () => {
    return (
      <>
        <TaskNavSection
          task={task}
          setDeleteModalOpen={setDeleteModalOpen}
          projectId={props.projects?._id}
          setIsModalView={setIsModalView}
          isModal={isModalView}
          setSelectedTab={setSelectedTab}
        />
        <TaskDetailSection
          task={task}
          projects={props.projects}
          taskProperties={props.taskProperties}
          projectId={props.projects?._id}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          workspaceProjects={props?.myProjects}
          isModal={isModalView} />
      </>
    )
  }

  return (
    <>
      <ConfirmationModal
        title='Delete Task?'
        description='Are you sure you want to delete this task?'
        isModalOpen={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onYesClick={deleteHandler}
      />

      {!isModalView && (
        <Resizable
          className={props.customClass}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          ref={resizableRef}
          defaultSize={{
            width: props?.drawerWidth,
            height: '100%',
          }}
          maxWidth='750px'
          minWidth='510px'
          onResizeStart={(e, direction, ref, delta) => {
            ref.classList.add('border-left-blue');
          }}
          onResizeStop={(e, direction, ref, delta) => {
            ref.classList.remove('border-left-blue');
            dispatch(setDrawerWidth(ref.offsetWidth));
          }}
        >
          <div className='open-ticket' ref={ticketDrawerRef}>
            {(Object.keys(task).length !== 0 && !isLoading) ? taskComponent() : <TaskPanelSkeleton />}
          </div>
        </Resizable>
      )}

      {Object.keys(task).length !== 0 && isModalView && (
        <Modal
          centered
          open={isModalView}
          footer={null}
          width={1340}
          zIndex={998}
          bodyStyle={{ height: '100%', margin: 0, padding: 0}}
          onCancel={async () => {
            const params = new URLSearchParams(location.search);
            params.delete('selectedTask');
            const newUrl = `${location.pathname}?${params.toString()}`;
            navigate(newUrl);
      
            await dispatch(isTicketOpen(false));
          }}
          className='taskDetailsModal'
          closeIcon={<></>}
        >
          {taskComponent()}
        </Modal>
      )}

    </>
  );
};
function mapStateToProps(state) {
  return {
    singleTask: state.taskReducer.singleTask,
    loading: state.taskReducer.loading,
    tasks: state.taskReducer.tasks,
    drawerWidth: state.toggleReducer.drawerWidth,
    projects: state.projectReducer.getProjects,
    taskProperties: state.taskReducer.taskProperties,
    myProjects: state.projectReducer.myProjects,
  };
}

export default connect(mapStateToProps)(TaskSidePanel);
