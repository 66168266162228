import moment from 'moment';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAllUsersForAdminView } from '../../actions/userAction';

const AllUsers = ({ allUsers, dispatch }) => {
  const forbidden = useSelector((state) => state.userReducer.forbidden);

  useEffect(() => {
    dispatch(getAllUsersForAdminView());
  }, [dispatch]);

  useEffect(() => {
    if (forbidden) {
      window.location.href = '/';
    }
  }, [forbidden])

  return (
    <div style={{ textAlign: 'center' }}>
      <span className='users-table-heading'>Found {allUsers?.length} users!</span>

      {allUsers?.length > 0 && <table className="users-table">
        <thead>
          <tr>
            <th>UserId</th>
            <th>Name</th>
            <th>Email</th>
            <th>Verified?</th>
            <th>Created At</th>
            <th>Timezone</th>
            <th>Onboarding</th>
            <th>Workspaces</th>
            <th>Projects</th>
            <th>Tasks</th>
            <th>WS Invites</th>
            <th>Did user sign up?</th>
            {/* <th>Profile Picture</th> */}
          </tr>
        </thead>
        <tbody>
          {allUsers.map(user => (
            <tr key={user._id}>
              <td>{user._id}</td>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.isVerified ? 'Yes' : 'No'}</td>
              <td>{moment(user.createdAt).tz('America/New_York').format('MMM D, YYYY [at] h:mma z')}</td>
              <td>{user.timezones?.map(tz => tz?.name + '\n')}</td>
              <td>{user.onboarding}</td>
              <td>{user.workspaceCount}</td>
              <td>{user.projectCount}</td>
              <td>{user.taskCount}</td>
              <td>{user.workspaceInvitesCount}</td>
              <td>{user.isRegisteredUser}</td>
              {/* <td><a href={user.avi}>Link</a></td> */}
            </tr>
          ))}
        </tbody>
      </table>}</div>
  );
}

function mapStateToProps(state) {
  return {
    allUsers: state.userReducer.allUsers,
  };
}

export default connect(mapStateToProps)(AllUsers);
