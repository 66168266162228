import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dropdown
} from 'antd';

import { PRIORITY_COLORS, PRIORITY_LABEL, taskPriority, PRIORITY_ICON } from '../../../../js/constants';
import { editTask } from '../../../../actions';
import { FormattedMessage } from 'react-intl';

const PriorityDropdown = (props) => {
  const { task, onClick, small, isTask } = props;
  const dispatch = useDispatch();

  const onChange = (priority) => {
    if (task?.priority !== priority.rank) {
      task.priority = priority.rank;
      dispatch(editTask({
        _id: task?._id,
        taskTitle: task?.taskTitle,
        priority: priority.rank,
      }));
    }
  }

  return (
    <Dropdown
      overlayStyle={{
        padding: 10
      }}
      trigger={['click']}
      menu={{
        items:
          taskPriority.map((priority, i) => {
            const html =
              <div
                className={`priority-pill flex justify-around px-3 text-xs ${small ? 'w-full' : 'w-24'}  ${i == 0 && 'text-slate-700'}`}
                style={{ background: PRIORITY_COLORS[priority?.rank] }}>
                {PRIORITY_ICON[priority?.rank] && !small && (
                  <img
                    src={`/images/icons/${PRIORITY_ICON[priority?.rank]}.svg`}
                    alt={priority?.name}
                    className="size-3 mr-1"
                  />
                )}
                <span> <FormattedMessage id={priority?.name?.toUpperCase()} defaultMessage={priority?.name} /> </span>
              </div>
            return ({
              key: priority?.rank,
              label: html,
              onClick: () => onClick ? onClick(priority) : onChange(priority)
            })
          })
      }}
      placement='bottom'
    >
      {isTask ?
        <div className='flex align-center justify-between text-center py-2 px-5'>
          <div className='font-14 truncate text-center capitalize rounded-xl'
            style={{ background: PRIORITY_COLORS[task?.priority] }}>
            {task?.priority != 'nill' ?
              <div className='flex align-center justify-center w-[100px] px-2'>
                {PRIORITY_ICON[task?.priority] && (
                  <img
                    src={`/images/icons/${PRIORITY_ICON[task?.priority]}.svg`}
                    alt={task?.priority}
                    className="size-3 mr-1"
                  />
                )}
                <div className={`${small ? 'font-12 text-white' : 'font-14'} `}>
                  <FormattedMessage id={PRIORITY_LABEL[task?.priority]?.toUpperCase()} defaultMessage={PRIORITY_LABEL[task?.priority]} />
                </div>
              </div> :
              <div className='font-14 cursor-pointer text-center'>
                Select
              </div>}
          </div>
          <img
            src={'/images/icons/black-arrow-down.svg'}
            style={{
              cursor: 'pointer',
              height: '10px',
              width: '10px',
            }}
            alt='icon'
          />
        </div>
        : <div
          className={`hover:opacity-90 cursor-pointer flex align-center justify-center gap-1 ${small ? 'h-5 border-radius10 py-1 px-4 flex align-center justify-center' : 'priority-pill w-24'}`}
          style={{ background: PRIORITY_COLORS[task?.priority] }}>
          {task?.priority != 'nill' ?
            <>
              {PRIORITY_ICON[task?.priority] && (
                <img
                  src={`/images/icons/${PRIORITY_ICON[task?.priority]}.svg`}
                  alt={task?.priority}
                  className="size-3 mr-1"
                />
              )}
              <div className={`${small ? 'font-12 text-white' : 'font-14'} `}>
                <FormattedMessage id={PRIORITY_LABEL[task?.priority]?.toUpperCase()} defaultMessage={PRIORITY_LABEL[task?.priority]} />
              </div>
            </> :
            <div className='font-16 bg-slate-100 border-radius10 text-slate-500 w-10 cursor-pointer text-center'>
              --
            </div>}
        </div>}
    </Dropdown>
  );
};

export default PriorityDropdown;
