import React from 'react';
import { useIntl } from 'react-intl';

const SearchInput = (props) => {
  const intl = useIntl()
  return (
    <div className={`w-[30vw] flex items-center justify-center relative`}>
      <img
        className={`absolute left-4 size-4`}
        src="/images/icons/search.svg"
        alt="Search Icon"
        style={{ objectFit: 'contain' }}
      />
      <input
        className={`py-2 rounded-[10px] border text-sm hover:border-[rgba(4,115,204,0.5)] focus:border-2 focus:border-[rgba(4,115,204,0.5)] focus:outline-none w-full flex px-12 justify-start bg-[#fefefe] cursor-pointer text-[#aaa] focus:bg-white`}
        onClick={props.onClick}
        placeholder={intl.formatMessage({ id: 'DASHBOARD_SEARCH_PLACEHOLDER', defaultMessage: 'Search   Tasks  |  Projects  |  Members' })}
        value={props?.value}
        onChange={props?.onChange}
      />
    </div>
  );
};

export default SearchInput;
