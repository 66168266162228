import React, { useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import MyAccount from '../../components/UI/molecules/admin-console/MyAccount';

const UserDrawer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState(0);

  const {
    user,
    currentWorkspace = {},
    pendingInvites
  } = props;

  return (
    <div className='h-[83vh]'>
      <MyAccount user={user} currentWorkspace={currentWorkspace} pendingInvites={[]} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  currentWorkspace: state.workspaceReducer.currentWorkspace,
  pendingInvites: state.workspaceReducer.pendingWorkspaceInvites,
});

export default connect(mapStateToProps)(UserDrawer);
