import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import TimeAgo from 'javascript-time-ago';
import DOMPurify from 'dompurify';
import FileHolder from '../../organisms/FileHolder';
import { useIntl } from 'react-intl';

const HtmlRenderer = ({ html, searchQuery }) => {
  const intl = useIntl();
  const escapeRegex = (string) => string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const highlightQueryInText = (text, query) => {
    if (!query) return text;

    const escapedQuery = escapeRegex(query);
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
  };

  const truncateAndHighlightHtml = (html, query, contextLength = 100) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const extractAndTruncate = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.nodeValue || "";

        // Find query position
        const queryIndex = query
          ? textContent.toLowerCase().indexOf(query.toLowerCase())
          : -1;

        // Truncate logic
        if (queryIndex === -1 || !query) {
          const truncatedText = textContent.length > contextLength
            ? `${textContent.slice(0, contextLength)} ${intl.formatMessage({ id: 'SEE_MORE', defaultMessage: '...see more' })}`
            : textContent;
          return highlightQueryInText(truncatedText, query);
        }

        const start = Math.max(0, queryIndex - contextLength / 2);
        const end = Math.min(textContent.length, queryIndex + contextLength / 2);
        const truncatedText = `${textContent.slice(
          start,
          end
        )}${end < textContent.length ? ' ...' : ''}`;

        return highlightQueryInText(truncatedText, query);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (
          node.tagName.toLowerCase() === 'span' &&
          node.classList.contains('mention')
        ) {
          const mentionContent = Array.from(node.childNodes)
            .map(extractAndTruncate)
            .join('');
          return `<span class="mention">${mentionContent}</span>`;
        }

        return Array.from(node.childNodes)
          .map(extractAndTruncate)
          .join('');
      }

      return '';
    };

    const truncatedHtml = extractAndTruncate(doc.body);
    return truncatedHtml;
  };

  const sanitizedHtml = DOMPurify.sanitize(html);
  const processedHtml = truncateAndHighlightHtml(sanitizedHtml, searchQuery);

  return (
    <div
      className="chat-search-message"
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    ></div>
  );
};


const SearchMessages = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    replies,
    comment,
    msgId,
    date,
    chatId,
    senderId,
    files,
    replySenderAvis,
    name,
    roomTitle,
    src,
    chats,
    searchQuery
  } = props;

  const loggedUser = useSelector((state) => state.userReducer.user);

  const [open, setOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();


  const commentSectionRef = useRef(null);

  // Message Edit handler

  const timeAgo = new TimeAgo(intl.locale);
  const isMyMessage = senderId === localStorage.getItem('Id');


  // const openChat = () => {
  //   dispatch(messageId(msgId));
  //   dispatch(putMessage(paramsId, msgId));
  //   dispatch(isChatOpen(true, msgId));
  //   const params = new URLSearchParams(location.search);
  //   params.delete('selectedTask'); 
  //   params.set('msgId', msgId)
  //   const newUrl = `${location.pathname}?${params.toString()}`;
  //   navigate(newUrl);
  // };

  return (
    <>
      <div
        ref={commentSectionRef}
        className={` relative hover:bg-gray-100 bg-white rounded-xl shadow-sm pt-4 pb-4 px-8'}`}
        onMouseOver={() => {
          setIsHovering(true);
        }}
        onMouseOut={() => {
          setIsHovering(false);
        }}
      >
        <header className="flex items-start  px-3">
          <img className="rounded-full shrink-0 mr-2  size-7 object-cover font-bold" src={src} alt={name} />
          <div className="grow">
            <div className="flex items-start  justify-between mb-0.5">
              <div className="xl:flex items-center mb-2 sm:mb-0">
                <div
                  className={`${!isMyMessage && ' cursor-pointer'} text-lg flex justify-center items-center text-slate-800 font-semibold text-left truncate`}><span>{name}</span> <span className='ml-5 text-[10px]'>•</span> <span className='text-xs ml-1 font-medium text-slate-500' >
                    {date.toLocaleTimeString(intl.locale, { hour: 'numeric', minute: 'numeric' })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          style={{
            textAlign: 'inherit',
            cursor: 'pointer',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
          }}

          className='font-16 mx-12 mb-2'
        >
          <HtmlRenderer html={comment} searchQuery={searchQuery} truncate />
        </div>
        {/* {isHovering && !editMessage && (
            <div className='commentBox' style={{ cursor: 'pointer' }}>
              <Tooltip title='Forward'>
                <>
                  <Icon
                    name='reply.svg'
                    height='20px'
                    className='hover:opacity-60'
                    pl='0px'
                    onClick={() => { 
                      //tooltips on hover like slack              
                    }}
                  />
                </>
              </Tooltip>        
            </div>
          )} */}
        <div className='d-flex ml-12'>
          {files &&
            files.map((file) => {
              return <FileHolder file={{ name: file?.name, url: file?.signedUrl }} />
            })}
        </div>
      </div>
    </>
  );
};

export default SearchMessages;
