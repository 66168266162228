import React, { useCallback, useEffect, useState } from "react";
import { IntlProvider } from 'react-intl';
import { useSelector } from "react-redux";
import { ConfigProvider } from 'antd';
import dayjs from "dayjs";
import TimeAgo from 'javascript-time-ago';

import enTimeAgo from 'javascript-time-ago/locale/en';
import ruTimeAgo from 'javascript-time-ago/locale/ru';

import enUSDateLocale from 'antd/locale/en_US';
import enMessages from '../../_locale/en-US';

import { localesLookup } from '../../_locale/localesLookupTable';

const defaultErrorHandlerForIntl = () => {};

const Internationalization = ({
    children
}) => {
    const [messages, setMessages] = useState();
    const [locale, setLocale] = useState('en-US');
    const [dateLocale, setDateLocale] = useState(enUSDateLocale);
    const [timeAgoLocale, setTimeAgoLocale] = useState(enTimeAgo);

    const userLocale = useSelector((state) => state.userReducer?.preferences?.locale);

    const handleLocaleChange = useCallback((userLocale) => {
        // const browserLanguage = navigator.language || 'en';
        // const selectedLanguage = localStorage.getItem('LOCALE') || 'en';
        const selectedLanguage = userLocale || navigator.language || 'en';
        const locale = localesLookup[selectedLanguage] || {
            messages: 'en-US',
            dates: 'en_US'
        };

        import(`../../_locale/${locale.messages}.js`).then(res => {
            setLocale(locale.messages);
            setMessages(res.default);
        }).catch((e) => {
            setLocale('en-US');
            setMessages(enMessages);
        });
        import(`antd/locale/${locale.dates}.js`).then(res => {
            setDateLocale(res.default);
            dayjs.locale(selectedLanguage);
        }).catch((e) => {
            setDateLocale(enUSDateLocale);
            dayjs.locale('en');
        });

        switch (selectedLanguage) {
            case 'ru':
                setTimeAgoLocale(ruTimeAgo);
                break;
            default:
                setTimeAgoLocale(enTimeAgo);
        }
    }, []);
    
    const handleLocaleEvent = useCallback((event) => {
        if (event?.detail) {
            handleLocaleChange(event?.detail)
        }
    }, [handleLocaleChange]);

    useEffect(() => {
        TimeAgo.addLocale(timeAgoLocale);
    }, [timeAgoLocale]);

    useEffect(() => {
        window.addEventListener('locale.change', handleLocaleEvent);
        return () => {
            window.removeEventListener('locale.change', handleLocaleEvent);
        }
    }, [handleLocaleEvent])

    useEffect(() => {
        handleLocaleChange();
    }, [handleLocaleChange]);

    useEffect(() => {
        handleLocaleChange(userLocale);
    }, [handleLocaleChange, userLocale])

    return (
        <IntlProvider locale={locale} messages={messages} onError={defaultErrorHandlerForIntl}>
            <ConfigProvider locale={dateLocale}>
                {messages && children}
            </ConfigProvider>
        </IntlProvider>
    );
}

export default Internationalization;
