import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { FileDrop } from 'react-file-drop';
import _ from 'lodash';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

import {
  Tooltip,
  Dropdown,
  Tabs,
  Skeleton,
  notification
} from 'antd';

import {
  editTask,
  getSingleTask,
  setTaskProperties,
  uploadTaskUrl,
  signedUploadUrl,
  deleteTaskFile,
  reorderTasks,
  addTaskToProject,
  removeTaskFromProject,
  toggleWorkspaceFieldsModal,
  getRecurringTasks
} from '../../../../actions';

import {
  isTicketOpen,
} from '../../../../actions';

import socket from '../../../../js/socket';
import { getDateTextLongFormat } from '../../../../js/functions';

import Loader from '../../atoms/global/Loader';
import TaskCommentSection from './TaskCommentSection';
import AssigneeSelect from '../assignee/AssigneeSelect';
import AddSubTask from '../ticket-drawer/AddSubTask.jsx';
import SubTask from '../../molecules/ticket-drawer/SubTask';
import useComponentVisible from '../../../../js/useComponentVisible';
import TaskFileHolder from '../../organisms/TaskFileHolder';
import DrawerCommentSection from '../global/DrawerCommentSection';
import { getTaskByIdentifier, updateSingleTask, updateTaskInState } from '../../../../actions/taskAction';
import ActivityViewer from '../../atoms/ticket-drawer/ActivityViewer';
import TextEditor from '../editor/TextEditor';
import PriorityDropdown from '../task-components/PriorityDropdown';
import CalendarDropdown from '../task-components/CalendarDropdown';

import HtmlRenderer from '../../organisms/HtmlRenderer';
import TaskCustomFields from './TaskCustomFields';
import { TaskButtons } from '../../atoms/buttons/TaskButtons';
import { TaskCardProjects } from '../task-components/TaskCardProjects.jsx';
import { FormattedMessage, useIntl } from 'react-intl';

const TaskDetailSection = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    task,
    isModal = false,
    selectedTab,
    workspaceProjects
  } = props;

  const [editPhase, setEditPhase] = useState('');
  const [taskTitle, setTaskTitle] = useState(task?.taskTitle);
  const [recurringTasks, setRecurringTasks] = useState([]);
  const [isEditDesc, setIsEditDesc] = useState(false);

  const [activityView, setActivityView] = useState('comments');
  const [taskComments, setTaskComments] = useState(props?.task?.comments?.filter(c => c?.type !== 'activity') || []);

  const [isLoading, setIsLoading] = useState(false);
  const [localPriority, setLocalPriority] = useState('--');
  const [description, setDescription] = useState(task?.taskDescription);

  const [isDragNdropVisible, setIsDragNdropVisible] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState('1');

  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [projects, setProjects] = useState(task?.projects || []);
  const [customFields, setCustomFields] = useState(task?.customFields || []);

  // TODO: Osama please uncomment the line below this when you start working on workpace tags integration
  // const [workspaceTags, setWorkspaceTags] = useState([]);

  const taskScrollRef = useRef();
  const params = useParams();
  const fileInputRef = useRef(null);
  const titleRef = useRef();
  const projectRef = useRef(null);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { filters } = useSelector((state) => state.taskReducer);
  const location = useLocation();

  const loggedUser = useSelector((state) => state.userReducer.user);
  const isListView = useSelector((state) => state.toggleReducer.isListView);
  const isRecurring = task?.recurringConfig?.recurring;

  useEffect(() => {
    if (!_.isEqual(_.sortBy(customFields), _.sortBy(task?.customFields))) {
      setCustomFields(task?.customFields);
    }

    const updatedTaskComments = activityView == 'all' ? props?.task?.comments : activityView == 'comments'
      ? props?.task?.comments?.filter(c => c?.type !== 'activity') : activityView == 'activity'
        ? props?.task?.comments?.filter(c => c?.type === 'activity') : [];
    setTaskComments(updatedTaskComments || []);

    task?.projects?.length > 0 && setProjects(task?.projects);
  }, [task])


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setShowProjectDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    socket.on('UpdateTaskEvent', function (task) {
      const selectedTaskParam = new URLSearchParams(location.search).get('selectedTask');
      const taskIdentifier = task?.titleIdentifier;
      const projectIdInUrl = window.location.pathname.split('/')[1];
      const taskId = task?._id;
      if (taskIdentifier?.toString() === selectedTaskParam?.toString()) {
        if (taskId !== 'board' || taskId !== 'chat') {
          dispatch(updateSingleTask(task))
        }
      }
      const isSelectedProjectOpen = projects?.find(p => p?.projectId?.toString() === projectIdInUrl?.toString());
      if (isSelectedProjectOpen) {
        // dispatch(getTasks(projectIdInUrl));
        dispatch(updateTaskInState(task));
      }
    });
  }, []);

  useEffect(() => {
    // if (editPhase != 'description') {
    setTaskTitle(task?.taskTitle);
    setDescription(task?.taskDescription);
    setEditPhase('');
    // }
    setLocalPriority(task?.priority === 'nill' ? '--' : task?.priority)
    setProjects(task?.projects);

    if (isRecurring) {
      dispatch(getRecurringTasks(task?._id, (res) => {
        setRecurringTasks(res);
      }));
    }
  }, [task]);

  // TO DO
  useEffect(() => {
    socket.on('SubtaskAdded', (payload) => {
      if (task?._id?.toString() === payload?.parentTaskId?.toString()) {
        dispatch(getTaskByIdentifier(task?.titleIdentifier));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    socket.on('message-received', (msg) => {
      if (msg?.taskId?.toString() === task?._id?.toString()) {
        const scrollHeight = taskScrollRef?.current?.scrollHeight;
        const height = taskScrollRef?.current?.clientHeight;
        const maxScrollTop = scrollHeight - height;
        setTimeout(() => {
          taskScrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop + 100 : 0;
        }, 100);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedTabIndex(selectedTab);
  }, [selectedTab]);

  const updateTaskTitle = () => {
    if (taskTitle.trim().length <= 0) {
      setTaskTitle(task.taskTitle);
      setEditPhase('');
      return;
    }

    if (taskTitle === task.taskTitle) {
      setEditPhase('');
      return;
    }

    editHandler(
      {
        _id: task._id,
        taskTitle: taskTitle,
        taskDescription: description
      },
      () => {
        setEditPhase('');
        setIsEditDesc(false);
        // setTaskTitle('');
      }
    );
  };

  const editHandler = async (data, callback) => {
    dispatch(
      editTask(data, null, () => {
      })
    );

    if (typeof callback === 'function') callback();
  };

  const onFileInputChange = async (e) => {
    try {
      const files = fileInputRef.current.files;
      uploadFiles(files);
    } catch (error) {
      console.error(error);
    }
  };

  const onFileDrop = async (files, e) => {
    setIsLoading(true);
    setIsDragNdropVisible(false);
    document
      .querySelector('.onResize-ticket-drawer')
      ?.classList?.remove('disable-pointer-events-div');
    try {
      uploadFiles(files);
    } catch (error) {
      console.error(error);
    }
  };

  const openTicket = (newId) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', newId)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
  };

  const uploadFiles = async (files) => {
    const fileList = [];
    for (const file of files) {
      setIsLoading(true);
      const uploadTaskResponse = await dispatch(uploadTaskUrl({
        fileName: file.name,
        taskId: task?._id,
        type: file?.type,
        fileSize: file?.size
      }));
      const fileId = uploadTaskResponse?.fileId;
      const signedUrl = uploadTaskResponse?.signedUrl;
      if (signedUrl) {
        fileList.push(fileId);
        await dispatch(signedUploadUrl(signedUrl, file));
      }
    }

    let allFiles = fileList;

    if (task?.files) {
      allFiles = fileList.concat(task?.files || []);
    }

    editHandler(
      {
        _id: task._id,
        taskTitle: task.taskTitle,
        files: allFiles,
        attachments: []
      }, () => {
        setIsLoading(false);
      }
    );
  }

  const handleChange = (evt) => {
    setDescription(evt);
    setEditPhase('description')
  };

  const delAttachment = (fileId) => {
    dispatch(deleteTaskFile(task._id || params.taskid, fileId, () => dispatch(getSingleTask(task._id || params.taskid))));
  };

  const handleChangePriority = (priority) => {
    if (localPriority !== priority.rank) {
      setLocalPriority(priority.rank);
      editHandler(
        {
          _id: task._id,
          taskTitle: taskTitle,
          priority: priority.rank
        },
        () => {
          setEditPhase('');
        }
      );
    }
  };

  // TODO: Osama please uncomment the line(s) below this when you start working on workpace tags integration
  // const handleChangeTags = (tags, e) => {
  //   if (e) {
  //     e.preventDefault()
  //   }

  //   setEditPhase('');

  //   dispatch(
  //     editTask({
  //       _id: task._id,
  //       tags
  //     },
  //       () => {
  //         if (task?.titleIdentifier) {
  //           dispatch(getTaskByIdentifier(task?.titleIdentifier));
  //         } else {
  //           dispatch(getSingleTask(task?._id, 'TD3'));
  //         }
  //       })
  //   );
  // }

  const descriptionHandler = (e) => {
    setTimeout(() => {
      if (task.taskDescription !== description) {
        editHandler(
          {
            _id: task._id,
            taskDescription: description,
          },
          () => {
            notification.success({
              placement: 'bottomRight',
              message: intl.formatMessage({ id: 'TASK_DESCRIPTION_UPDATED', defaultMessage: 'Task Description Updated!' })
            })
          }
        );
      }
      setEditPhase('');
      setIsEditDesc(false);
    }, 100);
  };

  const createSectionDropdown = (sectionsData, proj) => {
    const items = [];
    sectionsData && sectionsData.map((section, i) => {
      items.push({
        key: i,
        label:
          <div
            className='d-flex p-1 w-full'
            onClick={async () => {
              const projectId = proj?.project?._id;

              const data = {
                _id: task?._id,
                taskTitle: task?.taskTitle,
                preSection: currentSection?._id,
                section: section?._id,
                projectId: projectId,
                lastIndex: 0,
                newIndex: 0,
                returnTask: true,
                filters: filters,
                userId: loggedUser?._id
              };

              dispatch(reorderTasks(currentSection?._id, section?._id, task?._id, 0, 0, projectId));
              socket.emit("reorder-section", data);
            }}>
            {/* <div style={{ width: 3, marginRight: 10, background: section?.color || '#fff' }} /> */}
            <div className='truncate max-w-60'>
              <FormattedMessage id={`${section.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={section.title} />
            </div>
          </div>,
      })
    });
    return items;
  }

  const date = new Date(task.createdAt);
  const taskCreationTime = intl.formatDate(date, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  const currentProject = props?.task?.projects?.find(prj => prj?.project?._id?.toString() === props?.projectId?.toString()) || props?.task?.projects?.length > 0 ? props?.task?.projects[0] : null;
  const currentSection = currentProject?.section || props?.task?.projects?.sections?.find(section => section._id?.toString() === currentProject?.section?._id?.toString());

  let messageDate = '';
  const commentCount = props?.task?.comments?.filter(c => c?.type !== 'activity')?.length;
  const tabsContent = [
    {
      label: `${intl.formatMessage({ id: 'COMMENTS', defaultMessage: 'Comments' })} ${commentCount > 0 ? `(${commentCount})` : ''}`,
      key: '1',
      children: (
        <>
          <DrawerCommentSection
            height='30px'
            width='30px'
            containerWidth='auto'
            name={intl.formatMessage({ id: 'CREATED_THIS_TASK', defaultMessage: `{name} created this task.` }, { name: task.createdBy.fullName })}
            nameClass='font-16 text-gray-600 flex align-center'
            commentClass='font-12'
            src={task.createdBy.avi}
            comment=''
            showName={true}
            hours={taskCreationTime}
            context='task'
            roomId={task?.room}
            taskId={task?._id}
            senderId={task?.createdBy?._id}
            senderName={task.createdBy.fullName}
          />
          {taskComments?.map((chat, key) => {
            const date = new Date(chat?.createdAt);
            const timeAgo = new TimeAgo(intl.locale);
            const time = timeAgo.format(date);

            const sender = chat?.sender;

            let showDateRow = false;
            const currentMessageDate = new Date(chat?.createdAt).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });

            if (currentMessageDate !== messageDate) {
              showDateRow = true;
              messageDate = currentMessageDate;
            }


            return (
              <div key={key}>
                {showDateRow && (
                  <div className='date-row mx-10 mt-2'>
                    <div className='date-row-pill px-4'>
                      {getDateTextLongFormat(new Date(chat?.createdAt), intl)}
                    </div>
                    <div className='date-row-line border-slate-200' />
                  </div>)}
                <DrawerCommentSection
                  height='26px'
                  width='26px'
                  containerWidth='auto'
                  name={sender?.fullName}
                  nameClass='font-18 font-bold hover:underline cursor-pointer'
                  commentClass='font-16'
                  src={sender?.avi}
                  hours={time}
                  comment={chat?.messageDescription}
                  attachments={chat?.attachments}
                  showName={true}
                  msgId={chat?._id}
                  senderId={chat?.sender?._id}
                  files={chat?.files}
                  context='task'
                  roomId={task?.room}
                  taskId={task?._id}
                  senderName={sender?.fullName}
                />
              </div>
            );
          })}
        </>
      ),
    },
    {
      label: <FormattedMessage id="ACTIVITY" defaultMessage="Activity" />,
      key: '2',
      children: (
        <div className="space-y-4 w-full">
          {/* Block 1 */}
          <div className="p-4">
            <ul>
              {
                [{
                  type: 'activity',
                  action: ' created this task',
                  createdAt: task?.createdAt,
                  actor: task?.createdBy,
                  field: 'createdBy'
                }].map((chat, key) => {
                  var date = new Date(chat?.createdAt);
                  TimeAgo.addLocale(en);
                  const timeAgo = new TimeAgo('en-US');
                  const time = timeAgo.format(date);
                  return (
                    <ActivityViewer chat={chat} time={time} />
                  )
                })
              }
              {taskComments?.map((chat, key) => {
                var date = new Date(chat?.createdAt);
                TimeAgo.addLocale(en);
                const timeAgo = new TimeAgo('en-US');
                const time = timeAgo.format(date);
                return (
                  <ActivityViewer chat={chat} time={time} />
                )
              })}
            </ul>
          </div>
        </div>
      ),
    },
    {
      label: `${intl.formatMessage({ id: 'SUBTASKS', defaultMessage: 'Subtasks' })} ${task?.subtasks?.length > 0 ? `(${task?.subtasks?.length})` : ''}`,
      key: '3',
      children: (
        <div id='addSubtaskBtn' className='flex flex-col p-2 mb-10'>
          <div className='font-14 font-bold mb-2'>{task.subtasks?.length === 0 && !isComponentVisible && <FormattedMessage id="NO_SUBTASKS" defaultMessage="No Subtasks" />}</div>
          {task?.subtasks?.map((subTask, key) => {
            return (
              <div key={key}>
                <SubTask subTask={subTask} />
              </div>
            );
          })}
          <div ref={ref}>
            {isComponentVisible && (
              <AddSubTask
                id={task}
                setIsComponentVisible={setIsComponentVisible}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      label: `${intl.formatMessage({ id: 'FILES', defaultMessage: 'Files' })} ${task?.files?.length > 0 ? `(${task?.files?.length})` : ''}`,
      key: '4',
      children: (
        <div className='flex flex-wrap'>
          {task?.files?.length > 0 ?
            <div className='flex flex-col'>
              <TaskFileHolder files={task?.files || []} taskId={task._id} delAttachment={delAttachment} />
            </div> : <div className='font-14 font-bold p-2'><FormattedMessage id="NO_FILES" defaultMessage="No Files" /></div>}
        </div>
      ),
    },
  ];



  const commentSection = () => {
    return (
      <div className={isModal ? 'task-modal-reply-section' : 'task-reply-section'}>
        <Tabs
          activeKey={selectedTabIndex}
          items={tabsContent}
          onChange={index => {
            if (index == 3) {
              setActivityView('subtasks');
            } else if (index == 2) {
              setActivityView('activity');
              setTaskComments(props?.task?.comments?.filter(c => c?.type === 'activity') || []);
            } else if (index == 1) {
              setActivityView('comments');
              setTaskComments(props?.task?.comments?.filter(c => c?.type !== 'activity') || []);
            } else if (index == 4) {
              setActivityView('files');
            }
            props?.setSelectedTab(index);
            setSelectedTabIndex(index);
          }}
          tabBarStyle={{ padding: '0 15px' }}
          className='m-0 h-full' />
      </div>
    )
  }

  const colm1 = isModal ? 'col-span-2 flex align-center' : 'col-span-3 flex align-center';
  const colm2 = isModal ? 'col-span-5' : 'col-span-4';


  const AddButtonLabel = ({ title, onClick }) => {
    return (
      <Tooltip title={title}>
        <div className='flex flex-row align-center cursor-pointer' onClick={onClick}>
          <img
            src={'/images/icons/blue-round-add.svg'}
            alt='add'
            className='size-4'
          />
          <p className='ml-2 text-sm cursor-pointer hover:text-blue-500'>{title}</p>
        </div>
      </Tooltip>
    )
  }
  return (
    <div className={isModal ? 'task-detail-modal' : 'task-detail-section'}>
      <div
        ref={taskScrollRef}
        style={{
          overflowY: 'scroll',
          height: '100%',
          background: isModal ? 'white' : '#f6f6f6',
          scrollBehavior: 'smooth',
          paddingBottom: 40,
          width: isModal && '50%',
          scrollBehavior: 'smooth',
          paddingRight: isModal && 20,
        }} >
        {/* ------------------Drag and drop file --------------------- */}
        <div
          style={{
            width: '100%',
            color: 'black',
            borderRadius: '5px',
            padding: '5px 20px',
            height: isModal ? '100%' : '45vh'
          }}
          className={`fileDropDiv ${isDragNdropVisible ? '' : 'd-none'}`}
        >
          <FileDrop
            onDragLeave={() => {
              setIsDragNdropVisible(false);
            }}
            frame={document}
            onDrop={onFileDrop}
            onFrameDragEnter={() => {
              setIsDragNdropVisible(true);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.add('disable-pointer-events-div');
            }}
            onFrameDragLeave={() => {
              setIsDragNdropVisible(false);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.remove('disable-pointer-events-div');
            }}
            onFrameDrop={() => {
              setIsDragNdropVisible(false);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.remove('disable-pointer-events-div');
            }}
          >
            <FormattedMessage id="ADD_TO_TASK" defaultMessage="Add to task" />
          </FileDrop>
        </div>
        <div className='p-4 bg-white'>
          {editPhase === 'title' ? (
            <textarea
              autoFocus
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              onBlur={() => updateTaskTitle()}
              className='font-16 task-detail-title h-8'
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  updateTaskTitle();
                }
              }}
              style={{
                border: 'none',
                outline: 'none',
                fontWeight: '500',
                width: '100%',
                resize: 'none',
                minHeight: titleRef.current?.clientHeight + 5
              }}
            />
          ) : (
            <div
              className='task-detail-title'
              onClick={() => {
                setTaskTitle(task.taskTitle);
                setEditPhase('title');
              }}
              ref={titleRef}
            >
              <Tooltip title={intl.formatMessage({ id: 'CLICK_TO_EDIT', defaultMessage: 'Click to edit' })}>
                {taskTitle}
              </Tooltip>
            </div>
          )}
          <div className='ml-3 font-14 mt-2 mb-8 flex text-gray-400 items-center'>
            <FormattedMessage
              id="CREATED_BY_WHOM_AND_WHEN"
              defaultMessage="Created by <div><span>{name}</span> on {date}</div>"
              values={{
                name: task.createdBy.fullName,
                date: taskCreationTime,
                span: (msg) => (<div className='text-[#002337] mr-2'>{msg}</div>),
                div: (msg) => (<div className='ml-2  flex items-center '>{msg}</div>)
              }}
            />
          </div>

          <div className="grid grid-cols-7 gap-4 items-start ml-4">
            <div className='col-span-full flex align-start justify-between'>
              <div className={'flex mt-1 w-[50%]'}>
                <img
                  src='/images/icons/members-gray.svg'
                  alt=''
                  style={{
                    height: '16px',
                    width: '16px',
                    marginRight: 10
                  }}
                />
                <div className='font-14 text-[rgba(0,35,55,0.3)] '><FormattedMessage id="ASSIGNED_TO" defaultMessage="Assigned to" /></div>
              </div>
              <div className={'w-[50%] ml-10'}>
                <AssigneeSelect
                  projectUsers={task?.projectUsers || []}
                  task={props.task}
                  iconSize={30}
                  showNames
                  count={task?.projectUsers?.length} />
              </div>
            </div>
            <div className='col-span-full flex align-start justify-between'>
              <div className={'w-[50%] flex align-center'}>
                <img
                  src='/images/icons/calendar-gray.svg'
                  alt=''
                  style={{
                    height: '16px',
                    width: '16px',
                    marginRight: 10
                  }}
                />
                <div className='font-14 text-[rgba(0,35,55,0.3)]'><FormattedMessage id="DUE_DATE" defaultMessage="Due Date" /></div>
              </div>
              <div className='w-[50%] ml-10'>
                <CalendarDropdown task={task} y={isModal ? -10 : -60} x={isModal && 800} isLarge showIcon={true} />
              </div>
            </div>
            {isRecurring && <div className={`${colm1} ml-3`}>
              <img
                src='/images/icons/down-right.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'><FormattedMessage id="RECURRING_TASKS" defaultMessage="Recurring Tasks" /></div>
            </div>}
            {isRecurring && <div className={isModal ? 'col-span-2' : 'col-span-3'}>
              <Dropdown
                menu={{
                  items: recurringTasks.map((val) => {
                    if (val?.titleIdentifier === task?.titleIdentifier) {
                      return;
                    }

                    return ({
                      value: val?._id,
                      label: (
                        <div className={`d-flex p-1`}>
                          <div className='font-14 truncate w-full'><span className='font-12'>{val?.titleIdentifier}</span> | {val?.taskTitle}</div>
                        </div>
                      ),
                      onClick: () => {
                        openTicket(val?.titleIdentifier);
                      }
                    })
                  }),
                }}
                trigger={['click']}
              >
                <div className='btn-2 flex align-center justify-between'>
                  <div className='font-14 truncate mr-3'><FormattedMessage id="SELECT_TASK" defaultMessage="Select Task" /></div>
                  <img
                    src={'/images/icons/black-arrow-down.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '10px',
                      width: '10px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>
            </div>}
            {isRecurring && isModal && <div className='col-span-2' />}

            <div className='col-span-full flex align-start justify-between'>
              <div className={'flex w-[50%]'}>
                <img
                  src='/images/icons/priority-gray.svg'
                  alt=''
                  style={{
                    height: '16px',
                    width: '16px',
                    marginRight: 10
                  }}
                />
                <div className='font-14 text-[rgba(0,35,55,0.3)]'><FormattedMessage id="PRIORITY" defaultMessage="Priority" /></div>
              </div>
              <div className='flex align-start task-priority-pill w-[50%] ml-20 mb-5'>
                <PriorityDropdown onClick={handleChangePriority} task={task} />
              </div>
            </div>

            {// TODO: Osama please uncomment the line(s) below this when you start working on workpace tags integration
            }
            {/* <div className={colm1}>
              <img
                src='/images/icons/Chart.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Tags</div>
            </div>
            <div className={colm2}>
              <div className='flex align-start task-priority-pill'>
              <Select
                mode="multiple"
                placeholder="Select options"
                onChange={(e) => handleChangeTags(e)}
                value={task?.tags?.length > 0 ? task?.tags?.map(t => t?._id) : []}
              >
                {workspaceTags.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              </div>
            </div> */}
            <div className='col-span-full'>
              <div className='flex flex-row justify-between'>
                <div className='text-lg font-semibold '><FormattedMessage id="PROJECTS" defaultMessage="Projects" /></div>
                <div className='mr-4'>
                  <AddButtonLabel onClick={() => setShowProjectDropdown(true)} title={intl.formatMessage({ id: 'ADD_TO_PROJECTS', defaultMessage: 'Add to Projects' })} />
                </div>
              </div>
            </div>
            <div className='col-span-full' >
              <TaskCardProjects projects={projects} task={task} createSectionDropdown={createSectionDropdown} setProjects={setProjects} />
              {showProjectDropdown &&
                <div ref={projectRef}>
                  <Dropdown
                    className={`flex items-center space-x-4 py-2.5 mt-1 bg-blue-500 rounded-bl-lg rounded-br-lg border h-8 px-4 shadow-sm`}
                    trigger={['click']}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    menu={{
                      items: workspaceProjects?.filter(
                        project =>
                          !task?.projects?.some(p => p?.project?._id === project?._id)
                      )?.map(project => ({
                        key: project?._id,
                        label: project?.title,
                        onClick: async () => {
                          const x = projects;
                          x.push({
                            project: project,
                            section: project?.sections?.[0] || {}
                          });
                          await dispatch(addTaskToProject(task?._id, project?._id))
                          setProjects(x);
                          setShowProjectDropdown(false)
                        }
                      })),

                      style: {
                        overflow: 'scroll',
                        maxHeight: '25vh'
                      }
                    }}
                    placement='bottom'
                    arrow={{ pointAtCenter: true }}
                  >
                    <div className="font-14 text-white cursor-pointer whitespace-nowrap"><FormattedMessage id="SELECT_PROJECT" defaultMessage="Select Project" /></div>
                  </Dropdown>
                </div>
              }
            </div>

            <div className='col-span-full mb-2'>
              <div className='flex justify-between'>
                <div className='text-lg font-semibold '><FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" /></div>
                <div className='mr-4'>
                  <AddButtonLabel title={intl.formatMessage({ id: 'ADD_FIELD', defaultMessage: 'Add Field' })}
                    onClick={() => {
                      dispatch(toggleWorkspaceFieldsModal(true, props?.projectId));
                    }} />
                </div>
              </div>
            </div>
            <div className={'col-span-full'}>
              <TaskCustomFields id={task?._id} customFields={customFields} />
            </div>

            <div className='col-span-2 flex align-center mt-3'>
              <div className='font-18 font-bold'><FormattedMessage id="DESCRIPTION" defaultMessage="Description" /></div>
            </div>
            <div className='col-span-7'>
              <div>
                {isEditDesc ? <div
                  className='font-12'
                  style={{
                    border: '1px solid #D6D6D6',
                    padding: '10px 10px',
                    textAlign: 'justify',
                    borderRadius: '10px',
                  }}
                >
                  <div>
                    <form
                      className='h-full flex flex-col'
                    >
                      <TextEditor
                        large
                        isTask
                        placeholder={intl.formatMessage({ id: 'ADD_DESCRIPTION', defaultMessage: 'Add a description…' })}
                        onChange={handleChange}
                        onKeyUp={(e) => { }}
                        comment={description}
                        mentionOptions={[]}
                      // handleBlur={() => descriptionHandler()}
                      />
                      <TaskButtons
                        onHandle={descriptionHandler}
                        onCancel={() => {
                          setDescription(task?.taskDescription)
                          setIsEditDesc(false);
                        }} />
                    </form>
                  </div>
                </div> :
                  <div
                    onClick={() => setIsEditDesc(true)}
                    className={`task-descp-html-override w-full font-16 p-3 px-4 bg-gray-50 border-radius10 text-gray-600 hover:border-gray-300 border border-white ${isModal && 'min-h-40'}`}>
                    {description?.length > 0
                      ? <HtmlRenderer html={description} />
                      : <FormattedMessage id="ADD_DESCRIPTION_NEW" defaultMessage="Add description" />
                    }
                  </div>}
              </div>
            </div>

            {isModal && task?.files?.length > 0 && (
              <div className='flex align-center col-span-7'>
                <TaskFileHolder files={task?.files || []} taskId={task._id} delAttachment={delAttachment} />
              </div>
            )}
          </div>


          <input
            style={{ display: 'none' }}
            id="file-input"
            ref={fileInputRef}
            type='file'
            multiple
            onChange={onFileInputChange}
          />
        </div>
        {!isModal && commentSection()}
      </div>
      <div className={isModal ? 'modal-comment-section w-[50%]' : 'p-3 sticky bottom-0'} style={{ background: '#F6F6F6', zIndex: 10 }}>
        {isModal && commentSection()}
        {selectedTabIndex === '1' && <TaskCommentSection task={props.task} />}
        {selectedTabIndex === '3' && <div
          className='d-flex align-center mx-2 w-full mt-1'
          style={{
            height: '50px',
            borderRadius: 10,
            border: '1px solid #D6D6D6',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            dispatch(
              setTaskProperties({
                ...props.taskProperties,
                section: task.section,
                workspace: task.workspace,
                parentTaskId: task._id,
                projectId: task.projectId,
              })
            );
            setIsComponentVisible(true);
          }}
        >
          <div className='flex align-center'>
            <img
              src='/images/icons/addblack.svg'
              alt=''
              style={{
                height: '15px',
                width: '15px',
                marginRight: 10
              }}
            />
            <div className='font-14'>
              <FormattedMessage id="ADD_SUBTASK" defaultMessage="Add Subtask" />
            </div>
          </div>
        </div>}
        {selectedTabIndex === '4' && <div
          className='d-flex align-center mx-2 w-full mt-1'
          style={{
            height: '50px',
            borderRadius: 10,
            border: '1px solid #D6D6D6',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <div className='d-flex'>
            {isLoading ?
              <Loader size={15} isLoading={isLoading} type='clip' /> :
              <>
                <img
                  src='/images/icons/Upload.svg'
                  alt=''
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: 10
                  }}
                />
                <div className='font-14'>
                  <FormattedMessage id="DROP_FILES_OR_BROWSE" defaultMessage="Drop files to attach, or browse" />
                </div>
              </>
            }
          </div>
          <input
            style={{ display: 'none' }}
            id="file-input"
            ref={fileInputRef}
            type='file'
            multiple
            onChange={onFileInputChange}
          />
        </div>}
      </div>
    </div>
  );
};

export default TaskDetailSection;
