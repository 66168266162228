import { notification } from 'antd';
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';

import { addTask, deleteTask, editTask, isTicketOpen, setTaskProperties } from '../../../../actions';

const TaskContextMenu = ({ task, setIsEdit, props = {}, dispatch, isWorkspaceTasksScreen = false }) => {
  const intl = useIntl();

  const handleDeleteTask = (taskId) => {
    dispatch(
      deleteTask(taskId, () => { })
    );
    dispatch(isTicketOpen(false));
  };
  return [
    {
      key: task?._id + '1',
      label: <FormattedMessage id="COPY_LINK" defaultMessage="Copy Link" />,
      onClick: () => {
        navigator.clipboard.writeText(window.location.href + '?selectedTask=' + task?.titleIdentifier);
        notification.success({
          message: intl.formatMessage({ id: 'TASK_LINK_COPIED', defaultMessage: 'Task link copied to clipboard' }),
          placement: 'bottomRight',
        });
      }
    },
    {
      key: task?._id + '2',
      label: <FormattedMessage id="EDIT" defaultMessage="Edit" />,
      onClick: () => {
        dispatch(
          setTaskProperties({
            ...task,
          })
        );
        setIsEdit(task._id);
      }
    },
    {
      key: task?._id + '3',
      label: task?.isCompleted
        ? intl.formatMessage({ id: 'MARK_INCOMPLETE', defaultMessage: 'Mark Incomplete' })
        : intl.formatMessage({ id: 'MARK_COMPLETE', defaultMessage: 'Mark Complete' }),
      onClick: async () => {
        // const collaboratorsIds = task.participants && task.participants.map((member) => member._id);
        await dispatch(
          editTask(
            {
              _id: task._id,
              isCompleted: !task?.isCompleted
            }, () => {
              notification.success({
                message: intl.formatMessage({
                  id: task?.isCompleted ? 'INCOMPLETE' : 'COMPLETED',
                  defaultMessage: 'Completed'
                }),
                placement: 'bottomRight',
              });
            })
        )
      }
    },
    // {
    //   key: task?._id + '4',
    //   label: <FormattedMessage id="DUPLICATE_TASK" defaultMessage="Duplicate Task" />,
    //   onClick: () => {
    //     dispatch(addTask({
    //       ...props?.taskProperties,
    //       taskTitle: task?.taskTitle,
    //       taskDescription: task?.taskDescription,
    //       cost: 0,
    //       dueDate: task?.dueDate,
    //       section: task?.sectionId,
    //       sectionCounts: {},
    //       isCompleted: false,
    //       documentPath: '',
    //       projects: [{
    //         section: task?.sectionId,
    //         projectId: task?.projectId,
    //         position: 'bottom'
    //       }],
    //       addedTo: 'bottom',
    //       parentTaskId: null
    //     }));
    //   }
    // },
    {
      key: task?._id + '5',
      label: <div className='text-red-600 font-14'><FormattedMessage id="DELETE" defaultMessage="Delete" /></div>,
      onClick: () => handleDeleteTask(task._id)
    }
  ]
}

export default TaskContextMenu
