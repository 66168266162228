import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { notification, Table, Tag, Checkbox, Tooltip, Dropdown } from 'antd';
import moment from 'moment';

import {
  getUserTasks,
  isTicketOpen,
  isChatOpen
} from '../../../../actions';
import TaskFilter from './TaskFilter/TaskFilter.jsx';
import AssigneeIcon from './TaskFilter/Icons/Assignee.jsx';

import { addToUserFilterResults, deleteUserFilterResults, editTask, saveUserFilter, setUserTaskFilters, updateTaskInState } from '../../../../actions/taskAction';
import { setTeamsState } from '../../../../actions/toggleAction';
import UserIcon from './TaskFilter/Icons/UserIcon';
import HighPriorityIcon from './TaskFilter/Icons/HighPriority';
import MediumPriorityIcon from './TaskFilter/Icons/MediumPriority';
import LowPriorityIcon from './TaskFilter/Icons/LowPriority';
import LowestPriorityIcon from './TaskFilter/Icons/LowestPriority';
import PriorityIcon from './TaskFilter/Icons/Priority';
import CraetorIcon from './TaskFilter/Icons/Creator';
import ProjectIcon from './TaskFilter/Icons/Project';
import ResolutionIcon from './TaskFilter/Icons/Resolution';
import LabelIcon from './TaskFilter/Icons/Label';
import socket from '../../../../js/socket';

import { useLocation, useNavigate } from 'react-router-dom';
import { getSectionColor } from '../../../../js/helper/getRandomColor.js';
import PriorityDropdown from '../task-components/PriorityDropdown.jsx';
import CalendarDropdown from '../task-components/CalendarDropdown.jsx';
import AssigneeSelect from '../assignee/AssigneeSelect.jsx';
import TaskContextMenu from '../../atoms/menu/TaskContextMenu.jsx';
import EditTask from '../../atoms/input/EditTask.jsx';
import { FormattedMessage, useIntl } from 'react-intl';

const MyTasks = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userTasks,
    workspaceFilter,
    userFilters,
    userQuery,
    currentWorkspace,
    workspaceCustomFields,
    isTaskOpen
  } = props;

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState(userTasks || []);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState()
  const filters = useSelector((state) => state.taskReducer.userFilters);

  useEffect(() => {
    socket.on("TaskAdded", function (task) {
      dispatch(addToUserFilterResults(task));
    });
    socket.on("TaskRemoved", function (taskId) {
      dispatch(deleteUserFilterResults(taskId));
    });

    socket.on('UpdateTaskEvent', function (task) {
      dispatch(addToUserFilterResults(task));
    })

  }, []);

  const handleLabelSelection = async (labels) => {
    let assignees = [];
    let priority = [];
    let projects = [];
    let customFields = {};
    let creator = [];
    let resolution = [];


    if (labels?.length === 0) {
      setFilteredTasks(userTasks || []);
      setSelectedLabels([]);
      dispatch(setUserTaskFilters({
        ...filters, assignedTo: assignees, priority, projects, customFields, creator
      }));
      return;
    }

    labels.forEach((label) => {
      if (label?.type == 'Priority') {
        priority.push({ _id: label?.id });
      } else if (label?.type == 'Assignee') {
        assignees.push({ _id: label?.id });
      } else if (label?.type == 'Creator') {
        creator.push({ _id: label?.id });
      } else if (label?.type == 'Project') {
        projects.push({ _id: label?.id });
      } else if (label?.type == 'Resolution') {
        resolution.push({ _id: label?.id });
      } else if (label?.type == 'customField') {
        const existing = customFields[label?.customFieldId];
        if (Array.isArray(existing)) {
          existing.push({ _id: label?.id });
          customFields[label.customFieldId] = existing;
        } else {
          customFields[label.customFieldId] = [{
            _id: label?.id
          }]
        }
      }

    });

    dispatch(setUserTaskFilters({
      ...filters, assignedTo: assignees, priority, projects, customFields, creator, resolution
    }));
    dispatch(getUserTasks({ ...filters, assignedTo: assignees, priority, projects, customFields, creator, resolution }))
    setSelectedLabels(labels);
  };

  const getPriorityData = (p) => {
    const priorityTextMap = {
      p1: { text: 'P1', icon: <HighPriorityIcon /> },
      p2: { text: 'P2', icon: <MediumPriorityIcon /> },
      p3: { text: 'P3', icon: <LowPriorityIcon /> },
      p4: { text: 'P4', icon: <LowestPriorityIcon /> }
    };

    return priorityTextMap[p];
  }

  useEffect(() => {
    if (workspaceFilter) {
      const selectedFilter = workspaceFilter;
      const labels = selectedFilter?.labels || [];
      const updatedLabels = labels?.map(label => {
        switch (label?.type) {
          case 'Priority':
            const pData = getPriorityData(label?.id);
            return {
              ...label,
              icon: pData?.icon,
              parentIcon: <PriorityIcon />,
            };

          case 'Creator':
          case 'Assignee':
            return {
              ...label,
              icon: <UserIcon avi={label?.avi} />,
              parentIcon: label?.type === 'Creator' ? <CraetorIcon /> : <AssigneeIcon />,
            };

          case 'Project':
            return {
              ...label,
              parentIcon: <ProjectIcon />,
            };

          case 'Resolution':
            return {
              ...label,
              parentIcon: <ResolutionIcon />,
            };

          case 'customField':
            return {
              ...label,
              parentIcon: <LabelIcon />,
            };

          default:
            return label;
        }
      });

      setSelectedLabels(updatedLabels);

      dispatch(setUserTaskFilters(workspaceFilter?.query));
      dispatch(getUserTasks(workspaceFilter?.query))
    }

  }, [workspaceFilter]);

  useEffect(() => {
    if (userTasks != filteredTasks) {
      setFilteredTasks(userTasks);
    }
  }, [userTasks])

  const handleScroll = (e) => {
    const scrollableElement = e.target;

    // Check if the scrollbar is at the bottom
    if (
      scrollableElement?.scrollTop + scrollableElement?.clientHeight >=
      (scrollableElement?.scrollHeight - 10)
    ) {
      filteredTasks?.length > 40 && dispatch(getUserTasks(userFilters, userQuery));
    }
  };

  const handleSaveUserFilter = (defaultFilter) => {
    dispatch(saveUserFilter(userFilters, defaultFilter))
  }

  const handleResetFilter = () => {
    setSelectedLabels([]);
    dispatch(setUserTaskFilters({}, true));
    dispatch(getUserTasks({}));
    notification.success({
      message: intl.formatMessage({ id:'FILTERS_WERE_RESET', defaultMessage: 'The filters were reset!' }),
      placement: 'bottomRight',
    });
    // dispatch(saveUserFilter(props?.userFilters, defaultFilter))
  }

  const handleTaskClick = async (task) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };

  const handleTaskComplete = (task) => {
    dispatch(editTask({
      _id: task._id,
      isCompleted: !task?.isCompleted,
    }))
  }

  const columns = [
    {
      title: <div className='text-center'><FormattedMessage id="ID" defaultMessage="ID" /></div>,
      width: 100,
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="TASK_NAME" defaultMessage="Task Name" />,
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      render: (text) => (
        <div style={{ maxWidth: 450 }}>
          {text}
        </div>
      ),
    },
    {
      title: <div className='text-center'><FormattedMessage id="ASSIGNEE" defaultMessage="Assignee" /></div>,
      dataIndex: 'assignee',
      key: '1',
      width: 100,
    },
    {
      title: <div className='text-center'><FormattedMessage id="DATE" defaultMessage="Date" /></div>,
      dataIndex: 'date',
      key: '2',
    },
    {
      title: <div className='text-center'><FormattedMessage id="PRIORITY" defaultMessage="Priority" /></div>,
      dataIndex: 'priority',
      key: '3',
      width: 120,
    },
    { title: <FormattedMessage id="PROJECTS" defaultMessage="Projects" />, dataIndex: 'project', key: '8' },
    { title: <FormattedMessage id="SECTION" defaultMessage="Section" />, dataIndex: 'section', key: '9' },
    // { title: 'Comments', dataIndex: 'comments', key: '10', width: 200, },
    // { title: 'Subtasks', dataIndex: 'subtasks', key: '11', width: 200 },
    { title: <div className='text-center'><FormattedMessage id="CREATED" defaultMessage="Created" /></div>, dataIndex: 'created', key: '12', width: 120 },
    { title: <div className='text-center'><FormattedMessage id="UPDATED_FILTER" defaultMessage="Updated" /></div>, dataIndex: 'updated', key: '13', width: 120 },
  ];

  const taskMap = new Map();
  filteredTasks?.forEach(task => {
    taskMap.set(task?.titleIdentifier, task);
  });

  const dataSource = filteredTasks?.map((task, i) => {
    const customKeys = {}
    task?.customFields?.map(x => {
      const field = workspaceCustomFields?.find(y => x?._id == y?._id);
      const value = field?.possibleValues?.find(v => v?._id == x?.value);
      customKeys[x?._id] = <div className='text-center text-sm' key={value?._id}>{value?.label}</div>
    })

    const time = intl.formatDate(task?.createdAt) ;// moment(new Date(task?.createdAt)).format('DD MMM, YYYY')
    const timeUpdated = intl.formatDate(task?.updatedAt); // moment(new Date(task?.updatedAt)).format('DD MMM, YYYY')

    return {
      key: task?.titleIdentifier,
      id: <div className='text-xs text-center'>{task?.titleIdentifier}</div>,
      title: isEdit && isEdit === task._id ? (
        <EditTask
          taskTitle={props?.taskProperties?.taskTitle}
          setEditTaskVisible={setIsEdit}
          task={task}
        />
      ) : (
        <div
          onClick={() => handleTaskClick(task)}
          className='text-sm cursor-pointer hover:underline px-1'
        >
          {task?.taskTitle}
        </div>
      ),
      priority: <PriorityDropdown task={task} />,
      action: (
        <div
          onClick={() => handleTaskComplete(task)}
          className='flex align-center justify-center cursor-pointer'>
          <Tooltip title={
            task?.isCompleted
              ? intl.formatMessage({ id: 'MARK_INCOMPLETE', defaultMessage: 'Mark Incomplete' })
              : intl.formatMessage({ id: 'MARK_COMPLETE', defaultMessage: 'Mark Complete' })
          }>
            <img
              src='/images/icons/completedSubtask.svg'
              style={{
                objectFit: 'contain',
                width: 22,
                height: 22,
                opacity: task?.isCompleted ? 1 : 0.2
              }} />
          </Tooltip>
        </div>
      ),
      project: <>{task?.projects?.map((tag, i) => {
        let color = getSectionColor(i)
        return (
          <Tag color={color?.border} key={tag?._id} style={{ color: 'black' }}>
            {tag?.projectTitle || tag?.title}
          </Tag>
        );
      })}
      </>,
      section: <>{task?.projects?.map((tag, i) => {
        let color = getSectionColor(i + 2)
        return (
          <Tag color={color?.border} key={tag?._id + 'section'} style={{ color: 'black' }}>
            {tag?.sectionTitle && <FormattedMessage id={`${tag?.sectionTitle}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={tag?.sectionTitle} />}
          </Tag>
        );
      })}
      </>,
      created: <div className='text-xs text-center'>{time}</div>,
      updated: <div className='text-xs text-center'>{timeUpdated}</div>,
      assignee: <div className='flex justify-center'><AssigneeSelect task={task} iconSize={26} count={1} /></div>,
      date: <div className='relative px-3 flex justify-center'><CalendarDropdown task={task} isLarge /></div>,
      ...customKeys
    }
  });

  workspaceCustomFields?.map(field => {
    columns.push({
      title: <div className='text-center'>{field?.name}</div>,
      width: 120,
      dataIndex: field?._id,
      key: field?._id,
    })
  })

  columns?.push({
    title: <div className='text-center'><FormattedMessage id="COMPLETED" defaultMessage="Completed" /></div>,
    dataIndex: 'action',
    key: 'operation',
    fixed: !isTaskOpen ? 'right' : '',
    width: 120,
  })

  const defaultCheckedList = columns?.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns?.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns?.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  return (
    <div className="flex flex-col h-full w-full" style={{ overflow: 'hidden', height: 'calc(100vh-200px)' }}>
      {/* Content */}
      <TaskFilter
        selectedLabels={selectedLabels}
        onLabelSelection={handleLabelSelection}
        users={props.users}
        teams={props.teams}
        projects={currentWorkspace?.myProjects}
        handleSaveUserFilter={handleSaveUserFilter}
        handleResetFilter={handleResetFilter}
        filters={workspaceFilter}
      />
      <div className='flex align-center'>
        <div className='mx-3 text-sm opacity-70'><FormattedMessage id="SHOWING_COLUMNS" defaultMessage="Showing columns:" /></div>
        <Checkbox.Group
          value={checkedList}
          options={options}
          onChange={(value) => {
            setCheckedList(value);
          }}
          style={{ padding: 10 }}
        />
      </div>
      {dropdownVisible && selectedTask && (
        <Dropdown
          menu={{ items: TaskContextMenu({ task: selectedTask, setIsEdit, props, dispatch, isWorkspaceTasksScreen: true }) }}
          open={dropdownVisible}
          onOpenChange={(visible) => setDropdownVisible(visible)}
          trigger={['contextMenu']}
        >
          <div
            style={{
              position: 'absolute',
              left: dropdownPosition.x,
              top: dropdownPosition.y,
              zIndex: 1000
            }}
          />
        </Dropdown>
      )}
      {/* Table */}
      <Table
        columns={newColumns}
        dataSource={dataSource}
        pagination={false}
        size={'small'}
        scroll={{ x: 'max-content', y: 'calc(100vh - 215px)' }}
        onScroll={handleScroll}
        onRow={(record) => ({
          onContextMenu: (event) => {
            event.preventDefault();
            const task = taskMap.get(record.key);
            if (!task) return;
            setSelectedTask(task);
            setDropdownVisible(true);
            setDropdownPosition({ x: event.clientX, y: event.clientY });
          },
        })}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userTasks: state.taskReducer.userTasks,
    users: state.userReducer.users,
    user: state.userReducer.user,
    userQuery: state.taskReducer.userQuery,
    teams: state.teamReducer.getTeams,
    userFilters: state.taskReducer.userFilters,
    userTasksCount: state.taskReducer.userTasksCount,
    workspaceFilter: state.workspaceReducer.workspaceFilter,
    taskProperties: state.taskReducer.taskProperties,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    workspaceCustomFields: state.workspaceReducer.workspaceCustomFields,
    isTaskOpen: state.toggleReducer.isTicketOpen,
  };
}

export default connect(mapStateToProps)(MyTasks);
