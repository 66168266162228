// Layout.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { notification, Drawer } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import TaskSidePanel from '../UI/organisms/TaskSidePanel';
import ChatDrawer from '../UI/organisms/ChatDrawer';
import AiSidePanel from '../UI/organisms/AiSidePanel';
import UserChatPanel from '../UI/organisms/UserChatPanel';

import LeftNavBar from '../UI/organisms/LeftNavBar';
import TopNavbar from '../UI/molecules/upper-navbar/TopNavbar';

import WorkspaceFieldsModal from '../UI/molecules/modals/WorkspaceFieldsModal';
import DuplicateProjectModal from '../UI/molecules/modals/DuplicateProjectModal';
import CustomFieldsModal from '../UI/molecules/modals/CustomFieldsModal';

import {
  getWorkspaceDefaultFilter,
  resendConfirmEmail,
  acceptWorkspaceInvite,
  editUser,
  getWorkspaceById,
  getWorkspaces,
  toggleCustomFieldsModal,
  getSingleMessage,
  isChatOpen as chatOpener,
  isTicketOpen as ticketOpener,
} from '../../actions';
import { getUser, verifyUser, setUnauthorized } from '../../actions/userAction';
import { logout } from '../../actions/logoutAction';
import { getWorkspaceDashboardData, getWorkspaceInvites } from '../../actions/WorkspaceActions';
import { resetDrawer } from '../../actions/toggleAction';
import { isDrawerOpen } from '../../reducers/toggleReducer';
import { Mobile } from './Mobile';

import socket from '../../js/socket';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // You can add other Firebase services as needed
import { getMessaging, getToken } from 'firebase/messaging';
import { APPLICATION_NAME } from '../../js/constants';

import Loader from '../UI/atoms/global/Loader';
import AddChatDrawer from '../../src-new/drawers/AddChat';
import InviteMembersDrawer from '../../src-new/drawers/InviteMembers';
import UserDrawer from '../../src-new/drawers/UserDrawer';
import AddProject from '../../src-new/drawers/AddProject';
import MemberDrawer from '../../src-new/drawers/MemberDrawer';
import AddTaskDrawer from '../../src-new/drawers/AddTask';
import JoinProjectsDrawer from '../../src-new/drawers/JoinProjects';
import { getWorkspaceNotifications } from '../../actions/notificationAction';
import { FormattedMessage, useIntl } from 'react-intl';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize other Firebase services if needed

const Layout = (props) => {
  const {
    projectCustomFields,
    children,
    phase = 'home',
    currentWorkspace,
  } = props;

  const intl = useIntl();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { logout: auth0Logout } = useAuth0();

  const [user, setUser] = useState(true);

  const modalState = useSelector((state) => state.modalReducer);
  const showDrawer = useSelector((state) => isDrawerOpen(state));
  const workspaceFieldsModal = modalState.workspaceFieldsModal;
  const duplicateProjectModal = modalState.duplicateProjectModal;

  const unauthorized = useSelector((state) => state.userReducer.unauthorized);
  const forbidden = useSelector((state) => state.userReducer.forbidden);

  const setCustomFeildsModalOpen = (value) => {
    dispatch(toggleCustomFieldsModal(value));
  };

  const toggleState = useSelector((state) => state.toggleReducer);

  const {
    isChatSettingsOpen,
    teamDrawerState: isTeamDrawerState,
    isChatOpen,
    isTicketOpen,
    isAiOpen,
    activeChatMessage,
    isUserDrawerOpen,
    isMemberDrawerOpen,
    isWorkspaceInvites,
    isJoinProjects
  } = toggleState;

  const selectedTaskParam = new URLSearchParams(location.search).get(
    'selectedTask'
  );

  const singleTask = useSelector((state) => state.taskReducer.singleTask);
  const taskIdentifier = singleTask?.titleIdentifier;
  const { singleMessage } = useSelector((state) => state.messageReducer);
  const { 1: chatId } = window.location.pathname.split('/');
  const msgId = new URLSearchParams(location.search).get('msgId');
  const { query } = useSelector((state) => state.notificationReducer);
  const [isLoading, setIsLoading] = useState(false);

  const { 1: projectId, 2: path } = window.location.pathname.split('/');
  useEffect(() => {
    if (isTicketOpen && !selectedTaskParam && taskIdentifier) {
      dispatch(chatOpener(false));
      navigate(
        { search: `?selectedTask=${taskIdentifier}` },
        { replace: true }
      );
    } else if (selectedTaskParam) {
      dispatch(chatOpener(false));
      dispatch(ticketOpener(true));
      console.log('---- singleTask', singleTask);
    } else if (!isTicketOpen) {
      const params = new URLSearchParams(location.search);
      params.delete('selectedTask'); // Remove the specified parameter
      if (isChatOpen && !msgId) {
        params.set('msgId', singleMessage?._id);
      } else if (msgId) {
        dispatch(getSingleMessage(msgId));
        dispatch(chatOpener(true, msgId));
      }

      // Construct the new URL with updated parameters
      const newUrl = `${location.pathname}?${params.toString()}`;

      // Use navigate to replace the current URL
      navigate(newUrl, { replace: true });
    }
  }, [selectedTaskParam, activeChatMessage, projectId, chatId]);

  useEffect(() => {
    const handleAsync = () => {
      if (currentWorkspace?._id) {
        dispatch(getWorkspaceById());
        dispatch(getWorkspaceInvites());
        // Dashboard task is already loading workspace notifications. This is a redundant call.
        dispatch(getWorkspaceNotifications(query));
        dispatch(getWorkspaces('lean')).then((data) => {
          if (data?.length > 0) {
          } else {
            //navigate('/select-workspace', { replace: true });
          }
        });
      }
    };

    handleAsync();
  }, [currentWorkspace?._id]);

  useEffect(() => {
    if (unauthorized) {
      localStorage.removeItem('token');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('organization');
      localStorage.removeItem('toggle');
      localStorage.removeItem('inviteUrl');
      localStorage.removeItem('Id');

      sessionStorage.removeItem('workspaceId');
      sessionStorage.removeItem('organization');

      auth0Logout({
        logoutParams: { returnTo: `${window.location.origin}/signin` },
      });

      dispatch(logout());
      window.location.href = '/signin';
    }
  }, [unauthorized]);


  useEffect(() => {
    dispatch(getWorkspaceDashboardData());
    dispatch(
      getUser(null, (loggedUser) => {
        setUser(loggedUser);
        const userToBeVerified = new URLSearchParams(location.search).get(
          'verifyUser'
        );
        if (userToBeVerified) {
          const user = {
            _id: userToBeVerified,
            isVerified: true,
          };
          dispatch(
            verifyUser(user, () => {
              const params = new URLSearchParams(location.search);
              params.delete('verifyUser'); // Remove the specified parameter

              // Construct the new URL with updated parameters
              const newUrl = `${location.pathname}?${params.toString()}`;

              // Use navigate to replace the current URL
              navigate(newUrl, { replace: true });

              // setIsUserVerified(true);
              setUser({ ...loggedUser, isVerified: true });
            })
          );
        }
      })
    );
    dispatch(getWorkspaceDefaultFilter());
  }, []);

  const toggle = useSelector((state) => state.navReducer.toggle);
  const team = useSelector((state) => state.teamReducer.selectedTeam);
  const project = useSelector((state) => state.projectReducer.getProjects);
  const showOnboarding = useSelector(
    (state) => state.onboardingReducer.showOnboarding
  );

  const showNewTaskModal = modalState.newTaskModal.show;
  const showCustomFieldsModal = modalState.customFieldsModal.show;
  const showNewProjectModal = modalState.newProjectModal.show;
  const projectModalTeam = modalState.newProjectModal?.team || '';
  const teamsChatModal = modalState.teamsChatModal;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    document.title = `${APPLICATION_NAME}`;
    const fetchFCMData = async () => {
      try {
        console.log('[fetchFCMData] Fetching FCM Token');
        const messaging = getMessaging(app);
        const permission = await Notification.requestPermission();
        console.log('[fetchFCMData] permission', permission);
        const userId = localStorage.getItem('Id');
        const localUser = await dispatch(getUser(userId));
        console.log('[fetchFCMData] localUser', localUser);

        if (permission === 'granted' && localUser?._id) {
          const token = await getToken(messaging);
          console.log('[fetchFCMData] token', token);

          // Set the token state and send it to your server
          setFcmToken(token);
          // const userId = localStorage.getItem('Id');
          // const localUser = await dispatch(getUser(userId));
          const subscriptions = localUser?.subscriptions || {};
          const fbSubscriptions = subscriptions['firebase'] || [];
          fbSubscriptions.push(token);
          subscriptions['firebase'] = fbSubscriptions;

          await dispatch(
            editUser(
              {
                _id: userId,
                subscriptions,
              },
              async () => {
                await dispatch(getUser(userId));
              }
            )
          );
        }
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    fetchFCMData(); // Call the async function immediately
  }, []);

  useEffect(() => {
    const url = window.location.href;
    if (!localStorage.getItem('token')) {
      dispatch(setUnauthorized('Session Expired!'));
    } else {
      const splitUrl = url.split('inviteId=');
      if (splitUrl.length > 1) {
        const inviteId = splitUrl[1].split('&')?.[0] || '';
        if (inviteId) {
          dispatch(acceptWorkspaceInvite(inviteId)).then((res) => {
            if (res?._id) {
              notification.success({
                message: 'Workspace Joined!',
                placement: 'bottomRight',
              });
              navigate('/', { replace: true });
              socket.emit('invitation-accepted', inviteId);
            }
          });
        }
      }
    }
  }, [navigate]);

  useEffect(() => {
    setSidebarOpen(false);
  }, [window.location.pathname]);

  let name = '';

  if (phase === 'team') {
    name = team?.title;
  } else if (phase === 'project') {
    name = project?.title;
  }

  const isMobile = window.screen?.width < 700;

  if (isMobile) {
    return <Mobile />
  }

  return isLoading ? (
    <div className='h-[100vh] w-[100vw]'>
      <Loader
        newLoader
        isLoading={isLoading}
      />
    </div>
  ) : (
    <>
      {/* MODALS */}
      <WorkspaceFieldsModal
        isModalOpen={workspaceFieldsModal?.show}
        selectedProjectId={workspaceFieldsModal?.selectedProject}
      />
      <DuplicateProjectModal
        user={user}
        projects={project}
        isModalOpen={duplicateProjectModal?.show}
        project={duplicateProjectModal?.selectedProject}
      />

      <CustomFieldsModal
        projectCustomFields={projectCustomFields}
        prefilledData={modalState.customFieldsModal.data}
        isModalOpen={showCustomFieldsModal}
        onClose={() => setCustomFeildsModalOpen(false)}
      />

      <div className="flex" style={{ height: '100vh', overflow: 'hidden' }}>
        {(!isMobile || (isMobile && sidebarOpen)) && (
          <LeftNavBar
            setSidebarOpen={setSidebarOpen}
            isMobile={isMobile}
            setIsLoading={setIsLoading}
          />
        )}
        <div
          className="w-[100vw] lg:overflow-y-hidden overflow-y-scroll"
        >
          <TopNavbar name={name} projectData={project} phase={phase} />
          <div className='sub-container'>
            <div
              style={{
                overflow: 'scroll',
                width: '100%',
                height: 'calc(100vh - 60px)',
              }}
            >
              {children}
            </div>
            {isChatOpen && <ChatDrawer />}
            {isTicketOpen && <TaskSidePanel customClass="chat-drawer" />}
            <Drawer
              className="drawer"
              headerStyle={{ display: 'none' }}
              onClose={() => {
                dispatch(resetDrawer());
              }}
              styles={{
                padding: 0,
                margin: 0
              }}
              open={showDrawer}
              width={windowWidth > 600 ? 550 : '90%'}
            >
              {(isTeamDrawerState || isWorkspaceInvites) && <InviteMembersDrawer isWorkspaceInvites={isWorkspaceInvites} />}
              {isUserDrawerOpen && <UserDrawer />}
              {showNewProjectModal && <AddProject />}
              {teamsChatModal.show && <AddChatDrawer />}
              {isMemberDrawerOpen?.show && <MemberDrawer />}
              {isJoinProjects && <JoinProjectsDrawer />}
              {isAiOpen && <AiSidePanel />}
              {isChatSettingsOpen?.show && (
                <UserChatPanel
                  senderId={isChatSettingsOpen?.chatId}
                  me={user}
                  customClass="chat-drawer"
                />
              )}
              {showNewTaskModal && <AddTaskDrawer />}
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    projectCustomFields: state.projectReducer.projectCustomFields,
    chats: state.chatReducer.chats,
    teams: state.teamReducer.getTeams,
    myProjects: state.projectReducer.myProjects,
  };
}

export default connect(mapStateToProps)(Layout);
