import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY); // Replace with your actual Stripe public key

const StripeWrapper = () => {

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{
        applePay: {
          merchantId: 'merchant.com.workflo', // Add your Apple Merchant ID
        },
      }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

export default StripeWrapper;
