import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { isChatOpen, isTicketOpen, searchWorkspace } from '../../actions';
import { setTeamsState } from '../../actions/toggleAction';
import { getDateTextLongFormat } from '../../js/functions';
import TimeAgo from 'javascript-time-ago';
import SearchMessages from '../UI/molecules/search/SearchMessages';
import { NoSearchResultsFound } from '../UI/molecules/search/NoSearchResultsFound';
import SearchProjects from '../UI/molecules/search/SearchProjects';
import DashboardTask from '../../src-new/components/dashboard/Task';
import SearchChats from '../UI/molecules/search/SearchChats';
import { APPLICATION_NAME } from '../../js/constants';
import { Skeleton } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';


const Search = (props) => {
  const intl = useIntl();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSelector((state) => state.workspaceReducer.search);
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState('Tasks');
  const tabs = ['Tasks', 'Messages', 'Projects', 'Chats'];
  const loggedInUser = useSelector(state => state.userReducer.user);
  const [comments, setComments] = useState({ res: { body: { data: [] } } });
  const { users } = useSelector((state) => state.userReducer);
  const { user } = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(false);

  const handleTaskClick = async (e, task) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };
  useEffect(() => {
    if (search) {
      setIsLoading(false);
    }
  }, [search]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('query') || '';
    const tabQuery = params.get('tab') || 'Tasks';

    setQuery(searchQuery);
    setActiveTab(tabQuery);

    if (searchQuery) {
      setIsLoading(true);
      dispatch(searchWorkspace(searchQuery));
    }
  }, [location.search, dispatch]);


  const renderResults = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="  p-5  bg-white mb-3  rounded-xl w-full flex flex-col cursor-pointer">
              <Skeleton paragraph={{ rows: 2 }} />
            </div>
          ))}
        </div>
      );
    }

    switch (activeTab) {
      case 'Tasks':
        return search?.tasks?.length > 0 ? (
          search.tasks.map((task, index) => (
            <DashboardTask task={task} index={index} onClick={(e) => handleTaskClick(e, task)} isSearch={true} />
          ))
        ) : (
          <NoSearchResultsFound />
        );
      case 'Messages':
        return search?.messages?.length > 0 ? (
          search?.messages.map((chat, index) => {

            const lastReplyIndex = chat?.replies?.length - 1;
            const lastReplyTime = lastReplyIndex >= 0 ? chat?.replies[lastReplyIndex]?.createdAt : null;

            const date = new Date(chat?.createdAt);
            let messageDate = '';
            // const timeAgo = new TimeAgo(intl.locale);
            // const time = timeAgo.format(date);
            let roomTitle;

            const replySenders = uniqBy(chat?.replies?.map((reply) => reply?.sender), '_id');

            const senderDetails = users?.find((user) => user?._id === chat?.sender?._id);
            if (senderDetails) {
              chat.sender.avi = senderDetails.avi;
            }

            let showDateRow = false;
            const currentMessageDate = date.toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });
            if (currentMessageDate !== messageDate) {
              showDateRow = true;
              messageDate = currentMessageDate;
            }
            const filterChat = props?.chats && props?.chats?.find((msg) => msg._id === chat?.roomId);
            if (filterChat && filterChat.roomName) {
              roomTitle = filterChat.roomName;
              document.title = `${filterChat.roomName} - ${APPLICATION_NAME}`;
            } else {
              const otherUser =
                filterChat &&
                filterChat.members &&
                filterChat.members.find((mem) => mem._id != user._id);
              if (otherUser && otherUser.fullName) {
                roomTitle = otherUser.fullName;
                document.title = `${otherUser.fullName} - ${APPLICATION_NAME}`;
              }
            }
            return (
              <div key={chat._id} className='mb-5'>
                {showDateRow && (
                  <div className='relative mb-2 flex flex-row justify-between items-center'>
                    <div >{roomTitle}</div>
                    <div>{getDateTextLongFormat(date, intl)}</div>
                  </div>
                )}
                <SearchMessages
                  chats={search.messages}
                  key={chat._id}
                  senderId={chat?.sender?._id}
                  msgId={chat._id}
                  height="30px"
                  width="30px"
                  name={chat?.sender?.fullName}
                  src={chat?.sender?.avi}
                  date={date}
                  comment={chat?.messageDescription}
                  comments={comments}
                  roomTitle={roomTitle}
                  attachments={chat?.attachments}
                  files={chat?.files}
                  searchQuery={query}
                />
              </div>
            );
          })
        ) : (
          <NoSearchResultsFound />
        );
      case 'Projects':
        return search?.projects?.length > 0 ? (
          search.projects.map((project, index) => (
            <SearchProjects project={project} />
          ))
        ) : (
          <NoSearchResultsFound />
        );
      case 'Chats':
        const userId = localStorage.getItem('Id');
        const filteredChats = props?.chats?.filter((chat) => {
          const isDirectChat = chat.roomType === 'direct';
          const dmUser = chat.members?.find((member) => member._id !== userId);
          const name = isDirectChat ? dmUser?.fullName || 'User' : chat.roomName || '';
          return name.toLowerCase().includes(query.toLowerCase());
        });
        const nonProjectChats = filteredChats.filter((chat) => chat.roomType !== 'project');
        return (
          <>
            {nonProjectChats.length > 0 ? (
              nonProjectChats.map((chat) => (
                <SearchChats
                  key={chat?._id}
                  chat={chat}
                  userId={userId}
                  loggedInUser={loggedInUser}
                  params={params}
                />
              ))
            ) : (
              <NoSearchResultsFound />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='xl:px-40 px-4 '>
        <div className="flex flex-row gap-3 overflow-x-auto no-scrollbar my-4">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`py-1 px-5 rounded-2xl border shadow-sm ${activeTab === tab
                ? 'bg-[rgba(4,115,204,0.8)] text-white'
                : 'bg-white text-[rgba(4,115,204,0.8)] hover:bg-[rgba(4,115,204,0.8)] hover:text-white'
                }`}
              onClick={() => setActiveTab(tab)}
            >
              <FormattedMessage id={`${tab}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={tab} />
            </button>
          ))}
        </div>
      </div>
      <div className='xl:px-40 px-4 mt-5'>
        <div className='grid grid-cols-1  w-full  items-center justify-center'>
          {renderResults()}
        </div>
      </div>
    </div>
  );
};


function mapStateToProps(state) {
  return {
    chats: state.chatReducer.chats,
  };
}

export default connect(mapStateToProps)(Search);