import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import { isValidEmail } from '../../../../js/helper';
import '../../../../assets/style/global.css';
import { useIntl } from 'react-intl';

const UserSelect = (props) => {
  const {
    placeholder,
    setEmails,
    clearInput,
    members
  } = props;

  const intl = useIntl();
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const Users = useSelector((state) => state.userReducer.users);

  const DEAULT_ROLE = 'member';

  useEffect(() => {
    if (clearInput) {
      setValue([]);
      setInputValue('');
      setOptions([]);
    }
  }, [clearInput]);

  useEffect(() => {
    if (members && Users) {
      const existingUsers = [];


      const notInTeam = Users.filter(
        user => !members.find(member => member._id === user._id)
      );

      notInTeam && notInTeam.map(user => {
        existingUsers.push({
          ...user,
          value: user.email,
          label: user.fullName + ' - ' + user.email,
          role: user.role || DEAULT_ROLE
        });
      })

      existingUsers.length > 0 && setOptions(existingUsers);
    }

  }, [members]);

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    userId: localStorage.getItem('Id'),
    email: label,
    role: DEAULT_ROLE,
    label,
    value: label,
  });

  // const handleKeyDown = async (event) => {
  //   if (!inputValue) return;
  //   switch (event.key) {
  //     case 'Enter':
  //     case 'Tab':
  //     case ' ':
  //     case ',':
  //       await setValue((prev) => [...prev, createOption(inputValue)]);

  //       if (isValidEmail(inputValue)) {
  //         await setEmails(oldEmails => [...oldEmails, {
  //           // userId: localStorage.getItem('Id'),
  //           email: inputValue,
  //           role: DEAULT_ROLE,
  //         }]);
  //       }
  //       setInputValue('');
  //       event.preventDefault();
  //   }
  // };

  const noOptionsRenderer = useCallback(() => intl.formatMessage({ id: 'NO_OPTIONS', defaultMessage: 'No options' }), [intl]);

  return (
    <CreatableSelect
      noOptionsMessage={noOptionsRenderer}
      components={components}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      className={'font-14'}
      formatCreateLabel={(inputValue) => `Invite "${inputValue}"`}
      onChange={async (newValue) => {
        const newEmails = [];

        newValue.map(async value => {
          if (isValidEmail(value.email)) {
            newEmails.push({
              userId: value.userId,
              email: value.email,
              role: value.role,
            });
          } else {
            const option = createOption(value.label || value.value);
            if (isValidEmail(value.value)) {
              newEmails.push(option);
            }
          }
        });

        setEmails(newEmails);
        setValue(newValue);
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      placeholder={placeholder}
      options={options}
      value={value}
    />
  );
};


export default UserSelect;
