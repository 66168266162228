import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { toggleCustomFieldsModal, deleteProjectCustomField, getProjectCustomField } from '../../../../actions';
import { fieldTypes } from '../../../../js/constants';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';
import { Tooltip } from "antd";
import EmptyState from "../../atoms/empty-state/EmptyState";
import { FormattedMessage, useIntl } from "react-intl";

const ProjectCustomFields = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { project, editOverride, selectedFieldType = false, showField = false } = props;

  const [open, setOpen] = useState(false);
  const [fieldToRemove, setFieldToRemove] = useState();
  const [customFields, setCustomFields] = useState();


  useEffect(() => {
    dispatch(getProjectCustomField(project?._id));
  }, [project?._id]);


  useEffect(() => {
    setCustomFields(props?.customFields);
  }, [project, props?.customFields]);

  const addField = () => {
    if (window.location.pathname?.includes('settings')) {
      dispatch(toggleCustomFieldsModal(true));
    } else {
      editOverride({
        isUpdate: false
      });
    }
  }

  return (
    <div className="pt-2">
      <ConfirmationModal
        title={`${intl.formatMessage({ id: 'DELETE_FIELD', defaultMessage: 'Delete Field' })}?`}
        description={intl.formatMessage({ id: 'DELETE_FIELD_CONFIRMATION', defaultMessage: 'Are you sure you want to delete {fieldName}?' }, { fieldName: fieldToRemove?.name })}
        isModalOpen={open}
        onCancel={() => {
          setOpen(false);
          setFieldToRemove();
        }}
        onYesClick={() => {
          dispatch(deleteProjectCustomField(fieldToRemove?._id, project?._id, () => {
            const x = customFields?.filter(field => field?._id !== fieldToRemove?._id);
            setCustomFields(x);
            setFieldToRemove();
          }, intl));
          setOpen(false);
        }}
      />
      {customFields?.map((item, i) => {
        const icon = fieldTypes?.find(f => f.key == item?.type)?.icon || 'custom-fields.svg';
        const color = fieldTypes?.find(f => f.key == item?.type)?.color || '#33fff8';
        const isSelected = selectedFieldType === item?._id;
        return (
          <div
            key={item?._id}
            onClick={() => {
              if (editOverride) {
                editOverride({ ...item, isUpdate: true });
              } else {
                dispatch(toggleCustomFieldsModal(true, {
                  type: item?.type,
                  name: item?.name,
                  possibleValues: item?.possibleValues,
                  _id: item?._id,
                  isUpdate: true
                }));
              }
            }} className={`flex align-center justify-between col-span-full ${isSelected && showField ? 'border-blue-500' : 'border-transparent'} hover:border-blue-500 border hover:bg-slate-50 cursor-pointer rounded-lg p-2 bg-slate-100 mb-2`}>
            <div className='flex align-center'>
              <div style={{ backgroundColor: color }} className='rounded-lg'>
                <img
                  src={`/images/icons/${icon}`}
                  style={{
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    margin: '10px',
                    filter: 'invert(1) brightness(180%)'
                  }}
                  alt='icon'
                />
              </div>
              <div className='ml-3'>
                <div className='font-14 font-bold'>
                  {item?.name}
                </div>
                <div>
                  <FormattedMessage id="FIELD_TYPE" defaultMessage="Field Type" />: {intl.formatMessage({ id: `${item?.type}`.toUpperCase(), defaultMessage: item?.type }).toLowerCase()}
                </div>
              </div>
            </div>
            <div className="flex align-center">
              <Tooltip title={intl.formatMessage({ id: 'DELETE_FIELD', defaultMessage: 'Delete Field' })}>
                <div
                  onClick={() => {
                    setFieldToRemove(item);
                    setOpen(true);
                  }}
                  className='pr-2 text-red-500 cursor-pointer hover:underline font-14'>
                  <img src="/images/icons/delete-red.svg" height={25} width={25} />
                </div>
              </Tooltip>
            </div>
          </div>
        )
      })}
      {customFields?.length == 0 ?
        <div className="flex align-center justify-center flex-col">
          <EmptyState
            header={intl.formatMessage({ id: 'NO_CUSTOM_FIELDS', defaultMessage: 'You have no custom fields!'})}
            description={intl.formatMessage({ id: 'NO_CUSTOM_FIELDS_DESCRIPTION', defaultMessage: 'Create one for this project, or change projects.'})}
            iconSize='h-60 w-60 mt-10'
            src='/images/icons/no-task.svg' />
          <div
            onClick={(e) => {
              addField();
            }}
            className={`w-40 text-center px-4 py-2 bg-[#0074cc] m-5 font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
            <FormattedMessage id="ADD_FIELD" defaultMessage="Add Field" />
          </div>
        </div> :
        <div
          onClick={(e) => {
            addField();
          }}
          className={`w-40 text-center px-4 py-2 bg-[#0074cc] my-5 font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
          <FormattedMessage id="ADD_FIELD" defaultMessage="Add Field" />
        </div>}
    </div>
  );
};

export default ProjectCustomFields;