

import { setPaymentDetails } from "../../../../../actions";

export const getPlanDetails = (dispatch, navigate, isMonthly = false, planPrice = 0, license) => {

  return {
    monthlyStarterPlanDetails: {
      title: 'Basic Plan',
      description: 'Tailored for small teams with big needs',
      price: '$0.00',
      buttonLabel: license === 'Free Plan' ? 'Current License' : 'Downgrade',
      buttonAction: () => navigate('/', { replace: true }),
      image: 'triangle.svg',
      color: '#0473CF',
      features: [
        '10MB/file upload limit',
        '200MB total storage limit per workspace',
        'Unlimited Workspaces',
        '100 task limit',
        '2 teams limit',
        '5 Projects Per team',
        'Chat history for 90 days',
        '3 Custom Fields',
      ],
    },
    teamPlanDetails: {
      title: 'Team Plan',
      description: 'For mid-sized teams managing diverse projects efficiently',
      price: `$ {priceValue} / per user`,
      cost: isMonthly ? 10.99 : 7.99,
      buttonLabel: 'Purchase',
      buttonAction: async () => {
        await dispatch(
          setPaymentDetails({ isMonthly: isMonthly, price: planPrice })
        );
        navigate('/pricing/users');
      },
      image: 'starWhite.svg',
      color: '#00AA96',
      features: [
        '1GB/file upload limit',
        '25GB total storage limit per workspace',
        'Unlimited Workspaces',
        'Unlimited teams',
        'Unlimited Projects per team',
        'Chat history for ever',
        'Up to 10 custom fields',
      ],
    },
    enterprisePlanDetails: {
      title: 'Enterprise Plan',
      description: 'Contact us for early access!',
      price: 'Custom Pricing',
      buttonLabel: 'Contact Sales',
      buttonAction: () => {
        window.open('https://workflo.com/contact/', '_blank');
      },
      image: 'IsotoxalStar.svg',
      color: '#000',
      features: [
        'All of Team Plan',
        'Unlimited storage workspaced',
        'Unlimited custom fields',
        'Video Calling',
        'Automations',
        'A.I. assistant',
        'Custom dashboards',
        'Single Sign-on (SSO)',
      ],
    },
  }
};
