
import * as togglerTypes from '../actions/types/toggler.types';
const data = {
  isTicketOpen: false,
  isChatOpen: false,
  isTaskModalOpen: false,
  drawerWidth: 510,
  teamDrawerState: false,
  isWorkspaceInvites: false,
  isMobile: false,
  activeChatMessage: null,
  isAiOpen: false,
  isChatSettingsOpen: {
    show: false,
    chatId: ''
  },
  isMemberDrawerOpen: {
    show: false,
    member: {}
  },
  isJoinProjects: false,
  isListView: localStorage.getItem('list-view') === 'true' ? true : false,
  showTaskAsModal: localStorage.getItem('task-modal') === 'true' ? true : false,

  isUserDrawerOpen: false,
  isAddProjectOpen: false
};

const toggleReducer = (state = data, action) => {
  switch (action.type) {
    case togglerTypes.TICKET:
      return {
        ...state,
        isTicketOpen: action.payload,
        isChatOpen: action.payload ? false : state.isChatOpen,
        isAiOpen: action.payload ? false : state.isAiOpen,
        isChatSettings: false,
      };

    case 'isChatSettings':
      return {
        ...state,
        isChatSettingsOpen: {
          show: action.payload?.show,
          chatId: action?.payload?.chatId
        },
        isTicketOpen: false,
        isChatOpen: false,
        isAiOpen: false,
      }
    case togglerTypes.MEMBER:
      return {
        ...state,
        isMemberDrawerOpen: {
          show: action?.payload?.show,
          member: action?.payload?.member
        },
      }
    case 'chat':
      return {
        ...state,
        isChatOpen: action.payload?.val,
        activeChatMessage: action.payload?.msgId,
        isTicketOpen: action.payload?.val ? false : state.isTicketOpen,
        isAiOpen: action.payload?.val ? false : state.isAiOpen,
        isChatSettings: false,
      };

    case togglerTypes.AI:
      return {
        ...state,
        isAiOpen: action.payload,
      };

    case 'isListView':
      localStorage.setItem('list-view', action.payload)
      return {
        ...state,
        isListView: action.payload,
      };

    case 'showTaskAsModal':
      localStorage.setItem('task-modal', action.payload)
      return {
        ...state,
        showTaskAsModal: action.payload,
      };

    case 'isTaskModalOpen':
      return {
        ...state,
        isTaskModalOpen: action.payload,
      };

    case togglerTypes.PROJECTS_JOIN:
      return {
        ...state,
        isJoinProjects: action.payload,
      };

    case togglerTypes.COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case 'setDrawerWidth':
      return {
        ...state,
        drawerWidth: action.payload,
      };

    case togglerTypes.TEAMS:
      return {
        ...state,
        teamDrawerState: action.payload,
      };

    case togglerTypes.WORKSPACE:
      return {
        ...state,
        isWorkspaceInvites: action.payload,
      };

    case 'set-mobile':
      return {
        ...state,
        isMobile: action.payload,
      };

    case 'reset-drawer':
      return {
        ...data
      };

    case togglerTypes.USER:
      return {
        ...data,
        isUserDrawerOpen: action.payload,
      }

    default:
      return state;
  }
};

export const isDrawerOpen = (state) => {
  const {
    teamDrawerState,
    isChatSettingsOpen,
    isUserDrawerOpen,
    isMemberDrawerOpen,
    isWorkspaceInvites,
    isJoinProjects,
    isAiOpen
  } = state.toggleReducer;

  const {
    newProjectModal,
    teamsChatModal,
    newTaskModal
  } = state.modalReducer;

  return (
    teamDrawerState ||
    isUserDrawerOpen ||
    isWorkspaceInvites ||
    isMemberDrawerOpen?.show ||
    newProjectModal?.show ||
    teamsChatModal?.show ||
    isChatSettingsOpen?.show ||
    newTaskModal?.show ||
    isJoinProjects ||
    isAiOpen
  );
}

export default toggleReducer;
