import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editTask } from '../../../../actions';

const EditTask = ({ task, setEditTaskVisible }) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const formRef = useRef(null);

    const [taskTitle, setTaskTitle] = useState(task?.taskTitle || '');

    const handleChange = (e) => {
        setTaskTitle(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const finalTitle = taskTitle.trim() === '' ? task.taskTitle : taskTitle;

        dispatch(
            editTask({
                _id: task._id,
                taskTitle: finalTitle,
            })
        );

        setEditTaskVisible(false);
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = inputRef.current.selectionEnd = taskTitle.length;
        }
    }, [taskTitle]); 

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (formRef.current && !formRef.current.contains(e.target)) {
                formRef.current.dispatchEvent(
                    new Event('submit', { bubbles: true, cancelable: true })
                );
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, []);

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <textarea
                name="taskTitle"
                value={taskTitle}
                placeholder="Task name"
                onChange={handleChange}
                required
                autoFocus
                ref={inputRef}
                style={{ width: '100%', border: 'none', outline: 'none', resize: 'none', maxHeight: '40px' }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); 
                        handleSubmit(e);
                    }
                }}
            />
        </form>
    );
};

export default EditTask;
