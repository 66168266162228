const ROOM_TYPE = {
  PRIVATE: "Private",
  PUBLIC: "Public",
}

const APPLICATION_NAME = "Workflo";

const COLOURS = [
  "#106b82",
  "#6B93F3",
  "#f3936b",
  "#936BF3",
  "#F393F3",
];

const PRIORITY_COLORS = {
  p1: '#C20E35',
  p2: '#FF4500',
  p3: '#FFA500',
  p4: '#0074cc',
  s1: '#FE3C32',
  s2: '#F4BF00',
  s3: 'yellow',
  s4: '#0074cc',
};

const PRIORITY_LABEL = {
  p1: 'Critical',
  p2: 'High',
  p3: 'Medium',
  p4: 'Low',
};

const PRIORITY_ICON = {
  p1: 'priority-critical',
  p2: 'priority-high',
  p3: 'priority-medium',
  p4: 'priority-low',
};

const taskPriority = [
  {
    name: '--',
    rank: 'nill',
    color: ''
  },
  {
    name: PRIORITY_LABEL.p1,
    rank: 'p1',
    color: PRIORITY_COLORS.p1
  },
  {
    name: PRIORITY_LABEL.p2,
    rank: 'p2',
    color: PRIORITY_COLORS.p2
  },
  {
    name: PRIORITY_LABEL.p3,
    rank: 'p3',
    color: PRIORITY_COLORS.p3
  },
  {
    name: PRIORITY_LABEL.p4,
    rank: 'p4',
    color: PRIORITY_COLORS.p4
  }
]

const taskStatus = {
  allTasks: {
    key: 'all-tasks',
    label: 'All tasks'
  },
  completedTasks:
  {
    key: 'completed-tasks',
    label: 'Completed tasks'
  },
  incompletedTasks: {
    key: 'incompleted-tasks',
    label: 'Incompleted tasks',
  }
}

const dueDateFilterOptions = {
  allTasks: {
    key: 'all-tasks',
    label: 'Any time'
  },
  dueToday: {
    key: 'today',
    label: 'Today'
  },
  dueThisWeek: {
    key: 'this-week',
    label: 'This week'
  },
  dueThisMonth:
  {
    key: 'this-month',
    label: 'This month'
  }
}

const lastUpdatedFilterOptions = {
  allTasks: {
    key: 'all-tasks',
    label: 'Any time'
  },
  dueToday: {
    key: 'today',
    label: 'Today'
  },
  dueThisWeek: {
    key: 'this-week',
    label: 'This week'
  },
  dueThisMonth:
  {
    key: 'this-month',
    label: 'This month'
  }
}

const customFieldTypes = {
  dropdown: 'dropdown',
  text: 'text',
  assignee: 'assignee',
  date: 'date',
  number: 'number',
  status: 'status',
  priority: 'priority',
  cost: 'cost',
  link: 'link'
}

const accessStatus = {
  view: 'View',
  join: 'Join',
  request: 'Ask to join'
}

const fieldTypes = [
  {
    label: 'Status',
    key: 'status',
    color: '#7D3AD8',
    description: 'Indicates current state of a project',
    icon: 'notes.svg',
    options: [
      {
        key: 'to-do-statue',
        label: 'To Do',
        metadata: {
          color: 'red'
        }
      },
      {
        key: 'blocked-statue',
        label: 'Blocked',
        metadata: {
          color: 'red'
        }
      },
      {
        key: 'progress-statue',
        label: 'In Progress',
        metadata: {
          color: 'red'
        }
      },
      {
        key: 'review-statue',
        label: 'In Review',
        metadata: {
          color: 'red'
        }
      },
      {
        key: 'done-statue',
        label: 'Done',
        metadata: {
          color: 'red'
        }
      }
    ]
  },
  // {
  //   label: 'Assignee',
  //   key: 'assignee',
  //   color: '#FF9900',
  //   description: 'Tag project members',
  //   icon: 'User.svg'
  // },
  {
    label: 'Link',
    key: 'link',
    color: '#FF9900',
    description: 'Link to external page',
    icon: 'paper-pin.svg'
  },
  {
    label: 'Date',
    key: 'date',
    color: '#FF6969',
    description: 'Add a custom date for tracking deadlines',
    icon: 'date.svg'
  },
  {
    label: 'Text',
    key: 'text',
    color: '#00A099',
    description: 'Enter custom text to your workflow',
    icon: 'Text.svg'
  },
  {
    label: 'Dropdown',
    key: 'dropdown',
    color: '#EE3535',
    description: 'Select from custom predefined options',
    icon: 'dropdown.svg'
  },
  {
    label: 'Priority',
    key: 'priority',
    color: '#FF792E',
    description: 'Set task importance levels',
    icon: 'Flag.svg'
  },
  {
    label: 'Number',
    key: 'number',
    color: '#FF3823',
    description: 'Custom input of numerical values',
    icon: 'Hashtag.svg'
  },
  {
    label: 'Cost',
    key: 'cost',
    color: '#0473cc',
    description: 'Track expenses or costs related to tasks',
    icon: 'dollar.svg'
  }
]

const sectionColors = [
  {
    key: 0,
    bg: '#FEF5E7',
    border: '#F9E6CA'
  },
  {
    key: 1,
    bg: '#F4FDF8',
    border: '#CBF4DD'
  },
  {
    key: 2,
    bg: '#FFE5E1',
    border: '#FFD2CB'
  },
  {
    key: 3,
    bg: '#F2FAFC',
    border: '#DBEDF6'
  },
  {
    key: 4,
    bg: '#F1EFFF',
    border: '#DBD6F7'
  },
]

export {
  ROOM_TYPE,
  APPLICATION_NAME,
  COLOURS,
  PRIORITY_COLORS,
  PRIORITY_LABEL,
  PRIORITY_ICON,
  taskPriority,
  taskStatus,
  customFieldTypes,
  accessStatus,
  fieldTypes,
  dueDateFilterOptions,
  lastUpdatedFilterOptions,
  sectionColors
}