import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function StepOne({ onChange, handleClick, workspaceTitle }) {
  const intl = useIntl();
  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 font-bold mb-6">
          <FormattedMessage id="NAME_YOUR_WORKSPACE" defaultMessage="Name your new workspace" />
        </h1>
        <div className="space-y-3 mb-8">
          <input
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleClick();
              }
            }}
            value={workspaceTitle}
            type='text'
            placeholder={intl.formatMessage({ id: 'NAME_APPEARS_HERE', defaultMessage: 'Name appears here' })}
            onChange={onChange}
            className="w-full flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
        </div>
        <div className="flex items-center justify-between">
          <div
            onClick={handleClick}
            className={`btn bg-blue-500 hover:bg-blue-400 text-white ml-auto p-2 px-5 rounded-md cursor-pointer ${!workspaceTitle ? 'disabled' : ''}`}>
              <FormattedMessage id="NEXT" defaultMessage="Next" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepOne;