

import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/UI/atoms/global/Icon';
import { toggleNewProjectModal, toggleNewTaskModal } from '../../../actions';
import { FormattedMessage } from 'react-intl';

const DashboardOnboarding = ({ currentWorkspace }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const project = currentWorkspace?.myProjects?.[0];
  const hasProject = project?._id;

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return;
  }

  return (
    <div className='flex flex-col align-center justify-center h-full'>
      <div className='text-center'>
        <div className='flex align-center mb-2'>
          <div className='font-40 mr-3'><FormattedMessage id="WELCOME_TO" defaultMessage="Welcome to" /></div>
          <Icon
            name='logo.svg'
            height='36px' />
        </div>
        <div className='font-16 font-light'><FormattedMessage id="LETS_GET_STARTED" defaultMessage="Let's get started, what do you want to do first?" /></div>
      </div>
      <div className='flex align-center mt-10 w-[60%]'>
        {hasProject && <div
          onClick={() => dispatch(toggleNewTaskModal({
            title: 'My first task',
            value: true
          }))}
          className='nav-button new-task-nav-button font-16 h-9'>
          <FormattedMessage id="CREATE_NEW_TASK" defaultMessage="Create new task" />
        </div>}
        {/* <div className='mx-4 p-2 px-3 rounded font-16 bg-blue-200 cursor-pointer hover:opacity-80 shadow-sm'>
          Introduce yourself to the team
        </div> */}
        <div
          onClick={() => {
            if (hasProject) {
              navigate(`/${project?._id}/board`);
            } else {
              dispatch(toggleNewProjectModal({
                value: true
              }))
            }
          }}
          className='nav-button new-task-nav-button font-16 h-9 bg-orange-400'>
          {hasProject
            ? <FormattedMessage id="GO_TO_MY_PROJECT" defaultMessage="Go to my project" />
            : <FormattedMessage id="CREATE_MY_FIRST_PROJECT" defaultMessage="Create my first project" />}
        </div>
      </div>
    </div>
  );
};

export default DashboardOnboarding;