import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: "" },
};


export const toggleNewTaskModal = (data) => {
  return async (dispatch) => {
    console.log("-----> data", data)
    if (!data.title) {
      dispatch({
        type: "toggle-new-task-modal",
        payload: {
          title: '',
          value: data.value,
        },
      });
      return;
    }

    let res;
    try {
      // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      // res = await axios.post(`${baseUrl}/ai/query`, { query: `Summarize ${data?.title} into a short task title` }, config);
      // console.log('summary', res.data.result);
      dispatch({
        type: "toggle-new-task-modal",
        payload: {
          title: data.title,
          value: true,
        },
      });
      // dispatch({
      //   type: chatTypes.CHAT_SUMMARY,
      //   payload: res.data.data
      // });
    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleNewProjectModal = (data) => {
  return async (dispatch) => {
    if (!data.title) {
      dispatch({
        type: "toggle-new-project-modal",
        payload: {
          value: data.value,
          team: data?.team || ''
        },
      });
      return;
    }
  }
}

export const toggleNewTeamChatsModal = (data) => {
  return async (dispatch) => {
    if (!data.title) {
      dispatch({
        type: "toggle-new-teams-chats-modal",
        payload: {
          value: data.value,
          isTeams: data.isTeams
        },
      });
      return;
    }
  }
}

export const toggleCustomFieldsModal = (data, prefilled = {}) => {
  return async (dispatch) => {
    dispatch({
      type: "toggle-custom-fields-modal",
      payload: {
        data: prefilled,
        value: data
      },
    });
    return;
  }
}

export const toggleWorkspaceFieldsModal = (data, projectId) => {
  return async (dispatch) => {
    dispatch({
      type: "toggle-workspace-fields-modal",
      payload: {
        value: data,
        projectId: projectId || ''
      },
    });
    return;
  }
}

export const toggleDuplicateProjectModal = (data, proj) => {
  return async (dispatch) => {
    dispatch({
      type: "toggle-duplicate-project-modal",
      payload: {
        value: data,
        project: proj || {}
      },
    });
    return;
  }
}
