import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TimeAgo from 'javascript-time-ago';

import {
  getWorkspaceNotifications,
  updateViewNotification
} from "../../actions/notificationAction";

import { isTicketOpen } from "../../actions";
import { isChatOpen, setTeamsState } from '../../actions/toggleAction';
import HtmlRenderer from '../UI/organisms/HtmlRenderer';
import { APPLICATION_NAME } from '../../js/constants';
import { getLocalizedActivity } from '../../_locale/localesLookupTable';


const Activity = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector((state) => state.notificationReducer.notifications)?.notifications;
  const isListView = useSelector((state) => state.toggleReducer.isListView);

  const [isHover, setIsHover] = useState(false);


  
  useEffect(() => {
    document.title = `Activity - ${APPLICATION_NAME}`;
    dispatch(getWorkspaceNotifications());
  }, []);

  const handleNotificationClick = (notification) => {
    const notificationType = notification.notificationType;

    dispatch(updateViewNotification({ id: notification._id }));
    dispatch(getWorkspaceNotifications());
    dispatch(isTicketOpen(true));

    const projectId = notification.projects && notification.projects?.[0] || '';
    const taskIdentifier = notification.task?.titleIdentifier;

    try {
      if (
        notificationType === 'TaskNotification' ||
        notification.notificationType == 'ChatNotification'
      ) {
        const url = notification?.notificationType == 'TaskNotification' ? `/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}` : `/chat/${notification?.comment?.roomId}`
        navigate(url);
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'ChatReplyNotification') {
        dispatch(isChatOpen(true, notification?.repliedMessage?._id));
        const url = `/chat/${notification?.comment?.roomId}?msgId=${notification?.comment?._id}`
        navigate(url);
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'InviteNotification') {
        const url = `/${notification?.contextId}/board`;
        navigate(url);
      } else {
        navigate(`/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}`);
      }
    } catch (error) {
      navigate(`/`);
    }
  }

  return (
    <div style={{ background: '#fafafa', height: '92vh', overflowY: 'scroll' }}>
      <div>
        {notifications && notifications?.map(notif => {

          const icon = notif?.assignor?.avi || notif?.icon;

          const date = new Date(notif?.createdAt);
          const timeAgo = new TimeAgo(intl.locale);
          const time = timeAgo.format(date);

          const notificationType = notif?.notificationType;
          let notifActionLabel = '';
          let notifAction = () => { };

          if (notificationType === 'CommentNotification') {
            notifActionLabel = 'Go to Comment';
          } else if (notificationType === 'TaskNotification') {
            notifActionLabel = 'Open in Project';
            notifAction = () => {
              dispatch(isTicketOpen(true));
              navigate(`/activity?selectedTask=${notif?.task?.titleIdentifier}`)
            }
          } else if (notificationType === 'ChatReplyNotification') {
            notifActionLabel = 'Open in Thread';
            notifAction = () => dispatch(isChatOpen(true, notif?.repliedMessage?._id));
          }

          const isViewed = notif?.assignee?.isViewed;
          const description = notif?.description;

          const localizedTitle = () => getLocalizedActivity(notif?.title, intl);
          
          const localizedDescription = () => getLocalizedActivity(description, intl);

          return (
            <div className={`activity-item ${!isViewed && 'unread'}`} onClick={() => {
              if(!isHover) notifAction()
              handleNotificationClick(notif)
              }
            }>
              <div className='mr-2'>
                <img height={50} width={50} src={icon} style={{ borderRadius: 60 }} />
              </div>
              <div>
                <div className='font-14'>
                  {localizedTitle()}
                </div>
                {localizedDescription && notificationType !== 'TaskNotification' && <div className='additional-info mt-2'>
                  <HtmlRenderer html={localizedDescription()} />
                </div>}
                <div className='d-flex align-center mt-5'>
                  <div className='font-12 mr-2'>{time}</div>
                  <div
                    onMouseOver={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    className='activity-link'
                    // onClick={() => handleNotificationClick(notif)}
                  >
                    {notifActionLabel && <FormattedMessage id={`${notifActionLabel}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={notifActionLabel} />}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Activity;

const a = {
  GO_TO_COMMENT: 'Go to Comment',
  OPEN_IN_PROJECT: 'Open in Project',
  OPEN_IN_THREAD: 'Open in Thread'
}