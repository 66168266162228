import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getRandomColor } from '../../../../js/helper';
import { FormattedDate, FormattedMessage } from 'react-intl';

const ChatsTooltip = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [chats, setChats] = useState(props.chats);
  const [showProjectChats, setShowProjectChats] = useState(false);

  const toggleChats = () => {
    if (!showProjectChats) {
      const x = chats.filter(chat => chat.roomType !== 'project');
      setChats(x);
    } else {
      setChats(props.chats);
    }
    setShowProjectChats(!showProjectChats);
  }

  useEffect(() => {
    setChats(props.chats);
  }, [props.chats])

  return (
    <div className="col-span-full xl:col-span-6 bg-white w-[20vw]">
      <header className="flex align-center px-5 py-4 border-b border-slate-100">
        <h2 className="mr-5 font-20 font-light text-slate-800"><FormattedMessage id="RECENT_CHATS" defaultMessage="Recent Chats" /></h2>
      </header>
      <div className="p-3">
        {/* Card content */}
        <ul className="my-1">

          {chats?.length > 0 ? chats?.map((chat, i) => {
            const isDirectChat = chat?.roomType === 'direct'
            const isProjectChat = chat?.roomType === 'project'

            const dmUser = chat?.members?.filter(memeber => memeber._id !== localStorage.getItem('Id'));
            const name = isDirectChat && !isProjectChat ? dmUser[0]?.fullName : chat.roomName;
            const avi = isDirectChat ? dmUser[0]?.avi : chat.roomAvi;
            const mostRecentMessage = chat.mostRecentMessage || '';

            if (isProjectChat || !name) {
              return;
            }

            // const currentMessageDate = new Date(chat?.lastUpdate).toLocaleDateString('en-us',
            //   {
            //     weekday: "short",
            //     month: "short",
            //     day: "numeric"
            //   });

            const iconLetters = name?.slice(0, 2)?.toUpperCase() || '';

            let span = document.createElement('span');
            span.innerHTML = mostRecentMessage?.messageDescription;
            const htmlContent = span.textContent || span.innerText;

            return (
              <li
                className={`px-2 flex align-center justify-between hover:bg-indigo-50 hover:rounded-lg cursor-pointer ${window?.location?.pathname.includes(chat?._id) && 'bg-indigo-50 rounded-lg'}`}
                onClick={() => { navigate(`/chat/${chat?._id}`) }}
              >
                {avi ?
                  <img
                    src={avi}
                    className='my-3 mr-3 h-10 w-10'
                    alt='icon'
                    style={{ borderRadius: 30, objectFit: 'cover' }}
                  /> :
                  <div
                    className={'square-letter-large my-3 mr-3 w-8 h-8 font-14'}
                    style={{ background: getRandomColor(i + 1) }}>
                    {iconLetters}
                  </div>
                }
                <div className="grow flex-col justify-center py-2">
                  <div className="grow flex justify-between">
                    <div className="self-center font-16">{name}</div>
                    <div className='font-10'>
                      {/* {currentMessageDate} */}
                      <FormattedDate value={chat?.lastUpdate} />
                    </div>
                  </div>
                  {htmlContent !== "undefined" && <div className='font-14 truncate' style={{ width: '15vw' }}>
                    {htmlContent}
                  </div>}
                </div>
              </li>)
          }) : <li className="flex px-2">
            <div className="w-5 h-5 rounded-full shrink-0 bg-indigo-500 my-2 mr-3">
              <svg className="w-3 h-3 fill-current text-indigo-50" viewBox="0 0 36 36">

              </svg>
            </div>
            <div className="grow flex items-center text-sm py-2">
              <div className="grow flex justify-between">
                <div className="self-center"><FormattedMessage id="NO_CHATS" defaultMessage="No chats" /></div>
              </div>
            </div>
          </li>}
        </ul>
      </div>
    </div>
  );
};

export default ChatsTooltip;
