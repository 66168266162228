import React from "react";
import { Image, Modal, Tooltip, notification } from "antd";
import { useState } from "react";
import { useEffect } from "react";

const TaskFileHolder = ({ files, taskId, delAttachment }) => {
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.webm'];
  const unSupportedVideoExtensions = ['.wmv'];
  const imgExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
  const docExtensions = ['.doc', '.docx', '.txt', '.rtf'];
  const pptExtensions = ['.ppt', '.pptx'];
  const excelExtensions = ['.xls', '.xlsx', '.csv'];

  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    // Autoplay the video when the modal opens
    if (videoModalVisible) {
      document.getElementById('videoPlayer')?.play();
    }
  }, [videoModalVisible]);

  const iconMap = {
    pdf: '/images/icons/pdf.svg',
    doc: '/images/icons/document-file.svg',
    video: '/images/icons/video2.svg',
    csv: '/images/icons/excel.svg',
    ppt: '/images/icons/ppt.svg',
    excel: '/images/icons/excel.svg'
  }

  const handleClick = (file, fileType) => {
    if (fileType == 'video') {
      // file?.signedUrl
      setVideoSrc(file?.signedUrl);
      setVideoModalVisible(true);
    } else if (fileType === 'unsupportedVideo') {
      // Trigger download instead of opening in a new tab
      const link = document.createElement('a');
      link.href = file?.signedUrl;
      link.download = file?.name || 'downloaded-file'; // Ensure the file has a name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      try {
        window.open(file?.signedUrl, '_blank');
      } catch (error) {
        notification.error({
          message: 'Oops! Cannot open file',
          placement: 'bottomRight',
        });
      }
    }
  }

  const handleCloseVideoModal = () => {
    setVideoModalVisible(false);
    setVideoSrc('');
  };


  return (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {
      files
        ? files.map((file, key) => {
          const isVideo = videoExtensions.some(ext => file?.name?.includes(ext));
          const isImage = imgExtensions.some(ext => file?.name?.includes(ext));
          const isCsv = file?.name?.includes('.csv');
          const isPdf = file?.name?.includes('.pdf');
          const isDoc = docExtensions.some(ext => file?.name?.includes(ext));
          const isPpt = pptExtensions.some(ext => file?.name?.includes(ext));
          const isExcel = excelExtensions.some(ext => file?.name?.includes(ext));
          const isUnsupportedVideo = unSupportedVideoExtensions.some(ext => file?.name?.includes(ext));

          const icon = isVideo || isUnsupportedVideo ? iconMap.video : isCsv ? iconMap.csv : isPdf ? iconMap.pdf : isDoc ? iconMap.doc : isPpt ? iconMap.ppt : isExcel ? iconMap.excel : iconMap.doc;

          const fileType = isVideo ? 'video' : isUnsupportedVideo ? 'unsupportedVideo' : isImage ? 'image' : isCsv ? 'csv' : isPdf ? 'pdf' : isDoc ? 'doc' : isPpt ? 'ppt' : isExcel ? 'excel' : 'file';

          return (
            <div
              key={key}
              style={{ radius: 'none', border: !isImage && !isVideo && 'none' }}
              onMouseOver={() => setIsHover(file?._id)}
              onMouseOut={() => setIsHover()}
            >
              {videoModalVisible && (
                <Modal
                  title={null} // Set title to null to remove it
                  visible={videoModalVisible}
                  onCancel={handleCloseVideoModal}
                  footer={null}
                  centered
                  closeIcon={<div style={{ marginRight: '10px', fontSize: 50 }}>×</div>}     
                  width={'80vw'}            
                >
                  <video
                    id="videoPlayer"
                    controls
                    autoPlay // Autoplay the video
                    src={videoSrc}
                    type="video/mp4"
                    style={{ outline: 'none', border: 'none', height: '90vh', width: '100%' }} // Remove border and outline
                  >
                    Your browser does not support the video tag.
                  </video>
                </Modal>
              )}
              <Tooltip key={key} title={file?.name}>
                <div style={{ position: 'relative' }}>
                  {isImage ?
                    <Image
                      width={120}
                      height={120}
                      src={file?.signedUrl}
                      style={{ objectFit: 'cover', marginRight: 20 }}
                      preview={{ mask: '' }}
                    /> :
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => handleClick(file, fileType)}
                    >
                      <img
                        src={icon}
                        alt="PDF Icon"
                        style={{
                          width: 120,
                          height: 120,
                          marginRight: 20
                        }}
                      />
                    </div>

                  }
                  {isHover === file?._id && <div className="file-options">
                    <img
                      className='cursor-pointer'
                      src='https://cdn.iconscout.com/icon/free/png-128/download-1438181-1216144.png'
                      alt=''
                      style={{
                        height: '12px',
                        verticalAlign: 'text-top',
                      }}
                      onClick={() => {
                        window.document?.getElementById(`attachment${key}`)?.click();
                      }}
                    />
                    <img
                      className='cursor-pointer'
                      src='/images/icons/close.svg'
                      alt=''
                      style={{
                        height: '10px',
                        verticalAlign: 'text-top',
                      }}
                      onClick={(e) => {
                        delAttachment(file?._id)
                      }}
                    />
                  </div>}
                </div>
              </Tooltip>
            </div>
          );
        })
        : ''
    }</div>)
};

export default TaskFileHolder;
