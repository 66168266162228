import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import HeadingCard from "../molecules/dashboard/HeadingCard";
import Button from "../atoms/global/Button";
import LeftChatIcon from "../molecules/global/LeftChatIcon";
import socket from "../../../js/socket";

import AddSection from "../molecules/dashboard/AddSection";
import useAddSectionVisible from "../../../js/useAddSectionVisible";

import {
  addSection,
  getProjectById,
  deleteSection,
  addSingleTaskToState,
  removeSingleTaskFromState,
  reorderTasks,
  updateTaskPositions,
  getSingleTask,
  updateProjectSections
} from "../../../actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "../atoms/global/Loader";
import { getSectionColor } from "../../../js/helper/getRandomColor";
import { useIntl } from "react-intl";

const getItemStyle = (isDragging, draggableStyle, sectionKey) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  marginRight: "20px",
  background: getSectionColor(sectionKey)?.bg,
  borderRadius: 12,
  padding: 10,
  border: `1px solid ${getSectionColor(sectionKey)?.border}`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: "flex",
  padding: "10px 30px 15px 30px",
  overflow: "auto",
});
const TaskBoard = (props) => {
  const intl = useIntl();
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setsectionDescription] = useState("");
  const [phase, setphase] = useState("display");
  const [sections, setSections] = useState(
    props.projectData.sections.sort((a, b) => a.position - b.position)
  );
  const [placeholderProps, setPlaceholderProps] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const { 1: projectId } = window.location.pathname.split("/");

  const { setShowSection, showSection } = props;

  const loggedUser = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    if (props.projectData.title !== "") setSections(props.projectData.sections);
  }, [props.projectData]);

  useEffect(() => {
    socket.on("sections-arranged", (data) => {
      const projectIdReceived = data?.projectId;
      const updatedSections = data?.sections;
      if (projectIdReceived?.toString() == projectId?.toString()) {
        dispatch(updateProjectSections(updatedSections, projectIdReceived));
      }
      // dispatch(getProjectById(projectId, 'TaskBoard sections-rearranged'));
    });
  }, [dispatch, props.projectData.sections]);

  useEffect(() => {
    socket.on("sections-reordered", (updatedTasks) => {
      dispatch(updateTaskPositions(updatedTasks));
      const urlSearchParams = new URLSearchParams(window.location.search);
      const selectedTaskParam = urlSearchParams.get('selectedTask');


      if (selectedTaskParam) {
        const isSingleTaskUpdated = updatedTasks?.tasks?.find(tsk => tsk?.titleIdentifier?.toString() == selectedTaskParam?.toString());

        if (isSingleTaskUpdated) {
          dispatch(getSingleTask(isSingleTaskUpdated?.task?.toString()));
        }
      }
    });
  }, []);

  const params = useParams();
  const { sectionRef, isAddSectionVisible, setIsAddSectionVisible } =
    useAddSectionVisible(false);

  useEffect(() => {
    if (isAddSectionVisible) {
      const wrapperButton = document.getElementById("section-add-wrapper");
      wrapperButton.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [messagesEndRef, isAddSectionVisible]);
  useEffect(() => {
    socket.on("TaskAdded", function (task) {
      if (projectId) {
        const projects = task?.projects?.filter(p => p?.projectId?.toString() == projectId?.toString()) || [];
        task.projects = projects;
      }
      console.log("---- task TaskAdded", task);
      dispatch(addSingleTaskToState(task));
      // dispatch(getTasks(window.location.pathname.split("/")[1]));
      // dispatch(getProjectById(window.location.pathname.split("/")[1])); //create issue to render all projects
    });
    socket.on("TaskRemoved", function (taskId) {
      console.log("---- taskId", taskId);
      dispatch(removeSingleTaskFromState(taskId));
      // dispatch(getTasks(window.location.pathname.split("/")[1]));
      // dispatch(getProjectById(window.location.pathname.split("/")[1])); //create issue to render all projects
    });

  }, []);

  const callback = () => {
    setSectionTitle("");
    setIsAddSectionVisible(false);
    setShowSection(false);
    dispatch(getProjectById(window.location.pathname.split("/")[1]));
  };

  const sectionHandler = (req, payload = []) => {
    switch (req) {
      case "addSection":
        if (sectionTitle !== "") {
          return dispatch(
            addSection(
              {
                title: sectionTitle,
                description: sectionDescription,
                project: window.location.pathname.split("/")[1],
              },
              callback,
              intl
            )
          );
        } else {
          return setSectionTitle("");
        }
      case "deleteSection":
        return dispatch(
          deleteSection(payload, () => {
            dispatch(getProjectById(window.location.pathname.split("/")[1]));
          }, intl)
        );

      default:
        break;
    }
  };

  const editSectionByProjectId = () => {
    dispatch(getProjectById(window.location.pathname.split("/")[1]));
  };

  const reorder = (section, startIndex, endIndex) => {
    const result = Array.from(section);
    const [removed] = result.splice(startIndex, 1);
    removed.position = endIndex;
    result.splice(endIndex, 0, removed);

    return result;
  };

  // const onDragEnd = (result) => {
  //   // dropped outside the list
  //   console.log('result', result.type)
  //   if (!result.destination) {
  //     return;
  //   }

  //   const sections = reorder(
  //     columns.sections,
  //     result.source.index,
  //     result.destination.index
  //   );

  //   setColumns({ sections });
  // };
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  return (
    <>
      {isLoading ? <div className="chat-room-width"><Loader size={25} isLoading={isLoading} /></div> :

        <div
          className="onResize-ticket-drawer"
          style={{
            background: "#FBFBFB"
          }}
        >
          <div className="w-full h-full">
            {/* ..........................Dynamic sections................................ */}

            <DragDropContext
              onDragEnd={(e) => {
                if (e.type === "COLUMN") {
                  const rearrangedSections = reorder(
                    sections,
                    e.source.index,
                    e.destination.index
                  );

                  socket.emit("rearrange-sections", { sections: rearrangedSections, projectId: props.projectData?._id, userId: loggedUser?._id });
                  setSections(rearrangedSections);
                  return;
                }
                const _id = e?.draggableId;
                const preSection = e.source?.droppableId;
                const section = e.destination?.droppableId;
                const lastIndex = e.source?.index;
                const newIndex = e.destination?.index;
                if (_id && preSection && section && typeof lastIndex == 'number' && typeof newIndex == 'number') {
                  const task = props.getTask.find(
                    (task) => task._id === e.draggableId
                  );

                  const data = {
                    _id: e.draggableId,
                    taskTitle: task.taskTitle,
                    preSection: e.source.droppableId,
                    section: e.destination.droppableId,
                    projectId: props.projectData?._id,
                    lastIndex: e.source.index,
                    newIndex: e.destination.index,
                    filters: props.filters,
                    userId: loggedUser?._id
                  };

                  dispatch(reorderTasks(e.source.droppableId, e.destination.droppableId, e.draggableId, e.source.index, e.destination.index, props.projectData?._id));

                  socket.emit("reorder-section", data);

                  setPlaceholderProps({});

                }
              }}
              onDragUpdate={(update) => {
                if (!update.destination) {
                  return;
                }
                const draggableId = update.draggableId;
                const initialIndex = update.source.index;
                const destinationIndex = update.destination.index;

                const domQuery = `[${queryAttr}='${draggableId}']`;
                const draggedDOM = document.querySelector(domQuery);

                if (!draggedDOM) {
                  return;
                }
                const { clientHeight, clientWidth } = draggedDOM;

                const arr = [...draggedDOM.parentNode.children];
                if (initialIndex < destinationIndex) {
                  arr.splice(initialIndex, 1);
                }

                const clientY =
                  parseFloat(
                    window.getComputedStyle(draggedDOM.parentNode).paddingTop
                  ) +
                  arr.slice(0, destinationIndex).reduce((total, curr) => {
                    const style =
                      curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                  }, 0);


                setPlaceholderProps({
                  clientHeight,
                  clientWidth,
                  clientY,
                  clientX: parseFloat(
                    window.getComputedStyle(draggedDOM.parentNode).paddingLeft
                  ),
                });
              }}
            >
              {/* <DragDropContext onDragEnd={onDragEnd}> */}
              <Droppable
                droppableId="droppable"
                type="COLUMN"
                direction="horizontal"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {sections.length
                      ? sections.map((section, sectionKey) => (
                        <Draggable
                          key={section._id}
                          draggableId={section._id}
                          index={sectionKey}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={sectionKey}
                              // style={{ marginLeft: '25px' }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                sectionKey
                              )}
                            >
                              <HeadingCard
                                phase={phase}
                                editSectionByProjectId={editSectionByProjectId}
                                setphase={setphase}
                                sectionKey={sectionKey}
                                projectId={props.projectData._id}
                                sectionTitle={sectionTitle}
                                setSectionTitle={setSectionTitle}
                                sectionHandler={sectionHandler}
                                id={section._id}
                                title={section?.title}
                                description={section?.description}
                                number={section?.tasks?.length}
                                color={section?.color}
                                placeholderProps={placeholderProps}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))
                      : ""}

                    {(isAddSectionVisible || showSection) && (
                      <div ref={sectionRef} onBlur={() => setShowSection(false)}>
                        <AddSection
                          color="#24CF73"
                          sectionHandler={sectionHandler}
                          sectionTitle={sectionTitle}
                          setSectionTitle={setSectionTitle}
                        />
                      </div>
                    )}
                    <div
                      className={`ml-auto ${(isAddSectionVisible || showSection) && 'hidden'}`}
                      id="section-add-wrapper"
                      ref={messagesEndRef}
                      style={{ marginTop: 8 }}
                    >
                      <Button
                        name={intl.formatMessage({ id: 'ADD_SECTION', defaultMessage: 'Add Section' })}
                        src="addblack.svg"
                        height="16px"
                        class="font-16"
                        onClick={() => setIsAddSectionVisible(true)}
                        style={{ width: 'max-content' }}
                        containerClass={"ml-auto"}
                        reference={messagesEndRef}
                      />
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <LeftChatIcon />
        </div>}</>
  );
};

function mapStateToProps(state) {
  return {
    toggle: state.navReducer.toggle,
    isTicketOpen: state.toggleReducer.isTicketOpen,
    projectData: state.projectReducer.getProjects,
    getTask: state.taskReducer.tasks,
    filters: state.taskReducer.filters
  };
}

export default connect(mapStateToProps)(TaskBoard);
