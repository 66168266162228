export const CHAT = 'CHAT';
export const COLLAPSE = 'collapse';
export const TICKET = 'TICKET';
export const IS_TASK_MODAL_OPEN = 'IS_TASK_MODAL_OPEN';
export const SET_DRAWER_WIDTH = 'SET_DRAWER_WIDTH';
export const TEAMS = 'TEAMS';
export const WORKSPACE = 'WORKSPACE';
export const AI = 'AI';
export const USER = 'USER';
export const MEMBER = 'MEMBER';
export const PROJECTS_JOIN = 'PROJECTS_JOIN';