import moment from "moment";
import dayjs from "dayjs";
import { getLocalizedShortDate } from "../_locale/dateLocales";

const arrMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const getDate = (UTF) => {
  if (!UTF) return;
  const date = moment(UTF);
  return date.format("DD/MM/YYYY");
};

export const getDateForCard = (UTF) => {
  if (!UTF) return;
  const dateObj = moment(UTF);
  const currentYear = moment().year();
  const day = dateObj.date();
  const month = dateObj.month();
  const year = dateObj.year();

  const dateText = getDateText(UTF, dateObj);
  if (["Today", "Tomorrow", "Yesterday", ...days].includes(dateText)) {
    return dateText;
  }

  const suffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  const formattedDate = `${arrMonth[month].substring(0, 3)} ${day}${suffix(day)}`;
  return year === currentYear ? formattedDate : `${formattedDate}, ${year}`;
};

export const getDateWithoutChangingTZ = (UTF) => {
  if (!UTF) return;
  return moment(UTF).format("DD/MM/YYYY");
};

export const dayMonth = (UTF) => {
  const date = moment(UTF);
  const day = date.date();
  const month = arrMonth[date.month()].substring(0, 3);
  return `${day} ${month}`;
};

export const getToday = () => moment().format("DD/MM/YYYY");

export const getYesterday = () => moment().subtract(1, "day").format("DD/MM/YYYY");

export const getTomorrow = () => moment().add(1, "day").format("DD/MM/YYYY");

export const getDateColor = (UTF) => {
  if (!UTF) {
    return;
  }
  const today = dayjs().startOf("day"); // Start of today's date
  const givenDate = dayjs(UTF);

  if (givenDate.isSame(today, "day")) {
    return "orange";
  } else if (givenDate.isBefore(today, "day")) {
    return "red";
  } else {
    return "grey";
  }
};

export const isItThisWeek = (date) => {
  const inputDate = moment(date, "DD/MM/YYYY");
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");
  return inputDate.isBetween(startOfWeek, endOfWeek, null, "[]");
};

export const isItLastWeek = (date) => {
  const inputDate = moment(date, "DD/MM/YYYY");
  const startOfLastWeek = moment().subtract(1, "week").startOf("week");
  const endOfLastWeek = moment().subtract(1, "week").endOf("week");
  return inputDate.isBetween(startOfLastWeek, endOfLastWeek, null, "[]");
};

export const getDateText = (date, dateObj) => {
  if (!date) return "No due date";

  const formattedDate = getDate(date);
  const today = getToday();
  const tomorrow = getTomorrow();
  const yesterday = getYesterday();

  if (formattedDate === today) return "Today";
  if (formattedDate === tomorrow) return "Tomorrow";
  if (formattedDate === yesterday) return "Yesterday";
  if (isItThisWeek(formattedDate)) return days[moment(date).day()];
  return formattedDate;
};

export const getDateTextLocalized = (dateString, intl) => {
  if (!dateString) return "No due date";

  const shortDate = getDate(dateString);

  const momentDate = moment(dateString, "DD/MM/YYYY").startOf('day');
  const now = moment().startOf('day');

  if (momentDate.isSame(now, 'day')) return intl.formatMessage({ id: 'TODAY', defaultMessage: 'Today' });
  if (momentDate.diff(now, 'days') === 1) return intl.formatMessage({ id: 'TOMORROW', defaultMessage: 'Tomorrow' });
  if (momentDate.diff(now, 'days') === -1) return intl.formatMessage({ id: 'YESTERDAY', defaultMessage: 'Yesterday' });

  const dateObject = new Date(dateString);

  if (isItLastWeek(shortDate)) return dateObject.toLocaleDateString(intl.locale, { weekday: "long" });

  return dateObject.toLocaleDateString(intl.locale, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  });
};

export const getDateTextLongFormat = (dateParam, intl) => {
  if (!dateParam) return "No date specified";

  const date = dateParam instanceof Date ? dateParam : new Date(dateParam);

  const shortDate = getDate(date);

  const momentDate = moment(date, "DD/MM/YYYY").startOf('day');
  const year = momentDate.year();
  const now = moment().startOf('day');

  if (momentDate.isSame(now, 'day')) return intl.formatMessage({ id: 'TODAY', defaultMessage: 'Today' });
  if (momentDate.diff(now, 'days') === 1) return intl.formatMessage({ id: 'TOMORROW', defaultMessage: 'Tomorrow' });
  if (momentDate.diff(now, 'days') === -1) return intl.formatMessage({ id: 'YESTERDAY', defaultMessage: 'Yesterday' });

  if (isItLastWeek(shortDate)) return date.toLocaleDateString(intl.locale, { weekday: "long" });

  const isSameYear = momentDate.isSame(now, 'year');
  switch (intl.locale) {
    case 'en-US': {
      const localizedShortDate = getLocalizedShortDate(date, intl, true);
      return isSameYear ? localizedShortDate : `${localizedShortDate}, ${year}`;
    }
    default: {
      return date.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "short",
        year: isSameYear ? undefined : 'numeric'
      })
    }
  }
};

export const getUserLicense = (user, organizationId) => {
  const userOrg = user?.organizations?.find(o => o?._id?.toString() == organizationId?.toString());
  if (!userOrg) {
    console.log("---- User is not part of selected org");
    return "Free Plan";
  }

  const licenseMap = {
    "enterprise": "Enterprise Plan",
    "business": "Business Plan",
    "free": "Free Plan",
    "team": "Team Plan"
  }

  return licenseMap[userOrg?.license?.name];
}
