import React, { useState } from 'react';

import { Radio } from 'antd';
import { getRandomColor } from '../../../../js/helper';
import '../../../../assets/style/global.css';

const SelectWorkspaceCard = (props) => {
  const { email,
    workspaces,
    handleSelectedWorkspace,
    isInvites,
    handleAcceptWorkspace,
    handleDeclineWorkspace
  } = props;

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState('');
  const [hover, setHover] = useState(0);

  const onWorkspaceSelect = (workspace) => {
    setSelectedWorkspaceId(workspace._id);
    handleSelectedWorkspace(workspace);
  }

  if (workspaces.length == 0) {
    return <></>;
  }

  return (
    <div className='select-workspace-card'>
      {workspaces.map((workspace, i) => {
        const isSelected = selectedWorkspaceId === workspace._id;
        const title = workspace.title || workspace.targetName || '';
        const iconLetters = title.slice(0, 2).toUpperCase() || '';
        return (
          <div
            onMouseOver={() => setHover(i)}
            onMouseLeave={() => setHover(-1)}
            className={`select-workspace-row d-flex w-full ${isSelected && 'selected'} ${isInvites && 'invites'}`}
            onClick={() => !isInvites && onWorkspaceSelect(workspace)}>
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <div className='square-letter-large'
                style={{
                  background: getRandomColor(i + 1),
                }}>
                {iconLetters}
              </div>
              <div style={{ marginLeft: 15 }}>
                <div style={{ fontSize: 16, fontWeight: 600 }}>{title}</div>
                <div className='font-14'>{workspace?.description}</div>
              </div>
            </div>
            {!isInvites ?
              (hover == i ? <button className='butn-primary font-14' style={{ marginRight: 16 }}>Open</button> : <></>) :
              <div className='workspace-invite-buttons'>
                <button className='butn-secondary font-14' style={{ marginRight: 16 }} onClick={() => handleDeclineWorkspace(workspace)}>Decline</button>
                <button className='butn-primary font-14' onClick={() => handleAcceptWorkspace(workspace)}>Accept</button>
              </div>}
          </div>
        );
      })}
    </div>
  );
};


export default SelectWorkspaceCard;
