import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateQueryState } from "../../../../actions";
import { clearMessagesState } from "../../../../actions/messageAction";
import { setRoomId } from "../../../../actions/roomAction";
import { RESET_COUNTER, UPDATE_CURRENT_RECEIVER } from "../../../../actions/types/chat.types";
import { updateChatRoomMessagesReadBy } from "../../../../actions/chatRoomAction";
import { Badge } from "antd";
import { getDateTextLongFormat } from "../../../../js/functions";
import UserAvatar from "../../atoms/ticket-drawer/UserAvatar";
import { useIntl } from 'react-intl';

const SearchChats = ({ chat, userId, loggedInUser, params }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDirectChat = chat.roomType === 'direct';
  const dmUser = chat.members?.find((member) => member._id !== userId);
  const name = isDirectChat ? dmUser?.fullName || intl.formatMessage({ id: 'USER', defaultMessage: 'User' }) : chat.roomName || '';
  const avi = isDirectChat ? dmUser?.avi : chat.roomAvi;
  const mostRecentMessage = chat.mostRecentMessage || '';

  if (!name || !avi) {
    return null;
  }

  const p = document.createElement('span');
  p.innerHTML = mostRecentMessage?.messageDescription || '';
  const htmlContent = p.textContent || p.innerText;

  const hasNotification = chat.count > 0;
  const sender = chat?.mostRecentMessage?.sender?._id === loggedInUser?._id ? intl.formatMessage({ id: 'YOU', defaultMessage: 'You' }) : chat?.mostRecentMessage?.sender?.fullName;

  return (
    <li
      key={chat?._id}
      className={`flex items-center shadow-sm p-4 mb-2 cursor-pointer bg-white hover:bg-gray-100 nav-bar-chat ${params?.chatId?.toString() === chat?._id?.toString() && 'nav-item-active'
        } ${hasNotification && 'font-bold'}`}
      onClick={() => {
        if (params?.chatId?.toString() !== chat?._id?.toString()) {
          dispatch(updateQueryState(1));
          dispatch(clearMessagesState());
          dispatch(setRoomId(chat._id));
          dispatch({ type: RESET_COUNTER, payload: chat._id });
          navigate(`/chat/${chat._id}`);
          dispatch({
            type: UPDATE_CURRENT_RECEIVER,
            payload: chat.roomName,
          });
        }
        dispatch(updateChatRoomMessagesReadBy(chat._id, 'ChatsNavBar1'));
      }}
    >
      {avi && (isDirectChat ? (
        <UserAvatar
          classNames="mr-3 w-10 h-10 rounded-full object-cover"
          fullName={dmUser?.fullName}
          userId={dmUser?._id}
          src={dmUser?.avi}
        />
      ) : (
        <img
          src={avi}
          alt="icon"
          className="mr-3 w-10 h-10 rounded-full object-cover border-2 border-gray-300"
        />
      ))}
      <div className="flex flex-col flex-1">
        <div className="flex justify-between items-center">
          <div className={`font-medium text-sm truncate ${hasNotification ? 'font-bold' : ''}`}>
            {name.substring(0, hasNotification ? 20 : 25)}
          </div>
          <div className="text-xs text-gray-500 ml-4">
            {getDateTextLongFormat(new Date(chat.lastUpdate), intl)}
          </div>
        </div>
        <div className="flex items-center text-xs text-gray-600 truncate max-w-full">
          {chat?.mostRecentMessage?.sender?.fullName && (
            <span className="font-semibold">{sender}:</span>
          )}
          <span className="ml-1 w-[100px]">
            {mostRecentMessage?.messageDescription
              ? htmlContent
              : intl.formatMessage({
                  id: 'BE_FIRST_TO_SEND',
                  defaultMessage: 'Be the first to send <span>{roomTitle}</span> a message!'
                }, {
                  span: (msg) => (<span style={{ fontWeight: 600 }}>{msg}</span>),
                  roomTitle: name
                })
            }
          </span>
        </div>
      </div>
      {hasNotification && (
        <div className="ml-2">
          <Badge
            count={chat.count || 0}
            style={{
              backgroundColor: '#FE3C32',
              boxShadow: 'none',
            }}
          />
        </div>
      )}
    </li>
  );
};


export default SearchChats;