import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Popover } from 'antd';
import _debounce from 'lodash/debounce';

import { TaskButtons } from '../../atoms/buttons/TaskButtons';
import Icon from '../../atoms/global/Icon';
import { customFieldTypes, fieldTypes } from '../../../../js/constants';
import PriorityDropdown from '../task-components/PriorityDropdown';
import AssigneeSelect from '../assignee/AssigneeSelect';
import CalendarDropdown from '../task-components/CalendarDropdown';
import { editTask } from '../../../../actions';
import { isValidURL } from '../../../../js/helper';
import { FormattedMessage, useIntl } from 'react-intl';

const TaskCustomFields = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();

  const [customFields, setCustomFields] = useState(props?.customFields || []);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const debouncedFilterTasks = useCallback(
    _debounce((id, value) => {
      handleChangeCustomField(id, value);
    }, 1000),
    []
  );

  useEffect(() => {
    setCustomFields(props?.customFields?.sort((a, b) => {
      return a?.name > b?.name;
    }));
  }, [props?.customFields]);

  const handleChangeCustomField = (customFieldId, val, e) => {
    if (e) {
      e.preventDefault()
    }
    if (loading) {
      return;
    }

    setLoading(true);
    dispatch(
      editTask({
        _id: props?.id,
        customFieldId,
        customFieldValue: val,
      },
        () => {
          setLoading(false);
        })
    );
  }

  const updateField = (id, value) => {
    const x = customFields?.map(f => {
      if (f?._id == id) {
        return { ...f, value: value };
      }
      return f;
    })

    setCustomFields(x);
    debouncedFilterTasks(id, value);
  }

  const generateFields = (field) => {
    switch (field?.type) {
      case customFieldTypes.cost:
      case customFieldTypes.text:
      case customFieldTypes.number:
        return (
          <div className='flex align-center'>
            <input
              style={{ padding: 8, fontSize: 14, width: '100%', paddingLeft: 22, color: field?.type == customFieldTypes?.cost && 'green' }}
              value={field?.value}
              type={field?.type == 'text' ? 'text' : 'number'}
              placeholder={intl.formatMessage({
                id: 'ENTER_FIELD',
                defaultMessage: 'Enter {field}'
              }, {
                field: intl.formatMessage({ id: `${field?.name}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: field?.name })
              })}
              onChange={(e) => {
                updateField(field?._id, e.target.value);
              }}
            />
          </div>
        );

      case customFieldTypes.dropdown:
      case customFieldTypes.status:
        const value = field?.possibleValues?.find(v => v?._id == field?.value);
        return (
          <Dropdown
            menu={{
              items: field?.possibleValues.map((val) => ({
                value: val?._id?.toString(),
                label: (
                  <div className='d-flex p-1 align-center'>
                    <div className={`font-14 ${value?._id == val?._id && 'text-blue-500'}`}>
                      <FormattedMessage id={`${val?.label}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={val?.label} />
                    </div>
                  </div>
                ),
                onClick: () => {
                  updateField(field?._id, val?._id?.toString());
                }
              })),
            }}
            trigger={['click']}
          >
            <div className='flex align-center justify-between text-center py-2 px-5'>
              <div className='font-14 truncate text-center capitalize'>
                {value?.label
                  ? <FormattedMessage id={`${value?.label}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={value?.label} />
                  : <FormattedMessage id="SELECT" defaultMessage="Select" />
                }
              </div>
              <img
                src={'/images/icons/black-arrow-down.svg'}
                style={{
                  cursor: 'pointer',
                  height: '10px',
                  width: '10px',
                }}
                alt='icon'
              />
            </div>
          </Dropdown>
        );

      case customFieldTypes.assignee:
        return (
          <div className='pl-5 w-full'>
            <AssigneeSelect showNames />
          </div>
        );

      case customFieldTypes.link:
        return (
          // isValidURL
          <div className='flex align-center' onMouseEnter={() => setOpen(field?._id)}>
            <Popover
              content={
                <div className='flex align-center cursor-pointer' onClick={() => {
                  if (isValidURL(field?.value)) {
                    window.open(field?.value, '_blank', 'noopener,noreferrer');
                  }
                }}>
                  <div className='text-blue-500 underline'>
                    <FormattedMessage id="OPEN_LINK" defaultMessage="Open Link" />
                  </div>
                  <Icon
                    className='h-6 w-6 ml-2'
                    name='paper-plane.svg'
                    onClick={() => {
                    }} />
                </div>
              }
              trigger="hover"
              open={open == field?._id && isValidURL(field?.value)}
              onVisibleChange={(e) => {
                setOpen(false);
              }}
            >
              <input
                style={{ padding: 8, fontSize: 14, width: '100%', paddingLeft: 22, color: isValidURL(field?.value) && 'blue' }}
                value={field?.value}
                type={'text'}
                placeholder={
                  intl.formatMessage({
                    id: 'ENTER_FIELD',
                    defaultMessage: 'Enter {field}'
                  }, {
                    field: intl.formatMessage({ id: `${field?.name}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: field?.name })
                  })
                }
                onChange={(e) => {
                  updateField(field?._id, e.target.value);
                }}
              />
            </Popover>
          </div>
        )

      case customFieldTypes.priority:
        return (
          <PriorityDropdown
            task={{ priority: field?.value || 'nill' }}
            onClick={(p) => {
              updateField(field?._id, p?.rank);
            }}
            isTask
            small
          />
        );

      case customFieldTypes.date:
        return (
          <div className='flex align-start pl-3 w-full'>
            <CalendarDropdown
              task={{
                dueDate: field?.value
              }}
              onClick={(p) => {
                updateField(field?._id, p);
              }}
              isTask
            />
          </div>
        );

      default:
        return (
          <div>--</div>
        );
    }
  }

  return (
    <div>
      {customFields?.map((field, index) => {
        const icon = fieldTypes?.find(f => f.key == field?.type)?.icon || 'custom-fields.svg';
        const color = fieldTypes?.find(f => f.key == field?.type)?.color || '#33fff8';
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottomWidth: index === customFields?.length - 1 ? 1.25 : 0,
              borderBottomColor: index === customFields?.length - 1 ? '#E0E4E6' : 'transparent',
              borderLeftWidth: 1.25,
              borderRightWidth: 1.25,
              borderColor: '#E0E4E6',
              borderTopWidth: 1.25,
              borderTopLeftRadius: index === 0 ? 12 : 0,
              borderTopRightRadius: index === 0 ? 12 : 0,
              borderBottomLeftRadius: index === customFields?.length - 1 ? 12 : 0,
              borderBottomRightRadius: index === customFields?.length - 1 ? 12 : 0,
              paddingVertical: 2
            }}>
            <Popover
              content={
                <div>
                  <div className='flex align-center my-4'>
                    <input className='border-2 p-1 pl-2' value={field?.name} />
                    <Icon
                      className='bg-slate-200 h-8 ml-2'
                      name='minimize.svg'
                      onClick={() => {
                      }} />
                  </div>
                  <TaskButtons onCancel={hide} onHandle={hide} />
                </div>
              }
              title={`${intl.formatMessage({ id: 'EDIT', defaultMessage: 'Edit' })} ${intl.formatMessage({ id: `${field?.name}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: field?.name })}`}
              trigger="click"
              open={false}
            // open={open == field?._id}
            //F onOpenChange={() => setOpen(field?._id)}
            >
              <div
                className='hover:bg-slate-100 cursor-pointer flex flex-row items-center gap-2'
                style={{
                  width: '50%',
                  borderRightWidth: 1.25,
                  borderColor: '#E0E4E6',
                  padding: '8px 8px 8px 10px',
                  fontSize: '15px'
                }}>
                <div style={{ backgroundColor: color }} className='size-6 flex-shrink-0 rounded-md flex justify-center items-center '>
                  <img
                    src={`/images/icons/${icon}`}
                    style={{
                      cursor: 'pointer',
                      height: '14px',
                      width: '14px',
                      filter: 'invert(1) brightness(180%)'
                    }}
                    alt='icon'
                  />
                </div>
                <div className='truncate ml-1'>
                  <FormattedMessage id={`${field?.name}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={field?.name} />
                </div>
              </div>
            </Popover>

            <div
              className='cursor-pointer'
              style={{
                borderColor: '#E0E4E6',
                textAlign: 'center',
                width: '49%'
              }}
            >
              {generateFields(field)}
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default TaskCustomFields;
