export function isValidEmail(email) {
  let regex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  return regex.test(email);
}

export function isValidURL(url) {
  try {
    new URL(url);

    const pattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return pattern.test(url);
  } catch (err) {
    return false;
  }
}