import { COLOURS, sectionColors } from "../constants"

export function getRandomColor(index) {
  if (index > -1) {
    return COLOURS[index % COLOURS.length];
  }
  return COLOURS[Math.floor(Math.random() * COLOURS.length)];
}

export function getSectionColor(index) {
  return sectionColors[index % sectionColors.length];
}