import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  editTask,
  getSingleTask,
  isTicketOpen,
  setTaskProperties,
} from '../../../../actions';
import { dayMonth } from '../../../../js/functions';
import { Calendar, Tooltip } from 'antd';
import socket from '../../../../js/socket';
import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/global/Icon';
import { useIntl } from 'react-intl';
import CalendarDropdown from '../task-components/CalendarDropdown';

const SubTask = (props) => {

  const {
    taskTitle,
    isCompleted,
    _id,
    parentTaskId,
    dueDate
  } = props?.subTask;

  const intl = useIntl();
  const [iseditingTitleEnabled, setIseditingTitleEnabled] = useState(false);
  const [isCalender, setIsCalender] = useState(false);
  const [titleValue, setTitleValue] = useState(taskTitle);
  const [completed, setCompleted] = useState(isCompleted);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   socket.on("UpdateTaskEvent", function (id) {
  //     // console.log(id);
  //     // dispatch(getSingleTask(parentTaskId));
  //   });
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editTask(
        {
          _id: _id,
          taskTitle: titleValue,
        },
        () => {
          dispatch(getSingleTask(parentTaskId));
        }
      )
    );
  };
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const refr = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isCalender && refr.current && !refr.current.contains(e.target)) {
        setIsCalender(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isCalender]);

  return (
    <li className="flex bg-white align-center px-2 justify-between border border-slate-300 rounded-lg py-2 w-full cursor-pointer hover:bg-gray-50" onBlur={() => setIseditingTitleEnabled(false)}>
      <div className='flex align-center w-80'>
        <div
          onClick={() => {
            dispatch(
              editTask(
                {
                  _id: _id,
                  isCompleted: !completed,
                },
              )
            );
            setCompleted(!completed)
          }}
          className={`size-5 ml-1 rounded-full flex align-center justify-center items-center ${completed ? 'bg-green-600' : 'border border-gray-400'} mr-2`}
        >
          <svg className={`size-2 fill-current ${completed ? 'text-white' : 'text-slate-900'} `} viewBox="0 0 12 12">
            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
          </svg>
        </div>
        {iseditingTitleEnabled ? (
          <form
            onSubmit={handleSubmit}
            onBlur={handleSubmit}
            style={{ display: 'inline' }}
            className='w-full'
          >
            <input
              type='text'
              value={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
              className='w-full px-2 bg-gray-100'
              style={{ border: 'none', outline: 'none' }}
              autoFocus
            />
          </form>
        ) : (
          <div
            onClick={() => setIseditingTitleEnabled(true)}
            onBlur={() => setIseditingTitleEnabled(false)}
            className={`text-[14px] text-slate-600 ${completed && 'text-slate-400 opacity-50 pl-1'}`}>
            {titleValue?.substring(0, 32) || '--'}
          </div>
        )}
      </div>
      <CalendarDropdown
        task={props?.subTask}
      />
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    taskProperties: state.taskReducer.taskProperties,
  };
};
export default connect(mapStateToProps)(SubTask);