import React from "react";

export const AuthInput = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  error,
  isPassword = false,
  togglePasswordVisibility,
  passwordVisible,
}) => (
  <div className="mt-5 relative">
    <label className="font-weight-500 font-16 text-darkGray">{label}</label>
    <div className={isPassword ? "set-icon" : "pos-relative"}>
      <input
        type={isPassword && passwordVisible ? "text" : type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={error ? "inputTag border-red mt-0" : "inputTag mt-0"}
      />
      {isPassword && (
        <img
          className="password-eye"
          src={passwordVisible ? "/images/icons/noEye.svg" : "/images/icons/eye.svg"}
          alt="toggle visibility"
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
    {error && (
      <div className="absolute  flex align-center d-error ">
        <img src="/images/icons/error.svg" className="size-3 mr-1" alt="error icon" />
        <label className="font-12 text-red-600">{error}</label>
      </div>
    )}
  </div>
);

