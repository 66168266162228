import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Mobile } from '../../pages/Mobile';
import SignUpForm from "../molecules/SignUpForm";
import Icon from '../atoms/global/Icon';
import { FormattedMessage } from "react-intl";

const SignUp = () => {
  const [signup, setSignup] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  if (localStorage.getItem('token') != undefined) {
    setTimeout(() => {
      navigate('/select-workspace', { replace: true });
    }, 100);
  }


  const isMobile = window.screen?.width < 700;

  if (isMobile) {
    return <Mobile />
  }


  return (
    <div className='flex align-center justify-between h-[100vh]'>
      <div className="w-2/5 h-full p-5">
        <Icon
          name='logo.svg'
          height='26px'
          pr='20px' />
        <div className="flex flex-col align-center justify-center mt-24">
          <h1 className="text-darkGray font-40 text-center mb-2">
            <FormattedMessage id="WELCOME" defaultMessage="Welcome to Workflo!" /></h1>
          <SignUpForm setSignup={setSignup} setEmail={setEmail} />
        </div>
      </div>
      <div className="w-3/5 bg-blue-500 h-[97vh] mx-5 rounded-xl flex align-center justify-center">
        <div className="flex flex-col align-center justify-center">
          <div className="mb-20 text-4xl font-bold text-white text-center mx-10">
            <FormattedMessage id="WORKFLO_ADDVERTISEMENT_PHRASE_2" defaultMessage="Productivity Tailored To You" />
          </div>
          <img src="https://939023.p3cdn1.secureserver.net/wp-content/uploads/2024/12/Group-5141856-1.png" width={'95%'} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
