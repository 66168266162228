import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { joinProject } from '../../../actions';
import GroupAvatar from '../../../../src/components/UI/atoms/side-navbar/GroupAvatar.jsx';


const JoinProject = ({ project, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    access,
    requestedAccess = false,
    updatedAt,
    participants
  } = project;

  const isPublic = access == 'public';

  const onClick = () => {
    dispatch(joinProject({
      projectId: project?._id,
      role: 'member',
      isPublic
    }));
  }

  const date = new Date(updatedAt).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });

  return (
    <div onClick={() => onClick()} className='bg-slate-100 border hover:bg-slate-50 cursor-pointer p-3 m-2 rounded-xl'>
      <div className='w-full'>
        <div className='font-20 font-black'>{title}</div>
        <div className='text-sm font-light'>Last updated: {date}</div>
      </div>
      <div className='w-full h-[1px] bg-slate-300 my-2' />
      <div className='flex align-center justify-between w-full pt-1'>
        <div
          className='text-sm p-1 px-3 rounded-md capitalize text-grey-200 mr-3 w-20 text-center'
          style={{ background: requestedAccess ? 'lightgrey' : (isPublic ? '#bcedbc' : '#bbeae9') }}>
          {requestedAccess ? 'Pending' : access}
        </div>
        <GroupAvatar
          participants={participants}
          size={28}
        />
      </div>
    </div>
  );
};

export default JoinProject;