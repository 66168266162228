import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

import SearchInput from '../../../../src-new/components/inputs/SearchInput';
import { NoSearchResultsFound } from '../search/NoSearchResultsFound';
import { isMemberDrawerOpen, searchWorkspace } from '../../../../actions';
import { Skeleton, Tooltip, Popover } from 'antd';
import UserAvatar from '../../atoms/ticket-drawer/UserAvatar';
import { FormattedMessage } from 'react-intl';

const WorkspaceSearchBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialSearchTerm = queryParams.get('query') || '';

  const [searchInput, setSearchInput] = useState(initialSearchTerm);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);

  const [taskResults, setTaskResults] = useState([]);
  const [projectResults, setProjectResults] = useState([]);
  const [membersResults, setMembersResults] = useState([]);
  const [chatResults, setChatResults] = useState([]);

  const [selectedPill, setSelectedPill] = useState('');

  const { search } = useSelector((state) => state.workspaceReducer);

  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    setTaskResults(search?.tasks);
    setProjectResults(search?.projects);
    setMembersResults(search?.users);
    setChatResults(search?.messages);
  }, [search]);

  const filterTasks = (value) => {
    setSearchInput(value);
    setShowSearchDropDown(!!value);
    setIsLoading(true);
    dispatch(searchWorkspace(value));
  };

  useEffect(() => {
    if (search) {
      setIsLoading(false);
    }
  }, [search]);

  const debouncedFilterTasks = useCallback(
    _debounce((value) => {
      filterTasks(value);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    debouncedFilterTasks(value);
  };

  const handleButtonClick = () => {
    setShowSearchDropDown(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  };

  const pillClass = 'mx-1 border-2 hover:border-blue-400 hover:text-blue-500 cursor-pointer px-4 py-1 font-14 rounded-2xl';
  const noResults = taskResults?.length == 0 && projectResults?.length == 0 && membersResults?.length == 0; // && chatResults?.length == 0;

  const selectPill = (i) => {
    if (i == selectedPill) {
      setSelectedPill(0);
      return;
    }
    setSelectedPill(i);
  }

  return (
    <div className='relative bg-white'>
      <Popover
        content={
          <div className='w-[30vw]'>
            {isLoading ? (
              <div className="space-y-3 my-4 px-4">
                <Skeleton
                  active
                  paragraph={{ rows: 3, width: ['100%', '100%', '100%'] }}
                  title={false}
                />
              </div>
            ) : (
              <div>
                {searchInput && taskResults?.slice(0, selectedPill == 1 ? 8 : (selectedPill == 0 ? 3 : 0)).map((task, i) => (
                  <>
                    {i == 0 && <p className='text-xs pl-2 text-gray-500 bg-gray-100 py-2'>
                      <FormattedMessage id="LATEST_TASKS" defaultMessage="Latest tasks" />
                    </p>}
                    <div
                      key={i}
                      className="search-input-row"
                      onClick={() => {
                        const params = new URLSearchParams(location.search);
                        params.delete('msgId'); // Remove the specified parameter
                        params.set('selectedTask', task?.titleIdentifier)
                        // Construct the new URL with updated parameters
                        const newUrl = `${location.pathname}?${params.toString()}`;
                        // Use navigate to replace the current URL
                        navigate(newUrl);
                        setSearchInput('');
                        setTaskResults([]);
                        setShowSearchDropDown(false);
                      }}
                    >
                      <div className="search-task-title">{task.taskTitle}</div>
                      <div className="font-10">{task.titleIdentifier}</div>
                    </div>
                  </>
                ))}

                {searchInput && projectResults?.slice(0, selectedPill == 3 ? 8 : (selectedPill == 0 ? 3 : 0)).map((project, i) => (
                  <>
                    {i == 0 && <p className='text-xs pl-2 text-gray-500 bg-gray-100 py-2'>
                      <FormattedMessage id="PROJECTS" defaultMessage="Projects" />
                    </p>}
                    <div
                      key={i}
                      className="search-input-row"
                      onClick={() => {
                        navigate(`/${project?._id}/board`);
                        setShowSearchDropDown(false);
                      }}
                    >
                      <div className="search-task-title">{project?.title}</div>
                      <div className="font-10"><FormattedMessage id={`${project?.access}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={project?.access} /></div>
                    </div>
                  </>
                ))}

                {searchInput && membersResults?.slice(0, selectedPill == 4 ? 8 : (selectedPill == 0 ? 3 : 0)).map((member, i) => (
                  <>
                    {i == 0 && <p className='text-xs pl-2 text-gray-500 bg-gray-100 py-2'>
                      <FormattedMessage id="MEMBERS" defaultMessage="Members" />
                    </p>}
                    <div
                      key={i}
                      className="search-input-row"
                      onClick={() => {
                        dispatch(isMemberDrawerOpen(true, { member }));
                        setShowSearchDropDown(false);
                      }}
                    >
                      <div className='flex align-center w-full'>
                        <UserAvatar
                          classNames="h-5 w-5 rounded-full mr-2"
                          fullName={member?.fullName}
                          userId={member?._id}
                          src={member?.avi}
                        />
                        <div className="search-task-title">{member?.fullName}</div>
                      </div>
                      <div className="font-10">{member?.email}</div>
                    </div>
                  </>
                ))}

                {/* {searchInput && chatResults?.slice(0, selectedPill == 2 ? 8 : (selectedPill == 0 ? 3 : 0)).map((message, i) => {
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = message?.messageDescription;
                  const textContent = tempDiv.textContent || tempDiv.innerText || "";
                  const lines = textContent?.split("\n").map(line => line.trim()).filter(Boolean); // Clean and filter empty lines
                  const m = lines?.slice(0, 1).join("\n");
                  return (
                    <>
                      {i == 0 && <p className='text-xs pl-2 text-gray-500 bg-gray-100 py-2'>Messages</p>}
                      <div
                        key={i}
                        className="search-input-row"
                        onClick={() => {

                        }}
                      >
                        <div className="search-task-title">{m}</div>
                      </div>
                    </>
                  )
                })} */}

                {noResults && <NoSearchResultsFound />}

              </div>
            )}
            <div className="bg-gray-100 text-xs py-2 px-4 flex align-center justify-between mt-4">
              <div>
                <FormattedMessage id="NOT_EXPECTED_RESULT" defaultMessage="Not the results that you expected?" />
                
              </div>
              <div
                className="text-blue-500 cursor-pointer"
                onClick={() => {
                  const url = 'https://workflo.canny.io/bugs-and-feature-requests'
                  window.open(url, '_blank').focus();
                }}>
                  <FormattedMessage id="GIVE_FEEDBACK" defaultMessage="Give Feedback" />
                
              </div>
            </div>
          </div>
        }
        title={
          <div className='flex align-center'>
            <div className={`${selectedPill == 1 && 'border-blue-500'} ${pillClass}`} onClick={() => selectPill(1)}>
              <FormattedMessage id="TASKS" defaultMessage="Tasks" />
            </div>
            {/* <div className={`${selectedPill == 2 && 'border-blue-500'} ${pillClass}`} onClick={() => selectPill(2)}>Chats</div> */}
            <div className={`${selectedPill == 3 && 'border-blue-500'} ${pillClass}`} onClick={() => selectPill(3)}>
              <FormattedMessage id="PROJECTS" defaultMessage="Projects" />
            </div>
            <div className={`${selectedPill == 4 && 'border-blue-500'} ${pillClass}`} onClick={() => selectPill(4)}>
              <FormattedMessage id="MEMBERS" defaultMessage="Members" />
            </div>
          </div>
        }
        trigger="click"
        arrow={false}
        open={showSearchDropDown}
        // open={open == field?._id}
        onOpenChange={() => setShowSearchDropDown(true)}
        onVisibleChange={(t) => setShowSearchDropDown(t)}
      >
        <div className='w-full'>
          <SearchInput
            value={searchInput}
            onChange={handleInputChange}
            onClick={handleButtonClick}
          />
        </div>
      </Popover>
    </div>
  );
};

export default WorkspaceSearchBar;
