import React from 'react';
import { connect, useDispatch } from 'react-redux';
import TeamDrawerNav from '../molecules/team-drawer/TeamDrawerNav';
import TeamDrawerBody from '../molecules/team-drawer/TeamDrawerBody';
import { getUsers, updateProjectUsers } from '../../../actions';

const TeamDrawer = (props) => {
  const dispatch = useDispatch();

  const { allUsers, projectData, currentWorkspace, inviteDetails, showInvites = false } = props;

  return (
    <section className='team-drawer pt-4'>
      <TeamDrawerBody
        participants={inviteDetails?.existingMembers || projectData?.participants}
        allUsers={allUsers}
        projectId={projectData?._id}
        workspaceId={currentWorkspace?._id}
        inviteDetails={inviteDetails}
        showInvites={showInvites}
      />
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: () => dispatch(getUsers()),
});
const mapStateToProps = (state) => ({
  allUsers: state?.userReducer?.users,
  currentWorkspace: state?.workspaceReducer?.currentWorkspace,
  inviteDetails: state?.inviteReducer?.inviteDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDrawer);
