import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const IconAndName = (props) => {
  const intl = useIntl();
  const path = window.location.pathname;

  return (
    <div
      className={`${props.phase === 'sideNav' ? 'd-flex' : 'd-flex align-center'
        } ${props.classesForParentDiv} ${path === props?.path && 'nav-item-active'} relative`}
      style={{
        marginBottom: props.mb,
        marginRight: props.mr,
        cursor: 'pointer',
        width: props.width,
      }}
      onClick={() => {
        props?.onPress?.();
      }}
    >
      <img
        style={{ height: props.height, width: props.width, maxWidth: props.width }}
        src={'/images/icons/' + props.src}
        alt="icon"
      />
      <div
        className={props.class}
        style={{
          paddingLeft: '12px',
          color: props.color,
          paddingTop: props.namePT,
          opacity: 0.9
        }}
      >
        {props.name}
      </div>
     {props.name == intl.formatMessage({ id: 'WORKFLO_AI', defaultMessage: 'Workflo AI' }) && <div className='absolute right-3 px-2 py-[2px] bg-orange-300 rounded-md font-12 text-black'>
        <FormattedMessage id="BETA" defaultMessage="Beta" />
      </div>}
    </div>
  );
};

export default IconAndName;
