import React from "react";
import UserAvatar from "../ticket-drawer/UserAvatar";

const Avatars = ({ participants = [], count = 7, size = 32, showNames = false, children = <></> }) => {
  const overflowCount = participants?.length - count - 1;

  return (
    <div className={`avatar-group ${showNames && 'flex-wrap gap-2'}`}>
      {participants?.map((participant, i) => {
        if (i > count) return null;

        return (
          <div
            key={`${participant?._id}-${i}`}
            className="avatar"
            style={{
              height: size,
              marginRight: -8,
              zIndex: overflowCount > 0 && 1
            }}
          >
            <UserAvatar
              tooltip={participant?.label || participant?.fullName}
              alt="Avatar"
              classNames="avatar-image"
              fullName={participant?.label || participant?.fullName}
              userId={participant?.value || participant?._id}
              src={participant?.avi || participant?.assigneeId?.avi}
              styles={{
                height: size,
                width: size,
                borderRadius: "50%",
                border: "2px solid white",
                backgroundColor: "#F6F6F6",
              }}
            />
            {showNames && <div className="ml-1 mr-3 text-[#002337] ">{participant?.label}</div>}
          </div>
        );
      })}
      {children}
      {overflowCount > 0 && (
        <div
          className="secondary-avatar blue-pill"
          style={{
            height: size / 1,
            width: size * 1.8,
            fontSize: size / 2.2,
          }}
        >
          +{overflowCount.toString().padStart(2, "0")}
        </div>
      )}
    </div>
  );
};

export default Avatars;
