import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { InputNumber, Slider } from 'antd';

import {
  getPlanDetails
} from '../UI/molecules/pricing/data';

import Plan from '../UI/molecules/pricing/Plan';
import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'

import { createCheckoutSession } from '../../actions';
import { APPLICATION_NAME } from '../../js/constants';
import { getUserLicense } from '../../js/functions';
import { FormattedMessage } from 'react-intl';

const PricingUsers = () => {

  const navigate = useNavigate();
  document.title = `Pricing - ${APPLICATION_NAME}`
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(1);

  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  const isMonthly = useSelector((state) => state.paymentReducer?.plan?.isMonthly);
  const planPrice = useSelector((state) => state.paymentReducer?.plan?.price);
  const loggedInUser = useSelector(state => state.userReducer.user);
  const license = getUserLicense(loggedInUser, sessionStorage.getItem("organization") || localStorage.getItem("organization"))

  const {
    teamPlanDetails,
  } = getPlanDetails(dispatch, navigate, isMonthly, planPrice, license);


  const priceTable = () => (
    <div className="pricing-table">
      <Plan
        plan={teamPlanDetails}
      />
      <div className='flex flex-col'>
        <div className='user-quanitity-select' style={{ paddingTop: 30 }}>
          <div>
            <div className='font-24 text-center'>
              <FormattedMessage id="SELECT_AMOUNT_OF_SEATS" defaultMessage="Select the amount of Seats" />
            </div>
            <div className='font-12 text-center' style={{ margin: '20px 0' }}>
              <FormattedMessage id="SELECT_AMOUNT_OF_SEATS_DESCRIPTION" defaultMessage="Seats are the amount of people who you are part of your company" />
            </div>
            <div className='price-checkout'>
              ${Math.round((planPrice * inputValue) * 100) / 100} <span className='font-12'>USD</span>
              <div className="md:text-sm text-xs">
                {isMonthly
                  ? <FormattedMessage id="PER_MEMBER_PER_MONTH" defaultMessage="Per member, per month (before tax)" />
                  : <FormattedMessage id="PER_MEMBER_PER_YEAR" defaultMessage="Per member, per year (before tax)" />
                }
              </div>
            </div>
            <div className='d-flex align-center' style={{ justifyContent: 'center' }}>
              <div className='font-16'><FormattedMessage id="SEATS_NUMBER" defaultMessage="Seats" />:</div>
              <InputNumber
                min={1}
                max={60}
                style={{ margin: '0 16px' }}
                value={inputValue}
                onChange={onChange}
              />
            </div>
            <Slider
              min={1}
              max={60}
              onChange={onChange}
              value={typeof inputValue === 'number' ? inputValue : 0}
            />
          </div>
          <div className=' flex justify-center'>
            <div
              onClick={() => {
                document.title = `Payment - ${APPLICATION_NAME}`

                if (!localStorage.getItem('token')) {
                  navigate('/signin');
                } else {
                  dispatch(createCheckoutSession({
                    organizationId: sessionStorage.getItem('organization') || localStorage.getItem('organization'),
                    license: 'team',
                    frequency: isMonthly ? 'monthly' : 'yearly',
                    seats: inputValue
                  }));
                }
              }}
              className="pricing-button-primary">
              <FormattedMessage id="GO_TO_CHECKOUT" defaultMessage="Go to Checkout" />
            </div>
          </div>
        </div>
        <div className='font-10 mt-5 ml-3'>
          <FormattedMessage
            id="GO_TO_CHECKOUT_NOTE"
            defaultMessage="By clicking 'Go to Checkout' you agree to our <a>Terms of Service</a>"
            values={{
            a: (msg) => (
              <span className='link-button'
                onClick={() => window.open('https://workflo.com/terms-and-conditions/', '_blank').focus()} >
                {msg}
              </span>
            )
            }}
          />
        </div>
      </div>
    </div >
  )

  return (
    <div
      className='w-full'
      style={{ background: '#fafafa', flexDirection: 'column', height: '100vh' }}>
      <ProductNavBar />
      <div className='pt-1 pricing-container w-full h-full' style={{ flexDirection: 'column', overflowY: 'scroll', background: 'linear-gradient(to right, rgba(4, 115, 204, 0.03), rgba(4, 115, 204, 0))' }}>
        <h3 className="pricing-title mt-1">
          <FormattedMessage id="CHOOSE_A_PLAN" defaultMessage="Choose A Plan That Suits You" />
        </h3>
        {priceTable()}
      </div>
    </div>
  );
};

export default PricingUsers;
