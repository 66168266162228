import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { FormattedMessage, useIntl } from 'react-intl';

Chart.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  cutout: '83%',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

export const DashboardChart = ({ taskCount, data }) => {
  const intl = useIntl();
  const chartRef = useRef(null); // Reference to the chart instance

  // Re-render the chart on taskCount change
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.plugins.centerText = { taskCount }; // Update plugin data
      chart.update(); // Force chart to re-draw
    }
  }, [taskCount]);

  return (
    <div className='flex lg:flex-col sm:flex-row flex-col items-center justify-center'> 
      <div className="2xl:size-[13.5rem] xl:size-36 size-40 flex align-center justify-center items-center" >
        <Doughnut
          ref={chartRef}
          data={data}
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              centerText: { taskCount },
            },
          }}
          plugins={[
            {
              id: 'centerText',
              beforeDraw: (chart) => {
                const { width, height } = chart;
                const ctx = chart.ctx;
                ctx.restore();

                const fontSize = (height / 5).toFixed(2);
                ctx.font = `bold ${fontSize}px sans-serif`;
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';

                const { taskCount: currentTaskCount } = chart.options.plugins.centerText;
                const textNumber = currentTaskCount !== undefined ? currentTaskCount : '0';
                const textLabel = intl.formatMessage({ id: 'TOTAL_TASKS', defaultMessage: 'Total Tasks' });

                ctx.fillStyle = '#333';
                ctx.fillText(textNumber, width / 2, height / 2 - 10);

                ctx.font = `${(height / 10).toFixed(2)}px sans-serif`;
                ctx.fillStyle = '#666';
                ctx.fillText(textLabel, width / 2, height / 2 + 20);

                ctx.save();
              },
            },
          ]}
        />
      </div>
      <div className="flex lg:flex-row flex-col lg:ml-0 md:ml-8  flex-wrap my-1 gap-2 justify-center items-center px-2 lg:text-[14px] text-sm">
        <div className="flex flex-row gap-1 justify-center items-center">
          <div className="size-4 rounded mr-1 bg-[#FF974E]"></div><FormattedMessage id="INCOMPLETE" defaultMessage="Incomplete" />
        </div>
        <div className="flex flex-row gap-1 justify-center items-center m-5">
          <div className="size-4 rounded mr-1 bg-[#01A894]"></div><FormattedMessage id="COMPLETED" defaultMessage="Completed" />
        </div>
        <div className="flex flex-row gap-1 justify-center items-center">
          <div className="size-4 rounded mr-1 bg-[#FF4C4C]"></div><FormattedMessage id="UNASSIGNED" defaultMessage="Unassigned" />
        </div>
      </div>
    </div>
  );
};
