import { Tooltip, Badge } from 'antd';
import React from 'react';

import IconAndName from './IconAndName';

const NavTooltip = (props) => {

  const { tooltipComponent,
    classesForParentDiv,
    name,
    src,
    onClick,
    notificationCount,
  } = props;

  let hideTooltip = false;
  if (classesForParentDiv?.includes('selected')) {
    hideTooltip = true;
  }

  return (
    <Tooltip
      color='none'
      placement='right'
      title={!hideTooltip && tooltipComponent &&
        <div className='nav-tooltip' style={{ ...props.style, color: 'black', maxHeight: '55vh', height: 'fit-content' }}>
          {tooltipComponent}
        </div>
      }>
      <div style={{ position: 'relative' }}>
        <IconAndName
          name={name}
          phase='sideNav'
          src={`${src}.svg`}
          height={24}
          width={50}
          classesForParentDiv={classesForParentDiv}
          class={"navLinks"}
          iconFuction={onClick}
        />
       {notificationCount > 0 && <Badge
          className='tooltip-notification-bubble'
          count={notificationCount}
          style={{
            boxShadow: 'none',
            padding: 0
          }}
        />}
      </div>
    </Tooltip>
  );
};

export default NavTooltip;
