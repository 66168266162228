const data = {
  newTaskModal: {
    show: false,
    title: ''
  },
  teamsChatModal: {
    show: false,
    isTeams: false,
  },
  newProjectModal: {
    show: false,
    team: ''
  },
  customFieldsModal: {
    show: false,
    data: {}
  },
  workspaceFieldsModal: {
    show: false,
    selectedProject: ''
  },
  duplicateProjectModal: {
    show: false,
    selectedProject: ''
  },
};

const modalReducer = (state = data, action) => {
  switch (action.type) {
    case "toggle-new-task-modal":
      return {
        ...state,
        newTaskModal: {
          show: action.payload.value,
          title: action.payload?.title || '',
        },
      };
    case "toggle-new-project-modal":
      return {
        ...state,
        newProjectModal: {
          show: action.payload.value,
          team: action.payload?.team
        },
      };
    case "toggle-new-teams-chats-modal":
      return {
        ...state,
        teamsChatModal: {
          show: action.payload.value,
          isTeams: action.payload?.isTeams || false,
        },
      };
    case "toggle-custom-fields-modal":
      return {
        ...state,
        customFieldsModal: {
          show: action.payload?.value,
          data: action.payload?.data || {}
        },
      };
    case "toggle-workspace-fields-modal":
      return {
        ...state,
        workspaceFieldsModal: {
          show: action.payload?.value,
          selectedProject: action.payload?.projectId,
        },
      };
    case "toggle-duplicate-project-modal":
      return {
        ...state,
        duplicateProjectModal: {
          show: action.payload?.value,
          selectedProject: action.payload?.project,
        },
      };
    case 'reset-drawer': {
      return {
        ...data
      }
    }

    default:
      return state;
  }
};
export default modalReducer;
