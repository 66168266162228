import React, { useEffect } from "react";

export const MemberActionsMenu = ({ isMenuOpen, closeMenu, menuRef, actions, positionStyle }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef?.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, menuRef, closeMenu]);
  if (!isMenuOpen) return null;

  return (
    <div style={positionStyle} ref={menuRef} className="absolute sm:text-nowrap text-wrap sm:w-auto w-52 inline-block overflow-hidden bg-white shadow-lg rounded-lg z-50 ">
      {actions.map((action, index) => {
        return (
          <>
            <div
              key={index}
              className="flex flex-grow items-center px-4 py-3 cursor-pointer hover:bg-gray-100 sm:pr-14 pr-1"
              onClick={() => {
                action?.onClick();
                closeMenu();
              }}
            >
              <img
                src={action?.icon}
                alt={`${action?.label} icon`}
                className="sm:size-7 size-5 sm:mr-3 mr-1"
              />
              <div className="flex  flex-col">
                <p className="md:text-sm text-xs text-gray-700">{action?.label}</p>
                <p className="text-xs text-gray-700 sm:flex hidden">{action?.description}</p>
              </div>
            </div>
            {index !== actions.length - 1 && (
              <div className="bg-slate-100 w-full h-[1px]" />
            )}
          </>
        )
      })}
    </div>
  );
};
