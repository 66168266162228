import { Dropdown, Skeleton, Tooltip } from 'antd'
import React from 'react'

import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { removeTaskFromProject } from '../../../../actions'
import { isListView } from '../../../../actions/toggleAction'
import Icon from '../../atoms/global/Icon'
import { FormattedMessage, useIntl } from 'react-intl'


export const TaskCardProjects = ({ projects, task, createSectionDropdown, setProjects }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {projects?.map((prj, idx) => {
        if (!prj?.task) {
          return (
            <div className='col-span-full'>
              <Skeleton
                style={{
                  borderRadius: 6,
                  marginTop: 5,
                }}
                active={true}
                paragraph={{ rows: 1, width: ['100%'] }}
                title={false} />
            </div>
          )
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottomWidth: projects?.length - 1 == idx ? 1.25 : 0,
              borderBottomColor: projects?.length - 1 == idx ? '#E0E4E6' : 'transparent',
              borderLeftWidth: 1.25,
              borderRightWidth: 1.25,
              borderColor: '#E0E4E6',
              borderTopWidth: 1.25,
              borderTopLeftRadius: idx == 0 ? 12 : 0,
              borderTopRightRadius: idx == 0 ? 12 : 0,
              borderBottomLeftRadius: projects?.length - 1 == idx ? 12 : 0,
              borderBottomRightRadius: projects?.length - 1 == idx ? 12 : 0,
              paddingVertical: 2
            }}>
            <div className="flex flex-row items-center border-r border-r-gray-200 px-2 cursor-pointer w-[50%]">
              {/* Delete Project  */}
              {projects?.length > 1 && (
                <Tooltip title={intl.formatMessage({ id: 'REMOVE_PROJECT_FROM_TASK', defaultMessage: 'Remove Project From Task' })}>
                  <div
                    className="py-1 mx-1 align-center flex justify-center"
                    onClick={async () => {
                      const x = projects.splice(idx + 1, 1);
                      setProjects(x);
                      await dispatch(removeTaskFromProject(task?._id, prj?.project?._id));
                    }}
                  >
                    <img
                      className="text-slate-700 cursor-pointer size-2 mr-2"
                      src={"/images/icons/close.svg"}
                      alt="icon"
                    />
                  </div>
                </Tooltip>
              )}
              <div className="h-6 w-6 flex align-center justify-between rounded-md mr-2" style={{ backgroundColor: prj?.metadata?.colour }}>
                <img
                  src={`/images/icons/board.svg`}
                  style={{
                    cursor: 'pointer',
                    height: '14px',
                    width: '14px',
                    marginLeft: 5,
                  }}
                  alt='icon'
                />
              </div>
              <div
                className=" text-gray-600 md:text-sm text-xs truncate hover:text-blue-500 hover:underline"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  const selectedTask = new URLSearchParams(location.search).get(
                    "selectedTask"
                  );

                  const id = prj.project?._id;
                  let path = `/${id}/${isListView ? "list" : "board"}`;

                  if (selectedTask) {
                    path = `/${id}/${isListView ? "list" : "board"}?selectedTask=${selectedTask}`;
                  }
                  navigate(path);
                }}
              >
                {prj?.project?.title}
              </div>
            </div>
            <div className="truncate px-4 w-[50%]">
              <Dropdown
                className={`flex align-center justify-between cursor-pointer`}
                trigger={["click"]}
                menu={{
                  items: createSectionDropdown(prj?.project?.sections, prj),
                }}
                placement="bottom"
              >
                <div className='flex align-center justify-between'>
                  <span
                    className="text-gray-600 md:text-sm text-xs py-2.5 truncate"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <FormattedMessage id={`${prj?.section?.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={prj?.section?.title} />
                  </span>
                  <img
                    src={'/images/icons/black-arrow-down.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '10px',
                      width: '10px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>
            </div>
          </div>
        )
      })}
    </>
  )
}