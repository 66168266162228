import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { notification, Dropdown } from 'antd';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';
import { getRandomColor } from '../../../../js/helper';
import {
  removeMemberFromTeam,
  updateWorkspaceUsers,
  updateProjectUsers,
  updateTeamUsers,
  approveAccessRequest,
  denyAccessRequest,
  removeMemberFromProject,
  getProjectById
} from '../../../../actions';
import Icon from '../../atoms/global/Icon';

import Button from "../../atoms/global/Button";
import EmptyState from '../../atoms/empty-state/EmptyState';
import { FormattedMessage, useIntl } from 'react-intl';

const DashboardMembers = (props) => {
  const {
    data,
    phase,
    onAddMembers,
    invites,
    requests,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const User = useSelector((state) => state.userReducer.user);

  const [open, setOpen] = useState(false);
  const [selectedUser, setUser] = useState();

  const deleteUser = async () => {
    if (phase === 'project') {
      await dispatch(removeMemberFromProject({
        projectId: data?._id,
        members: [
          selectedUser
        ]
      }, () => {
        dispatch(getProjectById(data._id))
      }))
    } else if (phase === 'team') {
      await dispatch(removeMemberFromTeam({
        teamId: data._id,
        members: [
          selectedUser
        ]
      }));
    }
    setOpen(false);
  };

  const me = data?.members?.find(member => member._id === User._id);
  const role = me?.role || '';

  let title = intl.formatMessage({ id: 'TEAM_MEMBERS_AND_ROLES', defaultMessage: 'Team Members and Roles' });
  let isEditable = false;

  if (role === 'owner' || role === 'admin') {
    isEditable = true;
  }

  if (phase === 'project') {
    title = intl.formatMessage({ id: 'PROJECT_MEMBERS_AND_ROLES', defaultMessage: 'Project Members and Roles' });
  } else if (phase === 'workspace') {
    title = intl.formatMessage({ id: 'WORKSPACE_MEMBERS_AND_ROLES', defaultMessage: 'Workspace Members and Roles' });
  }

  const updateRole = async (role, userId) => {

    let newRole = {
      "users": [
        {
          "userId": userId,
          "role": role
        }
      ]
    }

    if (phase === 'workspace') {
      newRole.workspaceId = data._id;
      await dispatch(updateWorkspaceUsers(newRole));
    } else if (phase === 'team') {
      newRole.teamId = data._id;
      await dispatch(updateTeamUsers(newRole));
    } else {
      newRole.projectId = data._id;
      await dispatch(updateProjectUsers(newRole));
    }
  }

  return (
    <>
      <ConfirmationModal
        title={intl.formatMessage({ id: `REMOVE_FROM_${phase.toUpperCase()}`, defaultMessage: `Remove from ${phase}?` })}
        description={intl.formatMessage({ id: 'REMOVE_MEMBER_CONFIRMATION', defaultMessage: 'Are you sure you want to remove a member?' })}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={deleteUser}
      />
      <div className="mt-5 grid grid-cols-12 gap-6">
        <div className="flex flex-col col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
            <h2 className="font-semibold text-slate-800"><FormattedMessage id="JOIN_REQUESTS" defaultMessage="Join Requests" /></h2>
          </header>
          <div className="p-3">
            {/* Table */}
            {requests?.length > 0 ? <div className="overflow-x-auto" style={{ minHeight: 150, maxHeight: 350 }}>
              <table className="table-auto w-full" >
                {/* Table header */}
                <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left"><FormattedMessage id="NAME" defaultMessage="Name" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="REQUEST_DATE" defaultMessage="Request Date" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="ACTIONS" defaultMessage="Actions" /></div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
                  {/* Row */}
                  {requests?.map(request => (
                    <tr className='hover:bg-slate-100'>
                      <td className="p-2">
                        <div className="flex items-center h-6">
                          <div className="text-slate-800">{request?.userId?.fullName}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="text-center">{moment(new Date(request?.createdAt)).format('DD MMM, YYYY')}</div>
                      </td>
                      <td className="p-2">
                        <Button
                          name={intl.formatMessage({ id: 'APPROVE', defaultMessage: 'Approve' })}
                          src="tick.svg"
                          height="12px"
                          class="font-14"
                          onClick={() => dispatch(approveAccessRequest(request))}
                          style={{ width: 'max-content', height: 10 }}
                        /> <Button
                          name={intl.formatMessage({ id: 'DENY', defaultMessage: 'Deny' })}
                          src="cross.svg"
                          height="12px"
                          class="font-14"
                          onClick={() => dispatch(denyAccessRequest(request))}
                          style={{ width: 'max-content', height: 10 }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> :
              <div className='flex h-full w-full align-center justify-center py-4'>
                <EmptyState
                  header={intl.formatMessage({ id: 'NO_NEW_JOINERS', defaultMessage: 'No New Joiners!' })}
                  description={intl.formatMessage({ id: 'SIT_AND_RELAX', defaultMessage: 'Sit back and relax, nothing to see here.' })}
                  src='/images/icons/wave.svg' />
              </div>
            }
          </div>
        </div>
        <div className="flex flex-col col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
            <h2 className="font-semibold text-slate-800"><FormattedMessage id="PENDING_INVITES" defaultMessage="Pending Invites" /></h2>
          </header>
          <div className="p-3">
            {/* Table */}
            {invites?.length > 0 ? <div className="overflow-x-auto">
              <table className="table-auto w-full" >
                {/* Table header */}
                <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left"><FormattedMessage id="EMAIL" defaultMessage="Email" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="INVITED_ON" defaultMessage="Invited on" /></div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
                  {/* Row */}
                  {invites?.map(member => (
                    <tr className='hover:bg-slate-100'>
                      <td className="p-2">
                        <div className="flex items-center h-6">
                          <div className="text-slate-800">{member?.inviteeEmail}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="text-center">{moment(new Date(member?.createdAt)).format('DD MMM, YYYY')}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> :
              <div className='flex h-full w-full align-center justify-center py-4'>
                <EmptyState
                  header={intl.formatMessage({ id: 'YOU_ARE_ALL_CAUGHT_UP', defaultMessage: `You're All Caught Up!` })}
                  description={intl.formatMessage({ id: 'WANT_TO_INVITE_MORE', defaultMessage: 'Want to invite more members to your project?' })}
                  src='/images/icons/paper-plane.svg' />
              </div>
            }
          </div>
        </div>
        <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
            <div className="flex justify-between items-start">
              <h2 className="font-semibold text-slate-800">{title}</h2>
              <Button
                name={intl.formatMessage({ id: 'ADD_MEMBER', defaultMessage: 'Add Member' })}
                src="addblack.svg"
                height="12px"
                class="font-14"
                onClick={() => onAddMembers(true)}
                style={{ width: 'max-content', height: 10 }}
              />
            </div>
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto" style={{ maxHeight: 350 }}>
              <table className="table-auto w-full" >
                {/* Table header */}
                <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left"><FormattedMessage id="EMAIL" defaultMessage="Email" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="NAME" defaultMessage="Name" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="ROLE" defaultMessage="Role" /></div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center"><FormattedMessage id="ACTIONS" defaultMessage="Actions" /></div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
                  {/* Row */}
                  {data?.members?.map((member, i) => {
                    const iconLetters = member?.fullName?.slice(0, 2).toUpperCase();
                    return (
                      <tr className='cursor-pointer hover:bg-slate-100' onClick={(e) => { }}>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div
                              className={'square-letter-large font-14'}
                              style={{ background: getRandomColor(i + 1), height: 36, width: 36 }}>
                              {iconLetters}
                            </div>
                            <div className="text-slate-800">{member?.email}</div>
                            <img
                              onClick={() => {
                                navigator.clipboard.writeText(member?.email || '');
                                notification.success({
                                  message: intl.formatMessage({ id: 'EMAIL_COPIED_TO_CLIPBOARD', defaultMessage: 'Email copied to clipboard' }),
                                  placement: 'bottomRight',
                                });
                              }}
                              className='ml-3'
                              src={'/images/icons/copy.svg'}
                              height={18}
                              width={18}
                              style={{ cursor: 'pointer' }}
                              alt='icon'
                            />
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-center">{member?.fullName}</div>
                        </td>
                        <td className="p-2">
                          <Dropdown
                            trigger={['click']}
                            menu={{
                              items: [
                                {
                                  key: 'guest',
                                  label: intl.formatMessage({ id: 'GUEST', defaultMessage: 'Guest' }),
                                  onClick: () => updateRole('guest', member?._id)
                                },
                                {
                                  key: 'member',
                                  label: intl.formatMessage({ id: 'MEMBER', defaultMessage: 'Member' }),
                                  onClick: () => updateRole('member', member?._id)
                                },
                                {
                                  key: 'admin',
                                  label: intl.formatMessage({ id: 'ADMIN', defaultMessage: 'Admin' }),
                                  onClick: () => updateRole('admin', member?._id)
                                },
                              ],
                            }}
                            placement='bottom'
                            arrow
                            disabled={member.role === 'owner'}
                          >
                            <div className='flex align-center justify-center'>
                              <div
                                style={{
                                  color: '#747474',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                }}>{member.role}</div>
                              {member.role !== 'owner' && <img
                                src={'/images/icons/show-more.svg'}
                                style={{ margin: "10px" }}
                                alt="icon"
                              />}
                            </div>
                          </Dropdown>
                        </td>
                        <td className="p-2">
                          {member.role !== 'owner' && <div className='flex align-center justify-center'>
                            <Icon
                              name='delete-red.svg'
                              height='20px'
                              width='20px'
                              onClick={() => {
                                setOpen(true);
                                setUser(member._id);
                              }}
                            />
                          </div>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default DashboardMembers;
