import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getDateTextLongFormat } from '../../../../js/functions';
import { FormattedMessage, useIntl } from 'react-intl';

const SearchProjects = ({ project }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(`/${project?._id}/board`)}
            className="flex flex-col w-full bg-white hover:bg-gray-100 cursor-pointer rounded-xl shadow-sm p-4 my-2"
        >
            <div className="flex justify-between items-center">
                <span className="text-lg font-bold truncate">{project?.title}</span>
            </div>
            <div className="text-sm text-gray-500 mt-1">
                <FormattedMessage id="LAST_UPDATED" defaultMessage="Last Updated" />: {getDateTextLongFormat(new Date(project.updatedAt), intl)}
            </div>
            <div className="h-[1px] w-full my-4 bg-gray-300"></div>
            <div className="flex">
                <span
                    className={`px-2 py-1 text-xs font-medium rounded ${project?.access === 'public'
                        ? 'bg-green-100 text-green-700'
                        : 'bg-blue-100 text-[#0473cc]'
                        }`}
                >
                    {project?.access === 'public'
                        ? <FormattedMessage id="PUBLIC" defaultMessage="Public" />
                        : <FormattedMessage id="PRIVATE" defaultMessage="Private" />
                    }
                </span>
            </div>
        </div>
    )
}

export default SearchProjects
