import { FormattedMessage } from "react-intl"

export const TaskButtons = ({
  onCancel,
  onHandle
}) => {
  return (
    <div className='flex align-center m-2'>
      <div onClick={onCancel} className='font-14 cursor-pointer'><FormattedMessage id="CANCEL" defaultMessage="Cancel" /></div>
      <div onClick={onHandle} className='mx-4 font-14 text-indigo-400 font-black cursor-pointer inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-4 py-1.5 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out'><FormattedMessage id="SAVE" defaultMessage="Save" /></div>
    </div>
  )
}