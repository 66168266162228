import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signUp, getWorkspaces, getWorkspaceInvites, clearUnauthorized, clearError } from "../../../actions";
import moment from "moment-timezone";
import { validateEmail, validateName, validatePassword } from "../../../js/validation";
import { AuthInput } from "../../../src-new/components/inputs/AuthInput";
import { FormattedMessage, useIntl } from "react-intl";

const SignUpForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userToBeVerified = new URLSearchParams(location.search).get("email");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState(userToBeVerified || "");
  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCopyVisible, setPasswordCopyVisible] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordCopyError, setPasswordCopyError] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState("");



  const nameHandler = (e) => {
    const name = e.target.value;
    setFullName(name);

    validateName(name, intl, (isValid, message) => {
      setFullNameError(isValid ? "" : message);
    });

  };

  const emailHandler = (e) => {
    const mail = e.target.value;
    setEmailAddress(mail);

    validateEmail(mail, intl, (isValid, message) => {
      setEmailAddressError(isValid ? "" : message);
    });
  };

  const passwordHandler = (e) => {
    const passWord = e.target.value;
    setPassword(passWord);

    validatePassword(passWord, intl, (isValid, message) => {
      setPasswordError(isValid ? "" : message);
    });

    if (passwordCopy) {
      setPasswordCopyError(passWord === passwordCopy
        ? ""
        : intl.formatMessage({ id: 'PASSWORDS_DONT_MATCH', defaultMessage: "Passwords don't match" })
      );
    }
  };

  const confirmPasswordHandler = (e) => {
    const confirmPass = e.target.value.trim();
    setPasswordCopy(confirmPass);

    if (!confirmPass) {
      setPasswordCopyError(intl.formatMessage({ id: 'CONFIRM_PASSWORD_BLANK', defaultMessage: "Confirm Password cannot be blank" }));
    } else {
      setPasswordCopyError(confirmPass === password
        ? ""
        : intl.formatMessage({ id: 'PASSWORDS_DONT_MATCH', defaultMessage: "Passwords don't match" })
      );
    }
  };

  const termsHandler = (e) => {
    setIsTermsChecked(e.target.checked);
    setTermsError("");
  };

  const data = useSelector((state) => state.signupReducer);


  const submitHandler = async (e) => {
    e.preventDefault();

    if (!fullName) {
      setFullNameError(intl.formatMessage({ id: 'NAME_BLANK', defaultMessage: 'Name cannot be blank' }));
    }
    if (!emailAddress) {
      setEmailAddressError(intl.formatMessage({ id: 'EMAIL_BLANK', defaultMessage: 'Email cannot be blank' }));
    }
    if (!password) {
      setPasswordError(intl.formatMessage({ id: 'PASSWORD_BLANK', defaultMessage: 'Password cannot be blank' }));
    }
    if (!passwordCopy) {
      setPasswordCopyError(intl.formatMessage({ id: 'CONFIRM_PASSWORD_BLANK', defaultMessage: 'Confirm Password cannot be blank' }));
    }
    if (!isTermsChecked) {
      setTermsError(intl.formatMessage({ id: 'YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS', defaultMessage: 'You must accept the Terms and Conditions' }));
    }
    if (fullName && emailAddress && password && passwordCopy && isTermsChecked) {
      validateName(fullName, intl, (isValid, message) => {
        if (isValid) {
          validateEmail(emailAddress, intl, (isValid, message) => {
            if (isValid) {
              validatePassword(password, intl, (isValid, message) => {
                if (isValid) {
                  if (password !== passwordCopy) {
                    setPasswordCopyError(intl.formatMessage({ id: 'PASSWORDS_DONT_MATCH', defaultMessage: "Passwords don't match" }));
                  } else {
                    dispatch(
                      signUp({
                        fullName,
                        emailAddress,
                        password,
                        timezones: [
                          {
                            name: moment.tz.guess(),
                            isPrimary: true,
                          },
                        ],
                      })
                    )
                      .then(async () => {
                        dispatch(clearError())
                        props.setEmail(emailAddress);
                        await dispatch(getWorkspaces("SignUpForm"));
                        await dispatch(getWorkspaceInvites());
                        dispatch(clearUnauthorized());
                        navigate("/select-workspace", { replace: true });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                } else {
                  setPasswordError(message);
                }
              });
            } else {
              setEmailAddressError(message);
            }
          });
        } else {
          setFullNameError(message);
        }
      });
    }
  };

  // useEffect(() => {
  //   try {
  //     if (data.error && data.error.response && data.error.response.status != 0) {
  //       emailAddress ? setCheckMail(true) : setCheckMail(false);
  //       setCheckError(true);
  //     } else if (data.response && data.response.status && data.response.status == 200) {
  //       setCheckMail(false);
  //       setCheckError(false);
  //       props.setSignup(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [data.error, data.response, emailAddress, props]);

  return (
    <div className="form-div">
      <form onSubmit={submitHandler}>
        <AuthInput
          label={intl.formatMessage({ id: 'FULL_NAME', defaultMessage: 'Full name' })}
          name="fullName"
          value={fullName}
          onChange={nameHandler}
          placeholder="John Doe"
          error={fullNameError}
        />

        <AuthInput
          label={intl.formatMessage({ id: 'EMAIL_ADDRESS', defaultMessage: 'Email address' })}
          name="email"
          value={emailAddress}
          onChange={emailHandler}
          placeholder="email@workflo.com"
          error={emailAddressError}
        />

        <AuthInput
          label={intl.formatMessage({ id: 'PASSWORD', defaultMessage: 'Password' })}
          isPassword={true}
          type={passwordVisible ? 'text' : 'password'}
          value={password}
          onChange={passwordHandler}
          placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD', defaultMessage: 'Enter Password' })}
          error={passwordError}
          passwordVisible={passwordVisible}
          togglePasswordVisibility={() => setPasswordVisible(!passwordVisible)}
        />

        <AuthInput
          label={intl.formatMessage({ id: 'CONFIRM_PASSWORD', defaultMessage: 'Confirm password' })}
          isPassword={true}
          type={passwordCopyVisible ? 'text' : 'password'}
          value={passwordCopy}
          onChange={confirmPasswordHandler}
          placeholder={intl.formatMessage({ id: 'REENTER_PASSWORD', defaultMessage: 'Re Enter Password' })}
          error={passwordCopyError}
          passwordVisible={passwordCopyVisible}
          togglePasswordVisibility={() => { setPasswordCopyVisible(!passwordCopyVisible) }}
        />

        {/* <div className="font-16 mt-6">
          <label className="checkBox">
            <input className="check-mark" type="checkbox" id="check" />
            <span className="mark"></span>
          </label>
          <label htmlFor="check" className="font-weight-500 text-darkGray ml-3 cursor-pointer">Remember password</label>
        </div> */}
        <div className="font-16 mt-8 relative">
          <label className="checkBox">
            <input className="check-mark" type="checkbox" id="check" onChange={termsHandler}
              checked={isTermsChecked} />
            <span className="mark"></span>
          </label>
          <label htmlFor="check" className="font-weight-500 text-darkGray ml-3 cursor-pointer">
            <FormattedMessage
              id="ACCEPT_TERMS_AND_CONDITIONS"
              defaultMessage="I accept Workflo's <link>Terms and Conditions</link>"
              values={{
                link: (msg) => (
                  <span
                    className="underline text-blue-600"
                    onClick={() => window.open('https://workflo.com/terms-and-conditions/', '_blank')}
                  >
                    {msg}
                  </span>
                )
              }}
            />
          </label>

          {termsError && <div className="absolute flex align-center d-error ">
            <img src="/images/icons/error.svg" className="size-3 mr-1" alt="error icon" />
            <label className="font-12 text-red-600">{termsError}</label>
          </div>}
        </div>
        <button type="submit" className="button-tag font-20 font-weight-500 text-white">
          <FormattedMessage id="SIGN_UP" defaultMessage="Sign up" />!
        </button>

        <label className="font-weight-500 text-darkGray font-16 mt-5">
          <FormattedMessage id="HAVE_ACCOUNT" defaultMessage="Have an account?" /> <Link className="text-blue-500" to="/signin"><FormattedMessage id="LOG_IN" defaultMessage="Log in" /></Link>
        </label>
      </form>
    </div>
  );
};

export default SignUpForm;
