export const logout = () => {

  localStorage.removeItem('token');
  localStorage.removeItem('workspaceId');
  localStorage.removeItem('organization');
  localStorage.removeItem('toggle');
  localStorage.removeItem('inviteUrl');
  localStorage.removeItem('Id');


  sessionStorage.removeItem('workspaceId');
  sessionStorage.removeItem('organization');
  window.location.href = '/signin';

    return{
      type: "logout"
    }
  }