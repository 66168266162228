import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useParams } from 'react-router';
import { Badge, notification, Skeleton, Dropdown } from 'antd';

import TimeAgo from 'javascript-time-ago';

import {
  getWorkspaces,
  unsetActiveChat,
  updateProjectPreferences,
  toggleDuplicateProjectModal,
  toggleNewProjectModal,
  toggleNewTeamChatsModal,
  isTicketOpen
} from '../../../../actions';

import { isChatOpen, isJoinProjectsOpen } from '../../../../actions/toggleAction';
import { setRoomId } from '../../../../actions/roomAction';

import HtmlRenderer from '../../organisms/HtmlRenderer';

import {
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
} from '../../../../actions/types/chat.types';

import socket from '../../../../js/socket';
import '../../../../assets/style/chatTextArea.css';

import {
  updateChatRoomMessagesReadBy,
} from '../../../../actions/chatRoomAction';

import {
  clearMessagesState,
  updateQueryState,
} from '../../../../actions/messageAction';

import { getRandomColor, getSectionColor } from '../../../../js/helper';
import DropdownContent from '../onboarding/DropdownContent';
import EmptyState from '../../atoms/empty-state/EmptyState';
import Stepper from '../../atoms/side-navbar/Stepper';
import UserAvatar from '../../atoms/ticket-drawer/UserAvatar';
import { FormattedMessage, useIntl } from 'react-intl';
import { activityNotificationsLookup, getLocalizedActivity } from '../../../../_locale/localesLookupTable';

const NavBarContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    currentWorkspace,
    workspaces,
    toggle,
    activeChat,
    selectedLeftNav,
    project,
    notifications,
    myProjects,
    onBoardingStep,
    projectLoading
  } = props;

  const intl = useIntl();
  const showOnboarding = false;
  const isListView = useSelector((state) => state.toggleReducer.isListView);

  const [chatCollaps, setChatCollaps] = useState(true);
  const [workSpaceCollaps, setWorkSpaceCollaps] = useState({
    isWorkSpace: true,
  });

  const [workspaceId, setWorkspaceId] = useState(sessionStorage.getItem("workspaceId") || currentWorkspace?._id);
  const [isChatHover, setIsChatHover] = useState(false);
  const [isProjectHover, setIsProjectHover] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentWorkspace && currentWorkspace._id) {
      setWorkspaceId(currentWorkspace._id);
      setIsLoading(false);
    }
  }, [props.teams, currentWorkspace, workspaces]);

  useEffect(() => {
    socket.on('collaborators-added', ({ roomTitle, membersArray: members }) => {
      const isMember = members.includes(localStorage.getItem('Id'));
      if (isMember)
        notification.success({
          message: intl.formatMessage({ id: 'YOU_WERE_ADDED_TO_WORKSPACE', defaultMessage: `You were added as a participant in ${roomTitle} workspace` }, { roomTitle }),
          placement: 'bottomRight',
        });
      dispatch(getWorkspaces('lean'));
    });
  }, []);


  const [size, setSize] = useState({
    height: Number(localStorage.getItem('height')) || 230,
  });

  const minimizeTeamsPanel = () => {
    workSpaceCollaps.isWorkSpace
      ? setSize({ height: 75 })
      : setSize({
        height: Number(localStorage.getItem('height'))
          ? Number(localStorage.getItem('height'))
          : 230,
      });
    setWorkSpaceCollaps({
      ...workSpaceCollaps,
      isWorkSpace: !workSpaceCollaps.isWorkSpace,
    });
  };

  useEffect(() => {
    if (activeChat) {
      navigate(`/chat/${activeChat}`);
      dispatch(unsetActiveChat());
    }
  }, [activeChat, navigate]);

  useEffect(() => {
    if (project) {
      navigate(`/${project._id}/${isListView ? 'list' : 'board'}`);
    }
  }, [project, navigate]);

  const setShowProjectModal = (value) => {
    dispatch(toggleNewProjectModal({
      value: value
    }));
  };

  const setIsTeamsChatModalOpen = (value, isTeams) => {
    dispatch(toggleNewTeamChatsModal({
      value: value,
      isTeams: isTeams
    }));
  }

  const isHome = selectedLeftNav === "Home";
  const isTeams = selectedLeftNav === 'Projects';
  const isChats = selectedLeftNav === 'Chats';
  const isActivity = selectedLeftNav === 'Activity';

  const [isHover, setIsHover] = useState(false);

  return (
    <div style={{ height: !isHome ? '100%' : 'auto' }}>
      {(isTeams || isHome) &&
        <div
          style={{
            paddingLeft: toggle ? '' : '15px',
            paddingRight: toggle ? '' : '15px',
          }}
          className={
            `NavBarContainer 
          ${workSpaceCollaps.isWorkSpace ? 'dropDownShow' : 'dropDownCollaps'} 
          ${!isHome && 'hide-border'}}`
          }>
          <div>
            <div className='hor-line' />
            <div
              onMouseOver={() => setIsProjectHover(true)}
              onMouseLeave={() => setIsProjectHover(false)}
              className='d-flex align-center justify-content-between disable-select h-[38px] ml-2'>
              <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                  <DropdownContent
                    title={intl.formatMessage({ id: 'CONNECT_WITH_TEAM', defaultMessage: 'Connect with the team!' })}
                    descp={intl.formatMessage({ id: 'FAMILIARIZE_YOURSELF', defaultMessage: 'Familiarize yourself with the projects goals, scope, and team members.' })}
                  />
                )}
                arrow
                placement='bottomLeft'
                open={onBoardingStep === 1 && showOnboarding}
              >
                <div
                  className='d-flex align-center cursor-pointer'
                  onClick={() => minimizeTeamsPanel()}
                >
                  <img
                    src='/images/icons/projects.svg'
                    style={{ height: '20px', width: '20px', margin: '0 10px 0 5px' }}
                    alt='icon'
                  />
                  <div className='heading-nav font-16 text-white'><FormattedMessage id="PROJECTS" defaultMessage="Projects" /></div>
                </div>
              </Dropdown>
              <div className='d-flex align-center cursor-pointer'>
                <img
                  src='/images/icons/add.svg'
                  style={{ marginRight: '10px' }}
                  height={12}
                  width={12}
                  alt='icon'
                  onClick={() => setShowProjectModal(true)}
                />
              </div>
            </div>
            <div
              className={`scroll-y disable-select ml-1 mb-2`}
              style={{
                display: workSpaceCollaps.isWorkSpace ? 'block' : 'none',
              }}
            >
              {myProjects?.length > 0 ? myProjects?.map((project, index) => {

                if (!project?.title) {
                  return;
                }

                const me = project?.participants?.find(mem => mem?._id?.toString() == props.user?._id?.toString());

                const updateProjPreferences = async () => {
                  await dispatch(updateProjectPreferences({ ...me, notifications: !me?.notifications, projectId: project?._id }));
                }

                return (
                  <div key={project?._id + index}>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: project?._id + '1',
                            label: <div className='text-slate-600 font-14 p-2'><FormattedMessage id="COPY_PROJECT_LINK" defaultMessage="Copy Project Link" /></div>,
                            onClick: () => {
                              navigator.clipboard.writeText(`${window.location.origin}/${project._id}/board`);
                              notification.success({
                                message: intl.formatMessage({ id: 'PROJECT_LINK_COPIED', defaultMessage: 'Project link copied to clipboard' }),
                                placement: 'bottomRight',
                              });
                            }
                          },
                          {
                            key: project?._id + '2',
                            label: <div className='text-slate-600 font-14 p-2'>
                              {!me?.notifications
                                ? <FormattedMessage id="UNMUTE_PROJECT" defaultMessage="Unmute Project" />
                                : <FormattedMessage id="MUTE_PROJECT" defaultMessage="Mute Project" />
                              }</div>,
                            onClick: async () => {
                              updateProjPreferences();
                            }
                          },
                          {
                            key: project?._id + '3',
                            label: <div className='text-slate-600 font-14 p-2'>
                              <FormattedMessage id="DUPLICATE_PROJECT" defaultMessage="Duplicate Project" />
                            </div>,
                            onClick: () => {
                              dispatch(toggleDuplicateProjectModal(true, project))
                            }
                          },
                          {
                            key: project?._id + '4',
                            label: <div className='text-indigo-600 font-14 p-2'>
                              <FormattedMessage id="PROJECT_SETTINGS" defaultMessage="Project Settings" />
                            </div>,
                            onClick: () => {
                              navigate(`/${project?._id}/settings`)
                            }
                          }
                        ]
                      }}
                      trigger={['contextMenu']}
                    >
                      <div
                        onClick={() => navigate(`/${project?._id}/board`)}
                        className={`d-flex justify-between cursor-pointer align-center hover:bg-[#394867] w-full p-2 rounded my-0.5 ${params?.projectid == project?._id && 'nav-item-active'}`}>
                        <div className='flex align-center'>
                          {/* <div className='h-3 w-3 rounded ml-1 mr-2' style={{background: getRandomColor(index) }} /> */}
                          <span className="ml-1 truncate font-14">{project?.title}</span>
                        </div>
                        {project?.access !== 'public' && <img src={`/images/icons/white-lock.svg`} className="h-4 w-4 mr-1" alt="error" />}
                      </div>
                    </Dropdown>
                  </div>
                )
              }) :
                (!isLoading && !projectLoading) &&
                (
                  <Stepper
                    items={[
                      {
                        label: intl.formatMessage({ id: 'JOIN_PROJECTS', defaultMessage: 'Join Projects' }),
                        onPress: () => {
                          dispatch(isJoinProjectsOpen(true));
                        }
                      },
                      {
                        label: intl.formatMessage({ id: 'CREATE_PROJECT', defaultMessage: 'Create Project' }),
                        onPress: () => {
                          setShowProjectModal(true)
                        }
                      }
                    ]}
                    line={20} />
                )}
            </div>
          </div>
        </div>}
      {(isLoading || projectLoading) &&
        <div className='ml-2 mb-2'>
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
        </div>}
      {/* chat collaps .................................................................*/}
      <div className='hor-line' />
      <div>
        {(isChats || isHome) && <div
          className={`NavBarContainer ${chatCollaps ? 'dropDownShow' : 'dropDownCollaps'
            }`}
          style={{
            paddingLeft: toggle ? '10px' : '15px',
            paddingRight: toggle ? '10px' : '15px',
            paddingBottom: 10
          }}
        >
          <div
            onMouseOver={() => setIsChatHover(true)}
            onMouseLeave={() => setIsChatHover(false)}
            className='d-flex align-center justify-content-between h-[38px] ml-2 mt-3' style={{ paddingBottom: 10 }}>
            <Dropdown
              trigger={['click']}
              dropdownRender={() => (
                <DropdownContent
                  title={'Communication Channels!'}
                  descp={'Connect with the team through our communication chats for seamless interaction'}
                  nextButtonText='Get Started'
                />
              )}
              arrow
              placement='bottomLeft'
              open={onBoardingStep === 2 && showOnboarding}
            >
              <div
                className='d-flex align-center cursor-pointer'
                onClick={() => setChatCollaps(!chatCollaps)}
              >
                <img
                  src='/images/icons/chat-tooltip.svg'
                  style={{ height: 20, width: 20, margin: '0 10px 0 5px' }}
                  alt='icon'
                />
                <div className='heading-nav font-16 text-white'><FormattedMessage id="CHATS" defaultMessage="Chats" /></div>
              </div>
            </Dropdown>
            <div className='d-flex align-center cursor-pointer'>
              <img
                src='/images/icons/add.svg'
                style={{ marginRight: '10px' }}
                height={12}
                width={12}
                alt='icon'
                onClick={() => setIsTeamsChatModalOpen(true, false)}
              />
            </div>
          </div>
          {/* .........................Chat area.......................... */}
          {chatCollaps && <ul className='overflow-x-hidden'>
            {props?.chats &&
              props?.chats.map((chat, i) => {
                const isDirectChat = chat.roomType === 'direct'
                const isProjectChat = chat.roomType === 'project'

                const dmUser = chat.members?.filter(memeber => memeber._id !== localStorage.getItem('Id'));
                const name = isDirectChat && !isProjectChat ? dmUser[0]?.fullName || 'User' : chat.roomName;
                const avi = isDirectChat ? dmUser[0]?.avi : chat.roomAvi;
                const mostRecentMessage = chat.mostRecentMessage || '';

                const currentMessageDate = new Date(chat?.lastUpdate).toLocaleDateString('en-us',
                  {
                    weekday: "short",
                    month: "short",
                    day: "numeric"
                  });

                const iconLetters = name?.slice(0, 2).toUpperCase() || '';

                let span = document.createElement('span');
                span.innerHTML = mostRecentMessage?.messageDescription;
                const htmlContent = span.textContent || span.innerText;

                const hasNotification = chat.count > 0;
                const substringLength = hasNotification ? 25 : 35;

                if (isProjectChat || !name || !avi) {
                  return;
                }

                return (
                  <li
                    key={chat?._id}
                    className={`flex px-2 py-0.5 mb-1 cursor-pointer align-center nav-bar-chat ${params?.chatId?.toString() === chat?._id?.toString() && 'nav-item-active'} ${hasNotification && 'font-bold'}`}
                    onClick={() => {
                      if (params?.chatId?.toString() != chat?._id?.toString()) {
                        dispatch(updateQueryState(1));
                        dispatch(clearMessagesState());
                        dispatch(setRoomId(chat._id));
                        dispatch({ type: RESET_COUNTER, payload: chat._id });
                        navigate(`/chat/${chat._id}`);
                        dispatch({
                          type: UPDATE_CURRENT_RECEIVER,
                          payload: chat.roomName,
                        });
                      }
                      dispatch(updateChatRoomMessagesReadBy(chat._id, 'ChatsNavBar1'));
                    }}
                  >
                    <div className='flex align-center'>
                      {isDirectChat
                        ? <UserAvatar
                            classNames="my-1 mr-3 w-6 h-6 ml-1"
                            fullName={dmUser[0]?.fullName}
                            userId={dmUser[0]?._id}
                            src={dmUser[0]?.avi}
                            styles={{
                              objectFit: 'cover', borderRadius: '50%', border: '2px solid transparent', aspectRatio: 1
                            }}
                          />
                        : <img
                            src={chat.roomAvi}
                            className="my-1 mr-3 w-6 h-6 ml-1"
                            style={{ objectFit: 'cover', borderRadius: 60, border: '2px solid #ffffff59', aspectRatio: 1 }}
                            alt='icon'
                          />
                      }
                      <div>
                        <div className={`font-14 leading-5 self-center truncate ${hasNotification && 'font-bold'}`}>{name?.substring(0, hasNotification ? 20 : 25)}</div>
                        {/* {htmlContent !== 'undefined' && <div className={`font-12 leading-2 truncate max-w-[80%] ${hasNotification && 'font-bold'}`} style={{ width: hasNotification ? 150 : 200 }}>
                        {htmlContent}
                      </div>} */}
                      </div>
                    </div>
                    {hasNotification && <div>
                      <Badge
                        count={chat.count || 0}
                        // count={0}
                        style={{
                          backgroundColor: '#FE3C32',
                          boxShadow: 'none',
                        }}
                      />
                    </div>}
                  </li>)
              })}
          </ul>}
          {/* {(!props?.chats || props?.chats.length === 0) &&
            <div>
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
            </div>} */}
        </div>}
      </div>
      {isActivity &&
        <div>
          <div
            className='d-flex align-center cursor-pointer p-2.5'
            onClick={() => minimizeTeamsPanel()}
          >
            <img
              src='/images/icons/mail.svg'
              style={{ height: '18px', width: '18px', margin: '0 10px 0 5px' }}
              alt='icon'
            />
            <div className='heading-nav'><FormattedMessage id="ACTIVITY" defaultMessage="Activity" /></div>
          </div>
          <ul>
            {notifications && notifications?.map(notif => {

              const {
                assignee,
                assignor,
                title,
                description: desc
              } = notif;

              const {
                avi,
                fullName
              } = assignor || assignee || {};

              const icon = notif?.assignor?.avi || notif?.icon;

              const date = new Date(notif?.createdAt);
              const timeAgo = new TimeAgo(intl.locale);
              const time = timeAgo.format(date);

              const notificationType = notif?.notificationType;
              let notifActionLabel = '';
              let notifAction = () => { };

              // if (notificationType === 'CommentNotification') {
              //   notifActionLabel = 'Go to Comment';
              // } else
              if (notificationType === 'TaskNotification') {
                notifActionLabel = 'Open task';
                notifAction = () => {
                  dispatch(isTicketOpen(true));
                  navigate(`${window.location?.pathname}?selectedTask=${notif?.task?.titleIdentifier}`)
                }
              } else if (notificationType === 'ChatReplyNotification') {
                notifActionLabel = 'Open reply';
                notifAction = () => dispatch(isChatOpen(true, notif?.repliedMessage?._id));
              }

              const isViewed = notif?.assignee?.isViewed;
              const description = notif?.description;

              const localizedTitle = () => getLocalizedActivity(title, intl);

              const localizedDescription = () => getLocalizedActivity(description, intl);

              return (
                <div
                  onClick={() => notifAction()}
                  className='hover:bg-slate-600 border-r-4 border-slate-900 hover:border-blue-50 cursor-pointer p-4 mb-1'>
                  <div className='flex align-center'>
                    <img className="rounded-full w-10 h-10 mr-3" style={{ objectFit: 'cover' }} src={avi} alt="User notification" />
                    <div>
                      <div className='text-[16px] font-bold'>{localizedTitle()}</div>
                      <div className='text-xs'><FormattedMessage id="FROM" defaultMessage="From" /> {fullName} <span className='mx-1'>|</span> <span className='text-blue-600'>{time}</span></div>
                    </div>
                  </div>
                  <div className='mt-2 text-sm opacity-[80%]'>
                    <HtmlRenderer html={localizedDescription()} />
                  </div>
                </div>
              );
            })}
          </ul>
        </div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    toggle: state.navReducer.toggle,
    activeChat: state.chatReducer.activeChat,
    project: state.projectReducer.success,
    projectData: state.projectReducer.getProjects,
    showOnboarding: state.onboardingReducer.showOnboarding,
    onBoardingStep: state.onboardingReducer.onBoardingStep,
    projectLoading: state.projectReducer.loading,
  };
}

export default connect(mapStateToProps)(NavBarContainer);
