import React, { useState, useRef, useEffect } from 'react';

import AssigneeIcon from './Icons/Assignee.jsx';
import LabelsIcon from './Icons/Label.jsx';
import PriorityIcon from './Icons/Priority.jsx';
import ProjectIcon from './Icons/Project.jsx';
import StatusIcon from './Icons/Status.jsx';
import HighPriorityIcon from './Icons/HighPriority.jsx';
import MediumPriorityIcon from './Icons/MediumPriority.jsx';
import LowPriorityIcon from './Icons/LowPriority.jsx';
import '../../../../../assets/style/taskFilter.css';
import UserIcon from './Icons/UserIcon.jsx';
import LowestPriorityIcon from './Icons/LowestPriority.jsx';
import CustomFieldIcon from './Icons/CustomFieldIcon.jsx';
import CraetorIcon from './Icons/Creator.jsx';
import ResolutionIcon from './Icons/Resolution.jsx';
import UserAvatar from '../../../atoms/ticket-drawer/UserAvatar.jsx';
import { FormattedMessage, useIntl } from 'react-intl';

const TaskFilter = ({ selectedLabels, onLabelSelection, userFilters, users, teams, handleSaveUserFilter, handleResetFilter, projects }) => {
  const intl = useIntl();

  const handleClickOutside = (event) => {
    if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
      setMenuOpen(false);
      setLabelMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filterContainerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [labelMenuOpen, setLabelMenuOpen] = useState(false);
  const [labelData, setLabelData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputMenu, setSearchInputMenu] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setLabelMenuOpen(false);
    setSearchInputMenu('');
  };

  const saveFilter = (defaultFilter) => {
    handleSaveUserFilter(defaultFilter);
  }

  const resetFilter = () => {
    handleResetFilter();
  }

  const openLabelMenu = (dropdownData = []) => {
    setLabelData(dropdownData);
    setLabelMenuOpen(true);
    setMenuOpen(false);
    setSearchInputMenu('');
  };

  const toggleLabelCheck = (label) => {
    if (selectedLabels.some(item => item.id === label.id && item.type === label.type)) {
      const indexToRemove = selectedLabels.findIndex(
        (it) => it.id === label.id && it.type === label.type
      );

      if (indexToRemove !== -1) {
        selectedLabels.splice(indexToRemove, 1);
      }
      onLabelSelection(selectedLabels);

    } else {
      onLabelSelection([...selectedLabels, label]);
    }
  };

  let dropdownData = [
    // { text: 'Status', icon: <StatusIcon /> },
    {
      text: intl.formatMessage({ id: 'RESET_FILTERS', defaultMessage: 'Reset Filters' }),
      icon: '',
    },
    {
      text: intl.formatMessage({ id: 'ASSIGNEE', defaultMessage: 'Assignee' }),
      icon: <AssigneeIcon />,
      dropdown: users?.map(u => {
        return {
          text: u?.fullName,
          id: u?._id,
          icon: (
            <UserAvatar
              classNames="filter-icon"
              fullName={u?.fullName}
              userId={u?._id}
              src={u?.avi}
              styles={{
                marginRight: '8px'
              }}
            />
          ),
          type: 'Assignee',
          parentIcon: <AssigneeIcon />
        }
      }) || []
    },
    {
      text: intl.formatMessage({ id: 'PRIORITY', defaultMessage: 'Priority' }),
      icon: <PriorityIcon />,
      dropdown: [
        { text: intl.formatMessage({ id: 'CRITICAL', defaultMessage: 'Critical' }), id: 'p1', icon: <HighPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: intl.formatMessage({ id: 'HIGH', defaultMessage: 'High' }), id: 'p2', icon: <MediumPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: intl.formatMessage({ id: 'MEDIUM', defaultMessage: 'Medium' }), id: 'p3', icon: <LowPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: intl.formatMessage({ id: 'LOW', defaultMessage: 'Low' }), id: 'p4', icon: <LowestPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
      ],
    },
    // { text: 'Labels', icon: <LabelsIcon /> },
    {
      text: intl.formatMessage({ id: 'PROJECT', defaultMessage: 'Project' }),
      icon: <ProjectIcon />,
      dropdown: teams?.reduce((acc, team) => {
        const projectsArray = projects?.map(project => ({
          id: project._id,
          text: project.title,
          type: 'Project',
          parentIcon: <ProjectIcon />
        })) || [];

        return projectsArray;
      }, [])
    },
    {
      text: intl.formatMessage({ id: 'CREATED_BY', defaultMessage: 'Created By' }),
      icon: <CraetorIcon />,
      dropdown: users?.map(u => {
        return {
          text: u?.fullName,
          id: u?._id,
          icon: <UserIcon avi={u?.avi} />,
          type: 'Creator',
          parentIcon: <CraetorIcon />
        }
      }) || []
    },
    {
      text: intl.formatMessage({ id: 'COMPLETION', defaultMessage: 'Completion' }),
      icon: <ResolutionIcon />,
      dropdown: [
        {
          text: intl.formatMessage({ id: 'COMPLETED_TASKS', defaultMessage: 'Completed Tasks' }),
          id: true,
          type: 'Resolution',
          parentIcon: <ResolutionIcon />
        }, {
          text: intl.formatMessage({ id: 'INCOMPLETED_TASKS', defaultMessage: 'Incompleted Tasks' }),
          id: false,
          type: 'Resolution',
          parentIcon: <ResolutionIcon />
        }
      ]
    },
    // {
    //   text: 'Date',
    //   icon: <img
    //     src='/images/icons/date.svg'
    //     alt=''
    //     style={{
    //       height: '16px',
    //       width: '16px',
    //       marginRight: 10
    //     }}
    //   />,
    //   dropdown: [
    //     {
    //       text: 'Overdue',
    //       id: true,
    //       type: 'Resolution',
    //       parentIcon: <ResolutionIcon />
    //     },
    //     {
    //       text: 'Due Today',
    //       id: true,
    //       type: 'Resolution',
    //       parentIcon: <ResolutionIcon />
    //     },
    //     {
    //       text: 'Due This Week',
    //       id: false,
    //       type: 'Resolution',
    //       parentIcon: <ResolutionIcon />
    //     },
    //     {
    //       text: 'Due Next Week',
    //       id: false,
    //       type: 'Resolution',
    //       parentIcon: <ResolutionIcon />
    //     }
    //   ]
    // }
  ];
  const customFields = projects?.reduce((projectAccumulator, project) => {
    const cfArray = project?.customFields || [];
    const dropdownFields = cfArray
      .filter((cf) => cf?.type === 'dropdown')
      .map((cf) => ({
        text: cf?.name,
        icon: <LabelsIcon />,
        dropdown: cf?.possibleValues?.map((pv) => ({
          text: pv?.label, // TODO:
          id: pv?._id,
          type: 'customField',
          customFieldId: cf?._id,
          name: cf?.name
        })),
      }));
    return projectAccumulator.concat(dropdownFields);
  }, []);

  dropdownData = dropdownData.concat(customFields);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSearchInputMenuChange = (e) => {
    setSearchInputMenu(e.target.value);
  };
  const filteredLabelData = labelData?.filter((item) =>
    item?.text.toLowerCase().includes(searchInput.toLowerCase())
  );
  const filteredDropdownData = dropdownData?.filter((item) =>
    item?.text.toLowerCase().includes(searchInputMenu.toLowerCase())
  );

  const filterPill = (arry) => {
    if (arry?.length == 0) {
      return;
    }

    let label = arry?.shift();
    let count = arry?.length;

    return (
      <li className="relative m-1 bg-white inline-flex items-center justify-center text-[12px] font-medium leading-5 rounded-full px-4 py-1 border border-slate-300 shadow-sm duration-150 ease-in-out">
        <div className='h-5' onClick={() => {
          const dropItems = dropdownData?.find(x => x?.text == label?.type)?.dropdown;
          openLabelMenu(dropItems);
        }}>
          {/* TODO: */}
          {label?.name || label?.type} is {label?.text}{count > 0 && ` + ${count}`}
        </div>
        {/* <img
          className='text-slate-700 h-5 w-2 ml-2'
          src={'/images/icons/close.svg'}
          style={{
            cursor: 'pointer',
          }}
          alt='icon'
        /> */}
      </li>
    )
  }

  const assignedTo = selectedLabels?.filter(label => label?.type == 'Assignee');
  const priority = selectedLabels?.filter(label => label?.type == 'Priority');
  const creator = selectedLabels?.filter(label => label?.type == 'Creator');
  const resolution = selectedLabels?.filter(label => label?.type == 'Resolution');
  const projectsS = selectedLabels?.filter(label => label?.type == 'Project');

  return (
    <div ref={filterContainerRef} className="w-full task-filter shadow mb-1" style={{ zIndex: (menuOpen || labelMenuOpen) && 999999999 }}>
      {/* Header */}
      <div className="h-10 w-full flex align-center justify-between px-3 sticky top-0 bg-gray-50" style={{  zIndex: 999 }}>
        <div className='flex align-center'>
          <div className='flex align-center cursor-pointer hover:bg-gray-200 p-1 pr-3 pl-2 rounded-md' onClick={toggleMenu} >
            <img src="/images/icons/Filters.svg" className='mr-2 size-4' />
            <div className='font-14'><FormattedMessage id="FILTERS" defaultMessage="Filters" /></div>
          </div>
          {/* Filters */}
          <div className="flex justify-between ml-10">
            <ul className="flex flex-wrap -m-1">
              {filterPill(assignedTo)}
              {filterPill(priority)}
              {filterPill(creator)}
              {filterPill(resolution)}
              {filterPill(projectsS)}
            </ul>
          </div>
        </div>
        <div className='flex align-center'>
          <div className='font-14 mr-3 p-1 px-3 cursor-pointer hover:bg-gray-200 rounded-md' onClick={() => resetFilter()} ><FormattedMessage id="RESET_FILTERS" defaultMessage="Reset Filters" /></div>
          <div className='font-14 mr-3 p-1 px-3 cursor-pointer hover:bg-gray-200 rounded-md' onClick={() => saveFilter(true)}><FormattedMessage id="SAVE" defaultMessage="Save" /></div>
        </div>
      </div>
      {labelMenuOpen && (
        <div className="filter-menu">
          <input
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER', defaultMessage: 'Search...' })}
            className="filter-search-input"
          />
          <div className="divider" />
          <div className="filter-list max-h-[50vh]">
            {filteredLabelData.map((item, index) => (
              <label
                key={index}
                className="filter-item">
                <input
                  className="filter-checkbox"
                  type="checkbox"
                  onChange={() => toggleLabelCheck(item)}
                  checked={selectedLabels.some(it => it.id === item.id && it.type === item.type)}
                />
                {item.icon && <div className='w-[20%]'>{item.icon}</div>}
                <div className='w-[90%]'>{item.text}</div>
              </label>
            ))}
          </div>
        </div>
      )}
      {menuOpen && (
        <div className="filter-menu">
          <input
            type="text"
            value={searchInputMenu}
            onChange={handleSearchInputMenuChange}
            placeholder={intl.formatMessage({ id: 'SEARCH_FILTERS', defaultMessage: 'Search Filters' })}
            className="filter-search-input"
          />
          <div className="divider" />
          <div className="filter-dropdown">
            <div className="main-menu-filter-list">
              {filteredDropdownData.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (index == 0) {
                      resetFilter();
                    } else {
                      openLabelMenu(item.dropdown);
                    }
                  }}
                  className="filter-item py-2"
                  type="button"
                >
                  {item.icon}
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskFilter;
