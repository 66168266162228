import React, { useState, useEffect, useRef } from 'react';
import { Input, Dropdown } from "antd";
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getProjectById,
  uploadTaskUrl,
  addTask,
  signedUploadUrl,
  isTicketOpen,
  clearSingleTask,
  isChatOpen
} from '../../actions';

import '../../assets/style/modal.css';
import TextEditor from '../../components/UI/molecules/editor/TextEditor';

import PriorityDropdown from '../../components/UI/molecules/task-components/PriorityDropdown';

import { PRIORITY_COLORS } from '../../js/constants';
import { resetDrawer } from '../../actions/toggleAction';
import { FormattedMessage, useIntl } from 'react-intl';

const AddTaskDrawer = (props) => {
  const {
    openProject,
    projects,
    defaultTitle
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedProject, setSelectedProject] = useState();
  const [selectedSection, setSelectedSection] = useState();

  const [sectionsMenu, setSectionsMenu] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const [taskName, setTaskName] = useState('');
  const [taskNameDescription, setTaskDescription] = useState(defaultTitle || '');

  const [keepModalOpen, setKeepModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCreate = () => {
    onCreate(taskName, taskNameDescription, selectedProject._id, selectedSection._id, selectedAssignees);
    dispatch(resetDrawer());
  }

  useEffect(() => {
    setTaskDescription(defaultTitle);
  }, [defaultTitle])

  useEffect(() => {
    if (!selectedProject && openProject?._id) {
      onProjectSelect(openProject);
    } else if (projects?.length == 1) {
      onProjectSelect(projects[0]);
    }
  }, []);

  const isButtonDisabled = !taskName || !selectedProject || !selectedSection;

  const openNewTicket = (newTask) => {
    const params = new URLSearchParams(location.search);
    if (!newTask) {
      dispatch(isTicketOpen(false));
      params.delete('selectedTask');
      const newUrl = `${location.pathname}?${params.toString()}`;
      navigate(newUrl);
      return;
    }
    dispatch(clearSingleTask())
    params.delete('msgId'); // Remove the specified parameter

    params.set('selectedTask', newTask?.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
    dispatch(isChatOpen(false));
  };

  const onCreate = async (taskName, taskNameDescription, Project, Section, Assignee) => {
    const data = {
      taskTitle: taskName,
      taskDescription: taskNameDescription,
      // section: Section,
      isCompleted: false,
      // projectId: Project,
      projects: [
        {
          projectId: Project,
          section: Section,
          position: 'top'
        }
      ],
      dueDate: '',
      addedTo: 'top'
    };
    //TODO: Osama handle multiple assignees
    data.assignee = [];
    for (let a of Assignee) {
      data.assignee.push(
        {
          assigneeId: a
        }
      );
    }
    const files = fileInputRef.current.files;
    const uploadedFiles = await uploadFiles(files);
    data.files = uploadedFiles;
    const newTask = await dispatch(addTask(data));
    openNewTicket(newTask);
  }

  // Handle attachments
  const uploadFiles = async (files, task) => {
    const fileList = [];
    if (files?.length == 0 || !files) {
      return;
    }
    for (const file of files) {
      setIsLoading(true);
      const uploadTaskResponse = await dispatch(uploadTaskUrl({
        fileName: file.name,
        type: file?.type,
        fileSize: file?.size
        // taskId: task?._id,
      }));
      const fileId = uploadTaskResponse?.fileId;
      const signedUrl = uploadTaskResponse?.signedUrl;
      if (signedUrl) {
        fileList.push(fileId);
        await dispatch(signedUploadUrl(signedUrl, file));
      }
    }

    let allFiles = fileList;

    // if (task?.files) {
    //   allFiles = fileList.concat(task?.files || []);
    // }

    return allFiles;
    // dispatch(
    //   editTask(
    //     {
    //       _id: task._id,
    //       taskTitle: task.taskTitle,
    //       files: allFiles,
    //       attachments: []
    //     }, () => {
    //       setIsLoading(false);
    //     }
    //   ));
  }

  const onProjectSelect = (project) => {
    setSelectedProject(project);
    dispatch(getProjectById(project?._id, (res) => {
      const sections = [];
      res?.sections.map((section, i) => {
        sections.push({
          label:
            <div onClick={() => setSelectedSection(section)} className='py-2 font-14'>
              <FormattedMessage id={`${section.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={section.title} />
            </div>,
          key: i,
        })
      });
      setSectionsMenu(sections);
      res?.sections && setSelectedSection(res?.sections[0]);
    }));
  }

  return (
    <div className='h-[83vh]'>
      <div className='bg-[#0074cc] h-40 flex flex-col p-8' style={{ justifyContent: 'flex-end' }}>
        <div className='text-white font-black text-2xl'><FormattedMessage id="CREATE_TASK" defaultMessage="Create Task" /></div>
      </div>
      <div className='p-5 flex flex-col align-center justify-between h-full'>
        <div className='w-full'>
          <div className='font-14 font-bold mt-1 mb-2'><FormattedMessage id="TASK_DETAILS" defaultMessage="Task Details" /></div>
          <Input
            className='font-16 bg-gray-100 py-2 mb-3'
            placeholder={intl.formatMessage({ id: 'TASK_NAME', defaultMessage: 'Task Name' })}
            onChange={(e) => setTaskName(e.target.value)}
            maxLength={300}
            value={taskName}
          />
          <div className='overflow-scroll my-5' style={{ maxHeight: '35vh' }}>
            <TextEditor
              large
              isTask
              placeholder={intl.formatMessage({ id: 'ADD_DESCRIPTION', defaultMessage: 'Add a description...' })}
              onChange={(e) => setTaskDescription(e)}
              onKeyUp={(e) => { }}
              comment={taskNameDescription}
              mentionOptions={[]}
            />
          </div>
          <div className='font-14 font-bold mb-2'><FormattedMessage id="PROJECT_DETAILS" defaultMessage="Project Details" /></div>
          <div className='button-row d-flex'>
            <Dropdown
              trigger={['click']}
              menu={{
                items: projects?.map((project, i) => ({
                  label:
                    <div onClick={() => {
                      onProjectSelect(project);
                    }} className='py-2 font-14'>
                      {project?.title}
                    </div>,
                  key: i,
                }))
              }}>
              <div className={`cursor-pointer mr-3 w-full flex justify-between items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
                <div>{selectedProject
                  ? selectedProject?.title
                  : <FormattedMessage id="SELECT_PROJECT" defaultMessage="Select Project" />
                }</div>
                <img
                  src={'/images/icons/show-more.svg'}
                  style={{ margin: "10px" }}
                  alt="icon"
                />
              </div>
            </Dropdown>
            <Dropdown
              disabled={!selectedProject}
              menu={{
                items: sectionsMenu
              }}>
              <div className={`${!selectedProject && 'opacity-60'} cursor-pointer w-full flex justify-between items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
                <div>{selectedSection
                  ? <FormattedMessage id={`${selectedSection.title}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedSection.title} />
                  : <FormattedMessage id="SELECT_SECTION" defaultMessage="Select Section" />
                }</div>
                <img
                  src={'/images/icons/show-more.svg'}
                  style={{ margin: "10px" }}
                  alt="icon"
                />
              </div>
            </Dropdown>
          </div>
          <div className='font-14 font-bold mt-4 mb-2'><FormattedMessage id="ADDITIONAL_DETAILS" defaultMessage="Additional Details" /></div>
          <div className='flex'>
            {uploadedFiles && uploadedFiles?.map(f => (
              <div>
                <img
                  alt="not found"
                  className='h-24 pr-2 object-cover'
                  width={"250px"}
                  src={URL?.createObjectURL(f)}
                />
                <br />
                <button onClick={() => setUploadedFiles([])}>
                  <FormattedMessage id="REMOVE" defaultMessage="Remove" />
                </button>
              </div>
            ))}
            {uploadedFiles?.length < 4 && <div
              onClick={() => {
                fileInputRef.current.click();
              }}
              className='w-full flex align-center justify-center bg-gray-100 h-24 cursor-pointer hover:opacity-80 rounded'>
              <img src='/images/icons/ticket-pin.svg' className='mr-2' />
              <FormattedMessage id="UPLOAD_IMAGE" defaultMessage="Upload Image" />
              <input
                style={{ display: 'none' }}
                id="file-input"
                ref={fileInputRef}
                type='file'
                multiple
                onChange={(event) => {
                  if (uploadedFiles?.length < 4) {
                    setUploadedFiles([...uploadedFiles, ...event?.target?.files]);
                  }
                }}
              />
            </div>}
          </div>
        </div>
        <div
          onClick={(e) => {
            handleCreate();
          }}
          className={`w-full text-center px-4 py-2 bg-[#0074cc] ${isButtonDisabled && 'opacity-70'} font-16 text-white border-radius10 hover:bg-blue-500 cursor-pointer`}>
          <FormattedMessage id="CREATE_TASK" defaultMessage="Create Task" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projects: state.workspaceReducer.currentWorkspace?.myProjects,
  openProject: state.projectReducer.getProjects,
  defaultTitle: state.modalReducer.newTaskModal.title
});

export default connect(mapStateToProps)(AddTaskDrawer);
