import React from 'react';

const Stepper = ({ items = [], line = 22 }) => {

  if (items?.length === 0) {
    return;
  }

  const dot = ({ label = 'test', onPress = () => {}, active = false  }) => (
    <div className='flex align-center' onClick={onPress} key={label}>
      <div className='h-2 w-2 rounded-3xl mr-3' style={{ background: '#47474B' }} />
      <div
        className={`d-flex cursor-pointer align-center hover:bg-[#394867] w-full p-2 rounded my-0.5 ${active && 'nav-item-active'}`}
      >
        {/* <img src={`/images/icons/${project?.access !== 'public' ? 'white-lock' : 'box'}.svg`} className="h-4 w-4 mr-2 ml-1" alt="error" /> */}
        <span className="ml-1 truncate font-14">{label}</span>
      </div>
    </div>
  );

  return (
    <div className='my-2 relative ml-5'>
      {items?.map(item => dot(item))}
      <div style={{ height: items?.length * line, width: 2, background: '#47474B', position: 'absolute', left: 3, top: 20, overflow: 'hidden', bottom: 0, overflow: 'hidden' }} />
    </div>
  );
};

export default Stepper;
