import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tooltip } from "antd";

import { switchWorkspace, getTeamsByWorkspaceId, getChatRooms, getUserTasks, clearSingleTask, getWorkspaceDefaultFilter, getUsers, getWorkspaceProjects, getWorkspaceById } from '../../../../actions';
import { getRandomColor } from '../../../../js/helper';
import { getWorkspaceNotifications } from "../../../../actions/notificationAction";
import { getWorkspaceTasks, setUserTaskFilters } from "../../../../actions/taskAction";
import { getWorkspaceDashboardData } from "../../../../actions/WorkspaceActions";
import { useIntl } from "react-intl";

const SwitchWorkspacePanel = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [workspaces, setWorkspaces] = useState(props.workspaces);

  const { toggleWorkspacePanel } = props;

  useEffect(() => {
    setWorkspaces(props.workspaces);
  }, [props.workspaces]);

  return (
    <>
      <div className={"switch-workspace-nav"}>
        <div style={{
          width: 'auto',
          alignSelf: 'center'
        }}>
          {workspaces && workspaces?.map((workspace, i) => {
            const iconLetters = workspace.title?.slice(0, 2).toUpperCase() || '';
            return (
              <Tooltip placement="right" title={workspace.title || ''} key={iconLetters + i}>
                <div
                  key={iconLetters + i}
                  className={'circle-letter'}
                  onClick={ () => {
                    props?.setIsLoading(true);

                    dispatch(switchWorkspace(workspace, async() => {
                      sessionStorage.setItem("workspaceId", workspace._id);
                      localStorage.setItem("workspaceId", workspace._id);
                      sessionStorage.setItem("organization", workspace.organization);
                      localStorage.setItem("organization", workspace.organization);
                      await Promise.all([
                        dispatch(getWorkspaceDashboardData()),
                        dispatch(getWorkspaceById()),
                        dispatch(getWorkspaceDefaultFilter(workspace._id)),
                        dispatch(getUsers(workspace._id)),
                        dispatch(getWorkspaceNotifications()),
                        dispatch(clearSingleTask()),
                        dispatch(getWorkspaceTasks(workspace._id))
                      ]);

                      // dispatch(getWorkspaceDashboardData());
                      // dispatch(getWorkspaceById());
                      // // dispatch(getTeamsByWorkspaceId(workspace._id));
                      // dispatch(getWorkspaceDefaultFilter(workspace._id));
                      // // dispatch(getChatRooms());
                      // dispatch(getUsers(workspace._id));
                      // // dispatch(getWorkspaceProjects(workspace._id))
                      // // dispatch(getUserTasks({}, null, workspace?._id));
                      // dispatch(getWorkspaceNotifications())
                      // dispatch(clearSingleTask());
                      // dispatch(getWorkspaceTasks(workspace._id));
                      // // dispatch(setUserTaskFilters({}, true))
                      navigate('/', { replace: true });
                      toggleWorkspacePanel();
                      props?.setIsLoading(false);
                    }));
                  }}
                  style={{ background: getRandomColor(i + 1) }}>
                  {iconLetters}
                </div>
              </Tooltip>
            );
          }
          )}
        </div>
        <Tooltip placement="right" title={intl.formatMessage({ id: 'CREATE_WORKSPACE', defaultMessage: 'Create Workspace' })}>
          <div onClick={() => navigate('/create-workspace')}>
            <img src="/images/icons/workspaceAdd.svg" alt='img' />
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default SwitchWorkspacePanel
