import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Chat from './components/pages/Chat';
import Activity from './components/pages/Activity';
import SignIn from './components/UI/organisms/SignIn';
import SignUp from './components/UI/organisms/SignUp';
import CreateWorkspace from './components/UI/organisms/CreateWorkspace';
import SelectWorkspace from './components/UI/organisms/SelectWorkspace';
import ResetPassword from './components/UI/organisms/ResetPassword';
import NewPassword from './components/UI/organisms/NewPassword';
import Home from './components/pages/Home';
import Search from './components/pages/Search';
import CalendarComponent from './components/UI/organisms/CalendarComponent';

import ProjectMembers from './components/UI/organisms/ProjectMembers';
import ProjectCustomFieldsTab from './components/UI/organisms/ProjectCustomFields';
import ProjectSettings from './components/UI/organisms/ProjectSettings';
import TaskProject from './components/pages/TaskProject';
import socket from './js/socket';
import {
  getWorkspaceNotifications,
  updateNotificationState,
} from './actions/notificationAction';
import {
  getChatRooms,
  addChatMessage,
  downloadChatUrl,
  getFileUsingSignedUrl,
  getUsers,
  addCommentToTask,
  setIsMobile,
  deleteCommentFromTask,
  incrementChatUnreadCount,
  getWorkspaceById,
  getProjectById,
} from './actions';
import {
  addChatReply,
  deleteChatReply,
  deleteSingleMessage,
  editChatReply,
  editMessageDetails,
  setScrollPosition,
  updateSingleMessage,
} from './actions/messageAction';
import ProjectChat from './components/pages/ProjectChat';
import AdminConsole from './components/UI/organisms/AdminConsole';
import Layout from './components/pages/Layout';
import GetStarted from './components/pages/GetStarted';
import Pricing from './components/pages/Pricing';
import StripeWrapper from './components/UI/molecules/global/StripeWrapper';
import PaymentSuccess from './components/pages/PaymentSuccess';
import PaymentError from './components/pages/PaymentError';
import PricingUsers from './components/pages/PricingUsers';
import {
  getWorkspaceDashboardData,
  getWorkspaces,
} from './actions/WorkspaceActions';
import AllUsers from './components/pages/AllUsers';
import Dashboard from './src-new/pages/Dashboard';
import { verifyUser } from './actions/userAction';

function App() {
  const dispatch = useDispatch();

  const { query } = useSelector((state) => state.notificationReducer);
  const { user } = useSelector((state) => state.userReducer);
  const windowWidth = window.screen?.width || 501;
  const chats = useSelector((state) => state.chatReducer.chats);

  const workspaceId = localStorage.getItem('workspaceId');
  const token = localStorage.getItem('token');

  // Connecting to socket
  useEffect(() => {
    socket.on('connection', (sock) => {
      sock.on('disconnect', function () {
        console.log('Socket Disconnected');
      });
    });
  }, []);

  // Reconnecting to socket
  useEffect(() => {
    const reconnect = () => {
      if (document.visibilityState === 'visible' && !socket.connected) {
        socket.on('connection', (sock) => {
          sock.on('disconnect', function () {
            console.log('Socket Disconnected');
          });
        });
      }
    };

    document.addEventListener('visibilitychange', reconnect);

    return () => {
      document.removeEventListener('visibilitychange', reconnect);
    };
  }, []);

  // Recieving task messages

  useEffect(() => {
    dispatch(setIsMobile(windowWidth < 845));
    socket.on('message-received', (msg) => {
      dispatch(addCommentToTask(msg));
      // dispatch(getWorkspaceNotifications(query));
    });

    socket.on('ProjectAdded', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      // dispatch(getWorkspaceById());
      // dispatch(getWorkspaceDashboardData());
      dispatch({
        type: "add-project",
        payload: project
      });
    });

    socket.on('AddedToProject', (projectId) => {
      const { 1: selectedProjectId } = window.location.pathname.split("/");
      if(selectedProjectId?.toString() === projectId?.toString()) {
        dispatch(getProjectById(projectId));
      }
      // dispatch(getWorkspaceDashboardData());
      // dispatch(getWorkspaceNotifications(query));
    });

    socket.on('ProjectUpdated', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      // dispatch(deleteProjectTasksFromLRU(project?._id));
      // dispatch(getWorkspaceById());
      // dispatch(getWorkspaceDashboardData());
    });

    socket.on('ProjectDeleted', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      // dispatch(getWorkspaceDashboardData());
      // dispatch(getWorkspaceById());
      dispatch({
        type: "delete-project",
        payload: project?._id,
      });
    });

    socket.on('notifications', (msg) => {
      // dispatch(getWorkspaceNotifications(query));
      //TODO: BE must send notification object and frontend must add it to redux state instead of re fetching
    });

    socket.on('message-deleted', (payload) => {
      // dispatch(getChatRooms(query));
      const context = payload?.context;
      const messageId = payload?.messageId;
      if (context?.type === 'task') {
        dispatch(deleteCommentFromTask(messageId, context?._id));
      } else if (context?.type === 'project') {
        dispatch(deleteSingleMessage({ roomId: context?._id, messageId }));
        // TODO: Remove messageId from project chat. Match active project _id with context._id
      } else if (context?.type === 'chat') {
        dispatch(deleteSingleMessage({ roomId: context?._id, messageId }));
        // TODO: Remove messageId from chat. Match active chat _id with context._id or check if chat messages are present in state.
      }
    });

    socket.on('reply-deleted', (data) => {
      dispatch(deleteChatReply(data));
    });

    socket.on('message-edited', (result) => {
      if (result?.context === 'chat') {
        const regex = /\/chat\/([a-zA-Z0-9]+)/;
        const url = window?.location?.pathname;
        const match = url && url.match(regex);
        const chatRoomId = match ? match[1] : url.split('/')[1];

        if (result?.roomId === chatRoomId) {
          dispatch(
            editMessageDetails({
              roomId: chatRoomId,
              msgId: result?._id,
              messageDescription: result?.messageDescription,
            }),
          );
        }
        // dispatch(getChatRooms());
        dispatch(getWorkspaceById());
      } else if (result?.context === 'task') {
        dispatch(addCommentToTask(result));
      }
    });

    socket.on('reply-edited', (result) => {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];

      if (result?.roomId?.toString() === chatRoomId?.toString()) {
        // dispatch(
        //   editChatReply({
        //     roomId: chatRoomId,
        //     messageId: result?._id,
        //     messageDescription: result?.repliedMsg,
        //     parent: result?.parent
        //   })
        // );
        dispatch(updateSingleMessage(result));
      }
    });

    socket.on('user-added-successfully', () => {
      dispatch(getUsers());
    });

    socket.on('message-reply', function (message) {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];
      const msgId = new URLSearchParams(window?.location?.search).get('msgId');

      if (
        message?.roomId?.toString() === chatRoomId?.toString() ||
        msgId?.toString() === message?._id?.toString()
      ) {
        // dispatch(updateSingleMessage(message));
        dispatch(addChatReply(message));
        dispatch(setScrollPosition());
        message &&
          message.files &&
          message.files.map(async (file) => {
            const { signedUrl, fileId } = await dispatch(
              downloadChatUrl({
                fileId: file,
                chatId: message.roomId,
              }),
            );
            if (signedUrl) {
              await dispatch(getFileUsingSignedUrl(signedUrl, fileId));
            }
          });
        // dispatch(getMessages(chat.roomId, 'chat-received'));
      } else {
        console.log('Else runs...');
        // dispatch(getChatRooms());
        dispatch(getWorkspaceById());
        dispatch(getWorkspaceNotifications(query));
      }
    });

    socket.on('chat-received', function (chat) {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];

      dispatch(incrementChatUnreadCount(chat));
      if (chat?.roomId?.toString() === chatRoomId?.toString()) {
        dispatch(addChatMessage(chatRoomId, chat));
        dispatch(setScrollPosition());
        chat &&
          chat.files &&
          chat.files.map(async (file) => {
            const { signedUrl, fileId } = await dispatch(
              downloadChatUrl({
                fileId: file,
                chatId: chat.roomId,
              }),
            );
            if (signedUrl) {
              await dispatch(getFileUsingSignedUrl(signedUrl, fileId));
            }
          });
      } else if(!chats?.find(c => c?._id?.toString() === chatRoomId?.toString())) {
        // Chat is not loaded in state. This might be a new chat
        dispatch(getWorkspaceById());
      }else {
        console.log('Else runs...');
        // Get notification after 3s as chat-received is sent back before notification is inserted in database

        // setTimeout(() => {
        //   dispatch(getWorkspaceNotifications(query));
        // }, 3000);
      }
    });

    socket.on('UpdateNotification', (not) => {
      dispatch(updateNotificationState(not));
    });

    socket.on('InviteAccepted', (payload) => {
      if (payload?.targetType === 'worksapce') {
        dispatch(getWorkspaces('lean'));
      }
    });
  }, [dispatch]);

  const getPath = (dest) => {
    const userToBeVerified = new URLSearchParams(window.location.search).get(
      'verifyUser',
    );
    if (userToBeVerified) {
      console.log('----- userToBeVerified', userToBeVerified);
      const user = {
        _id: userToBeVerified,
        isVerified: true,
      };
      dispatch(verifyUser(user, () => {}));
    }
    if (user || token) {
      return `/${workspaceId}/${dest}`;
    }
    return '/';
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/all-tasks"
          element={
            <Layout phase={'home'}>
              <Home />
            </Layout>
          }
        />
        <Route
          exact
          path="/home"
          element={
            <Layout phase="home">
              <Home />
            </Layout>
          }
        />
        <Route exact path="/get-started" element={<GetStarted />} />
        <Route
          exact
          path="/activity"
          element={
            <Layout phase="home">
              <Activity />
            </Layout>
          }
        />
        <Route
          path="/search"
          element={
            <Layout phase={'search'}>
              <Search />
            </Layout>
          }
        />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/pricing/users" element={<PricingUsers />} />
        <Route exact path="/payment/success" element={<PaymentSuccess />} />
        <Route exact path="/payment/error" element={<PaymentError />} />

        <Route
          exact
          path="/:projectid/board"
          element={
            <Layout phase="project">
              <TaskProject />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/list"
          element={
            <Layout phase="project">
              <TaskProject />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/chat"
          element={
            <Layout phase="project">
              <ProjectChat />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/:taskid"
          element={
            <Layout phase="project">
              <TaskProject />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/:taskid/:fullView"
          element={
            <Layout phase="project">
              <TaskProject />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/calendar"
          element={
            <Layout phase="project">
              <CalendarComponent />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/settings"
          element={
            <Layout phase="project">
              <ProjectSettings />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/members"
          element={
            <Layout phase="project">
              <ProjectMembers />
            </Layout>
          }
        />
        <Route
          exact
          path="/:projectid/custom-fields"
          element={
            <Layout phase="project">
              <ProjectCustomFieldsTab />
            </Layout>
          }
        />
        <Route
          exact
          path="/chat/:chatId"
          element={
            <Layout phase="chat">
              <Chat />
            </Layout>
          }
        />
        {/* <Route exact path='/teams' element={
          <Layout phase='teams'>
            <Teams />
          </Layout>
        } /> */}
        <Route
          exact
          path="/admin-console"
          element={
            <Layout phase="admin-console">
              <AdminConsole />˝
            </Layout>
          }
        />
        <Route exact path="/create-workspace" element={<CreateWorkspace />} />
        <Route exact path="/select-workspace" element={<SelectWorkspace />} />
        <Route
          exact
          path={getPath('')}
          element={
            user?._id || token ? (
              <Layout phase="workspace">
                <Dashboard />
              </Layout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
        <Route
          exact
          path="/invite"
          element={
            <Layout phase="workspace">
              <Dashboard />
            </Layout>
          }
        />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/createpassword" element={<NewPassword />} />
        <Route exact path="/payment" element={<StripeWrapper />} />
        <Route exact path="/admin/sales" element={<AllUsers />} />
        <Route
          path="*"
          element={
            user?._id || token ? (
              <Navigate to={getPath('')} replace />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
