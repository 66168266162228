import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { toggleNewProjectModal } from "../../../../actions";
import { getRandomColor } from '../../../../js/helper';
import Button from "../../atoms/global/Button";
import EmptyState from '../../atoms/empty-state/EmptyState';
import { FormattedMessage, useIntl } from 'react-intl';

const ProjectsTooltip = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const projects = props.projects;
  const isListView = useSelector((state) => state.toggleReducer.isListView);

  return (
    <div className="col-span-full xl:col-span-6 bg-white w-[20vw]">
      <header className="flex align-center justify-between px-5 py-4 border-b border-slate-100">
        <h2 className="mr-5 font-20 font-light text-slate-800"><FormattedMessage id="PROJECTS" defaultMessage="Projects" /></h2>
        <Button
          name={intl.formatMessage({ id: 'ADD_PROJECT', defaultMessage: 'Add Project' })}
          src="addblack.svg"
          height="12px"
          class="font-12"
          onClick={() => dispatch(toggleNewProjectModal({
            value: true
          }))}
          style={{ width: 'max-content', height: 10 }}
        />
      </header>
      <div className="p-3">

        {/* Card content */}
        {projects?.length > 0 ? projects?.map((project, index) => (
          <div>
            <header
              className={`px-4 py-3 my-1 flex align-center hover:bg-indigo-50 hover:rounded-lg cursor-pointer ${window?.location?.pathname.includes(project?._id) && 'bg-indigo-50 rounded-lg'}`}
              onClick={() => navigate(`/${project?._id}/board`)} >
              <div className={`square-letter-large h-10 w-10`} style={{ background: getRandomColor(index) }}>
                <img src='/images/icons/board.svg' />
              </div>
              <span className="ml-3 truncate font-14">{project?.title}</span>
            </header>
            {/* <ul className="my-1">
            
            </ul> */}
          </div>
        )) : <div className='my-4'>
          <EmptyState
            header={intl.formatMessage({ id: 'NO_PROJECTS', defaultMessage: 'You Have No Projects!' })}
            description={intl.formatMessage({ id: 'CREATE_PROJECT_TO_GET_STARTED', defaultMessage: 'Create a new project to get started.' })}
            iconSize='h-16 w-16'
            src='/images/icons/no-task.svg' />
        </div>}
      </div>
    </div>
  );
};

export default ProjectsTooltip;
