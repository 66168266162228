import React, { useState } from 'react';
import _ from 'lodash';
import { updateChatRoomSettings, getChatRoomSettings } from '../../../../actions'
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function RoomPicture({ room, isRoom = false }) {
  const [newPicture, setNewPicture] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const dispatch = useDispatch();
  const roomId = _.get(room, '_id');

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event) => {
    const newFile = await toBase64(event.target.files[0]);
    setNewPicture(newFile);
    dispatch(
      updateChatRoomSettings(
        roomId, { ...room, roomAvi: newFile },
        () => {
          dispatch(getChatRoomSettings(roomId));
        }
      )
    );
  };

  return (
    <div
      className={`relative  
      ${isRoom ? 'max-w-[80px] max-h-[80px] md:max-w-[100px] md:max-h-[100px]' : 'max-w-[100px] max-h-[100px] md:max-w-[300px] md:max-h-[300px]'} `}
      onMouseEnter={() => setShowUploadButton(true)}
      onMouseLeave={() => setShowUploadButton(false)}
    >
      <img src={newPicture || room?.roomAvi} alt="Profile Picture" width="300" height="300" />
      {showUploadButton && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/jpeg,image/png';
            fileInput.onchange = handleFileChange;
            fileInput.click();
          }}
        >
          <p style={{ color: 'white' }}>
            <FormattedMessage id="CHANGE_ROOM_PICTURE" defaultMessage="Change Room Picture" />
          </p>
        </div>
      )}
    </div>
  );
}

export default RoomPicture;