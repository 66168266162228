import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import JoinProject from '../components/dashboard/JoinProject';
import EmptyState from '../../components/UI/atoms/empty-state/EmptyState';

const JoinProjectsDrawer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    user,
    allProjects,
    myProjects
  } = props;

  return (
    <div className='h-[83vh]'>
      <div className='bg-[#0074cc] h-40 flex flex-col px-8' style={{ justifyContent: 'flex-end' }}>
        <div className='text-white font-black text-2xl mb-6'>Join Projects</div>
      </div>
      <div className='m-2 overflow-scroll h-full'>
        {allProjects?.map(project => {
          if (myProjects?.find(x => x?._id == project?._id)) {
            return;
          }
          return <JoinProject project={project} user={user} />
        })}
        {(allProjects?.length == 0 || myProjects?.length == allProjects?.length) && <EmptyState
          header={"No Active Projects!"}
          description={'Create a New Project or Join one to get Started!'}
          iconSize='h-60 w-60'
          src='/images/icons/no-project.svg' />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  allProjects: state.workspaceReducer.workspaceProjects,
  myProjects: state.projectReducer.myProjects,
});

export default connect(mapStateToProps)(JoinProjectsDrawer);
