import { Dropdown } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function StepFour({ setSelectedTemplate, handleClick, onBack, isCreating, templates, selectedTemplate }) {
  const intl = useIntl();
  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">

        <h1 className="text-3xl text-slate-800 font-bold mb-6">
          <FormattedMessage id="SELECT_PROJECT_TEMPLATE" defaultMessage="Select project template!" />
        </h1>
        <div className='font-light mb-8'>
          <FormattedMessage
            id="TEMPLATES_DESCRIPTION"
            defaultMessage="Templates are sections within your projects where you can categorize tasks. Choose from our predefined templates, you can edit them after!"
          />
        </div>
        {/* Form */}
        <Dropdown menu={{
          items: templates?.map(template => {
            return ({
              key: template?._id,
              label: intl.formatMessage({ id: `${template?.name}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: template?.name }),
              onClick: () => setSelectedTemplate(template)
            })
          })
        }}
          trigger={'click'}>
          <div className={`cursor-pointer w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
            <div>{!!selectedTemplate
              ? <FormattedMessage id={`${selectedTemplate?.name}`.replaceAll(' ', '_').toUpperCase()} defaultMessage={selectedTemplate?.name} />
              : <FormattedMessage id="SELECT_TEMPLATE" defaultMessage="Select Template" />
            }</div>
          </div>
        </Dropdown>
        <div className="flex items-center justify-between mt-8">
          <div
            onClick={onBack}
            className="text-16 text-blue-500 underline hover:no-underline px-2 border-radius10 cursor-pointer">
              <FormattedMessage id="GO_BACK" defaultMessage="Go Back" />
          </div>
          <div
            onClick={handleClick}
            className={`btn bg-blue-500 hover:bg-blue-400 text-white ml-auto p-2 px-5 rounded-md cursor-pointer ${!selectedTemplate?.name || isCreating && 'disabled'}`}>
              <FormattedMessage id="CREATE_WORKPLACE" defaultMessage="Create Workplace" />
          </div>
        </div>

      </div>
    </div>
  );
}

export default StepFour;