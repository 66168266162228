import axios from 'axios';
import { notification } from 'antd';
import { UnauthorizedHandler } from '../js/helper/UnauthorizedHandler';

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: '' },
};

export const signUp = (data, callBack) => {
  return async (dispatch) => {
    try {
      let fullName = await data.fullName;
      let email = await data.emailAddress;
      let password = await data.password;
      let timezones = await data.timezones;

      const res = await axios.post(`${baseUrl}/register`, {
        fullName: fullName,
        email: email,
        password: password,
        timezones: timezones,
        locale: navigator.language || 'en',
      });
      dispatch({
        type: 'signUp-success',
        payload: res,
      });
      dispatch({
        type: 'user',
        payload: res.data.data,
      });
      localStorage.setItem('token', res?.data?.data?.token);
      localStorage.setItem('Id', res?.data?.data?._id);
      //callBack();
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: 'signUp-error',
        payload: error?.response?.data?.message,
      });
      notification.error({
        message: error?.response?.data?.message || 'Unable to register user',
        placement: 'bottomRight',
      });
      throw new Error(error?.response?.data?.message);
    }
  };
};

export const resendEmail = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${baseUrl}/register`, data);
      dispatch({
        type: 'signUp-success',
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: 'signUp-error',
        payload: error,
      });
    }
  };
};

export const resendConfirmEmail = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      await axios.post(`${baseUrl}/register/resendConfirmEmail`, data, config);
      notification.success({
        message: 'Verification email sent',
        placement: 'bottomRight',
      });
    } catch (error) {
      dispatch({
        type: 'signUp-error',
        payload: error,
      });
      notification.error({
        message: 'Unable to send email',
        placement: 'bottomRight',
      });
    }
  };
};

export const signIn = (data, callBack, callBackNotVerified, callBackError) => {
  return async (dispatch) => {
    try {
      let res = null;
      if (data.token) {
        res = await axios.post(`${baseUrl}/sso-login`, data);
      } else {
        res = await axios.post(`${baseUrl}/login`, data);
      }

      // if (res.data.isVerified) {
      dispatch({
        type: 'signin-success',
        payload: res,
      });

      localStorage.setItem('token', res.data.token);
      localStorage.setItem('Id', res.data.userId);
      if (res.data?.defaultWorkspaceId) {
        sessionStorage.setItem('workspaceId', res.data.defaultWorkspaceId);
        localStorage.setItem('workspaceId', res.data.defaultWorkspaceId);
        sessionStorage.setItem('organization', res.data.organization);
        localStorage.setItem('organization', res.data.organization);
      }
      dispatch({
        type: 'user',
        payload: res.data.user,
      });
      callBack();
      // } else {
      //   callBackNotVerified();
      // }
      //error handle //verify your email
    } catch (err) {
      dispatch({
        type: 'signin-error',
        payload: err,
      });
      callBackError(err);
    }
  };
};

export const sendOTP = (data, callback, callBackError) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(`${baseUrl}/register/resetpassword`, {
        email: data,
      });
      console.log('sendOTP', res.status);
      if (res.status === 200) {
        dispatch({
          type: 'OTP-success',
          payload: res,
        });
        callback();
        notification.success({
          message: 'OTP sent',
          placement: 'bottomRight',
        });
      } else {
        callBackError();
        notification.error({
          message: 'Oops! somthing Went Wrong',
          placement: 'bottomRight',
        });
      }
    } catch (error) {
      dispatch({
        type: 'OTP-error',
        payload: error,
      });
      callBackError();
      notification.error({
        message: 'Oops! somthing Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};

export const verifyOTP = (data, callBack) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${baseUrl}/register/verifyotp`, {
        email: data.email,
        otp: data.otp,
      });
      dispatch({
        type: 'OTP-verified',
        payload: res.data.data,
      });
      if (res.data.data.isOTPConfirmed) {
        callBack();
      }
    } catch (error) {
      dispatch({
        type: 'OTP-error',
        payload: error,
      });
      notification.error({
        message: 'OTP could not be verified. Please try again!',
        placement: 'bottomRight',
      });
    }
  };
};

export const saveEmailLead = (email) => {
  return async () => {
    try {
      await axios.post(`${baseUrl}/user/lead`, {
        email: email,
      });
    } catch (error) {

    }
  };
};

export const updatePassword = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(`${baseUrl}/register/update-password`, {
        _id: data.id,
        password: data.password,
      });
      dispatch({
        type: 'password-update',
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: 'password-error',
        payload: error,
      });
    }
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: 'clear-error',
    });
  };
};
