import React, { useMemo } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import HtmlRenderer from '../../../components/UI/organisms/HtmlRenderer';
import { addChatRoom, isChatOpen, isTicketOpen } from '../../../actions';
import { getWorkspaceNotifications, updateViewNotification } from '../../../actions/notificationAction';
import { setTeamsState } from '../../../actions/toggleAction';
import UserAvatar from '../../../components/UI/atoms/ticket-drawer/UserAvatar';
import { activityNotificationsLookup, getLocalizedActivity } from '../../../_locale/localesLookupTable';

const DashboardActivity = ({ notification }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const workspaceId = localStorage.getItem('workspaceId');

  const {
    createdAt,
    task,
    notificationType,
    projects,
    assignor,
    title,
    description,
    _id
  } = notification;

  const {
    avi,
    fullName,
    _id: assignorId
  } = assignor || {};

  const currentMessageDate = new Date(createdAt).toLocaleDateString('en-us',
    {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric"
    });


  const handleNotificationClick = () => {
    const projectId = projects && projects?.[0] || '';
    const taskIdentifier = task?.titleIdentifier;

    dispatch(updateViewNotification({ id: _id }));
    dispatch(getWorkspaceNotifications());
    dispatch(isTicketOpen(true, taskIdentifier));

    try {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('selectedTask')) {
        searchParams.delete('selectedTask');
      }

      // Remove the 'msgId' parameter if it exists
      if (searchParams.has('msgId')) {
        searchParams.delete('msgId');
      }
      if (
        notificationType === 'TaskNotification' ||
        notificationType == 'ChatNotification'
      ) {
        // const url = notification?.notificationType == 'TaskNotification' ? `/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}` : `/chat/${notification?.comment?.roomId}`
        if (notification?.notificationType == 'TaskNotification') {
          searchParams.set('selectedTask', taskIdentifier);
          const newUrl = `${location.pathname}?${searchParams.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          navigate(`/chat/${notification?.comment?.roomId}`);
        }
        dispatch(setTeamsState(false))
      } else if (notificationType == 'ChatReplyNotification') {
        dispatch(isChatOpen(true, notification?.repliedMessage?._id));
        searchParams.set('msgId', notification?.comment?._id);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // const url = `/chat/${notification?.comment?.roomId}?msgId=${notification?.comment?._id}`
        // navigate(url);
        dispatch(setTeamsState(false))
      } else if (notificationType == 'InviteNotification') {
        const url = `/${notification?.contextId}/board`;
        navigate(url);
      } else if (notificationType == 'WorkspaceJoinedNotification') {
        // Do nothing
        const members = [notification?.assignor];
        dispatch(addChatRoom({ members, roomName: null, workspaceId }))
        .then(createdChat => {
          setTimeout(() => {
            navigate(`/chat/${createdChat._id.toString()}`);
          }, 100);
        })

      } else if (notificationType == 'ProjectJoinedNotification') {
        const url = `/${notification?.contextId}/board`;
        navigate(url);
        // Do nothing
      } else {
        searchParams.set('selectedTask', taskIdentifier);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // navigate(`/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}`);
      }
    } catch (error) {
      navigate(`/`);
    }
  }

  const localizedTitle = useMemo(() => getLocalizedActivity(title, intl), [intl, title]);

  const localizedDescription = useMemo(() => getLocalizedActivity(description, intl), [intl, description]);

  return (
    <div onClick={handleNotificationClick} className='hover:bg-slate-50 border-l-4 border-slate-100 hover:border-blue-400 cursor-pointer p-4 px-8 mb-1'>
      <div className='flex align-center'>
        <UserAvatar
          alt="User notification"
          classNames="rounded-full xl:size-10 size-8 xl:mr-3 mr-2"
          fullName={fullName}
          userId={assignorId}
          src={avi}
          styles={{
            objectFit: 'cover',
          }}
        />
        <div>
          <div className='2xl:text-[16px] lg:text-[12px] text-[16px] font-bold'>{localizedTitle}</div>
          <div className='text-xs'><FormattedMessage id="FROM" defaultMessage="From"/> {fullName} <span className='mx-1'>|</span> <span className='text-blue-600'><FormattedDate value={currentMessageDate} /></span></div>
        </div>
      </div>
      <div className='mt-2 text-sm opacity-[80%]' style={{ wordWrap: 'break-word' }}>
        <HtmlRenderer html={localizedDescription} />
      </div>
    </div>
  );
};

export default DashboardActivity;
