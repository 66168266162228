import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  deleteChatFile,
  isChatOpen,
  setDrawerWidth,
  signedUploadUrl,
  uploadChatUrl,
} from '../../../actions';
import DrawerCommentSection from '../molecules/global/DrawerCommentSection';
import ChatDrawerTextArea from '../molecules/global/ChatDrawerTextArea';
import TimeAgo from 'javascript-time-ago';
import socket from '../../../js/socket';
import { Resizable } from 're-resizable';

import { Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatDrawer = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const { currentReceiver } = useSelector(
    (state) => state.chatReducer
  );
  const { singleMessage } = useSelector((state) => state.messageReducer);

  const drawerWidth = useSelector((state) => state.toggleReducer.drawerWidth);
  const dispatch = useDispatch();
  const closeChat = () => {
    dispatch(isChatOpen(false));
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter

    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);
  };

  const { messageDescription, sender, createdAt, _id } = singleMessage;

  useEffect(() => {
    socket.on('connection', (s) => {
      s.on('disconnect', function () {
        console.log('Socket Disconnected');
      });
    });
  }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1500)
  };

  const chatDrawerCommentsSecRef = useRef(null);
  const chatDrawerTextAreaDivRef = useRef(null);

  const setImageUrl = async (file) => {
    const chatIdPattern = /\/(?:chat\/)?([^/]+)/;
    const match = window.location.pathname.match(chatIdPattern);
    let roomId;
    if (match && match.length > 1) {
      roomId = match[1];
    }

    const uploadChatResponse = await dispatch(uploadChatUrl({
      fileName: file?.name,
      chatId: props?.roomId || roomId,
      fileSize: file?.size,
      type: file?.type
    }))
    const signedUrl = uploadChatResponse?.signedUrl;
    const fileId = uploadChatResponse?.fileId;
    if (signedUrl) {
      await dispatch(signedUploadUrl(signedUrl, file));
      return fileId;
    }
  }

  const delAttachment = async (fileId) => {
    dispatch(deleteChatFile(fileId));
  }

  let lastSenderId = undefined;

  const generateComment = (reply, i) => {
    let showName =
      !lastSenderId || reply.sender?._id !== lastSenderId;
    lastSenderId = reply.sender?._id;
    const date = new Date(reply.createdAt);
    const timeAgo = new TimeAgo(intl.locale);
    const time = timeAgo.format(date);
    return (
      <div key={reply?._id}>
        {i === 0 && (
          <div className='date-row mx-10 mt-2'>
            <div className='date-row-pill px-4'>
              <FormattedMessage id="REPLIES" defaultMessage="Replies" />
            </div>
            <div className='date-row-line border-slate-200' />
          </div>
        )}
        <DrawerCommentSection
          msgId={reply?._id}
          height='35px'
          width='35px'
          containerWidth='auto'
          name={reply.sender.fullName}
          nameClass='font-18-600 ml-1'
          commentClass='font-16 ml-3 mt-5'
          showName={showName}
          src={reply.sender.avi}
          hours={time}
          comment={reply.repliedMsg}
          attachments={reply?.attachments}
          files={reply?.files}
          senderId={reply.sender?._id}
          context='chat'
          roomId={singleMessage?.roomId}
          senderName={reply.sender.fullName}
        />
      </div>
    );
  };

  const resizableRef = useRef(null);

  return (
    <>{singleMessage?._id &&
      <Resizable
        className='chat-drawer'
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        ref={resizableRef}
        defaultSize={{
          width: props.drawerWidth,
          height: '100%',
        }}
        maxWidth='750px'
        minWidth='510px'
        onResizeStart={(e, direction, ref, delta) => {
          ref.classList.add('border-left-blue');
        }}
        onResizeStop={(e, direction, ref, delta) => {
          ref.classList.remove('border-left-blue');
          dispatch(setDrawerWidth(ref.offsetWidth));
        }}
      >
        <div className='chat-reply-container scroll-y mt-0.5'>
          <div className='flex align-center justify-between h-16 px-4 border-b-2 border-slate-200'>
            <div className='font-16-400 '>
              <FormattedMessage
                id="REPLYING_TO"
                defaultMessage="Replying to <span>{name}</span>"
                values={{
                  span: (msg) => (<span style={{ color: '#0074cc', marginLeft: '5px' }}>{msg}</span>),
                  name: sender?.fullName
                }}
              />
            </div>
            <Tooltip title={intl.formatMessage({ id: 'CLOSE', defaultMessage: 'Close' })} className='pr-2'>
              <div onClick={closeChat} className='hover:opacity-60 cursor-pointer'>
                <img src='/images/icons/close.svg' alt='close' />
              </div>
            </Tooltip>
          </div>

          <div
            className='scroll-y full-height'
            style={{
              overflowY: 'scroll',
              backgroundColor: '#f7f7f7',
            }}
            ref={chatDrawerCommentsSecRef}
          >
            <div className='chat-section px-2 mt-2'>
              {generateComment({
                sender: sender,
                repliedMsg: messageDescription,
                createdAt: createdAt,
                _id: _id
              })}
              <div>
                {singleMessage?.replies.map((reply, i) => generateComment(reply, i))}
                <div ref={messagesEndRef} />
              </div>
            </div>
          </div>
          <div ref={chatDrawerTextAreaDivRef} style={{ paddingBottom: '1rem', background: '#f7f7f7' }}>
            <ChatDrawerTextArea
              scrollToBottom={scrollToBottom}
              roomName={currentReceiver}
              emojiPosition={drawerWidth}
              setImageUrl={setImageUrl}
              delAttachment={delAttachment}
            />
          </div>
        </div>
      </Resizable>}</>
  );
};

export default ChatDrawer;
