export const getLocalizedShortDate = (date, intl, shortMonth) => {
    let ordinal = '';

    const parts = intl.formatDateToParts(date, {
        year: '2-digit',
        month: shortMonth ? 'short' : 'long',
        day: '2-digit'
    });
    let shortDate = parts[0].value + parts[1].value + parts[2].value

    const day = parts[2].value;

    switch (intl.locale) {
        case 'en-US': 
            ordinal =
                day % 10 === 1 && day !== 11
                    ? "st"
                    : day % 10 === 2 && day !== 12
                        ? "nd"
                        : day % 10 === 3 && day !== 13
                            ? "rd"
                            : "th";
            break;
        default: {
            ordinal = ''
        }
    }

    shortDate = shortDate + ordinal
    return shortDate;
}