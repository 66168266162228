import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import SigninForm from '../molecules/SigninForm';
import {
  signIn,
  getWorkspaces,
  getWorkspaceInvites,
  getUser,
} from '../../../actions';
import Icon from '../atoms/global/Icon';
import Loader from '../atoms/global/Loader';
import { Mobile } from '../../pages/Mobile';
import { FormattedMessage } from 'react-intl';

const SignIn = () => {
  const [passLoginIsOpen, setPassLoginIsOpen] = useState(false);
  const navigate = useNavigate();

  const signInError = useSelector((state) => state.signupReducer.error);

  const dispatch = useDispatch();

  const {
    loginWithRedirect,
    user,
    getAccessTokenSilently,
    isLoading,
    error,
    logout,
    ...props
  } = useAuth0();

  useEffect(() => {
    const login = async () => {
      try {
        const token = await getAccessTokenSilently();

        dispatch(
          signIn(
            { token },
            async () => {
              await dispatch(getWorkspaces('SigninForm'));
              await dispatch(getWorkspaceInvites());
              const user_id = localStorage.getItem('Id');
              await dispatch(getUser(user_id));
              navigate('/select-workspace', { replace: true });
            },
            () => { },
            (e) => {
              console.log(e);
            },
          ),
        );
      } catch (e) {
        console.log(e);
        logout();
      }
    };

    if (user) {
      login();
    }
  }, [user]);

  const isMobile = window.screen?.width < 700;

  if (isMobile) {
    return <Mobile />
  }

  if (!error && (user || isLoading)) {
    return <Loader newLoader={true} fullscreen={true} />;
  }


  return (
    <div className="flex align-center justify-between h-[100vh]">
      <div className="w-2/5 h-full p-5">
        <Icon name="logo.svg" height="26px" pr="20px" />
        <div className="flex flex-col align-center justify-center mt-24 mx-10">
          <h1
            className="text-darkGray font-40"
            style={{ textAlign: 'center', marginBottom: 30 }}
          >
            <FormattedMessage id="WELCOME_BACK_SIGN_IN" defaultMessage="Welcome back!" />            
          </h1>

          <button
            onClick={() => loginWithRedirect()}
            className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-white text-gray-800 font-medium rounded-md border border-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 text-base"
          >
            <img
              src="/images/icons/google-logo.svg"
              alt="Google Logo"
              className="h-5 w-5"
            />
            <div><FormattedMessage id="CONTINUE_GOOGLE" defaultMessage="Continue with Google"/></div>
            <div>|</div>
            <img
              src="/images/icons/apple-logo.svg"
              alt="Apple Logo"
              className="h-5 w-5"
            />
            <div><FormattedMessage id="CONTINUE_APPLE" defaultMessage="Continue with Apple"/></div>
          </button>

          {!passLoginIsOpen && (signInError || error) && (
            <div className="font-12 text-red-500">
              <FormattedMessage id="SSO_LOGIN_FAILED" defaultMessage="SSO Login Failed"/>
            </div>
          )}
          <div className="mt-10 flex items-center gap-4 w-80">
            <div
              className="flex-grow h-px"
              style={{ backgroundColor: '#C9C9C9' }}
            ></div>
            <div
              className="text-lg font-bold whitespace-nowrap"
              style={{ color: '#686868' }}
            >
              Or
            </div>
            <div
              className="flex-grow h-px"
              style={{ backgroundColor: '#C9C9C9' }}
            ></div>
          </div>
          <SigninForm />
        </div>
      </div>
      <div className="w-3/5 bg-blue-500 h-[97vh] mx-5 rounded-xl flex align-center justify-center">
        <div className="flex flex-col align-center justify-center">
          <div className="mb-20 text-4xl font-bold text-white text-center mx-10">
            <FormattedMessage id="WORKFLO_ADDVERTISEMENT_PHRASE_2" defaultMessage="Productivity Tailored To You" />
          </div>
          <img
            src="https://939023.p3cdn1.secureserver.net/wp-content/uploads/2024/12/Group-5141856-1.png"
            width={'95%'}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
