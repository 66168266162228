import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Tooltip, Layout } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import IconAndName from '../atoms/side-navbar/IconAndName';
import Stepper from '../atoms/side-navbar/Stepper';
import NavTooltip from '../atoms/side-navbar/NavTooltip';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  collapse,
  toggleNewTaskModal,
  toggleNewProjectModal,
  toggleNewTeamChatsModal,
  openInvitePanel,
  toggleWorkspaceFieldsModal,
} from '../../../actions';
import NavBarContainer from '../molecules/side-navbar/NavBarContainer';
import SwitchWorkspacePanel from '../molecules/side-navbar/SwitchWorkspacePanel';
import { truncateString } from '../../../js/helper';

import NotificationTooltip from '../molecules/tooltips/NotificationTooltip';
import ChatsTooltip from '../molecules/tooltips/ChatsTooltip';
import ProjectsTooltip from '../molecules/tooltips/ProjectsTooltip';
import Icon from '../atoms/global/Icon';
import { isAIDrawerOpen, isUserDrawerOpen } from '../../../actions/toggleAction';
import { getUserLicense } from '../../../js/functions';

const LeftNavBar = (props) => {
  const {
    defaultResize,
    workspaces,
    myProjects,
    teams,
    chats,
    isMobile,
    setSidebarOpen,
    setIsLoading
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const toggle = useSelector((state) => state.navReducer.toggle);
  const loggedUser = useSelector((state) => state.userReducer.user);
  const modalState = useSelector((state) => state.modalReducer);

  const [nav, setNav] = useState(false);
  const [selectedLeftNav, setSelectedLeftNav] = useState("Home");
  const [currentWorkspace, setCurrentWorkspace] = useState(props.currentWorkspace);
  const [isWorkspaceDowndownOpen, setIsWorkspaceDowndownOpen] = useState(false);

  const [notifications, setNotifcations] = useState(props.notifications?.notifications || props.notifications);
  const [unredNotifications, setUnredNotifications] = useState(0);
  const [unredChatNotifications, setUnredChatNotifications] = useState(0);

  const [localChats, setLocalChats] = useState(chats);
  const [localTeams, setLocalTeams] = useState(teams);
  const [localProjects, setLocalProjects] = useState(myProjects);

  const toggleWorkspacePanel = () => {
    setNav((nav) => !nav);
  };

  const navCollaps = (reset) => {
    if (defaultResize) defaultResize();
    dispatch(collapse(reset != undefined ? reset : !toggle));
  };

  useEffect(() => {
    setCurrentWorkspace(props?.currentWorkspace);
    setLocalTeams(teams);
    setLocalChats(chats);
    setLocalProjects(myProjects);
  }, [props?.currentWorkspace, workspaces, chats, teams, myProjects]);

  useEffect(() => {
    const handleAsync = async () => {
      await Promise.all([
        setNotifcations(props.notifications?.notifications || props.notifications),
        setUnredChatNotifications(props.notifications?.mentionRepliesNotifications),
        setUnredNotifications(props.notifications?.unreadNotifications + props?.pendingInvites?.length)
      ]);
    };

    handleAsync();
  }, [props.notifications])

  const workspaceName = truncateString(currentWorkspace?.title, 22) || intl.formatMessage({ id: 'MY_WORKSPACE', defaultMessage: 'My workspace' });
  const iconLetters = workspaceName?.slice(0, 2)?.toUpperCase();

  const me = currentWorkspace?.members?.find(m => m?._id?.toString() === loggedUser?._id?.toString());
  const isOwnerOrAdmin = me?.role == 'owner' || me?.role == 'admin';

  const workspaceDropdownItems = isOwnerOrAdmin ? [
    {
      key: '1',
      label: (
        <div className='flex flex-col align-center justify-center px-2 cursor-pointer hover:bg-slate-100 w-100' onClick={() => {
          navigate('/');
          setNav(false);
        }}>
          <div
            className={'square-letter-large my-3 mx-0 w-10 h-10 font-12'}
            style={{ background: '#F4BF00' }}>
            {iconLetters}
          </div>
          <div className="grow flex items-center border-b border-slate-200 text-sm py-2">
            <div className="grow flex justify-between">
              <div className='d-flex align-center' style={{ flexDirection: 'column' }}>
                <div className='font-20'>{workspaceName}</div>
                <div className='font-12-600'>
                  <FormattedMessage id="GO_TO_WORKSPACE_DASHBOARD" defaultMessage="Go to workspace dashboard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-1000' onClick={async () => {
          await dispatch(openInvitePanel(true, {
            pastInvites: currentWorkspace?.pendingInvites || [],
            existingMembers: currentWorkspace?.members || [],
            selectWorkspaceMembers: false,
            type: 'Workspace'
          }));
          setNav(false);
        }}>
          <div className='font-16'>
            <FormattedMessage id="INVITE_OTHERS_TO_WORKSPACE" defaultMessage="Invite others to workspace" />
          </div>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/create-workspace')}>
          <div className='font-16'>
            <FormattedMessage id="CREATE_NEW_WORKSPACE" defaultMessage="Create new workspace" />
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/select-workspace')}>
          <div className='font-16'>
            <FormattedMessage id="CHANGE_WORKSPACE" defaultMessage="Change Workspace" />
          </div>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/admin-console')}>
          <div className='font-16'>
            <FormattedMessage id="WORKSPACE_SETTINGS" defaultMessage="Workspace settings" />
          </div>
        </div>
      ),
    },
  ] : [
    {
      key: '1',
      label: (
        <div className='flex flex-col align-center justify-center px-2 cursor-pointer hover:bg-slate-100 w-100' onClick={() => {
          navigate('/');
          setNav(false);
        }}>
          <div
            className={'square-letter-large my-3 mx-0 w-10 h-10 font-12'}
            style={{ background: '#F4BF00' }}>
            {iconLetters}
          </div>
          <div className="grow flex items-center border-b border-slate-200 text-sm py-2">
            <div className="grow flex justify-between">
              <div className='d-flex align-center' style={{ flexDirection: 'column' }}>
                <div className='font-20'>{workspaceName}</div>
                <div className='font-12-600'>
                  <FormattedMessage id="GO_TO_WORKSPACE_DASHBOARD" defaultMessage="Go to workspace dashboard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/create-workspace')}>
          <div className='font-16'>
            <FormattedMessage id="CREATE_NEW_WORKSPACE" defaultMessage="Create new workspace" />
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/select-workspace')}>
          <div className='font-16'>
            <FormattedMessage id="CHANGE_WORKSPACE" defaultMessage="Change Workspace" />
          </div>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/admin-console')}>
          <div className='font-16'>
            <FormattedMessage id="WORKSPACE_SETTINGS" defaultMessage="Workspace settings" />
          </div>
        </div>
      ),
    },
  ];


  const navTooltipOnClick = (name) => {
    setSelectedLeftNav(name);
  }

  const NavIcon = (name, src, css, path, height = 16, items = [], line = 22) => {
    return (
      <>
        <IconAndName
          name={name}
          phase='sideNav'
          src={`${src}.svg`}
          height={height}
          classesForParentDiv={css}
          class={'font-14 leading-3 align-center'}
          path={path}
          onPress={() => {
            if (name == 'Settings') {
              dispatch(isUserDrawerOpen(true))
            } else if (name == 'Workflo AI') {
              dispatch(isAIDrawerOpen(true))
            } else {
              navigate(path);
            }
          }}
        />
        <Stepper items={items} line={line} />
      </>
    );
  };

  const homeLinks = () => {
    const license = getUserLicense(loggedUser, sessionStorage.getItem("organization") || localStorage.getItem("organization")) || 'Free Plan';
    return (
      <div style={{ padding: 10 }}>
        {NavIcon(intl.formatMessage({ id: 'DASHBOARD', defaultMessage: 'Dashboard' }), 'home', 'nav-item dashboardNavLink', `/${currentWorkspace?._id}/`, 20, [
          {
            label: intl.formatMessage({ id: 'INVITE_MEMBERS', defaultMessage: 'Invite Members' }),
            onPress: async () => {
              await dispatch(openInvitePanel(true, {
                pastInvites: currentWorkspace?.pendingInvites || [],
                existingMembers: currentWorkspace?.members || [],
                selectWorkspaceMembers: false,
                type: 'Workspace'
              }));
              setNav(false);
            },
            active: false
          },
          {
            label: intl.formatMessage({ id: 'CUSTOM_FIELDS', defaultMessage: 'Custom Fields' }),
            onPress: () => {
              dispatch(toggleWorkspaceFieldsModal(true))
            },
            active: false
          },
        ])}
        {NavIcon(intl.formatMessage({ id: 'TASKS', defaultMessage: 'Taks' }), 'tasks-tooltip', 'nav-item homeNavLink', '/all-tasks', 20)}
        {license != 'Free Plan' && NavIcon(intl.formatMessage({ id: 'WORKFLO_AI', defaultMessage: 'Workflo AI' }), 'custom-fields-white', 'nav-item aiNavLink', '/AI', 20)}
        {NavIcon(intl.formatMessage({ id: 'SETTINGS', defaultMessage: 'Settings' }), 'settingsWhite', 'nav-item teamsyNavLink', '/admin-console', 20)}
      </div>
    );
  }

  const setShowTaskModal = (value) => {
    dispatch(toggleNewTaskModal({
      value: value
    }));
  };

  const setShowProjectModal = (value) => {
    dispatch(toggleNewProjectModal({
      value: value
    }));
  };

  const setIsTeamsChatModalOpen = (value, isTeams) => {
    dispatch(toggleNewTeamChatsModal({
      value: value,
      isTeams: isTeams
    }));
  }

  const { Header, Content, Footer, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className='side-bar-container d-flex' style={{ height: '100%' }}>
      {!collapsed && <div className='quick-side-menu'>
        {collapsed &&
          <Tooltip title={intl.formatMessage({ id: 'SHOW_MENU', defaultMessage: 'Show Menu' })}>
            <div className='hover:opacity-60 mb-10'>
              <Icon name='minimize.svg' onClick={() => setCollapsed(false)} />
            </div>
          </Tooltip>}
        <div className='square-letter'
          onClick={toggleWorkspacePanel}
          style={{
            color: 'black',
            fontWeight: 600,
            background: 'white',
            border: '2px solid white',
            height: 40,
            width: 40,
            fontSize: '14px',
            marginBottom: '1rem',
            boxShadow: 'rgb(255 255 255 / 24%) 0px 1px 4px',
            marginTop: toggle ? '0' : '1.5rem'
          }}>
          {iconLetters}
        </div>
        {nav ?
          <SwitchWorkspacePanel toggleWorkspacePanel={toggleWorkspacePanel} workspaces={workspaces} setIsLoading={setIsLoading} />
          :
          <div className='flex flex-col h-full justify-between align-center w-full'>
            <div>
              <NavTooltip
                name={intl.formatMessage({ id: 'HOME', defaultMessage: 'Home' })}
                src={'home'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Home' && 'selected')}`}
                onClick={() => {
                  navTooltipOnClick('Home');
                }} />
              <div className='h-0.5 bg-gray-400 mx-3 mt-3' />
              <NavTooltip
                name={intl.formatMessage({ id: 'ACTIVITY', defaultMessage: 'Activity' })}
                src={'mail'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Activity' && 'selected')}`}
                onClick={() => {
                  navTooltipOnClick('Activity');
                }}
                notificationCount={unredNotifications}
                tooltipComponent={<NotificationTooltip notifications={notifications} />} />
              <NavTooltip
                name={intl.formatMessage({ id: 'PROJECTS', defaultMessage: 'Projects' })}
                src={'projects'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Projects' && 'selected')}`}
                onClick={() => navTooltipOnClick('Projects')}
                tooltipComponent={<ProjectsTooltip projects={myProjects} />} />
              <NavTooltip
                name={intl.formatMessage({ id: 'CHATS', defaultMessage: 'Chats' })}
                src={'chat-tooltip'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Chats' && 'selected')}`}
                onClick={() => navTooltipOnClick('Chats')}
                notificationCount={unredChatNotifications || 0}
                tooltipComponent={<ChatsTooltip chats={chats} />} />
            </div>
            {!toggle && <Dropdown
              trigger={['click']}
              className='mb-6 h-7'
              placement='topRight'
              menu={{
                items: [
                  {
                    key: 'projects-modal',
                    label: <div className='font-16 p-2'><FormattedMessage id="NEW_PROJECT" defaultMessage="New Project" /></div>,
                    onClick: () => setShowProjectModal(true)
                  },
                  {
                    key: 'chats-modal',
                    label: <div className='font-16 p-2'><FormattedMessage id="NEW_CHAT" defaultMessage="New Chat" /></div>,
                    onClick: () => setIsTeamsChatModalOpen(true, false)
                  },
                  // {
                  //   key: 'teams-modal',
                  //   label: <div className='font-16 p-2'>New Team</div>,
                  //   onClick: () => setIsTeamsChatModalOpen(true, true)
                  // },
                  {
                    key: 'task-modal',
                    label: <div className='font-16 p-2'><FormattedMessage id="NEW_TASK" defaultMessage="New Task" /></div>,
                    onClick: () => setShowTaskModal(true)
                  }
                ],
              }}
            >
              <img
                className='nav-button nav-settings-icon'
                src={'/images/icons/add.svg'}
                alt='icon'
              />
            </Dropdown>}
          </div>}
      </div>}
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={'20rem'} style={{ background: '#323337' }}>
          {!collapsed ? <div className={'sideNavBar h-full'}>
            <div>
              <div className={`nav-section justify-between pt-5 ${selectedLeftNav !== 'Home' && 'border-none'}`}>
                <div className='flex align-center'>
                  <div className='workspace-header'>{workspaceName}</div>
                </div>
                {toggle && <Tooltip title={intl.formatMessage({ id: 'HIDE_MENU', defaultMessage: 'Hide Menu' })}>
                  <div className='hover:opacity-60'>
                    <Icon name='minimize.svg' onClick={() => {
                      setCollapsed(!collapsed)
                    }} />
                  </div>
                </Tooltip>}
              </div>
              <div className='scroll-y' style={{ height: 'calc(100vh - 105px)', padding: '0px 10px' }}>
                {(selectedLeftNav === "Home") && homeLinks()}
                <NavBarContainer
                  chats={localChats}
                  teams={localTeams}
                  myProjects={localProjects}
                  workspaces={workspaces}
                  notifications={notifications}
                  currentWorkspace={currentWorkspace}
                  selectedLeftNav={selectedLeftNav} />
              </div>
            </div>
            <div className='d-flex align-center justify-center mb-10' style={{ width: '98%' }}>
              <div className='nav-button new-task-nav-button h-8 font-16' onClick={() => setShowTaskModal(true)}>
                <FormattedMessage id="CREATE_NEW_TASK" defaultMessage="Create new task" />
              </div>
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 'projects-modal',
                      label: <div className='font-16 p-2'><FormattedMessage id="NEW_PROJECT" defaultMessage="New Project" /></div>,
                      onClick: () => setShowProjectModal(true)
                    },
                    {
                      key: 'chats-modal',
                      label: <div className='font-16 p-2'><FormattedMessage id="NEW_CHAT" defaultMessage="New Chat" /></div>,
                      onClick: () => setIsTeamsChatModalOpen(true, false)
                    },
                    {
                      key: 'workspace-modal',
                      label: <div className='font-16 p-2'><FormattedMessage id="NEW_WORKSPACE" defaultMessage="New Workspace" /></div>,
                      onClick: () => navigate('/create-workspace')
                    },
                    // {
                    //   key: 'teams-modal',
                    //   label: <div className='font-16 p-2'>New Team</div>,
                    //   onClick: () => setIsTeamsChatModalOpen(true, true)
                    // }
                  ],
                }}
                placement='top'
                arrow
              >
                <div className='nav-button new-task-nav-button w-8 h-8'>
                  <img
                    className='h-3 w-3'
                    src={'/images/icons/add.svg'}
                    alt='icon'
                  />
                </div>
              </Dropdown>
            </div>
          </div> :

            <div className='quick-side-menu pl-2'>
              {collapsed &&
                <Tooltip title={intl.formatMessage({ id: 'SHOW_MENU', defaultMessage: 'Show Menu' })}>
                  <div className='hover:opacity-60 mb-4 mt-2'>
                    <Icon name='minimize.svg' onClick={() => setCollapsed(false)} />
                  </div>
                </Tooltip>}
              <div className='square-letter'
                onClick={toggleWorkspacePanel}
                style={{
                  color: 'black',
                  fontWeight: 600,
                  background: 'white',
                  border: '2px solid white',
                  height: 40,
                  width: 40,
                  fontSize: '14px',
                  marginBottom: '1rem',
                  boxShadow: 'rgb(255 255 255 / 24%) 0px 1px 4px',
                  marginTop: toggle ? '0' : '1.5rem'
                }}>
                {iconLetters}
              </div>
              {nav ?
                <SwitchWorkspacePanel toggleWorkspacePanel={toggleWorkspacePanel} workspaces={workspaces} setIsLoading={setIsLoading} />
                :
                <div className='flex flex-col h-full justify-between align-center w-full'>
                  <div>
                    <NavTooltip
                      name={intl.formatMessage({ id: 'HOME', defaultMessage: 'Home' })}
                      src={'home'}
                      classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Home' && 'selected')}`}
                      onClick={() => {
                        navTooltipOnClick('Home');
                      }} />
                    <div className='h-0.5 bg-gray-400 mx-3 mt-3' />
                    <NavTooltip
                      name={intl.formatMessage({ id: 'ACTIVITY', defaultMessage: 'Activity' })}
                      src={'mail'}
                      classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Activity' && 'selected')}`}
                      onClick={() => {
                        navTooltipOnClick('Activity');
                      }}
                      notificationCount={unredNotifications}
                      tooltipComponent={<NotificationTooltip notifications={notifications} />} />
                    <NavTooltip
                      name={intl.formatMessage({ id: 'PROJECTS', defaultMessage: 'Projects' })}
                      src={'projects'}
                      classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Projects' && 'selected')}`}
                      onClick={() => navTooltipOnClick('Projects')}
                      tooltipComponent={<ProjectsTooltip projects={myProjects} />} />
                    <NavTooltip
                      name={intl.formatMessage({ id: 'CHATS', defaultMessage: 'Chats' })}
                      src={'chat-tooltip'}
                      classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Chats' && 'selected')}`}
                      onClick={() => navTooltipOnClick('Chats')}
                      notificationCount={unredChatNotifications || 0}
                      tooltipComponent={<ChatsTooltip chats={chats} />} />
                  </div>
                  {!toggle && <Dropdown
                    trigger={['click']}
                    className='mb-6 h-7'
                    placement='topRight'
                    menu={{
                      items: [
                        {
                          key: 'projects-modal',
                          label: <div className='font-16 p-2'><FormattedMessage id="NEW_PROJECT" defaultMessage="New Project" /></div>,
                          onClick: () => setShowProjectModal(true)
                        },
                        {
                          key: 'chats-modal',
                          label: <div className='font-16 p-2'><FormattedMessage id="NEW_CHAT" defaultMessage="New Chat" /></div>,
                          onClick: () => setIsTeamsChatModalOpen(true, false)
                        },
                        // {
                        //   key: 'teams-modal',
                        //   label: <div className='font-16 p-2'>New Team</div>,
                        //   onClick: () => setIsTeamsChatModalOpen(true, true)
                        // },
                        {
                          key: 'task-modal',
                          label: <div className='font-16 p-2'><FormattedMessage id="NEW_TASK" defaultMessage="New Task" /></div>,
                          onClick: () => setShowTaskModal(true)
                        }
                      ],
                    }}
                  >
                    <img
                      className='nav-button nav-settings-icon'
                      src={'/images/icons/add.svg'}
                      alt='icon'
                    />
                  </Dropdown>}
                </div>}
            </div>
          }

        </Sider>
      </Layout>


    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    myProjects: state.projectReducer.myProjects,
    workspaces: state.workspaceReducer.getWorkspaces,
    projectCustomFields: state.projectReducer.projectCustomFields,
    chats: state.chatReducer.chats,
    teams: state.teamReducer.getTeams,
    notifications: state.notificationReducer.notifications,
    pendingInvites: state.workspaceReducer.pendingWorkspaceInvites,
  };
}

export default connect(mapStateToProps)(LeftNavBar);
