// validation for all input fields

/**
 * Validates an email address and executes a callback with the result.
 *
 * @param {string} email - The email address to validate.
 * @param {function} callback - The callback function to execute with the validation result.
 */
export const validateEmail = (email, intl, callback) => {
  // Regex checks for valid email format: alphanumeric characters, underscore, dash with a letter mandatory, followed by '@', domain name, and TLD (2+ letters).
  if (!email.trim()) {
    callback && callback(false, intl.formatMessage({ id: 'EMAIL_BLANK', defaultMessage: 'Email cannot be blank' }));
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

  // Test the email against the regex
  const isValid = emailRegex.test(email);

  // Execute the callback function with the validation result
  callback && callback(isValid, isValid
    ? intl.formatMessage({ id: 'EMAIL_VALID', defaultMessage: 'Email is valid.' })
    : intl.formatMessage({ id: 'EMAIL_INVALID_FORMAT', defaultMessage: 'Invalid email format.' })
  );
  return isValid;
};

/**
 * Validates a password against security criteria and executes a callback with the result.
 *
 * @param {string} password - The password to validate.
 * @param {function} callback - The callback function to execute with the validation result and message.
 */
export const validatePassword = (password, intl, callback) => {
  if (!password.trim()) {
    callback && callback(false, intl.formatMessage({ id: 'PASSWORD_BLANK', defaultMessage: 'Password cannot be blank.' }));
    return false;
  }

  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  let isValid = true;
  let message = intl.formatMessage({ id: 'PASSWORD_VALID', defaultMessage: 'Password is valid.' });

  if (password.length < minLength) {
    isValid = false;
    message = intl.formatMessage({ id: 'PASSWORD_MIN_LENGTH_LONG', defaultMessage: 'Password must be at least {minLength} characters long.' }, { minLength });
  } else if (!hasUppercase) {
    isValid = false;
    message = intl.formatMessage({ id: 'PASSWORD_UPPERCASE_LETTER', defaultMessage: 'Password must contain at least one uppercase letter.' });
  } else if (!hasLowercase) {
    isValid = false;
    message = intl.formatMessage({ id: 'PASSWORD_LOWERCASE_LETTER', defaultMessage: 'Password must contain at least one lowercase letter.' });
  } else if (!hasNumber) {
    isValid = false;
    message = intl.formatMessage({ id: 'PASSWORD_NUMERIC_DIGIT', defaultMessage: 'Password must contain at least one numeric digit.' });
  } else if (!hasSpecialChar) {
    isValid = false;
    message = intl.formatMessage({ id: 'PASSWORD_SPECIAL_CHARACTER', defaultMessage: 'Password must contain at least one special character.' });
  }

  callback && callback(isValid, message);
  return isValid;
};

/**
 * Validates a name to ensure it contains only alphabetic characters.
 *
 * @param {string} name - The name to validate.
 * @param {function} callback - The callback function to execute with the validation result and message.
 */
export const validateName = (name, intl, callback) => {
  if (!name.trim()) {
    callback && callback(false, intl.formatMessage({ id: 'FULL_NAME_BLANK', defaultMessage: 'Full Name cannot be blank!' }));
    return false;
  }

  const nameRegex = /^[a-zA-Z\s]+$/;

  const isValid = nameRegex.test(name);
  const message = isValid
    ? intl.formatMessage({ id: 'NAME_VALID', defaultMessage: 'Name is valid.' })
    : intl.formatMessage({ id: 'ONLY_ALPHABETIC', defaultMessage: 'Name must contain only alphabetic characters.' });

  callback && callback(isValid, message);
  return isValid;
};
