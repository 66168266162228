import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FileDrop } from 'react-file-drop';
import { Modal, Checkbox } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';

import Icon from '../../atoms/global/Icon';
import socket from '../../../../js/socket';
import TextAreaFileHolder from '../../organisms/TextAreaFileHolder';

import '../../../../assets/style/chatTextArea.css';
import TextEditor from '../editor/TextEditor';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatDrawerTextArea = (props) => {
  const intl = useIntl();
  const params = useParams();

  const [comment, setComment] = useState('');
  const [file, setFile] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheck, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isDragNdropVisible, setIsDragNdropVisible] = useState(false);
  const [isPasting, setIsPasting] = useState(false);

  const chats = useSelector((state) => state.chatReducer.chats);
  const singleMessage = useSelector(
    (state) => state.messageReducer.singleMessage
  );
  const { users } = useSelector((state) => state.userReducer);

  const mentionOptions = users.map(user => ({
    id: user._id,
    value: user.fullName,
    icon: user.avi
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEmoji = (emojiData) => {
    let sym = emojiData.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setSelectedEmoji(emojiData.unified);
    setComment(comment + emoji);
  };

  const onFileDrop = async (files) => {
    setIsDragNdropVisible(false);
    try {
      changeHandler(files, true)
    } catch (error) {
      console.error(error);
    }
  };

  // Attachments
  const changeHandler = async (e, isDragDrop = false) => {
    try {
      let fileList = [];
      let getFile = isDragDrop ? e : e.target.files;

      for (const file of getFile) {
        setIsLoading(true);
        const fileId = await props.setImageUrl(file);
        setIsLoading(false);
        if(fileId) {
          fileList.push({
            fileId,
            file
          });
        }
      }

      setFile(fileList);
      props?.setChatAreaHeight(262);
    } catch (error) {
      console.error(error);
    }
    document.getElementById('file').remove();
  };

  const handlePaste = async (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
      setComment(comment + pastedText);
    }
    const pastedItems = e?.clipboardData?.items || [];
    const imageOrFiles = [];

    for (let item of pastedItems) {
      const isImageOrFile = item?.type?.indexOf("image") || item?.kind === "file";
      if (isImageOrFile && item.getAsFile()) {
        imageOrFiles.push(item.getAsFile())
      }
    }
    imageOrFiles?.length > 0 && changeHandler({
      target: {
        files: imageOrFiles
      }
    })
  };


  const handleSubmit = (textarea) => {
    const chatId = params.chatId || params.projectid;
    let messageDescription = comment?.trim();
    const extraP = messageDescription.slice(-11);
    const extraBlock = messageDescription.slice(-29);
    if (extraP == '<p><br></p>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 11);
    } 
    if (extraBlock == '<blockquote><br></blockquote>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 29);
    }

    if (messageDescription || file.length) {
      const fileIds = file.map(fileObj => ({ _id: fileObj.fileId }));
      if (isCheck) {
        const mainMsg = {
          sender: { _id: localStorage.getItem('Id') },
          messageDescription,
          roomId: chatId,
          files: fileIds,
          attachments: [],
          members: chats
            .filter((chat) => chat._id === chatId)
            .map((member) => member._id),
          // members: chats.filter((chat) => chat._id === params.chatId)[0].members,
        };
        socket.emit('chat', mainMsg);
      }

      const msg = {
        sender: { _id: localStorage.getItem('Id') },
        repliedMsg: comment,
        messageId: singleMessage._id,
        attachments: [],
        files: fileIds
      };
      socket.emit('update-chatReply', msg);
    }
    setComment('');
    setFile([]);
    props?.scrollToBottom();
  };

  const handleKeyUp = (e) => {
    if (e?.target?.value?.trim() === '') {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setComment('');
      handleSubmit(e.target);
      return;
    }
  };

  const deleteFile = (fileId) => {
    props.delAttachment(fileId);
    const updatedFiles = file.filter((f) => f?.fileId !== fileId);
    setFile(updatedFiles);
  }

  return (
    <div className='chat-area'>
      <div
        style={{
          width: '100%',
          height: '100%',
          color: 'black',
          borderRadius: '5px',
          position: 'absolute',
          backgroundColor: 'none',
          left: 0,
          top: 0
        }}
        className={`fileDropDiv ${isDragNdropVisible ? '' : 'd-none'}`}
      >

        <FileDrop
          frame={document}
          onDrop={onFileDrop}
          onFrameDragEnter={() => {
            setIsDragNdropVisible(true);
          }}
          onFrameDragLeave={() => {
            setIsDragNdropVisible(false);
          }}
          onFrameDrop={() => {
            setIsDragNdropVisible(false);
          }}
        >
          <FormattedMessage id="ATTACH_FILE_TO_REPLY" defaultMessage="Attach file to reply" />          
        </FileDrop>

      </div>
      <TextEditor
        placeholder={intl.formatMessage({ id: 'ADD_TO_REPLIES', defaultMessage: 'Add to replies...' })}
        onChange={(content) => {
          setComment(content)
        }}
        onKeyUp={(e) => handleKeyUp(e)}
        comment={comment}
        mentionOptions={mentionOptions}
      />
      <TextAreaFileHolder fileImages={file} delAttachment={deleteFile} isLoading={isLoading} />
      <div className='d-flex justify-content-between align-center'>
        <div className='d-flex align-center'>
          <div className='d-flex align-center'>
            <div style={{ color: '#747474', marginRight: 10 }}>
              <Checkbox onChange={(e) => setIsChecked(e.target.checked)} />
            </div>
            <div className='font-14 truncate' style={{ width: 200 }}>
              <FormattedMessage
                id="ALSO_SEND_TO_CHAT"
                defaultMessage="Also send to #{roomName}"
                values={{
                  roomName: props.roomName
                }}
              />
            </div>
          </div>
          <Icon
            name='blueAdd.svg'
            height='19px'
            pr='13px'
            onClick={() => {
              let input = document.createElement('INPUT');
              input.setAttribute('type', 'file');
              input.setAttribute('id', 'file');
              input.style.display = 'none';
              document.body.appendChild(input);
              input.click();
              input.addEventListener('change', changeHandler);
            }}
          />
          <div className='small-vertical-line' />
          <Icon name='smiley.svg' onClick={showModal} height='13px' pr='13px' />
          <Modal
            className='chatDrawerEmojiModal'
            title='Basic Modal'
            style={{ marginRight: `${props.emojiPosition + -500}px` }}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <EmojiPicker onEmojiClick={handleEmoji} autoFocusSearch={false} />
          </Modal>
          <Icon name='at-the-rate.svg' height='13px' pr='13px' />
          <Icon name='Aa.svg' height='12px' />
        </div>
        <div onClick={handleSubmit}>
          <Icon name='send.svg' height='13px' />
        </div>
      </div>
    </div>
  );
};

export default ChatDrawerTextArea;
