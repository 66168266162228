import {
  isChatOpen,
  isTicketOpen,
} from './toggleAction';

export const openInvitePanel = (open, data) => {
  return async (dispatch) => {
    dispatch({
      type: "setInviteData",
      payload: data,
    });

    dispatch(isChatOpen(false));
    dispatch(isTicketOpen(false));

    dispatch({
      type: data?.type == 'Workspace' ? 'WORKSPACE' : 'TEAMS',
      payload: open,
    });
  };
};