import React from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DesktopOutlined,
  FileOutlined,
  TeamOutlined,
  PayCircleOutlined,
  ProjectOutlined
} from '@ant-design/icons';

import { getUserLicense } from '../../js/functions';

import DashboardActivity from '../components/dashboard/Activity';
import EmptyState from '../../components/UI/atoms/empty-state/EmptyState';
import { DashboardChart } from '../../components/UI/molecules/workspace/DashboardChart';
import DashboardPriorityChart from '../components/dashboard/PriorityChart';
import DashboardOnboarding from '../components/dashboard/Onboarding';

import { openInvitePanel } from '../../actions';
import { isJoinProjectsOpen } from '../../actions/toggleAction';
import WorkspaceInvite from '../components/invites/WorkspaceInvite';

const Dashboard = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    dashboardTasks,
    notifications,
    user,
    currentWorkspace,
    pendingInvites,
    myProjects
  } = props;

  const completedTasks = dashboardTasks?.completedTasks || 0;
  const unassignedTasks = dashboardTasks?.unassignedTasks || 0;
  const taskCount = dashboardTasks?.totalTasks || 0;

  const data = {
    labels: [
      intl.formatMessage({ id: 'INCOMPLETE', defaultMessage: 'Incomplete' }),
      intl.formatMessage({ id: 'COMPLETED', defaultMessage: 'Completed' }),
      intl.formatMessage({ id: 'UNASSIGNED', defaultMessage: 'Unassigned' })
    ],
    datasets: [
      {
        data: [Math.max(taskCount - completedTasks, 0), completedTasks, unassignedTasks],
        backgroundColor: ['#FF974E', '#01A894', '#FF4C4C'],
        hoverBackgroundColor: ['#FF974E', '#01A894', '#FF4C4C'],
      },
    ],
  };

  const Card = ({ label, onPress, icon, color = 'lightblue' }) => {
    const intl = useIntl();
    return (
      <div
        onClick={onPress}
        className={`cursor-pointer border-[rgba(4,114,204,0.3)] border-2 shadow-indigo-50 hover:border-[rgba(4,114,204,0.5)] bg-[rgba(255,255,255,0.6)] m-2 px-2 py-4 shadow-sm rounded-xl flex flex-col align-center justify-center`}>
        <div
          style={{ backgroundColor: color }}
          className={`xl:h-12 xl:w-12 w-8 h-12 rounded-lg lg:flex items-center justify-center hidden`}>
          {icon}
        </div>
        <div className={`xl:mt-4 mt-2`}>
          <div className={` font-normal ${false ? 'text-sm' : 'text-sm lg:text-xs xl:text-md 2xl:text-lg whitespace-nowrap truncate'}`}>
            {label}
          </div>
        </div>
      </div>
    )
  }

  const license = getUserLicense(user, sessionStorage.getItem("organization") || localStorage.getItem("organization"));
  const toggleState = useSelector((state) => state.toggleReducer);

  const {
    isChatOpen,
    isTicketOpen,
  } = toggleState;

  const isPanelOpen = isTicketOpen || isChatOpen;
  const showOnboarding = (taskCount + unassignedTasks + completedTasks == 0) || myProjects?.length == 0;
  const hasInvites = pendingInvites?.length > 0;

  return (
    <div className={`grid ${!isPanelOpen ? 'xl:grid-cols-3' : 'xl:grid-cols-2'} xl:grid-rows-2 lg:grid-cols-2 lg:grid-rows-3 h-[85vh] m-10`}>
      <div className='border-2 m-4 col-span-2'>
        {showOnboarding ? <DashboardOnboarding currentWorkspace={currentWorkspace} /> : <DashboardPriorityChart currentWorkspace={currentWorkspace} />}
      </div>
      {!isPanelOpen && <div className='border-2 m-4 flex align-center justify-center row-span-2'>
        <div className='h-full w-full overflow-scroll'>
          {/* {hasInvites && (
            <>
              {pendingInvites?.map(invite => <WorkspaceInvite invite={invite} />)}
            </>
          )} */}
          {notifications?.notifications?.map((notification) => <DashboardActivity notification={notification} />)}
          {!hasInvites && notifications?.notifications?.length == 0 && <EmptyState
              header={intl.formatMessage({ id: 'YOU_ARE_ALL_CAUGHT_UP', defaultMessage: `You're All Caught Up!` })}
              description={intl.formatMessage({ id: 'SIT_AND_RELAX', defaultMessage: 'Sit back and relax, nothing to see here.' })}
            src='/images/icons/no-mail.svg' />}
        </div>
      </div>}
      <div className='border-2 m-4 p-5 grid grid-cols-2 grid-rows-2 '>
        <Card
          label={intl.formatMessage({ id: 'JOIN_PROJECTS', defaultMessage: 'Join Projects' })}
          icon={<DesktopOutlined />}
          onPress={() => {
            dispatch(isJoinProjectsOpen(true));
          }}
        />
        <Card
          label={intl.formatMessage({ id: 'LEAVE_FEEDBACK', defaultMessage: 'Leave Feedback' })}
          icon={<FileOutlined />}
          onPress={() => {
            const url = 'https://workflo.canny.io/bugs-and-feature-requests'
            window.open(url, '_blank').focus();
          }}
        />
        <Card
          label={`${intl.formatMessage({ id: `${license}`.replaceAll(' ', '_').toUpperCase(), defaultMessage: license }) || intl.formatMessage({ id: 'VIEW_PLAN', defaultMessage: 'View Plan' })}`}
          icon={<PayCircleOutlined />}
          onPress={() => {
            navigate('/pricing');
          }}
        />
        <Card
          label={intl.formatMessage({ id: 'ADD_MEMBER', defaultMessage: 'Add Member' })}
          icon={<TeamOutlined />}
          onPress={() => {
            dispatch(openInvitePanel(true, {
              pastInvites: currentWorkspace?.pendingInvites || [],
              existingMembers: currentWorkspace?.members || [],
              selectWorkspaceMembers: false,
              type: 'Workspace'
            }));
          }}
        />
      </div>
      <div className='border-2 m-4'>
        <div>
          <div className='mb-10 h-10 p-2'>
            <div className='flex w-28 align-center cursor-pointer bg-gray-200 hover:bg-gray-100 p-1 pr-3 pl-2 rounded-md' onClick={() => navigate('/all-tasks')} >
              <ProjectOutlined className='mr-2 size-4' />
              <div className='font-14'><FormattedMessage id="VIEW_TASKS"  defaultMessage="View Tasks" /></div>
            </div>
          </div>
          <DashboardChart taskCount={taskCount} data={data} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardTasks: state.taskReducer.dashboardTasks,
  user: state.userReducer.user,
  notifications: state.notificationReducer.notifications,
  currentWorkspace: state.workspaceReducer.currentWorkspace,
  allProjects: state.workspaceReducer.workspaceProjects,
  myProjects: state.projectReducer.myProjects,
  userTasks: state.taskReducer.userTasks,
  chats: state.chatReducer.chats,
  pendingInvites: state.workspaceReducer.pendingWorkspaceInvites,
});

export default connect(mapStateToProps)(Dashboard);
