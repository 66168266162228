import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Radio,
  Dropdown,
  notification,
  DatePicker,
  Tooltip
} from 'antd';

import dayjs from "dayjs";

import { editTask } from '../../../../actions';
import Icon from '../../atoms/global/Icon';
import { getDateColor } from '../../../../js/functions';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalizedShortDate } from '../../../../_locale/dateLocales';

const CalendarDropdown = (props) => {
  const { task, isLarge, onClick, showIcon = true, isTask = false } = props;
  const intl = useIntl();
  const options = ['day', 'week', 'month', 'year'];

  const [calendarDate, setCalendarDate] = useState();

  const isDateSelected = calendarDate !== null && calendarDate;
  const isRecurring = task?.recurringConfig?.recurring;
  const recurringDuration = task?.recurringConfig?.recurringDuration;

  const [showRecurringDate, setShowRecurringDate] = useState(isRecurring || false);
  const [frequency, setFrequency] = useState(recurringDuration?.unit || 'day');
  const [duration, setDuration] = useState(recurringDuration?.duration || 1);
  const [iteration, setIteration] = useState(0);
  const [isTriggerComplete, setTriggerComplete] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (task?.dueDate) {
      const formattedDate = dayjs(task?.dueDate).format("YYYY-MM-DD");
      setCalendarDate(dayjs(formattedDate, 'YYYY-MM-DD'));
    } else {
      setCalendarDate(null);
    }
  }, [task?.dueDate]);

  const onChange = (date, dateString) => {
    const dueDate = dayjs(date, 'YYYY-MM-DD');

    if (onClick) {
      onClick(date);
      return;
    }

    setCalendarDate(dueDate);

    dispatch(
      editTask({
        _id: task?._id,
        dueDate: dueDate,
        recurringConfig: {
          recurring: showRecurringDate || false
        }
      })
    );

  };

  const getDurationItems = () => {
    let items = [];
    for (let i = 1; i <= 7; i++) {
      items.push({
        key: i,
        label: i,
        onClick: () => setDuration(i)
      })
    }
    return items;
  }

  const handleRecurringTask = async () => {
    const sections = task?.projects.map(prj => ({
      project: prj?.project?._id || '',
      section: prj?.section?._id || '',
    }));

    isDateSelected && await dispatch(
      editTask({
        _id: task._id,
        dueDate: calendarDate,
        recurringConfig: {
          recurring: true,
          recurringDuration: {
            duration: duration, // any numeric value
            unit: frequency, //allowed values are day, week, year
            iteration: iteration
          },
          trigger: isTriggerComplete ? 'done' : 'auto',
          projectSections: sections, // This array stores the destination section for recurring tasks. It is an array because the task may be in multiple projects.
        }
      }, null, () => {
        notification.success({
          message: intl.formatMessage({ id: 'RECURRING_TASK_SET_NOTIFICATION', defaultMessage: 'Task set to recurring!' }),
          placement: 'bottomRight',
        });
      })
    );
  };


  const calendarIcon = () => {
    const title = isRecurring
      ? intl.formatMessage({ id: 'RECURRING', defaultMessage: 'Recurring' })
      : intl.formatMessage({ id: 'DATE', defaultMessage: 'Date' });
    return (
      <div>
        <Tooltip title={title}>
          {isRecurring ? <img
            src={`/images/icons/Refresh.svg`}
            alt=''
            style={{
              height: '16px',
              width: '16px',
            }}
          /> : <svg className={`w-3.5 h-3.5 shrink-0 fill-gray-300`} viewBox="0 0 16 16">
            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" fill={getDateColor(calendarDate)} />
          </svg>}
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      <DatePicker
        size={isLarge ? 'middle' : 'small'}
        placeholder={intl.formatMessage({ id: 'SET_DATE', defaultMessage: 'Set Date' })}
        onChange={onChange}
        style={{
          borderColor: getDateColor(calendarDate),
          color: showIcon ? getDateColor(calendarDate) : '#002337',
          border: (!isLarge || !showIcon) ? 'none' : '',
          width: isTask ? '95%' : 'fit-content'
        }}
        format={(date) => getLocalizedShortDate(date, intl)}
        suffixIcon={showIcon ? calendarIcon() : null}
        value={calendarDate}
        allowClear={false}
        panelRender={(Nodeee => {

          if (!showRecurringDate) {
            return Nodeee;
          }

          return (
            <div className='w-full p-3 flex flex-col justify-between'>
              <div className='flex align-center'>
                <Icon
                  name='close.svg'
                  height='12px'
                  onClick={() => {
                    setShowRecurringDate(false);
                  }}
                />
                <div className='font-14 text-slate-400 ml-4'>
                  {isRecurring
                    ? <FormattedMessage id="EDIT_CAPITALIZED" defaultMessage="EDIT" />
                    : ''
                  } <FormattedMessage id="RECURRING_TASK" defaultMessage="RECURRING TASK" /></div>
              </div>
              <div className='mt-4'>
                <div className='font-14 mb-2'><FormattedMessage id="REPEAT_EVERY" defaultMessage="Repeat every" /></div>
                <div className='flex align-center'>
                  {frequency !== 'year' && <Dropdown
                    className='mr-2'
                    overlayStyle={{ zIndex: 10000 }}
                    trigger={['click']}
                    menu={{
                      items: getDurationItems()
                    }}
                    placement='bottom'
                    arrow
                  >
                    <div className='flex align-center justify-center border border-slate-200 p-1 px-4'>
                      <div className='font-14'>{duration}</div>
                      <img
                        src={'/images/icons/show-more.svg'}
                        style={{ margin: "10px" }}
                        alt="icon"
                      />
                    </div>
                  </Dropdown>}
                  <Dropdown
                    overlayStyle={{ zIndex: 10000 }}
                    trigger={['click']}
                    menu={{
                      items: options?.map(option => ({
                        key: option,
                        label: option,
                        onClick: () => setFrequency(option)
                      })),
                    }}
                    placement='bottom'
                    arrow
                  >
                    <div className='flex align-center justify-center border border-slate-200 p-1 px-5'>
                      <div className='font-14'>{frequency}{duration > 1 && 's'}</div>
                      <img
                        src={'/images/icons/show-more.svg'}
                        style={{ margin: "10px" }}
                        alt="icon"
                      />
                    </div>
                  </Dropdown>
                </div>
              </div>
              <div className='flex flex-col mt-4 font-16'>
                <div className='font-14 mb-2'><FormattedMessage id="CREATE_NEW_TASKS" defaultMessage="Create new tasks" /></div>
                <Radio className='mb-2' checked={isTriggerComplete} onChange={() => setTriggerComplete(true)}><FormattedMessage id="UPON_COMPLETION" defaultMessage="Upon Completion" /></Radio>
                <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}><FormattedMessage id="NOW" defaultMessage="Now" /></Radio>
              </div>

              <div className='my-5 flex flex-col justify-center'>
                <div className='flex align-center mb-2'>
                  <div className='font-14 mr-5'><FormattedMessage id="ENDS" defaultMessage="Ends" /></div>
                </div>
                <Radio checked={isTriggerComplete} onChange={() => setTriggerComplete(true)}><FormattedMessage id="NEVER" defaultMessage="Never" /></Radio>
                {/* <div className='flex align-center justify-between'>
                <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}>On</Radio>
                <DatePicker />
              </div> */}
                <div className='flex align-center justify-between'>
                  <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}><FormattedMessage id="AFTER" defaultMessage="After" /></Radio>
                  <div className='ml-2 flex align-center'>
                    <input
                      max={99}
                      className='h-8 w-12 px-1 border-slate-400 border'
                      type='number'
                      value={iteration}
                      onChange={(e) => setIteration(e?.target?.value)} />
                    <div className='font-14 ml-5'><FormattedMessage id="OCCURANCES" defaultMessage="Occurances" /></div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col align-center justify-around'>
                <div
                  onClick={() => setShowRecurringDate(false)}
                  className='cursor-pointer hover:text-blue-500 p-2 border-t-2 w-full text-center border-slate-100'>
                  {isRecurring
                    ? <FormattedMessage id="SET_DATE" defaultMessage="Set Date" />
                    : <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
                  }
                </div>
                <div
                  onClick={() => {
                    setShowRecurringDate(false);
                    handleRecurringTask();
                  }}
                  className='cursor-pointer hover:text-blue-500 p-2 border-t-2 w-full text-center border-slate-100'>
                  {isRecurring
                    ? <FormattedMessage id="SET_DATE" defaultMessage="Update" />
                    : <FormattedMessage id="SAVE" defaultMessage="Save" />
                  }
                </div>
              </div>
            </div>
          );
        })}
        // renderExtraFooter={() => (
        //   <div
        //     onClick={() => setShowRecurringDate(!showRecurringDate)}
        //     className='text-center cursor-pointer hover:text-blue-500'>
        //     {isRecurring ? 'View' : 'Make'} Recurring
        //   </div>
        // )}
      />
    </>
  );
};

export default CalendarDropdown;
