import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import Icon from '../atoms/global/Icon';
import ProductNavBar from '../molecules/upper-navbar/ProductNavBar';
import SelectWorkspaceCard from '../molecules/select-workspace/SelectWorkspaceCard';

import { logout } from '../../../actions/logoutAction';
import { useAuth0 } from '@auth0/auth0-react';
import {
  acceptWorkspaceInvite,
  declineWorkspaceInvite,
  switchWorkspace,
  getWorkspaces,
  getWorkspaceInvites,
  clearUnauthorized,
} from '../../../actions';
import '../../../assets/style/global.css';
import '../../../assets/style/workspace.css';
import { FormattedMessage, useIntl } from 'react-intl';

const SelectWorkspace = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = props;

  const { logout: auth0Logout } = useAuth0();

  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState(props.workspaces);
  const [pendingInvites, setPendingInvites] = useState(props.pendingInvites);

  useEffect(() => {
    dispatch(getWorkspaces('lean'));
    dispatch(getWorkspaceInvites());
    dispatch(clearUnauthorized());
  }, []);

  useEffect(() => {
    setPendingInvites(props.pendingInvites);
    setWorkspaces(props.workspaces);
  }, [props.pendingInvites, props.workspaces]);

  const handleSelectedWorkspace = async (workspace) => {
    setSelectedWorkspace(workspace);
    setWorkspace(workspace);
  };

  const setWorkspace = async (workspace) => {
    if (workspace) {
      sessionStorage.setItem(
        'workspaceId',
        workspace.targetId || workspace._id
      );
      localStorage.setItem(
        'workspaceId',
        workspace.targetId || workspace._id
      );
      sessionStorage.setItem(
        'organization',
        workspace?.organization?._id || workspace?.organization
      );
      localStorage.setItem(
        'organization',
        workspace?.organization?._id || workspace?.organization
      );

      await dispatch(
        switchWorkspace(workspace, navigate('/', { replace: true }))
      );
    }
  };

  const handleAcceptWorkspace = async (workspace) => {
    await dispatch(
      acceptWorkspaceInvite(workspace._id, () => {
        setWorkspaces([...workspaces, workspace]);
        dispatch(switchWorkspace(workspace, navigate('/', { replace: true })));
      })
    );
  };

  const handleDeclineWorkspace = async (workspace) => {
    await dispatch(
      declineWorkspaceInvite(workspace._id, () => {
        setWorkspaces([...workspaces, workspace]);
      })
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('workspaceId');
    localStorage.removeItem('organization');
    localStorage.removeItem('toggle');
    localStorage.removeItem('inviteUrl');
    localStorage.removeItem('Id');

    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('organization');

    dispatch(logout());
    auth0Logout({
      logoutParams: { returnTo: `${window.location.origin}/signin` },
    });
    window.location.href = '/signin';
  };

  const noData = pendingInvites?.length === 0 && workspaces?.length === 0;

  return (
    <div className="flex align-center justify-between h-[100vh] overflow-hidden">
      <div className="w-2/5 h-full p-5">
        <Icon name="logo.svg" height="26px" pr="20px" />
        <div className="flex flex-col align-center justify-center mt-24">
          <div className="select-workspace-container">
            <div className="select-workspace-title">
              <FormattedMessage
                id="WELCOME_BACK"
                defaultMessage={`👋 Welcome ${user?.fullName || 'back'}! Wishing you a productive day ahead!`}
                values={{
                  fullName: user?.fullName || intl.formatMessage({ id: 'BACK', defaultMessage: 'back' })
                }}
              />
              
            </div>
            {noData ? (
              <div className="font-16 text-indigo-600 text-center mb-4">
                <FormattedMessage id="NO_WORKSPACES_BUT_YOU_CAN_CREATE" defaultMessage="You have no workspaces, but you can create one!" />
              </div>
            ) : (
              <div style={{ color: '#434343', padding: 20 }}>
                <FormattedMessage id="WORKSPACES_FOR" defaultMessage="Workspaces for" />{' '}
                <span className="text-blue-primary ">{user.email || ''}</span>
              </div>
            )}

            {!noData && (
              <div className="select-workspace">
                {pendingInvites?.length > 0 && (
                  <div className="w-full my-4">
                    <div className="pl-4 mb-1 font-bold">
                      <FormattedMessage
                        id="WORKSPACE_INVITES"
                        defaultMessage="Workspace Invites"
                      />
                    </div>
                    <SelectWorkspaceCard
                      email={user.email || ''}
                      workspaces={pendingInvites}
                      handleSelectedWorkspace={handleSelectedWorkspace}
                      handleAcceptWorkspace={handleAcceptWorkspace}
                      handleDeclineWorkspace={handleDeclineWorkspace}
                      isInvites
                    />
                  </div>
                )}
                <SelectWorkspaceCard
                  workspaces={workspaces}
                  handleSelectedWorkspace={handleSelectedWorkspace}
                />
              </div>
            )}
            <button
              onClick={() => {
                if (true) {
                  navigate('/create-workspace');
                }
              }}
              className={`butn-primary font-14 workspace-open-button`}
            >
              <FormattedMessage id="CREATE_WORKSPACE" defaultMessage="Create Workspace" />
            </button>
            <div
              className="single-workspace-card"
              onClick={() => handleLogout()}
            >
              <img src="images/icons/noWorkspace.svg" width={100} height={70} />
              <div
                className="d-flex align-center"
                style={{ justifyContent: 'space-between', width: '100%' }}
              >
                <div style={{ paddingLeft: 20 }}>
                  <FormattedMessage
                    id="NOT_SEEING_WORKSPACE"
                    defaultMessage="Not seeing your workspace?"
                  />
                </div>
                <span
                  className="text-blue-primary"
                  style={{ cursor: 'pointer', paddingRight: 20 }}
                >
                  <FormattedMessage
                    id="TRY_DIFFERENT_EMAIL"
                    defaultMessage="Try a different email"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/5 bg-slate-200 h-[97vh] mx-5 rounded-xl flex align-center justify-center">
        <div className="flex flex-col align-center justify-center">
          <div className="mb-20 text-4xl font-bold text-blue-500 text-center mx-10">
            <FormattedMessage
              id="WORKFLO_ADDVERTISEMENT_PHRASE_2"
              defaultMessage="Productivity Tailored To You"
            />
          </div>
          <img
            src="https://939023.p3cdn1.secureserver.net/wp-content/uploads/2024/12/Group-5141856-1.png"
            width={'95%'}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    workspaces: state.workspaceReducer.getWorkspaces,
    pendingInvites: state.workspaceReducer.pendingWorkspaceInvites,
  };
}

export default connect(mapStateToProps)(SelectWorkspace);
