import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import {
  signIn,
  getWorkspaces,
  getWorkspaceInvites,
  getUser,
} from '../../../actions';
import { clearUnauthorized } from '../../../actions/userAction';
import { AuthInput } from '../../../src-new/components/inputs/AuthInput';
import { FormattedMessage, useIntl } from 'react-intl';

const SigninForm = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const [check, setCheck] = useState(false);
  const [checkMail, setCheckMail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [passError, setPassError] = useState(false);

  const [isVerifiedError, setIsVerifiedError] = useState(false);

  useEffect(() => {
    navigate('/signin');
  }, [props.verificationRes, navigate]);

  const handlePass = () => {
    setCheck((check) => !check);
  };

  const emailHandler = (e) => {
    // let regex = new RegExp(
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
    // );
    setCheckMail(false);
    setIsVerifiedError(false);
    const mail = e.target.value;
    setEmailAddress(mail);
    // regex.test(mail) ? setCheckMail(false) : setCheckMail(true);
    if (!mail.trim()) {
      setCheckMail(true);
    } else {
      setCheckMail(false);
    }
  };

  const passwordHandler = (e) => {
    // let regex = new RegExp(
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
    // );
    const passWord = e.target.value;
    setPassword(passWord);
    setPassError(false);
    setCheckPass(false);
    // regex.test(passWord) ? setCheckPass(false) : setCheckPass(true);

    if (!passWord.trim()) {
      setCheckPass(true);
    } else {
      setCheckPass(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const isEmailEmpty = !emailAddress;
    const isPasswordEmpty = !password;

    setCheckMail(isEmailEmpty);
    setCheckPass(isPasswordEmpty);

    if (isEmailEmpty || isPasswordEmpty) {
      return;
    }
    const user_id = localStorage.getItem('Id');
    const fetchdata = await {
      email: emailAddress,
      password: password,
    };
    dispatch(
      signIn(
        fetchdata,
        async () => {
          await dispatch(getWorkspaces('SigninForm'));
          await dispatch(getWorkspaceInvites());
          await dispatch(getUser(user_id));
          dispatch(clearUnauthorized());
          navigate('/select-workspace', { replace: true });
          setCheckMail(false);
          setPassError(false);
        },
        //add call back
        () => {
          setCheckMail(true);
          setIsVerifiedError(true);
        },
        (data) => {
          if (data.response.status === 404) {
            emailAddress ? setCheckMail(true) : setCheckMail(false);
          } else if (data.response.status === 400) {
            password ? setCheckPass(true) : setCheckPass(false);
            setPassError(true);
          }
        }
      )
    );
  };

  return (
    <div className="w-full">
      <form onSubmit={submitHandler}>
        <AuthInput
          label={intl.formatMessage({ id: 'EMAIL', defaultMessage: 'Email' })}
          name="email"
          value={emailAddress}
          onChange={emailHandler}
          placeholder="email@workflo.com"
          error={
            checkMail && !emailAddress
              ? intl.formatMessage({ id: 'EMAIL_REQUIRED', defaultMessage: 'Email is required!' })
              : isVerifiedError && checkMail
              ? intl.formatMessage({ id: 'USER_NOT_EXIST', defaultMessage: "This user doesn't exist. Please verify the email filled. Try another account or Sign up instead." })
              : checkMail && intl.formatMessage({ id: 'EMAIL_INVALID', defaultMessage: 'This email is invalid!' })
          }
        />
        <AuthInput
          label={intl.formatMessage({ id: 'PASSWORD', defaultMessage: 'Password' })}
          type={check ? 'text' : 'password'}
          name="password"
          value={password}
          onChange={passwordHandler}
          placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD', defaultMessage: 'Enter Password' })}
          togglePasswordVisibility={handlePass}
          error={
            checkPass && !password
              ? intl.formatMessage({ id: 'PASSWORD_REQUIRED', defaultMessage: 'Password is required!' })
              : checkPass && passError && intl.formatMessage({ id: 'PASSWORD_INVALID', defaultMessage: 'This password is invalid!' })
          }
          isPassword={true}
          passwordVisible={check}
        />
        <div className="d-flex mt-6 align-center justify-between">
          <span>
            <label className="checkBox">
              <input className="check-mark" type="checkbox" id="check" />
              <span className="mark"></span>
            </label>
            <label htmlFor='check' className="font-weight-500 font-16 text-darkGray ml-3 cursor-pointer">
              <FormattedMessage id="REMEMBER_PASSWORD" defaultMessage="Remember password" />
            </label>
          </span>
          <span>
            <Link
              className="text-dark font-weight-500 font-16"
              to="/resetpassword"
            >
              <FormattedMessage id="FORGOTTEN_PASSWORD" defaultMessage="Forgotten password?" />
            </Link>
          </span>
        </div>
        <button
          type="submit"
          className="flex align-center justify-center button-tag font-18 font-weight-500 text-white"
        >
          <FormattedMessage id="SIGN_IN" defaultMessage="Sign in!" />
        </button>
        <br />
        <div className="d-flex font-weight-500 text-darkGray font-18">
          <label>
            <FormattedMessage id="DONT_HAVE_ACCOUNT" defaultMessage="Don't have an account?" />{' '}
            <Link className="text-blue-500" to="/signup">
              <FormattedMessage id="SIGN_UP" defaultMessage="Sign up" />
            </Link>
          </label>
        </div>
      </form>
    </div>
  );
};
const mapStateToProp = (state) => {
  return {
    verificationRes: state.userReducer.success,
  };
};
export default connect(mapStateToProp)(SigninForm);
